import React, { useState, useEffect } from "react";
// import {Carousel} from '3d-react-carousal';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import countryList from "react-select-country-list";
import { savecustomization } from "../../services/settings/CustomizationDesktop";
import ReactLoading from "react-loading";
import { getuniqueid } from "services/kyc/KycScreens";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";

// importing images
import kycNext from "../../assets/img/kycNext.png";
import backGrey from "../../assets/img/kyc/backGrey.png";
import unionGrey from "../../assets/img/kyc/unionGrey.png";
import footerLogo from "../../assets/img/footerLogo.png";

// screen 1 data
import screen1Main from "../../assets/img/kyc/screen1Main.png";

// screen 2 data
import langGlobe from "../../assets/img/kyc/langGlobe.png";
import downArrow from "../../assets/img/kyc/downArrow.png";

// screen 3 data
import Select from "react-select";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";

// screen 4 data
import scre4Icon from "../../assets/img/kyc/scre4Icon.png";

// screen 5 data
import Webcam from "react-webcam";
import warning from "../../assets/img/kyc/warning.png";
import enter from "../../assets/img/kyc/enter.png";
import videoFrame from "../../assets/img/kyc/videoFrame.png";

// screen 10 data
import passport from "../../assets/img/kyc/passport.png";
import nationalID from "../../assets/img/kyc/nationalID.png";
import utilityBill from "../../assets/img/kyc/utilityBill.png";

// screen 12 data
import uploadCloud from "../../assets/img/kyc/upload-cloud.png";
import video from "../../assets/img/kyc/video.png";

// screen 15 data
import {
  RecordWebcam,
  useRecordWebcam,
  CAMERA_STATUS,
} from "react-record-webcam";
import videoFrameRect from "../../assets/img/kyc/videoFrameRect.png";

// screen 18 data
import documentFrame from "../../assets/img/kyc/documentFrame.png";
import faceFrame from "../../assets/img/kyc/faceFrame.png";

// screen 19 data
import location from "../../assets/img/kyc/location.png";

// screen 24 data
import black from " ../../assets/img/kycmobile/black.png";
import warning24 from " ../../assets/img/kycmobile/warning.png";
import loading from " ../../assets/img/kycmobile/loading.png";

// screen 26 data
import editbutton from "../../assets/img/kyc/editbutton.png";

// screen 27 data
import successIcon from "../../assets/img/kyc/successIcon.png";

// screen 28 data
import errorIcon from "../../assets/img/kyc/errorIcon.png";

type WebcamRenderProps = {
  status: string,
  isWebcamOn: boolean,
  isPreview: boolean,
  isRecording: boolean,
  openCamera: () => void,
  closeCamera: () => void,
  retake: () => void,
  start: () => void,
  stop: () => void,
  download: () => void,
};

export default function Desktop(props) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const buttonColorDesktop = props.buttonColorDesktop;
  const progressColorDesktop = props.progressColorDesktop;
  const activeFontFamilyDesktop = props.activeFontFamilyDesktop;
  const logoFileDesktop = props.logoFileDesktop;

  const [screen1Flag, setScreen1Flag] = useState(props.screen1FlagDesktop);
  const [screen2Flag, setScreen2Flag] = useState(props.screen2FlagDesktop);
  const [screen3Flag, setScreen3Flag] = useState(props.screen3FlagDesktop);
  const [screen4Flag, setScreen4Flag] = useState(props.screen4FlagDesktop);
  const [screen10Flag, setScreen10Flag] = useState(props.screen10FlagDesktop);
  const [screen11Flag, setScreen11Flag] = useState(props.screen11FlagDesktop);
  const [screen12Flag, setScreen12Flag] = useState(props.screen12FlagDesktop);
  const [screen19Flag, setScreen19Flag] = useState(props.screen19FlagDesktop);
  const [screen25Flag, setScreen25Flag] = useState(props.screen25FlagDesktop);
  const [screen26Flag, setScreen26Flag] = useState(props.screen26FlagDesktop);
  const [screen27Flag, setScreen27Flag] = useState(props.screen27FlagDesktop);
  const [screen28Flag, setScreen28Flag] = useState(props.screen28FlagDesktop);

  var form, checkBtn;
  const webcamRef = React.useRef(null);

  const [start, setStart] = useState(false);
  const [question, setQuestion] = useState("");
  const [status, setStatus] = useState("");

  const options = React.useMemo(() => countryList().getData(), []);
  const [value, setValue] = React.useState("");
  const [urlId, setUrlId] = React.useState(null);

  React.useEffect(() => {
    getuniqueid().then((response) => {
      if (response.data.success) {
        setUrlId(response.data.kyc_id);
      }
    }).catch((error) => {
      console.log("error: ", error);
    })
  }, [])

  const changeHandler = (v) => {
    setValue(v);
  };

  const MINUTE_MS = 140;

  const startProcess = () => {
    setStart(true);
    setQuestion("");
    setStatus("");
  };

  const handleUserMedia = React.useCallback(() => {
    const mediaStream = webcamRef.current.getScreenshot();
  }, [webcamRef]);

  const recordWebcam = useRecordWebcam();

  useEffect(() => {
    recordWebcam.open();
  }, []);

  const typeOfDoc = [
    { label: "Passport", value: 1 },
    { label: "Driving License", value: 2 },
    { label: "State ID", value: 3 },
  ];

  const optionsScreen2 = [
    { label: "English (United States)", value: 1 },
    { label: "English (UK)", value: 2 },
    { label: "French (France)", value: 3 },
    { label: "Arabic (Saudia Arabia)", value: 4 },
    { label: "German (Germany)", value: 5 },
    { label: "Urdu (Pakistan)", value: 6 },
  ];

  const optionsDocuments = [
    { label: "ID Card", value: 1 },
    { label: "Passport", value: 2 },
    { label: "Driving License", value: 3 },
  ];

  const optionsAddressVerifications = [
    { label: "Utility Bill", value: 1 },
    { label: "Bank Statement", value: 2 },
    { label: "Insurance Document", value: 3 },
    { label: "Resident Permit", value: 4 },
    { label: "Mortgage Statement", value: 5 },
    { label: "Tax Bill", value: 6 },
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  const next = () => {
    setCurrentSlide(currentSlide + 1);
  };

  const prev = () => {
    setCurrentSlide(currentSlide - 1);
  };

  const updateCurrentSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    let formdata = {
      buttonColor: buttonColorDesktop,
      progressColor: progressColorDesktop,
      activeFontFamily: activeFontFamilyDesktop,
      logoFile: logoFileDesktop,
      screen1: screen1Flag,
      screen2: screen2Flag,
      screen3: screen3Flag,
      screen4: screen4Flag,
      screen10: screen10Flag,
      screen11: screen11Flag,
      screen12: screen12Flag,
      screen19: screen19Flag,
      screen25: screen25Flag,
      screen26: screen26Flag,
      screen27: screen27Flag,
      screen28: screen28Flag
    };
    // console.log("formdata: ", formdata);
    savecustomization(formdata).then((response) => {
      setLoading(false);
      if (response.data.success) {
        window.location.reload();
      }
    }).catch((error) => {
      setLoading(false);
      console.log("error", error);
    });
  }

  useEffect(() => {
    if (start) {
      // const ws = new WebSocket('ws://ec2-13-40-135-129.eu-west-2.compute.amazonaws.com:7000/ws')
      const ws = new WebSocket("wss://ai.the-hedge.io:7000/ws");

      ws.addEventListener("open", () => {
        const interval = setInterval(() => {
          var atob = require("atob");
          const mediaStream = webcamRef.current.getScreenshot();
          if (mediaStream) {
            var len = mediaStream.length;
            var ab = new ArrayBuffer(len);
            var ia = new Uint8Array(ab);
            for (var i = 0; i < len; i++) {
              ia[i] = mediaStream.charCodeAt(i);
            }
            var bb = new Blob([ab]);

            var bytes = new Uint8Array(len);
            for (var i = 0; i < len; i++) {
              bytes[i] = mediaStream.charCodeAt(i);
            }
            ws.send(mediaStream);
          }
        }, MINUTE_MS);

        return () => clearInterval(interval);
      });
      ws.onmessage = (message) => {
        let data = JSON.parse(message.data);
        let datamain = JSON.parse(data);
        setQuestion(datamain.question);
        setStatus(datamain.status);
      };
      return () => {
        ws.close();
      };
    }
  }, [start]);

  const disableDates = () => {
    const today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (dd < 10) {
      dd = "0" + dd;
    }

    return yyyy + "-" + mm + "-" + dd;
  };

  return (
    <>
      <div className="mt-8  flex flex-col items-center justify-center KYC-DesktopComp " style={{width:"80%"}}>
        <div className="w-full flex flex-row items-center justify-center  desktopSettings">
          <div className="prevBtn">
            <button
              className="prevBtnKyc"
              style={{ minWidth: "40px", outline: "none" }}
              onClick={prev}
            >
              <img
                src={kycNext}
                alt="previous"
                style={{ transform: "rotate(-180deg)" }}
              />
            </button>
          </div>
          <main className="bg-white mx-4" style={{ maxWidth: "90%" }}>
            <section className="kyc">
              <div
                className="carouselScreen shadow-2xl rounded-2xl mt-16 bg-white"
                style={{ height: "40vw" }}
              >
                <Carousel
                  emulateTouch={true}
                  selectedItem={currentSlide}
                  onChange={updateCurrentSlide}
                  showArrows={false}
                  showIndicators={false}
                  showStatus={false}
                >
                  {/* screen1 */}
                  <div className={"screenOne " + (screen1Flag ? "" : "disabled")}>
                    {/* top bar with progress and back/close buttons */}
                    <div className="progressSection w-full flex flex-row justify-between p-16 pt-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img src={backGrey} alt="backArrow" className="" />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-4">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "7%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button onClick={() => setScreen1Flag(!screen1Flag)}>
                          <img src={unionGrey} alt="crossArrow" className="" style={{ transform: screen1Flag ? 'rotate(0deg)' : 'rotate(45deg)' }} />
                        </button>
                      </div>
                    </div>
                    {/* top bar with progress and back/close buttons */}

                    <div className="mainContent w-full flex flex-row items-center justify-between h-full pb-12">
                      <div className="imgArea lg:w-6/12 md:w-12/12 sm:w-12/12 px-12">
                        <img
                          src={screen1Main}
                          alt="welcome picture"
                          className="mx-auto"
                        />
                      </div>
                      <div className="textArea text-left relative lg:w-6/12 md:w-12/12 sm:w-12/12">
                        <div
                          className="row w-full flex flex-col py-6 "
                          style={{ paddingRight: "32px" }}
                        >
                          <h1 className="font-bold text-3xl">
                            Welcome! Let’s start your verfication
                          </h1>
                          <p className="font-normal text-lg mt-6 textLightGray">
                            You’ll need to answer a few questions and this process
                            only takes a few minutes
                          </p>

                          <Link onClick={next}>
                            <button
                              style={{ outline: "none" }}
                              className="mx-auto lg:w-7/12 md:w-12/12 pinkBg py-4 mt-6  rounded-lg color-white font-bold text-base"
                            >
                              Let’s go!
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* screen2 */}
                  <div className={"screenTwo " + (screen2Flag ? "" : "disabled")}>
                    <div className="progressSection w-full flex flex-row justify-between p-16 pt-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img src={backGrey} alt="backArrow" className="" />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-4">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "14%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button 
                          style={{ cursor: "unset" }}
                          // onClick={() => setScreen2Flag(!screen2Flag)}
                        >
                          <img src={unionGrey} alt="crossArrow" className="" style={{ transform: screen2Flag ? 'rotate(0deg)' : 'rotate(45deg)' }} />
                        </button>
                      </div>
                    </div>
                    {/* top bar with progress and back/close buttons */}

                    <div className="mainContent w-full flex flex-row h-full">
                      <div className="textArea lg:w-10/12 md:w-12/12 px-12 mx-auto">
                        <div className=" row w-full flex flex-col px-16 py-6 text-center">
                          <h1 className="font-bold text-3xl">
                            Select your language
                          </h1>

                          <div className="langSelect kycscreens py-10 mt-10">
                            <Select
                              className="globeSelect"
                              styles={customStyles}
                              options={optionsScreen2}
                            />
                          </div>

                          <div className="buttonAction lg:w-10/12 mx-auto">
                            <Link onClick={next}>
                              <button className="mx-auto lg:w-12/12 md:w-12/12 pinkBg py-4 my-4 rounded-lg color-white font-bold text-base">
                                Next
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* screen3 */}
                  <div className={"screen3 " + (screen3Flag ? "" : "disabled")}>
                    {/* top bar with progress and back/close buttons */}
                    <div className="progressSection w-full flex flex-row justify-between p-16 pt-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img src={backGrey} alt="backArrow" className="" />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-4">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "21%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button onClick={() => setScreen3Flag(!screen3Flag)}>
                          <img src={unionGrey} alt="crossArrow" className="" style={{ transform: screen3Flag ? 'rotate(0deg)' : 'rotate(45deg)' }} />
                        </button>
                      </div>
                    </div>
                    {/* top bar with progress and back/close buttons */}

                    <div
                      className="mainContent w-full flex flex-row h-full "
                      style={{ marginTop: "1%" }}
                    >
                      <div className="textArea lg:w-10/12 md:w-12/12 sm:w-12/12 px-12 mx-auto">
                        <div className="row w-full flex flex-col px-16 text-center">
                          <h1 className="font-bold text-2xl">
                            Personal/Basic Information
                          </h1>
                          <p className="font-normal text-base mt-2 textLightGray">
                            Please enter your personal details as printed on your
                            Identity document.
                          </p>

                          <div className=" lg:w-12/12 md:w-12/12 sm:w-12/12 mx-auto text-left formDiv ">
                            <Form
                              class="kycPersonalDetailsForm mt-4"
                              // onSubmit={(e) => handleRegister(e)} ref={c => {form = c;}}
                            >
                              <div className="field relative w-full mb-4 ">
                                <label className="label block text-blueGray-600 text-xs mb-2">
                                  Email
                                </label>
                                <Input
                                  type="email"
                                  className="desktopInput input-field border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  // placeholder="Johnsmith@gmail.com"
                                  // value={password}
                                  // onChange={(e) => setPassword(e.target.value)}
                                  // validations={[required]}
                                />
                              </div>
                              <div className="names two_col mb-4">
                                <div className="field relative w-full ">
                                  <label className="label block text-blueGray-600 text-xs mb-2">
                                    First Name
                                  </label>
                                  <Input
                                    type="text"
                                    className="desktopInput input-field border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    // placeholder="john"
                                    // value={email}
                                    // onChange={(e) => setEmail(e.target.value)}
                                    // validations={[required, validEmail]}
                                  />
                                </div>
                                <div className="field relative w-full ">
                                  <label className="label block text-blueGray-600 text-xs mb-2">
                                    Last Name
                                  </label>
                                  <Input
                                    type="text"
                                    className="desktopInput input-field border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    // placeholder="smith"
                                    // value={password}
                                    // onChange={(e) => setPassword(e.target.value)}
                                    // validations={[required]}
                                  />
                                </div>
                              </div>

                              <div className="field relative w-full mb-4 ">
                                <label className="label block text-blueGray-600 text-xs mb-2">
                                  Date of Birth
                                </label>
                                {/* <Input
                                  type="date"
                                  max={disableDates()}
                                  className="desktopInput input-field border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  // value={email}
                                  // onChange={(e) => setEmail(e.target.value)}
                                  // validations={[required, validEmail]}
                                /> */}
                                <DatePicker
                                          portalId="root-portal"
                                          maxDate={addDays(new Date(), 0)}
                                          className="desktopInput input-field border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                          // selected={data[i].birthDate}
                                          // onChange={(value, event) => {
                                          //   event.target = {
                                          //     value: value,
                                          //     name:"birthDate"
                                          //   };
                                          //   handleDataChange(event, i)
                                          // }}
                                        />
                              </div>

                              <div className="field relative w-full ">
                                <label className="label block text-blueGray-600 text-xs mb-2">
                                  Nationality
                                </label>
                                <Select
                                  className="desktopInput"
                                  style={{ all: "unset" }}
                                  placeholder="Search Country"
                                  options={options}
                                  value={value}
                                  onChange={changeHandler}
                                  menuPortalTarget={document.body}
                                  styles={customStyles}
                                />
                              </div>

                              {message && (
                                <div className="form-group">
                                  <div
                                    className="alert alert-danger"
                                    role="alert"
                                  >
                                    {message}
                                  </div>
                                </div>
                              )}

                              <CheckButton
                                style={{ display: "none" }}
                                ref={(c) => {
                                  checkBtn = c;
                                }}
                              />
                            </Form>
                          </div>

                          <div className="buttonAction lg:w-10/12 md:w-10/12 sm:w-10/12 mx-auto">
                            <Link onClick={next}>
                              <button className="mx-auto lg:w-12/12 md:w-12/12 pinkBg py-4 mt-4 rounded-lg color-white font-bold text-base">
                                Next
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* screen4 */}
                  <div className={"screen4 " + (screen4Flag ? "" : "disabled")}>
                    {/* top bar with progress and back/close buttons */}
                    <div className="progressSection w-full flex flex-row justify-between p-16 pt-4 pb-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img src={backGrey} alt="backArrow" className="" />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-4">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "28%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button onClick={() => setScreen4Flag(!screen4Flag)}>
                          <img src={unionGrey} alt="crossArrow" className="" style={{ transform: screen4Flag ? 'rotate(0deg)' : 'rotate(45deg)' }} />
                        </button>
                      </div>
                    </div>
                    {/* top bar with progress and back/close buttons */}

                    <div className="mainContent w-full flex flex-row h-full pb-8">
                      <div className="textArea lg:w-10/12 md:w-12/12 sm:w-12/12 px-12 mx-auto">
                        <div className="row w-full flex flex-col px-16 text-center">
                          <img
                            src={scre4Icon}
                            alt="welcome picture"
                            className="mx-auto"
                            style={{ width: "50%" }}
                          />

                          <h1 className="font-bold text-2xl">
                            Begin video selfie with liveness detection
                          </h1>
                          <p className="font-normal text-base mt-4 textLightGray">
                            You will need to upload your identity document with a
                            validity period of at least one month and scan your
                            face for liveness detection
                          </p>

                          <div className="buttonAction lg:w-10/12 md:w-10/12 sm:w-10/12 mx-auto">
                            <Link onClick={next}>
                              <button
                                className="mx-auto lg:w-8/12 md:w-12/12 pinkBg py-4 mt-4 rounded-lg color-white font-bold text-base"
                                style={{ width: "70%" }}
                              >
                                Lets do it!
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* screen5 */}
                  <div className={"screen5 " + (screen4Flag ? "" : "disabled")}>
                    {/* top bar with progress and back/close buttons */}
                    <div className="progressSection w-full flex flex-row justify-between p-16 pt-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img src={backGrey} alt="backArrow" className="" />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-4">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "35%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button onClick={() => setScreen4Flag(!screen4Flag)}>
                          <img src={unionGrey} alt="crossArrow" className="" style={{ transform: screen4Flag ? 'rotate(0deg)' : 'rotate(45deg)' }} />
                        </button>
                      </div>
                    </div>
                    {/* top bar with progress and back/close buttons */}

                    <div className="mainContent w-full flex h-full pb-16 items-center">
                      <div className="textArea lg:w-8/12 md:w-6/12 sm:w-12/12">
                        <div className="videoFrame">
                          <div className="videoBox relative">
                            <img
                              src={videoFrame}
                              alt="videoFrame"
                              className="absolute videoFrameFace"
                            />
                            <Webcam
                              audio={false}
                              ref={webcamRef}
                              screenshotFormat="image/jpeg"
                              width={"100%"}
                              mirrored={true}
                              onUserMedia={handleUserMedia}
                            />
                          </div>

                          <div className="cameraControls">
                            <div className="w-full py-4 bgLightGrey rounded-b-lg text-center">
                              <div className="flex flex-row items-center text-center justify-center cursor-pointer">
                                <button
                                  // onClick={startProcess}
                                  className="mr-8 text-white text-base font-bold"
                                >
                                  Start
                                </button>
                                <p className="flex flex-row items-center text-white text-xs">
                                  or press
                                  <img
                                    src={enter}
                                    alt="warning"
                                    className="ml-3"
                                    style={{ width: "auto" }}
                                  />
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="lg:w-4/12 md:w-6/12 sm:w-12/12 ">
                        <div className="row w-full flex flex-col  text-center turnLeft">
                          <h1 className="font-bold text-2xl uppercase">
                            {/* {question} */}
                            Turn Left
                          </h1>

                          <div className="warningTryAgain flex flex-col mt-16">
                            {/* {status === "fail" && (
                              <> */}
                            <img
                              src={warning}
                              alt="warning"
                              className="mx-auto"
                              style={{ width: "auto" }}
                            />
                            <p className="font-normal text-xl mt-4 textLightGray">
                              Please try again!
                            </p>
                            {/* </>
                            )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* screen10 */}
                  <div className={"screen10 " + (screen10Flag ? "" : "disabled")}>
                    {/* top bar with progress and back/close buttons */}
                    <div className="progressSection w-full flex flex-row justify-between p-16 pt-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img src={backGrey} alt="backArrow" className="" />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-4">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "42%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button onClick={() => setScreen10Flag(!screen10Flag)}>
                          <img src={unionGrey} alt="crossArrow" className="" style={{ transform: screen10Flag ? 'rotate(0deg)' : 'rotate(45deg)' }} />
                        </button>
                      </div>
                    </div>
                    {/* top bar with progress and back/close buttons */}

                    <div className="mainContent w-full flex flex-row h-full ">
                      <div className="textArea lg:w-10/12 md:w-12/12 sm:w-12/12 px-12 mx-auto">
                        <div className="row w-full flex flex-col text-center">
                          <h1 className="font-bold text-2xl">
                            To complete Identity verification you will need one of
                            the following
                          </h1>

                          <div className="requiredDocGrid flex flex-row w-full mt-4 items-end">
                            <div className="flex flex-col w-full mr-8 items-center requiredDocGroup">
                              <img
                                src={utilityBill}
                                alt="welcome picture"
                                className="mx-auto mb-4"
                                style={{ width: "60%" }}
                              />
                              <h1 className="font-bold text-base mt-2">
                                National ID Card
                              </h1>
                              <p className="font-normal text-sm mt-2 textLightGray">
                                You will need to add live picture of your ID card
                                for verification
                              </p>
                            </div>
                            <div className="flex flex-col w-full mr-8 items-center requiredDocGroup">
                              <img
                                src={passport}
                                alt="welcome picture"
                                className="mx-auto"
                                style={{ width: "50%" }}
                              />
                              <h1 className="font-bold text-base mt-2">
                                Passport
                              </h1>
                              <p className="font-normal text-sm mt-2 textLightGray">
                                You will need to add live picture of your
                                passport for verification
                              </p>
                            </div>
                            <div className="flex flex-col w-full items-center requiredDocGroup">
                              <img
                                src={nationalID}
                                alt="welcome picture"
                                className="mx-auto mb-4"
                                style={{ width: "60%" }}
                              />
                              <h1 className="font-bold text-base mt-2">
                                Driving License
                              </h1>
                              <p className="font-normal text-sm mt-2 textLightGray">
                                You will need to add live picture of your driving
                                license for verification
                              </p>
                            </div>
                          </div>

                          <div className="buttonAction lg:w-8/12 md:w-10/12 sm:w-10/12 mx-auto ">
                            <Link onClick={next}>
                              <button
                                className="lg:w-8/12 md:w-12/12 mx-auto pinkBg py-4 mt-4 rounded-lg color-white font-bold text-base"
                                style={{ width: "70%" }}
                              >
                                Got it!
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* screen11 */}
                  <div className={"screen11 " + (screen11Flag ? "" : "disabled")}>
                    {/* top bar with progress and back/close buttons */}
                    <div className="progressSection w-full flex flex-row justify-between p-16 pt-4 pb-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img src={backGrey} alt="backArrow" className="" />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-4">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "49%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button onClick={() => setScreen11Flag(!screen11Flag)}>
                          <img src={unionGrey} alt="crossArrow" className="" style={{ transform: screen11Flag ? 'rotate(0deg)' : 'rotate(45deg)' }} />
                        </button>
                      </div>
                    </div>
                    {/* top bar with progress and back/close buttons */}

                    <div className="mainContent w-full flex flex-row h-full ">
                      <div className="textArea lg:w-10/12 md:w-12/12 sm:w-12/12 px-12 mx-auto">
                        <div className="row w-full flex flex-col px-16 py-6 text-center">
                          <h1 className="font-bold text-2xl">
                            Identity Information
                          </h1>
                          <p className="font-normal text-base mt-4 textLightGray">
                            Please enter your dentity information.
                          </p>

                          <div className="lg:w-10/12 md:w-12/12 sm:w-12/12 mx-auto text-left">
                            <Form
                              class="kycPersonalDetailsForm mt-4"
                              // onSubmit={(e) => handleRegister(e)} ref={c => {form = c;}}
                            >
                              <div className="field relative w-full mb-6">
                                <label className="label block text-blueGray-600 text-xs mb-2">
                                  Nationality
                                </label>
                                <Select
                                  className=""
                                  style={{ all: "unset" }}
                                  placeholder="Search Country"
                                  options={options}
                                  value={value}
                                  onChange={changeHandler}
                                  menuPortalTarget={document.body}
                                  styles={customStyles}
                                />
                              </div>

                              <div className="field relative w-full mb-6">
                                <label className="label block text-blueGray-600 text-xs mb-2">
                                  Type of Document
                                </label>
                                <Select
                                  options={typeOfDoc}
                                  isSearchable={false}
                                  menuPortalTarget={document.body}
                                  styles={customStyles}
                                />
                              </div>

                              <div className="field relative w-full mb-6">
                                <label className="label block text-blueGray-600 text-xs mb-2">
                                  ID Number
                                </label>
                                <Input
                                  type="number"
                                  className="input-field border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  // placeholder="xxxxx-xxxxxxx-x"
                                  // value={password}
                                  // onChange={(e) => setPassword(e.target.value)}
                                  // validations={[required]}
                                />
                              </div>

                              {message && (
                                <div className="form-group">
                                  <div
                                    className="alert alert-danger"
                                    role="alert"
                                  >
                                    {message}
                                  </div>
                                </div>
                              )}

                              <CheckButton
                                style={{ display: "none" }}
                                ref={(c) => {
                                  checkBtn = c;
                                }}
                              />
                            </Form>
                          </div>

                          <div className="buttonAction lg:w-10/12 md:w-10/12 sm:w-12/12 mx-auto">
                            <Link onClick={next}>
                              <button className="w-full mx-auto pinkBg py-4 mt-4 rounded-lg color-white font-bold text-base">
                                Next
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* screen12 */}
                  <div className={"screen12 " + (screen12Flag ? "" : "disabled")}>
                    {/* top bar with progress and back/close buttons */}
                    <div className="progressSection w-full flex flex-row justify-between p-16 pt-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img src={backGrey} alt="backArrow" className="" />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-4">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "56%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button onClick={() => setScreen12Flag(!screen12Flag)}>
                          <img src={unionGrey} alt="crossArrow" className="" style={{ transform: screen12Flag ? 'rotate(0deg)' : 'rotate(45deg)' }} />
                        </button>
                      </div>
                    </div>
                    {/* top bar with progress and back/close buttons */}

                    <div className="mainContent w-full flex flex-row h-full ">
                      <div className="textArea lg:w-10/12 md:w-12/12 sm:w-12/12 px-12 mx-auto">
                        <div className="row w-full flex flex-col px-16 py-6 text-center">
                          <h1 className="font-bold text-2xl">
                            How’d you like to provide your document?
                          </h1>

                          <div className="docSelect kycscreens py-10 mt-10">
                            <Select
                              className="globeSelect"
                              styles={customStyles}
                              options={optionsDocuments}
                            />
                          </div>

                          <div className="buttonAction lg:w-12/12 md:w-12/12 sm:w-12/12 mx-auto flex justify-between">
                            <button className="w-full mx-auto py-6 px-6 mt-4 rounded-lg font-bold text-sm kycButtonGreyBg mr-8 max-w-210-px">
                              <Link
                                onClick={next}
                                className="flex flex-row items-center"
                              >
                                <img
                                  src={video}
                                  alt="video"
                                  className="mx-auto"
                                  style={{ width: "40px" }}
                                />
                                Use Webcam
                              </Link>
                            </button>
                            <button className="flex flex-row items-center w-full mx-auto py-6 px-6 mt-4 rounded-lg font-bold text-sm kycButtonGreyBg max-w-210-px">
                              <img
                                src={uploadCloud}
                                alt="video"
                                className="mx-auto"
                                style={{ width: "40px" }}
                              />
                              Upload a File
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* screen15 */}
                  <div className={"screen15 " + (screen12Flag ? "" : "disabled")}>
                    {/* top bar with progress and back/close buttons */}
                    <div className="progressSection w-full flex flex-row justify-between p-16 pt-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img src={backGrey} alt="backArrow" className="" />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-4">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "63%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button onClick={() => setScreen12Flag(!screen12Flag)}>
                          <img src={unionGrey} alt="crossArrow" className="" style={{ transform: screen12Flag ? 'rotate(0deg)' : 'rotate(45deg)' }} />
                        </button>
                      </div>
                    </div>
                    {/* top bar with progress and back/close buttons */}

                    <div className="mainContent w-full flex flex-row h-full pb-16 items-center ">
                      <div className=" lg:w-7/12 md:w-6/12 sm:w-12/12">
                        {/* camera status start */}
                        {/* <p>Camera status: {recordWebcam.status}</p> */}
                        {/* camera status end */}

                        <Link onClick={next}>
                          <div className="videoFrame">
                            <div className="videoBox relative">
                              <div
                                className="absolute videoFrameFace rect"
                                style={{ zIndex: "100" }}
                              >
                                <img
                                  src={videoFrameRect}
                                  alt="videoFrame"
                                  style={{ width: "auto" }}
                                />
                                <p className="text-white text-sm text-center mt-4">
                                  Make sure the text is clearly visible
                                </p>
                              </div>
                              <Webcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                width={"100%"}
                                mirrored={true}
                                onUserMedia={handleUserMedia}
                              />
                            </div>

                            <div className="cameraControls">
                              <div className="w-full py-4 bgLightGrey rounded-b-lg text-center">
                                <div className="flex flex-row items-center text-center justify-center cursor-pointer">
                                  <button className="mr-8 text-white text-lg font-bold">
                                    Start
                                  </button>
                                  <p className="flex flex-row items-center text-white text-xs">
                                    or press
                                    <img
                                      src={enter}
                                      alt="warning"
                                      className="ml-3"
                                      style={{ width: "auto" }}
                                    />
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="lg:w-5/12 md:w-6/12 sm:w-12/12">
                        <div className="row w-full flex flex-col px-6 text-center">
                          <h1 className="font-bold text-2xl">
                            Capture the front side
                          </h1>

                          <div className="warningTryAgain flex flex-col mt-4">
                            <img
                              src={warning}
                              alt="warning"
                              className="mx-auto"
                              style={{ width: "auto" }}
                            />
                            <p className="font-normal text-base mt-4 textLightGray">
                              Please try again!
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* screen15 (back) */}
                  <div
                    className={"screen15b " + (screen12Flag ? "" : "disabled")}
                  >
                    {/* top bar with progress and back/close buttons */}
                    <div className="progressSection w-full flex flex-row justify-between p-16  pt-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img src={backGrey} alt="backArrow" className="" />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-4">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "63%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button onClick={() => setScreen12Flag(!screen12Flag)}>
                          <img src={unionGrey} alt="crossArrow" className="" style={{ transform: screen12Flag ? 'rotate(0deg)' : 'rotate(45deg)' }} />
                        </button>
                      </div>
                    </div>
                    {/* top bar with progress and back/close buttons */}

                    <div className="mainContent w-full flex h-full pb-16 items-center ">
                      <div className="lg:w-7/12 md:w-6/12 sm:w-12/12">
                        {/* camera status start */}
                        {/* <p>Camera status: {recordWebcam.status}</p> */}
                        {/* camera status end */}

                        <Link onClick={next}>
                          <div className="videoFrame">
                            <div className="videoBox relative">
                              <div
                                className="absolute videoFrameFace rect"
                                style={{ zIndex: "100" }}
                              >
                                <img
                                  src={videoFrameRect}
                                  alt="videoFrame"
                                  style={{ width: "auto" }}
                                />
                                <p className="text-white text-sm text-center mt-4">
                                  Make sure the text is clearly visible
                                </p>
                              </div>
                              <Webcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                width={"100%"}
                                mirrored={true}
                                onUserMedia={handleUserMedia}
                              />
                            </div>

                            <div className="cameraControls">
                              <div className="w-full py-4 bgLightGrey rounded-b-lg text-center">
                                <div className="flex flex-row items-center text-center justify-center cursor-pointer">
                                  <button className="mr-8 text-white text-lg font-bold">
                                    Start
                                  </button>
                                  <p className="flex flex-row items-center text-white text-xs">
                                    or press
                                    <img
                                      src={enter}
                                      alt="warning"
                                      className="ml-3"
                                      style={{ width: "auto" }}
                                    />
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="lg:w-5/12 md:w-6/12 sm:w-12/12">
                        <div className="row w-full flex flex-col px-6 text-center">
                          <h1 className="font-bold text-2xl">
                            Capture the back side
                          </h1>

                          <div className="warningTryAgain flex flex-col mt-4">
                            <img
                              src={warning}
                              alt="warning"
                              className="mx-auto"
                              style={{ width: "auto" }}
                            />
                            <p className="font-normal text-base mt-4 textLightGray">
                              Please try again!
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* screen18 */}
                  <div className={"screen18 " + (screen12Flag ? "" : "disabled")}>
                    {/* top bar with progress and back/close buttons */}
                    <div className="progressSection w-full flex flex-row justify-between p-16 pt-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img src={backGrey} alt="backArrow" className="" />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-4">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "63%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button onClick={() => setScreen12Flag(!screen12Flag)}>
                          <img src={unionGrey} alt="crossArrow" className="" style={{ transform: screen12Flag ? 'rotate(0deg)' : 'rotate(45deg)' }} />
                        </button>
                      </div>
                    </div>
                    {/* top bar with progress and back/close buttons */}

                    <div className="mainContent w-full flex h-full pb-16 items-center ">
                      <div className="lg:w-7/12 md:w-6/12 sm:w-12/12">
                        {/* camera status start */}
                        {/* <p>Camera status: {recordWebcam.status}</p> */}
                        {/* camera status end */}

                        <Link onClick={next}>
                          <div className="videoFrame">
                            <div className="videoBox relative">
                              <div
                                className="absolute videoFrameFaceDoc rect"
                                style={{ zIndex: "100" }}
                              >
                                <div className="flex flex-row items-center justify-between">
                                  <div className="document mr-8">
                                    <img
                                      src={documentFrame}
                                      alt="docFrame"
                                      style={{ width: "auto" }}
                                    />
                                    <p className="text-white text-sm text-center mt-4">
                                      Document
                                    </p>
                                  </div>
                                  <div className="face">
                                    <img
                                      src={faceFrame}
                                      alt="faceFrame"
                                      style={{ width: "auto" }}
                                    />
                                    <p className="text-white text-sm text-center mt-4">
                                      Face
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <Webcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                width={"100%"}
                                mirrored={true}
                                onUserMedia={handleUserMedia}
                              />
                            </div>

                            <div className="cameraControls">
                              <div className="w-full py-4 bgLightGrey rounded-b-lg text-center">
                                <div className="flex flex-row items-center text-center justify-center cursor-pointer">
                                  <button className="mr-8 text-white text-lg font-bold">
                                    Start
                                  </button>
                                  <p className="flex flex-row items-center text-white text-xs">
                                    or press
                                    <img
                                      src={enter}
                                      alt="warning"
                                      className="ml-3"
                                      style={{ width: "auto" }}
                                    />
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="lg:w-5/12 md:w-6/12 sm:w-12/12">
                        <div className="row w-full flex flex-col px-6 text-center">
                          <h1 className="font-bold text-2xl">
                            Show Your Document Along With Your Face
                          </h1>

                          <div className="warningTryAgain flex flex-col mt-4">
                            <img
                              src={warning}
                              alt="warning"
                              className="mx-auto"
                              style={{ width: "auto" }}
                            />
                            <p className="font-normal text-base mt-4 textLightGray">
                              Please try again!
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* screen19 */}
                  <div className={"screen19 " + (screen19Flag ? "" : "disabled")}>
                    {/* top bar with progress and back/close buttons */}
                    <div className="progressSection w-full flex flex-row justify-between p-16 pt-4 pb-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img src={backGrey} alt="backArrow" className="" />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-4">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "77%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button onClick={() => setScreen19Flag(!screen19Flag)}>
                          <img src={unionGrey} alt="crossArrow" className="" style={{ transform: screen19Flag ? 'rotate(0deg)' : 'rotate(45deg)' }} />
                        </button>
                      </div>
                    </div>
                    {/* top bar with progress and back/close buttons */}

                    <div className="mainContent w-full flex flex-row h-full pb-4">
                      <div className="textArea lg:w-10/12 md:w-12/12 sm:w-12/12 px-12 mx-auto">
                        <div className="row w-full flex flex-col px-16 text-center">
                          <img
                            src={location}
                            alt="welcome picture"
                            className="mx-auto"
                            style={{ width: "40%" }}
                          />

                          <h1 className="font-bold text-2xl mt-3">
                            Almost there! Just need to verify your address.
                          </h1>
                          <p className="font-normal text-base mt-4 textLightGray">
                            You will need a utility bill or bank statement that
                            has your name and your address. This document must not
                            be older than 6 months.
                          </p>

                          <div className="buttonAction lg:w-10/12 md:w-10/12 sm:w-10/12 mx-auto">
                            <Link onClick={next}>
                              <button className="lg:w-10/12 md:w-12/12 mx-auto pinkBg py-4 mt-4 rounded-lg color-white font-bold text-base">
                                Got it!
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* screen20 */}
                  <div className={"screen20 " + (screen19Flag ? "" : "disabled")}>
                    {/* top bar with progress and back/close buttons */}
                    <div className="progressSection w-full flex flex-row justify-between p-16 pt-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img src={backGrey} alt="backArrow" className="" />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-4">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "56%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button onClick={() => setScreen19Flag(!screen19Flag)}>
                          <img src={unionGrey} alt="crossArrow" className="" style={{ transform: screen19Flag ? 'rotate(0deg)' : 'rotate(45deg)' }} />
                        </button>
                      </div>
                    </div>
                    {/* top bar with progress and back/close buttons */}

                    <div className="mainContent w-full flex flex-row h-full pt-16 ">
                      <div className="textArea lg:w-10/12 md:w-12/12 sm:w-12/12 mx-auto">
                        <div className="row w-full flex flex-col py-6 text-center">
                          <h1 className="font-bold text-2xl">
                            Address Verification
                          </h1>
                          <p className="text-sm font-normal">
                            How’d you like to provide us the document?
                          </p>

                          <div className="flex flex-row items-center justify-bweteen w-full">
                            <div className="lg:w-6/12 md:w-12/12 sm:w-12/12 text-left">
                              <div className="py-10 ">
                                <Select
                                  className="globeSelect kycscreens"
                                  styles={customStyles}
                                  options={optionsAddressVerifications}
                                />
                              </div>
                            </div>

                            <div className="buttonAction lg:w-6/12 md:w-12/12 sm:w-12/12 flex flex-col ">
                              <div className="flex flex-row justify-center items-center mb-6 pl-4">
                                <button className="w-full mx-auto py-4 px-6 mt-4 rounded-lg font-bold text-sm kycButtonGreyBg mr-8 max-w-210-px">
                                  <Link
                                    onClick={next}
                                    className="flex flex-col items-center"
                                  >
                                    <img
                                      src={video}
                                      alt="video"
                                      className="mx-auto"
                                      style={{ width: "30px" }}
                                    />
                                    Use Webcam
                                  </Link>
                                </button>
                                <button className="flex flex-col items-center w-full mx-auto py-4 px-6 mt-4 rounded-lg font-bold text-sm kycButtonGreyBg max-w-210-px">
                                  <img
                                    src={uploadCloud}
                                    alt="video"
                                    className="mx-auto"
                                    style={{ width: "30px" }}
                                  />
                                  Upload a File
                                </button>
                              </div>
                              <Link to="">
                                <p className="font-normal text-xs underline">
                                  Enter Manually
                                </p>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* screen21 */}
                  <div className={"screen21 " + (screen19Flag ? "" : "disabled")}>
                    {/* top bar with progress and back/close buttons */}
                    <div className="progressSection w-full flex flex-row justify-between p-16 pt-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img src={backGrey} alt="backArrow" className="" />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-4">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "80%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button onClick={() => setScreen19Flag(!screen19Flag)}>
                          <img src={unionGrey} alt="crossArrow" className="" style={{ transform: screen19Flag ? 'rotate(0deg)' : 'rotate(45deg)' }} />
                        </button>
                      </div>
                    </div>
                    {/* top bar with progress and back/close buttons */}

                    <div className="mainContent w-full flex h-full pb-16 items-center ">
                      <div className="textArea lg:w-7/12 md:w-6/12 sm:w-12/12">
                        {/* camera status start */}
                        {/* <p>Camera status: {recordWebcam.status}</p> */}
                        {/* camera status end */}

                        <Link onClick={next}>
                          <div className="videoFrame">
                            <div className="videoBox relative">
                              <div
                                className="absolute videoFrameFace rect"
                                style={{ zIndex: "100", top: "25%" }}
                              >
                                <img
                                  src={videoFrameRect}
                                  alt="videoFrame"
                                  style={{
                                    width: "auto",
                                    transform: "rotate(90deg)"
                                  }}
                                />
                              </div>
                              <Webcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                width={"100%"}
                                mirrored={true}
                                onUserMedia={handleUserMedia}
                              />
                            </div>

                            <div className="cameraControls">
                              <div className="w-full py-4 bgLightGrey rounded-b-lg text-center">
                                <div className="flex flex-row items-center text-center justify-center cursor-pointer">
                                  <button className="mr-8 text-white text-lg font-bold">
                                    Start
                                  </button>
                                  <p className="flex flex-row items-center text-white text-xs">
                                    or press
                                    <img
                                      src={enter}
                                      alt="warning"
                                      className="ml-3"
                                      style={{ width: "auto" }}
                                    />
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="lg:w-5/12 md:w-6/12 sm:w-12/12">
                        <div className="row w-full flex flex-col px-6 text-center">
                          <h1 className="font-bold text-2xl">
                            Make sure the text is clearly visible
                          </h1>

                          <div className="warningTryAgain flex flex-col mt-4">
                            <img
                              src={warning}
                              alt="warning"
                              className="mx-auto"
                              style={{ width: "auto" }}
                            />
                            <p className="font-normal text-base mt-4 textLightGray">
                              Blur document type. <br /> Please try again!
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* screen24 */}
                  <div className={"screen24 " + (screen19Flag ? "" : "disabled")}>
                    {/* top bar with progress and back/close buttons */}
                    <div className="progressSection w-full flex flex-row justify-between p-16 pt-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img src={backGrey} alt="backArrow" className="" />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-4">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "80%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button onClick={() => setScreen19Flag(!screen19Flag)}>
                          <img src={unionGrey} alt="crossArrow" className="" style={{ transform: screen19Flag ? 'rotate(0deg)' : 'rotate(45deg)' }} />
                        </button>
                      </div>
                    </div>
                    {/* top bar with progress and back/close buttons */}

                    <div className="mainContent w-full flex h-full pb-16 items-center">
                      <div className=" lg:w-7/12 md:w-6/12 sm:w-12/12">
                        {/* camera status start */}
                        {/* <p>Camera status: {recordWebcam.status}</p> */}
                        {/* camera status end */}

                        <Link onClick={next}>
                          <div className="videoFrame">
                            <div className="videoBox relative">
                              <div
                                className="absolute videoFrameFace rect"
                                style={{ zIndex: "100", top: "45%" }}
                              >
                                <img
                                  src={warning24}
                                  alt="warning"
                                  style={{ width: "auto" }}
                                />
                              </div>
                              <img src={black} alt="black" />
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="textArea lg:w-5/12 md:w-6/12 sm:w-12/12">
                        <div className="row w-full flex flex-col px-6 pl-0 text-left">
                          <ul>
                            <li>Blur/focus Issue</li>
                            <li>Light is too high/low</li>
                            <li>Image size is too large/small</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* screen25 */}
                  <div className={"screen25 " + (screen25Flag ? "" : "disabled")}>
                    {/* top bar with progress and back/close buttons */}
                    <div className="progressSection w-full flex flex-row justify-between p-16 pt-4 pb-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img src={backGrey} alt="backArrow" className="" />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-4">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "84%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button onClick={() => setScreen25Flag(!screen25Flag)}>
                          <img src={unionGrey} alt="crossArrow" className="" style={{ transform: screen25Flag ? 'rotate(0deg)' : 'rotate(45deg)' }} />
                        </button>
                      </div>
                    </div>
                    {/* top bar with progress and back/close buttons */}

                    <div className=" mainContent w-full flex flex-row h-full ">
                      <div className="textArea lg:w-10/12 md:w-12/12 sm:w-12/12 px-12 mx-auto">
                        <div className="row w-full flex flex-col px-12 py-6 text-center">
                          <h1 className="font-bold text-2xl">
                            Please enter your address details
                          </h1>

                          <div className="lg:w-10/12 md:w-12/12 sm:w-12/12 mx-auto text-left">
                            <Form
                              class="kycPersonalDetailsForm mt-8"
                              // onSubmit={(e) => handleRegister(e)} ref={c => {form = c;}}
                            >
                              <div className="field relative w-full mb-6">
                                <label className="label block text-blueGray-600 text-xs mb-2">
                                  Country of residence
                                </label>
                                <Select
                                  className=""
                                  style={{ all: "unset" }}
                                  placeholder="Search Country"
                                  options={options}
                                  value={value}
                                  onChange={changeHandler}
                                  menuPortalTarget={document.body}
                                  styles={customStyles}
                                />
                              </div>

                              <div className="names two_col mb-3">
                                <div className="field relative w-full mb-3">
                                  <label className="label block text-blueGray-600 text-xs mb-2">
                                    City
                                  </label>
                                  <Input
                                    type="text"
                                    className="input-field border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    // placeholder="Islamabad"
                                    // value={email}
                                    // onChange={(e) => setEmail(e.target.value)}
                                    // validations={[required, validEmail]}
                                  />
                                </div>
                                <div className="field relative w-full mb-3">
                                  <label className="label block text-blueGray-600 text-xs mb-2">
                                    Postal Code
                                  </label>
                                  <Input
                                    type="number"
                                    className="input-field border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    // placeholder="xxxxx"
                                    // value={password}
                                    // onChange={(e) => setPassword(e.target.value)}
                                    // validations={[required]}
                                  />
                                </div>
                              </div>

                              <div className="field relative w-full mb-6">
                                <label className="label block text-blueGray-600 text-xs mb-2">
                                  Address
                                </label>
                                <Input
                                  type="text"
                                  className="input-field border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  // placeholder="House No 2431, Street No 37, Islamabad"
                                  // value={email}
                                  // onChange={(e) => setEmail(e.target.value)}
                                  // validations={[required, validEmail]}
                                />
                              </div>

                              {message && (
                                <div className="form-group">
                                  <div
                                    className="alert alert-danger"
                                    role="alert"
                                  >
                                    {message}
                                  </div>
                                </div>
                              )}

                              <CheckButton
                                style={{ display: "none" }}
                                ref={(c) => {
                                  checkBtn = c;
                                }}
                              />
                            </Form>
                          </div>

                          <div className="buttonAction lg:w-10/12 md:w-10/12 sm:w-10/12 mx-auto">
                            <Link onClick={next}>
                              <button className="lg:w-12/12 md:w-12/12 mx-auto pinkBg py-4 mt-4 rounded-lg color-white font-bold text-base">
                                Next
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* screen26 */}
                  <div className={"screen26 " + (screen26Flag ? "" : "disabled")}>
                    {/* top bar with progress and back/close buttons */}
                    <div className="progressSection w-full flex flex-row justify-between p-16 pt-4 pb-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img src={backGrey} alt="backArrow" className="" />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-4">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "91%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button onClick={() => setScreen26Flag(!screen26Flag)}>
                          <img src={unionGrey} alt="crossArrow" className="" style={{ transform: screen26Flag ? 'rotate(0deg)' : 'rotate(45deg)' }} />
                        </button>
                      </div>
                    </div>
                    {/* top bar with progress and back/close buttons */}

                    <div className="mainContent w-full flex flex-row h-full mt-16">
                      <div className="textArea lg:w-12/12 md:w-12/12 sm:w-12/12 px-12 mx-auto">
                        <div className="row w-full flex flex-col  text-center">
                          <h1 className="font-bold text-4xl">
                            Review your information
                          </h1>

                          <div className="infoTable lg:w-12/12 md:w-12/12 sm:w-12/12 mx-auto text-left  pb-4">
                            <div className="reviewInfoTables w-full flex flex-wrap">
                              <div className="lg:w-4/12 md:w-4/12 sm:w-4/12 pr-4">
                                <div
                                  className="flex flex-row justify-between infoTableFlex"
                                  style={{
                                    paddingBottom: "10px",
                                    borderBottom: "2px solid #A8A8A8",
                                  }}
                                >
                                  <h4 className="tableHeading text-base font-bold text-stone-500">
                                    Personal Information
                                  </h4>
                                  <button>
                                    <img
                                      className=""
                                      src={editbutton}
                                      alt="editbutton"
                                      style={{ width: "auto" }}
                                    />
                                  </button>
                                </div>
                                <table className="w-full mt-3 table-layout-fixed">
                                  <tbody>
                                    <tr>
                                      <td className="text-left text-sm text-stone-500">
                                        Email
                                      </td>
                                      <td className="text-left text-sm">
                                        johnsmith@gmail.com
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="text-left text-sm text-stone-500">
                                        First Name
                                      </td>
                                      <td className="text-left text-sm">John</td>
                                    </tr>
                                    <tr>
                                      <td className="text-left text-sm text-stone-500">
                                        Last Name
                                      </td>
                                      <td className="text-left text-sm">Smith</td>
                                    </tr>
                                    <tr>
                                      <td className="text-left text-sm text-stone-500">
                                        Date of Birth
                                      </td>
                                      <td className="text-left text-sm">
                                        02/12/1996
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="text-left text-sm text-stone-500">
                                        Nationality
                                      </td>
                                      <td className="text-left text-sm">
                                        Pakistani
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="lg:w-4/12 md:w-4/12 sm:w-4/12 pr-4">
                                <div
                                  className="flex flex-row justify-between"
                                  style={{
                                    paddingBottom: "10px",
                                    borderBottom: "2px solid #A8A8A8",
                                  }}
                                >
                                  <h4 className="tableHeading text-lg font-bold text-stone-500">
                                    Identity Information
                                  </h4>
                                  <button>
                                    <img
                                      className=""
                                      src={editbutton}
                                      alt="editbutton"
                                      style={{ width: "auto" }}
                                    />
                                  </button>
                                </div>
                                <table className="w-full mt-3 table-layout-fixed">
                                  <tbody>
                                    <tr>
                                      <td className="text-left text-sm text-stone-500">
                                        ID Card No
                                      </td>
                                      <td className="text-left text-sm">
                                        xxxxx-xxxxxxx-x
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="text-left text-sm text-stone-500">
                                        Passport No
                                      </td>
                                      <td className="text-left text-sm">
                                        xxx-xxxxx-x
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="lg:w-4/12 md:w-4/12 sm:w-4/12 ">
                                <div
                                  className="flex flex-row justify-between"
                                  style={{
                                    paddingBottom: "10px",
                                    borderBottom: "2px solid #A8A8A8",
                                  }}
                                >
                                  <h4 className="tableHeading text-lg font-bold text-stone-500">
                                    Address Information
                                  </h4>
                                  <button>
                                    <img
                                      className=""
                                      src={editbutton}
                                      alt="editbutton"
                                      style={{ width: "auto" }}
                                    />
                                  </button>
                                </div>
                                <table className="w-full mt-3 table-layout-fixed">
                                  <tbody>
                                    <tr>
                                      <td className="text-left text-sm text-stone-500">
                                        Country
                                      </td>
                                      <td className="text-left text-sm">
                                        Pakistan
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="text-left text-sm text-stone-500">
                                        City
                                      </td>
                                      <td className="text-left text-sm">
                                        Islamabad
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="text-left text-sm text-stone-500">
                                        Postal Code
                                      </td>
                                      <td className="text-left text-sm">xxxxx</td>
                                    </tr>
                                    <tr>
                                      <td className="text-left text-sm text-stone-500 align-top">
                                        Address
                                      </td>
                                      <td className="text-left text-sm">
                                        House No. 123, Street No. 45, Islamabad,
                                        Pakistan
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>

                          <div className="buttonAction lg:w-10/12 md:w-10/12 sm:w-10/12 mx-auto">
                            <Link onClick={next}>
                              <button className="lg:w-12/12 md:w-12/12 mx-auto pinkBg py-4  rounded-lg color-white font-bold text-base">
                                All Good!
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* screen27 */}
                  <div className={"screen27 " + (screen27Flag ? "" : "disabled")}>
                    {/* top bar with progress and back/close buttons */}
                    <div className="progressSection w-full flex flex-row justify-between p-16 pt-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img src={backGrey} alt="backArrow" className="" />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-4">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "100%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button onClick={() => setScreen27Flag(!screen27Flag)}>
                          <img src={unionGrey} alt="crossArrow" className="" style={{ transform: screen27Flag ? 'rotate(0deg)' : 'rotate(45deg)' }} />
                        </button>
                      </div>
                    </div>
                    {/* top bar with progress and back/close buttons */}

                    <div className="mainContent w-full flex flex-row h-full mb-8">
                      <div className="textArea lg:w-10/12 md:w-12/12 sm:w-12/12 px-12 mx-auto">
                        <div className="row w-full flex flex-col px-16 text-center">
                          <img
                            src={successIcon}
                            alt="welcome picture"
                            className="mx-auto"
                            style={{ width: "30%" }}
                          />

                          <h1 className="font-bold text-2xl mt-4">
                            Thank you for letting us know you.
                          </h1>
                          <p className="font-normal text-base mt-4 textLightGray">
                            We will review your information and get back to you
                            shortly.
                          </p>

                          <div className="buttonAction lg:w-10/12 md:w-10/12 sm:w-10/12 mx-auto">
                            <Link onClick={next}>
                              <button className="lg:w-12/12 md:w-12/12 sm-12/12 mx-auto pinkBg py-4 mt-4 rounded-lg color-white font-bold text-base">
                                Submit
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* screen28 */}
                  <div className={"screen28 " + (screen28Flag ? "" : "disabled")}>
                    {/* top bar with progress and back/close buttons */}
                    <div className="progressSection w-full flex flex-row justify-between p-16 pt-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img src={backGrey} alt="backArrow" className="" />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-4">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "100%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button onClick={() => setScreen28Flag(!screen28Flag)}>
                          <img src={unionGrey} alt="crossArrow" className="" style={{ transform: screen28Flag ? 'rotate(0deg)' : 'rotate(45deg)' }} />
                        </button>
                      </div>
                    </div>
                    {/* top bar with progress and back/close buttons */}

                    <div className="mainContent w-full flex flex-row h-full mb-8">
                      <div className="textArea lg:w-10/12 md:w-12/12 sm:w-12/12 px-12 mx-auto">
                        <div className="row w-full flex flex-col px-16 text-center">
                          <img
                            src={errorIcon}
                            alt="welcome picture"
                            className="mx-auto"
                            style={{ width: "40%" }}
                          />

                          <h1 className="font-bold text-2xl mt-4">
                            Unable to submit the information.
                          </h1>
                          <p className="font-normal text-base mt-4 textLightGray">
                            The information you provided is incorrect. Please try
                            again.
                          </p>

                          <div className="buttonAction lg:w-10/12 md:w-10/12 sm:w-10/12 mx-auto">
                            <Link onClick={next}>
                              <button className="lg:w-12/12 md:w-12/12 sm-12/12 mx-auto pinkBg py-4 mt-4 rounded-lg color-white font-bold text-base">
                                Submit
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel>
              </div>
            </section>
          </main>
          <div className="prevBtn">
            <button
              className="prevBtnKyc"
              style={{ minWidth: "40px", outline: "none" }}
              onClick={next}
            >
              <img src={kycNext} alt="previous" />
            </button>
          </div>
        </div>
        <img class="footerImg" src={footerLogo} alt="footer trademark" style={{width:"20%",marginTop:"-6%",zIndex:"10"}}/>
      </div>

      <div className="mt-16 mr-0 px-12 flex flex-row items-center justify-end fullScreenBtn" style={{width:"80%"}}>
        {loading && 
          <ReactLoading
            className="margin-auto mt-3 mb-3"
            type={"bars"}
            color={"#a10b1d"}
            height={"10%"}
            width={"10%"}
          />
        }
        <button onClick={handleSubmit} type="button" className="buttonPink px-6 py-2 rounded-lg text-sm font-bold mr-4" style={{ background: "#ebedf2", color: "#a10b1d" }}>Save Changes</button>
        {urlId && 
          <Link to={"/kyc/1/"+urlId} target="_blank">
            <button className="buttonPink px-6 py-2 rounded-lg text-sm font-bold">Generate Link</button>
          </Link>
        }
      </div>

    </>
  );
}
