import React from "react";
import { Link } from "react-router-dom";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import Select from 'react-select';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

// components
import TableDropdown from "components/Dropdowns/TableDropdown.js";

// images
import ExportIcon from "../../assets/img/icons/exporticon.png";
import ElipsesOne from "../../assets/img/event_elp_1.png";
import ElipsesTwo from "../../assets/img/event_elp_2.png";
import ElipsesError from "../../assets/img/event_elp_error.png";
import VerticalLine from "../../assets/img/verticalLine.png";
import statusAccountGreen from "../../assets/img/statusAccountGreen.png";
import statusLocGreen from "../../assets/img/statusLocGreen.png";
import statusIDGreen from "../../assets/img/statusIDGreen.png";
import statusMapGreen from "../../assets/img/statusMapGreen.png";
import statusAccountRed from "../../assets/img/statusAccountRed.png";
import statusLocRed from "../../assets/img/statusLocRed.png";
import statusIDRed from "../../assets/img/statusIDRed.png";
import statusMapRed from "../../assets/img/statusMapRed.png";

export default function CaseManagementAml() {
  const renderTooltipID = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div className="popoverHover px-4 py-2 rounded-lg font-bold text-sm" style={{ background: "#a10b1d", color: "#FFF" }}>
        Identity Verification
      </div>
    </Tooltip>
  );
  const renderTooltipGovt = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div className="popoverHover px-4 py-2 rounded-lg font-bold text-sm" style={{ background: "#a10b1d", color: "#FFF" }}>
        Government ID Verification
      </div>
    </Tooltip>
  );
  const renderTooltipAddress = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div className="popoverHover px-4 py-2 rounded-lg font-bold text-sm" style={{ background: "#a10b1d", color: "#FFF" }}>
        Address Verification
      </div>
    </Tooltip>
  );
  const renderTooltipAml = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div className="popoverHover px-4 py-2 rounded-lg font-bold text-sm" style={{ background: "#a10b1d", color: "#FFF" }}>
        AML Screening
      </div>
    </Tooltip>
  );

  const color = "light";
  const [individualActive, setIndividualActive]  = React.useState(true);
  const [corporateActive, setCorporateActive]  = React.useState(false);

  const circularBarColorAccepted = "#15E49A";
  const circularBarColorPending = "#FFC102";
  const circularBarColorDeclined = "#FA364C";

  function setIndivActive() {
    setIndividualActive(true);
    setCorporateActive(false);
  }
  function setCorpActive() {
    setIndividualActive(false);
    setCorporateActive(true);
  }

  const risklevel = [
    { label: "Risk Level", value: 1 },
  ];
  const countrywise = [
    { label: "Country Wise", value: 1 },
  ];
  const status = [
    { label: "Status", value: 1 },
    { label: "Active", value: 2 },
    { label: "Inactive", value: 3 },
  ];
  const timeperiod = [
    { label: "Time Period", value: 1 },
  ];
  const tags = [
    { label: "Tags", value: 1 },
  ];

  const defaultValueRiskLevel = risklevel[0];
  const defaultValueCountryWise = countrywise[0];
  const defaultValueStatus = status[0];
  const defaultValueTime = timeperiod[0];
  const defaultValueTags = tags[0];

  const customStylesSelect = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#828282' : '#828282',
      backgroundColor: state.isSelected ? '#EBEDF2' : '#FFF',
      padding: 10,
      fontSize: '14px',
      boxShadow: '0 !important'
    }),
  }

  return (
    <>
      <div className="casemanagement flex flex-wrap">
        <div className="w-full xl:w-9/12 lg:w-12/12 md:w-12/12 sm:w-12/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
            <div className="rounded-lg flex justify-between bg-white px-12 py-6 border-0 w-full mb-6 shadow-lg">
              <div className="flex">
                <div 
                  className="rounded-lg px-4 py-2 mr-4 cursor-pointer" 
                  style={individualActive ? { backgroundColor: "#EBEDF2" } : {backgroundColor: "#FFF"}}
                  onClick={setIndivActive}
                >
                  <h4 className="font-semibold text-sm">Individual</h4>
                </div>
                <div 
                  className="rounded-lg px-4 py-2 cursor-pointer" 
                  style={corporateActive ? { backgroundColor: "#EBEDF2" } : {backgroundColor: "#FFF"}}
                  onClick={setCorpActive}
                >
                  <h4 className="font-semibold text-sm">Corporate</h4>
                </div>
              </div>
              <div className="flex px-4 py-2 cursor-pointer">
                <img src={ExportIcon} alt="export icon" className="mr-3" style={{width:"20px", height:"20px"}} />
                <h4 className="font-semibold text-sm" style={{color: "#a10b1d"}}>Export CSV</h4>
              </div>
            </div>
            <div
              className={
                "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg " +
                (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
              }
            >
              <div className="rounded-t mb-0 px-4 py-4 border-0">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full max-w-full flex-grow flex-1">
                    <Form class="filtersTable" 
                      // onSubmit={(e) => handleLogin(e)} 
                      // ref={c => {form = c;}}
                    >
                      <div className="flex justify-between items-center justify-center flex-wrap">
                        <div className="searchInputTables">
                          <div className="fieldSearch relative w-full mb-3">
                            <label
                              className="label block font-semibold text-black-600 text-xs mb-2"
                            >
                              Search
                            </label>
                            <Input
                              type="text"
                              className="searchFilter input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              // placeholder="Search by name, reference ID or inquiry ID"
                              // value={email}
                              // onChange={(e) => setEmail(e.target.value)}
                              // validations={[required, validEmail]}
                            />
                          </div>
                        </div>
                        <div className="flex flex-wrap">
                          <div className="mr-3">
                            <div className="field dropdown relative w-full mb-3">
                              <Select styles={customStylesSelect} options={ timeperiod } isSearchable={false} defaultValue={defaultValueTime} />
                            </div>
                          </div>
                          <div className="mr-3">
                            <div className="field dropdown relative w-full mb-3">
                              <Select styles={customStylesSelect} options={ risklevel } isSearchable={false} defaultValue={defaultValueRiskLevel} />
                            </div>
                          </div>
                          <div className="mr-3">
                            <div className="field dropdown relative w-full mb-3">
                              <Select styles={customStylesSelect} options={ status } isSearchable={false} defaultValue={defaultValueStatus} />
                            </div>
                          </div>
                          <div className="mr-3">
                            <div className="field dropdown relative w-full mb-3">
                              <Select styles={customStylesSelect} options={ countrywise } isSearchable={false} defaultValue={defaultValueCountryWise} />
                            </div>
                          </div>
                          <div>
                            <div className="field dropdown relative w-full mb-3">
                              <Select styles={customStylesSelect} options={ tags } isSearchable={false} defaultValue={defaultValueTags} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
              <div className="block w-full overflow-x-auto">
                {/* Projects table */}
                <table className="items-center w-full bg-transparent border-collapse">
                  <thead className="border border-solid border-t-0 border-l-0 border-r-0 border-blueGray-300">
                    <tr>
                      <th
                        className={
                          "px-6 align-middle py-3 text-sm capitalize whitespace-nowrap font-semibold text-left text-black-600"
                        }
                      >
                        Name
                      </th>
                      <th
                        className={
                          "px-6 align-middle py-3 text-sm capitalize whitespace-nowrap font-semibold text-left text-black-600"
                        }
                      >
                        KYC Status
                      </th>
                      <th
                        className={
                          "px-6 align-middle py-3 text-sm capitalize whitespace-nowrap font-semibold text-left text-black-600"
                        }
                      >
                        Risk Level
                      </th>
                      <th
                        className={
                          "px-6 align-middle py-3 text-sm capitalize whitespace-nowrap font-semibold text-left text-black-600"
                        }
                      >
                        Assigned To
                      </th>
                      <th
                        className={
                          "px-6 align-middle py-3 text-sm capitalize whitespace-nowrap font-semibold text-left text-black-600"
                        }
                      >
                        Reviewed By
                      </th>
                      <th
                        className={
                          "px-6 align-middle py-3 text-sm capitalize whitespace-nowrap font-semibold text-left text-black-600"
                        }
                      >
                        Inquiry Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="cursor-pointer active-row">
                      <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-left flex flex-col items-left">
                        <Link to="/aml/casemanagementdetail">
                        <span
                          className={
                            "text-base font-bold text-black-600"
                          }
                        >
                          John Snow
                        </span>
                        <div className="text-xs flex flex-col otherDetailsName">
                          <span>Inquiry ID: inq_128710343324adawkm</span>
                          <span>Creation Date: 10/02/2022</span>
                        </div>
                        </Link>
                      </th>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        <div className="statusGrid flex flex-row">
                          <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltipID}
                          >
                            <img className="account mr-3" src={statusAccountGreen} alt="status-account" />
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltipGovt}
                          >
                            <img className="id mr-3" src={statusIDGreen} alt="status-id" />
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltipAddress}
                          >
                            <img className="loc mr-3" src={statusLocRed} alt="status-loc" />
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltipAml}
                          >
                            <img className="map" src={statusMapGreen} alt="status-map" />
                          </OverlayTrigger>
                        </div>
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        <div className="circularProgress mx-auto">
                          <CircularProgressbar 
                            value={80} 
                            strokeWidth={25}
                            styles={buildStyles({
                              strokeLinecap: 'butt',
                              pathColor: `${circularBarColorAccepted}`,
                              trailColor: '#FFF'
                            })}
                          />
                        </div>
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4">
                        <div className="flex items-center">
                          <div className="relative w-full">
                            <p className="text-sm font-normal">Moderator</p>
                          </div>
                        </div>
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4">
                        <div className="flex items-center">
                          <div className="relative w-full">
                            <p className="text-sm font-normal">Moderator</p>
                          </div>
                        </div>
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                        {/* <TableDropdown /> */}
                        <div className="buttonAction">
                          <button className="w-full py-3 px-4 rounded-lg font-semibold text-white-400 uppercase buttonGreen">
                            Approved
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr className="cursor-pointer">
                      <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-left flex flex-col items-left">
                        <Link to="/aml/casemanagementdetail">
                        <span
                          className={
                            "text-base font-bold text-black-600"
                          }
                        >
                          John Snow
                        </span>
                        <div className="text-xs flex flex-col otherDetailsName">
                          <span>Inquiry ID: inq_128710343324adawkm</span>
                          <span>Creation Date: 10/02/2022</span>
                        </div>
                        </Link>
                      </th>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        <div className="statusGrid flex flex-row">
                          <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltipID}
                          >
                            <img className="account mr-3" src={statusAccountGreen} alt="status-account" />
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltipGovt}
                          >
                            <img className="id mr-3" src={statusIDGreen} alt="status-id" />
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltipAddress}
                          >
                            <img className="loc mr-3" src={statusLocRed} alt="status-loc" />
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltipAml}
                          >
                            <img className="map" src={statusMapGreen} alt="status-map" />
                          </OverlayTrigger>
                        </div>
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        <div className="circularProgress mx-auto">
                          <CircularProgressbar 
                            value={40} 
                            strokeWidth={25}
                            styles={buildStyles({
                              strokeLinecap: 'butt',
                              pathColor: `${circularBarColorPending}`,
                              trailColor: '#FFF'
                            })}
                          />
                        </div>
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4">
                        <div className="flex items-center">
                          <div className="relative w-full">
                            <p className="text-sm font-normal">Moderator</p>
                          </div>
                        </div>
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4">
                        <div className="flex items-center">
                          <div className="relative w-full">
                            <p className="text-sm font-normal">Moderator</p>
                          </div>
                        </div>
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                        {/* <TableDropdown /> */}
                        <div className="buttonAction">
                          <button className="w-full py-3 px-4 rounded-lg font-semibold text-white-400 uppercase buttonYellow">
                            Pending
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full xl:w-3/12 lg:w-12/12 md:w-12/12 sm:w-12/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg mt-16">
            <div className="px-6">
              <div className="flex flex-wrap justify-center">
                <div className="w-full px-4 flex justify-center">
                  <div className="relative">
                    <img
                      alt="..."
                      src={require("assets/img/ProfilePicture.png").default}
                      className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-12 lg:-ml-12 max-w-100-px"
                    />
                  </div>
                </div>
                <div className="w-full px-4 text-center mt-12">
                  <div className="flex justify-center py-4 lg:pt-4 pt-8">
                    <h3 className="text-xl font-semibold leading-normal mb-2 text-black-700 mb-2">
                      John Snow
                    </h3>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <div className="text-base leading-normal mt-0 mb-2 text-white-400 font-bold uppercase">
                  <button className="w-full py-4 px-4 rounded-lg font-semibold text-white-400 uppercase buttonGreen text-sm">
                    Approved
                  </button>
                </div>
              </div>
              <div className="py-4 text-left">
                <div className="flex flex-wrap">
                  <div className="flex items-center justify-center font-semibold w-full">
                    <p className="lg:w-2/12 md:w-2/12 sm:w-2/12 text-sm">Info</p>
                    <div className="lg:w-10/12" style={{ height: "0", border: "1px solid #D3D3D3" }}></div>
                  </div>
                  <div className="mt-2 fields block">
                    <div className="enquiryId mt-2" style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                      <p className="text-xs">Inquiry ID</p>
                      <b className="text-xs">inq_128710343324adawkm</b>
                    </div>
                    <div className="IdNo mt-2" style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                      <p className="text-xs">ID Number</p>
                      <b className="text-xs">1234 5678832 3</b>
                    </div>
                    <div className="dob mt-2" style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                      <p className="text-xs">Date of Birth</p>
                      <b className="text-xs">10/12/2022</b>
                    </div>
                    <div className="nationality mt-2" style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                      <p className="text-xs">Nationality</p>
                      <b className="text-xs">Pakistani</b>
                    </div>
                  </div>
                </div>
              </div>

              <div className="py-4 text-left">
                <div className="flex flex-wrap">
                  <div className="flex items-center justify-center font-semibold w-full">
                    <p className="lg:w-2/12 md:w-2/12 sm:w-2/12 text-sm">Event</p>
                    <div className="lg:w-10/12" style={{ height: "0", border: "1px solid #D3D3D3" }}></div>
                  </div>
                  <div className="w-full mt-2 fields block eventTimeline">
                    <div className="status mt-2">
                      <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                        <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                        <div className="text text-xs font-semibold">
                          Created
                          <p className="createdDate font-normal mt-2">10/20/22  |  12:30am</p>
                        </div>
                      </div>
                      <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                        <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                        <div className="text text-xs font-semibold"></div>
                      </div>
                    </div>
                    <div className="status mt-2">
                      <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                        <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                        <div className="text text-xs font-semibold">
                          Government ID verification started
                          <p className="createdDate font-normal mt-2">10/20/22  |  12:30am</p>
                        </div>
                      </div>
                      <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                        <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                        <div className="text text-xs font-semibold"></div>
                      </div>
                    </div>
                    <div className="status mt-2">
                      <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                        <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                        <div className="text text-xs font-semibold">
                          Government ID verification submitted
                          <p className="createdDate font-normal mt-2">10/20/22  |  12:30am</p>
                        </div>
                      </div>
                      <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                        <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                        <div className="text text-xs font-semibold"></div>
                      </div>
                    </div>
                    <div className="status mt-2">
                      <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                        <div className="imgTag"><img src={ElipsesTwo} alt="created" /></div>
                        <div className="text text-xs font-semibold">
                          Government ID verification passed
                          <p className="createdDate font-normal mt-2">10/20/22  |  12:30am</p>
                        </div>
                      </div>
                      <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                        <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                        <div className="text text-xs font-semibold"></div>
                      </div>
                    </div>
                    <div className="status mt-2">
                      <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                        <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                        <div className="text text-xs font-semibold">
                          Selfie video verification started
                          <p className="createdDate font-normal mt-2">10/20/22  |  12:30am</p>
                        </div>
                      </div>
                      <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                        <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                        <div className="text text-xs font-semibold"></div>
                      </div>
                    </div>
                    <div className="status mt-2">
                      <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                        <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                        <div className="text text-xs font-semibold">
                          Selfie video verification submitted
                          <p className="createdDate font-normal mt-2">10/20/22  |  12:30am</p>
                        </div>
                      </div>
                      <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                        <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                        <div className="text text-xs font-semibold"></div>
                      </div>
                    </div>
                    <div className="status mt-2">
                      <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                        <div className="imgTag"><img src={ElipsesTwo} alt="created" /></div>
                        <div className="text text-xs font-semibold">
                          Selfie video verification passed
                          <p className="createdDate font-normal mt-2">10/20/22  |  12:30am</p>
                        </div>
                      </div>
                      <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                        <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                        <div className="text text-xs font-semibold"></div>
                      </div>
                    </div>
                    <div className="status mt-2">
                      <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                        <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                        <div className="text text-xs font-semibold">
                          Document verification started
                          <p className="createdDate font-normal mt-2">10/20/22  |  12:30am</p>
                        </div>
                      </div>
                      <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                        <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                        <div className="text text-xs font-semibold"></div>
                      </div>
                    </div>
                    <div className="status mt-2">
                      <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                        <div className="imgTag"><img src={ElipsesOne} alt="created" /></div>
                        <div className="text text-xs font-semibold">
                          Document verification submitted
                          <p className="createdDate font-normal mt-2">10/20/22  |  12:30am</p>
                        </div>
                      </div>
                      <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                        <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                        <div className="text text-xs font-semibold"></div>
                      </div>
                    </div>
                    <div className="status mt-2">
                      <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                        <div className="imgTag"><img src={ElipsesTwo} alt="created" /></div>
                        <div className="text text-xs font-semibold">
                          Document verification passed
                          <p className="createdDate font-normal mt-2">10/20/22  |  12:30am</p>
                        </div>
                      </div>
                      <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                        <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                        <div className="text text-xs font-semibold"></div>
                      </div>
                    </div>
                    <div className="status mt-2">
                      <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                        <div className="imgTag"><img src={ElipsesTwo} alt="created" /></div>
                        <div className="text text-xs font-semibold">
                          Inquiry completed
                          <p className="createdDate font-normal mt-2">10/20/22  |  12:30am</p>
                        </div>
                      </div>
                      <div className="w-full items-center justify-center" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                        <div className="imgTag px-2"><img className="ml-1" src={VerticalLine} alt="created" /></div>
                        <div className="text text-xs font-semibold"></div>
                      </div>
                    </div>
                    <div className="status mt-2">
                      <div className="w-full mt-2" style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
                        <div className="imgTag"><img src={ElipsesTwo} alt="created" /></div>
                        <div className="text text-xs font-semibold">
                          Inquiry approved by Workflow
                          <p className="createdDate font-normal mt-2">10/20/22  |  12:30am</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
