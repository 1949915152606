import axios from "axios";
import address from "./Address";


export function getAllSanctionList() {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");
    const user = JSON.parse(storage.getItem("user"));

    if (accessToken) {
        return axios.get(address + "/sanctions_list/" + user.id, { headers: {"Authorization" : `Bearer ${accessToken}`} });
    } else {
        return false;
    }
}

export function getAllOpenSanctionList() {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.get(address + "/open-sanctions-lists", { headers: {"Authorization" : `Bearer ${accessToken}`} });
    } else {
        return false;
    }
}