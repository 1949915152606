import React from "react";
import Select from "react-select";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";

export default function NetworkSettings() {
  const color = "light";
  const [value, setValue] = React.useState([]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "12px",
    }),
  };

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  return (
    <>
      <div className="networkSetting flex flex-wrap">
        <div className="w-full xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
            <div
              className={
                "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg " +
                (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
              }
            >
              <div className="rounded-t mb-0 px-4 py-4 border-0">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full px-4 max-w-full flex-grow flex-1 ">
                    <div className="px-4 mr-4" style={{ paddingLeft: "0" }}>
                      <h4 className="borderDefault summaryHeading py-2 font-bold text-base">
                        Nodes of Interest
                      </h4>
                    </div>
                    <p class="text-xs whitespace-nowrap mt-2">
                      These settings will be used as default for graph
                      functionality where applicable.
                    </p>
                    <div className="w-full mb-8 mt-6">
                      <Form class="inquireForm w-full">
                        <div className="lg:w-12/12 md:w-12/12 sm:w-12/12 mr-4">
                          <div className="searchInputTables mt-4">
                            <div className="fieldInquire relative w-full mb-3">
                              <label className="label block font-semibold text-black-600 text-xs mb-2">
                                Properties
                              </label>
                              <Select
                                closeMenuOnSelect={false}
                                isMulti
                                options={options}
                                style={{ all: "unset"}}
                                className="propertySelect input_Fields"
                                menuPortalTarget={document.body}
                                styles={customStyles}
                              />
                            </div>
                          </div>
                          <div className="searchInputTables mt-6">
                            <div className="fieldInquire relative w-full mb-3">
                              <label className="label block font-semibold text-black-600 text-xs mb-2">
                                Accounts Tags
                              </label>
                              <Select
                                closeMenuOnSelect={false}
                                isMulti
                                options={options}
                                style={{ all: "unset" }}
                                className="accountSelect input_Fields"
                                styles={customStyles}
                                menuPortalTarget={document.body}
                              />
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
            <div
              className={
                "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg " +
                (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
              }
            >
              <div className="rounded-t mb-0 px-4 py-4 border-0">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full px-4 max-w-full flex-grow flex-1 ">
                    <div className="px-4 mr-4" style={{ paddingLeft: "0" }}>
                      <h4 className="borderDefault summaryHeading py-2 font-bold text-base">
                        Explorer Defaults
                      </h4>
                    </div>
                    <div className="w-full mb-8 mt-6">
                      <Form class="inquireForm w-full">
                        <div className="lg:w-12/12 md:w-12/12 sm:w-12/12 mr-4">
                          <div className="searchInputTables mt-4">
                            <div className="fieldInquire relative w-full mb-3">
                              <label className="label block font-semibold text-black-600 text-xs mb-2">
                                Minimum Cluster Size
                              </label>
                              <Input
                                type="number"
                                name="minCluster"
                                className="amlInquireFields input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                style={{ fontSize: "0.75rem" }}
                              />
                            </div>
                          </div>
                          <div className="searchInputTables mt-6">
                            <div className="fieldInquire relative w-full mb-3">
                              <label className="label block font-semibold text-black-600 text-xs mb-2">
                                Maximum Cluster Size
                              </label>
                              <Input
                                type="number"
                                name="maxCluster"
                                className="amlInquireFields input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                style={{ fontSize: "0.75rem" }}
                              />
                            </div>
                          </div>
                          <div className="searchInputTables mt-6">
                            <div className="fieldInquire relative w-full mb-3">
                              <label className="label block font-semibold text-black-600 text-xs mb-2">
                                Data Range (days)
                              </label>
                              <Input
                                type="number"
                                name="dataRange"
                                className="amlInquireFields input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                style={{ fontSize: "0.75rem" }}
                              />
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
