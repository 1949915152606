import React, { useState, useContext, useRef, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { KycMobContext } from "../../layouts/KYCMob";
import Select from "react-select";
import { savelanguage } from "services/kyc/KycScreens";
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import platform from "platform";
import {QRCodeSVG} from 'qrcode.react';
import { getevents } from "services/casemanagement/Onboarding";
import { saveevent } from "services/kyc/KycScreens";

import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import countryList from 'react-select-country-list';
import { savebasicinfo } from "../../services/kyc/KycScreens";
import { getacceptablecountries } from "../../services/settings/Acceptable";
import DatePicker from "react-datepicker";
import Moment from "moment";
import { addDays } from "date-fns";

import Webcam from "react-webcam";
import { saveliveness } from "services/kyc/KycScreens";
import ReactLoading from "react-loading";

import warningImage from " ../../assets/img/kycmobile/warningMessage.png";
import cameraFrameImg from " ../../assets/img/kycmobile/camera_frame.png";
import cameraButton from " ../../assets/img/kycmobile/camera_button.png";
import cycle from "../../assets/img/icons/cycle.svg";

import backButtonImg from " ../../assets/img/kycmobile/backBtn.png";
import closeButtonImg from " ../../assets/img/kycmobile/cross_white.png";
import mainImg from " ../../assets/img/kycmobile/frame_6.png";
import footerImg from " ../../assets/img/kycmobile/footer_trademark.png";
import mobileLaptop from "../../assets/img/mobile_laptop.png";
import mainImgScreen4 from "../../assets/img/kycmobile/rectangle.png";

export default function Screen() {
  const [flagScreen1, setFlagScreen1] = React.useState(true);
  const [flagScreenQR, setFlagScreenQR] = React.useState(false);
  const [flagScreen2, setFlagScreen2] = React.useState(false);
  const [flagScreen3, setFlagScreen3] = React.useState(false);
  const [flagScreen4, setFlagScreen4] = React.useState(false);
  const [flagScreen5, setFlagScreen5] = React.useState(false);
  const [flagScreen10, setFlagScreen10] = React.useState(false);
  const [flagScreen11, setFlagScreen11] = React.useState(false);
  const [flagScreen12, setFlagScreen12] = React.useState(false);
  const [flagScreen13, setFlagScreen13] = React.useState(false);
  const [flagScreen14, setFlagScreen14] = React.useState(false);
  const [flagScreen16, setFlagScreen16] = React.useState(false);
  const [flagScreen17, setFlagScreen17] = React.useState(false);
  const [flagScreen17i, setFlagScreen17i] = React.useState(false);
  const [flagScreen18, setFlagScreen18] = React.useState(false);
  const [flagScreen19, setFlagScreen19] = React.useState(false);
  const [flagScreen22, setFlagScreen22] = React.useState(false);
  const [flagScreen24, setFlagScreen24] = React.useState(false);
  const [flagScreen25, setFlagScreen25] = React.useState(false);
  const [flagScreen26, setFlagScreen26] = React.useState(false);
  const [flagScreen27, setFlagScreen27] = React.useState(false);

  // screen 1
  const [returningClient, setReturningClient] = useState(false);
  const [alreadyComplete, setAlreadyComplete] = useState(false);
  const [returningUrl, setReturningUrl] = useState(null);
  const { data } = useContext(KycMobContext);
  const url = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
  const [urlId, setUrlId] = React.useState(url);

  React.useEffect(() => {
    if (url) {
      getevents(url).then((response) => {
        // console.log("response: ", response);
        if (response.data.success) {
          if (response.data.event.length > 0) {
            setReturningClient(true);
            if (response.data.event[response.data.event.length - 1].event_desc === "Created") {
              setReturningUrl("/kyb/e/" + url);
            } else if (response.data.event[response.data.event.length - 1].event_desc === "Basic Information Saved") {
              setReturningUrl("/kyb/g/" + url);
            } else if (response.data.event[response.data.event.length - 1].event_desc === "Selfie video verification started") {
              setReturningUrl("/kyb/g/" + url);
            } else if (response.data.event[response.data.event.length - 1].event_desc === "Selfie video verification passed") {
              setReturningUrl("/kyb/10/" + url);
            } else if (response.data.event[response.data.event.length - 1].event_desc === "Government ID verification started") {
              setReturningUrl("/kyb/10/" + url);
            } else if (response.data.event[response.data.event.length - 1].event_desc === "Government ID verification passed") {
              setReturningUrl("/kyb/18/" + url);
            } else if (response.data.event[response.data.event.length - 1].event_desc === "Document verification started") {
              setReturningUrl("/kyb/18/" + url);
            } else if (response.data.event[response.data.event.length - 1].event_desc === "Document verification passed") {
              setReturningUrl("/kyb/25/" + url);
            } else if (response.data.event[response.data.event.length - 1].event_desc === "Inquiry completed") {
              setAlreadyComplete(true);
            }
          }
        }
      })
      .catch((error) => {
        console.log("error: ", error);
      });
    }
  }, []);

  const handleCross = () => {
    var ua = window.navigator.userAgent;

    if (ua.indexOf("iPhone") > 0) {
      window.close();
    }
    window.close();
  };

  const continueFunction = () => {
    if (returningUrl == "/kyb/e/" + url) {
      setFlagScreen1(false);
      setFlagScreenQR(false);
      setFlagScreen2(false);
      setFlagScreen3(true);
      setFlagScreen4(false);
      setFlagScreen5(false);
      setFlagScreen10(false);
      setFlagScreen11(false);
      setFlagScreen12(false);
      setFlagScreen13(false);
      setFlagScreen14(false);
      setFlagScreen16(false);
      setFlagScreen17(false);
      setFlagScreen17i(false);
      setFlagScreen18(false);
      setFlagScreen19(false);
      setFlagScreen22(false);
      setFlagScreen24(false);
      setFlagScreen25(false);
      setFlagScreen26(false);
      setFlagScreen27(false);
    } else if (returningUrl == "/kyb/g/" + url) {
      setFlagScreen1(false);
      setFlagScreenQR(false);
      setFlagScreen2(false);
      setFlagScreen3(false);
      setFlagScreen4(true);
      setFlagScreen5(false);
      setFlagScreen10(false);
      setFlagScreen11(false);
      setFlagScreen12(false);
      setFlagScreen13(false);
      setFlagScreen14(false);
      setFlagScreen16(false);
      setFlagScreen17(false);
      setFlagScreen17i(false);
      setFlagScreen18(false);
      setFlagScreen19(false);
      setFlagScreen22(false);
      setFlagScreen24(false);
      setFlagScreen25(false);
      setFlagScreen26(false);
      setFlagScreen27(false);
    } else if (returningUrl == "/kyb/10/" + url) {
      setFlagScreen1(false);
      setFlagScreenQR(false);
      setFlagScreen2(false);
      setFlagScreen3(false);
      setFlagScreen4(false);
      setFlagScreen5(false);
      setFlagScreen10(true);
      setFlagScreen11(false);
      setFlagScreen12(false);
      setFlagScreen13(false);
      setFlagScreen14(false);
      setFlagScreen16(false);
      setFlagScreen17(false);
      setFlagScreen17i(false);
      setFlagScreen18(false);
      setFlagScreen19(false);
      setFlagScreen22(false);
      setFlagScreen24(false);
      setFlagScreen25(false);
      setFlagScreen26(false);
      setFlagScreen27(false);
    }
  }

  // screen 2
  const [messageScreen2, setMessageScreen2] = useState("");
  const history = useHistory();
  const [languageScreen2, setLanguageScreen2] = useState(null);
  const [deviceTypeScreen2, setDeviceTypeScreen2] = React.useState(platform.manufacturer);
  const [deviceOsScreen2, setDeviceOsScreen2] = React.useState(platform.os.family);
  const [browserScreen2, setBrowserScreen2] = React.useState(platform.name);
  const [latitudeScreen2, setLatitudeScreen2] = React.useState(null);
  const [longitudeScreen2, setLongitudeScreen2] = React.useState(null);
  var fpPromiseScreen2 = null;
  var result_fingerprintScreen2 = null;

  function showPositionScreen2(position) {
    setLatitudeScreen2(position.coords.latitude);
    setLongitudeScreen2(position.coords.longitude);
  }

  function getLocationScreen2() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPositionScreen2);
    }
  }

  React.useEffect(()=>{
    getLocationScreen2();
  }, []);

  const optionsScreen2 = [
    { label: "English (United States)", value: 1 },
    { label: "English (UK)", value: 2 },
    { label: "French (France)", value: 3 },
    { label: "Arabic (Saudia Arabia)", value: 4 },
    { label: "German (Germany)", value: 5 },
    { label: "Urdu (Pakistan)", value: 6 },
  ];

  const customStylesScreen2 = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
    }),
  };

  const handleChangeSelectScreen2 = (event) => {
    setLanguageScreen2(event.label);
  };

  const handleSubmitScreen2 = () => {
    setMessageScreen2("");
    if (languageScreen2) {
      fpPromiseScreen2 = FingerprintJS.load({ apiKey: 'shovSBGBTHqND9JRFNaa' });
      ;(async () => {
        // Get the visitor identifier when you need it.
        const fp = await fpPromiseScreen2;
        result_fingerprintScreen2 = await fp.get({extendedResult: true});
        
        let visitor_id = result_fingerprintScreen2.visitorId;
        let os = result_fingerprintScreen2.os;
        let os_version = result_fingerprintScreen2.osVersion;
        let first_seen = result_fingerprintScreen2.firstSeenAt.global;
        let last_seen = result_fingerprintScreen2.lastSeenAt.global;
        let ip = result_fingerprintScreen2.ip;
        let browser_name = result_fingerprintScreen2.browserName;
        let browser_version = result_fingerprintScreen2.browserVersion;
        let incognito = result_fingerprintScreen2.incognito;

        let formdata = {
          inq_id: urlId,
          language: languageScreen2,
          browser_name: browser_name,
          browser_version: browser_version,
          os: os,
          os_version: os_version,
          visitor_id: visitor_id,
          first_seen: first_seen,
          last_seen: last_seen,
          ip: ip,
          incognito: incognito,
        };
    
        savelanguage(formdata).then((response) => {
          if (response.data.success) {
            setFlagScreen1(false);
            setFlagScreenQR(false);
            setFlagScreen2(false);
            setFlagScreen3(true);
            setFlagScreen4(false);
            setFlagScreen5(false);
            setFlagScreen10(false);
            setFlagScreen11(false);
            setFlagScreen12(false);
            setFlagScreen13(false);
            setFlagScreen14(false);
            setFlagScreen16(false);
            setFlagScreen17(false);
            setFlagScreen17i(false);
            setFlagScreen18(false);
            setFlagScreen19(false);
            setFlagScreen22(false);
            setFlagScreen24(false);
            setFlagScreen25(false);
            setFlagScreen26(false);
            setFlagScreen27(false);
          }
        }).catch((error) => {
          console.log("error: ", error)
        });
      })();
    } else {
      setMessageScreen2("Please select a language.");
    }
  }

  // QR screen
  const [main, setMain] = useState(true);
  const [QR, setQR] = useState(false);

  const handleMain = () => {
    setMain(false);
    setQR(true);
  };

  const handleBack = () => {
    setQR(false);
    setMain(true);
  };

  // screen 3
  const [messageScreen3, setMessageScreen3] = useState("");
  const [valueScreen3, setValueScreen3] = React.useState("")
  const [optionsScreen3, setOptionsScreen3] = React.useState(() => countryList().getData(), []);
  var formScreen3, checkBtnScreen3;
  
  const [formdataScreen3, setFormdataScreen3] = useState({
    email: "",
    first_name: "",
    last_name: "",
    dob: null,
    nationality: "",
    inq_id: url,
  });

  const handleChangeSelectScreen3 = (event, { name }) => {
    setFormdataScreen3((values) => ({ ...values, [name]: event }));
  };

  const handleInputChangeScreen3 = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormdataScreen3((values) => ({ ...values, [name]: value }));
  };

  const handleInputChangeDateScreen3 = (date) => {
    const name = "dob";
    const value = date;
    setFormdataScreen3((values) => ({ ...values, [name]: value }));
  };

  React.useEffect(() => {
    getacceptablecountries().then((response) => {
      if (response.data.success) {
        if (response.data.acceptable_countries.length > 0) {
          var filteredArray = optionsScreen3.filter(function(item_op) {
            return response.data.acceptable_countries.filter(function(item_resp) {
              return item_resp.country === item_op.label;
            }).length !== 0
          });
          setOptionsScreen3(filteredArray);
        }
      }
    }).catch((error) => {
      console.log("error: ", error);
    });
  }, []);

  const customStylesScreen3 = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      paddingTop: 0,
    }),
  };

  const disableDatesScreen3 = () => {
    const today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (dd < 10) {
      dd = "0" + dd;
    }
    return yyyy + "-" + mm + "-" + dd;
  };

  const handleSubmitScreen3 = () => {
    if (formdataScreen3.nationality !== "") {
      let formdata = {
        email: formdataScreen3.email,
        first_name: formdataScreen3.first_name,
        last_name: formdataScreen3.last_name,
        dob: formdataScreen3.dob,
        nationality: formdataScreen3.nationality.label,
        inq_id: url,
      };
      savebasicinfo(formdata)
        .then((response) => {
          if (response.data.success) {
            setFlagScreen1(false);
            setFlagScreenQR(false);
            setFlagScreen2(false);
            setFlagScreen3(false);
            setFlagScreen4(true);
            setFlagScreen5(false);
            setFlagScreen10(false);
            setFlagScreen11(false);
            setFlagScreen12(false);
            setFlagScreen13(false);
            setFlagScreen14(false);
            setFlagScreen16(false);
            setFlagScreen17(false);
            setFlagScreen17i(false);
            setFlagScreen18(false);
            setFlagScreen19(false);
            setFlagScreen22(false);
            setFlagScreen24(false);
            setFlagScreen25(false);
            setFlagScreen26(false);
            setFlagScreen27(false);
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    } else {
      setMessageScreen3("Nationality is required!");
    }
  };

  // screen 4
  const handleNextScreen4 = () => {
    let formdata = {
      inq_id: url,
      event_desc: "Selfie video verification started",
      passed: 0
    };
    saveevent(formdata).then((response) => {
      if (response.data.success) {
        setFlagScreen1(false);
        setFlagScreenQR(false);
        setFlagScreen2(false);
        setFlagScreen3(false);
        setFlagScreen4(false);
        setFlagScreen5(true);
        setFlagScreen10(false);
        setFlagScreen11(false);
        setFlagScreen12(false);
        setFlagScreen13(false);
        setFlagScreen14(false);
        setFlagScreen16(false);
        setFlagScreen17(false);
        setFlagScreen17i(false);
        setFlagScreen18(false);
        setFlagScreen19(false);
        setFlagScreen22(false);
        setFlagScreen24(false);
        setFlagScreen25(false);
        setFlagScreen26(false);
        setFlagScreen27(false);
      }
    }).catch((error) => {
      console.log("error: ", error);
    })
  }

  return (
    <>
      {flagScreen1 && 
        <div
          className="screenOne h-full flex flex-col"
          style={{ fontFamily: data ? data.font_family : "Gilroy" }}
        >
          <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
            <div className="backArrow">
              <button 
                onClick={() => {
                  
                }}
              >
                <img src={backButtonImg} alt="backArrow" className="" />
              </button>
            </div>
            <div className="progressBar w-full py-3 px-12">
              <div className="relative lg:w-8/12 mx-auto">
                <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                  <div
                    style={{
                      width: "7%",
                      backgroundColor: data ? data.progressbar_color : "#a10b1d",
                    }}
                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center "
                  ></div>
                </div>
              </div>
            </div>
            <div className="crossArrow">
              <button onClick={handleCross}>
                <img src={closeButtonImg} alt="crossArrow" className="" />
              </button>
            </div>
          </div>

          {!returningClient && 
            <>
              <div class="mainContent">
                <div class="mainImage">
                  <img class="mainImg" src={mainImg} alt="main image" />
                </div>
                <div class="mainText">
                  <h2>Lets verify your Identity</h2>
                </div>
                <div class="supportingText">
                  <p class="supportingTextPara">
                    You will need to answer a few questions and this process only
                    takes a few minutes
                  </p>
                </div>
              </div>

              <div class="buttonAction">
                <button
                  style={{ backgroundColor: data ? data.button_color : "#a10b1d" }}
                  class="action"
                  onClick={() => {
                    setFlagScreen1(false);
                    setFlagScreenQR(true);
                    setFlagScreen2(false);
                    setFlagScreen3(false);
                    setFlagScreen4(false);
                    setFlagScreen5(false);
                    setFlagScreen10(false);
                    setFlagScreen11(false);
                    setFlagScreen12(false);
                    setFlagScreen13(false);
                    setFlagScreen14(false);
                    setFlagScreen16(false);
                    setFlagScreen17(false);
                    setFlagScreen17i(false);
                    setFlagScreen18(false);
                    setFlagScreen19(false);
                    setFlagScreen22(false);
                    setFlagScreen24(false);
                    setFlagScreen25(false);
                    setFlagScreen26(false);
                    setFlagScreen27(false);
                  }}
                >
                  Let's Start!
                </button>
              </div>
            </>
          }

          {returningClient && 
            <>
              <div class="mainContent">
                <div class="mainImage">
                  <img class="mainImg" src={mainImg} alt="main image" />
                </div>
                <div class="mainText">
                  <h2>Welcome back!</h2>
                </div>
                <div class="supportingText">
                  <p class="supportingTextPara">
                    Let's continue from where we left off!
                  </p>
                </div>
              </div>

              <div class="buttonAction">
                <button
                  style={{ backgroundColor: data ? data.button_color : "#a10b1d" }}
                  class="action"
                  onClick={continueFunction}
                >
                  Let's Continue!
                </button>
              </div>
            </>
          }

          {returningClient && alreadyComplete && 
            <>
              <div class="mainContent">
                <div class="mainImage">
                  <img class="mainImg" src={mainImg} alt="main image" />
                </div>
                <div class="mainText">
                  <h2>Inquiry Already Submitted!</h2>
                </div>
                <div class="supportingText">
                  <p class="supportingTextPara">
                    You can generate a new link to submit another inquiry!
                  </p>
                </div>
              </div>
            </>
          }

          <div class="footer">
            <img class="footerImg" src={footerImg} alt="footer" />
          </div>
        </div>
      }

      {flagScreenQR && 
        <div
          className="screenOne h-full flex flex-col"
          style={{ fontFamily: data ? data.font_family : "Gilroy" }}
        >
          <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
            <div className="backArrow">
              <button 
                onClick={() => {
                  setFlagScreen1(true);
                  setFlagScreenQR(false);
                  setFlagScreen2(false);
                  setFlagScreen3(false);
                  setFlagScreen4(false);
                  setFlagScreen5(false);
                  setFlagScreen10(false);
                  setFlagScreen11(false);
                  setFlagScreen12(false);
                  setFlagScreen13(false);
                  setFlagScreen14(false);
                  setFlagScreen16(false);
                  setFlagScreen17(false);
                  setFlagScreen17i(false);
                  setFlagScreen18(false);
                  setFlagScreen19(false);
                  setFlagScreen22(false);
                  setFlagScreen24(false);
                  setFlagScreen25(false);
                  setFlagScreen26(false);
                  setFlagScreen27(false);
                }}
              >
                <img src={backButtonImg} alt="backArrow" className="" />
              </button>
            </div>
            <div className="progressBar w-full py-3 px-12">
              <div className="relative lg:w-8/12 mx-auto">
                <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                  <div
                    style={{
                      width: "7%",
                      backgroundColor: data ? data.progressbar_color : "#a10b1d",
                    }}
                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center "
                  ></div>
                </div>
              </div>
            </div>
            <div className="crossArrow">
              <button onClick={handleCross}>
                <img src={closeButtonImg} alt="crossArrow" className="" />
              </button>
            </div>
          </div>

          <div class="mainContent">
            <div class="mainImage mt-2">
              <QRCodeSVG className="mx-auto" value={window.location.origin + "/kyc/screen/" + urlId} size="200"/>
            </div>
            <div class="mainText mt-4">
              <h2>Continue on mobile</h2>
            </div>
            <div class="supportingText">
              <p class="supportingTextPara">
                Please scan the QR code below with your mobile device to continue your verification.
              </p>
            </div>
          </div>

          <div class="buttonAction">
            <button
              style={{ backgroundColor: data ? data.button_color : "#a10b1d" }}
              class="action"
              onClick={() => {
                setFlagScreen1(false);
                setFlagScreenQR(false);
                setFlagScreen2(true);
                setFlagScreen3(false);
                setFlagScreen4(false);
                setFlagScreen5(false);
                setFlagScreen10(false);
                setFlagScreen11(false);
                setFlagScreen12(false);
                setFlagScreen13(false);
                setFlagScreen14(false);
                setFlagScreen16(false);
                setFlagScreen17(false);
                setFlagScreen17i(false);
                setFlagScreen18(false);
                setFlagScreen19(false);
                setFlagScreen22(false);
                setFlagScreen24(false);
                setFlagScreen25(false);
                setFlagScreen26(false);
                setFlagScreen27(false);
              }}
            >
              Continue here
            </button>
          </div>

          <div class="footer">
            <img class="footerImg" src={footerImg} alt="footer" />
          </div>
        </div>
      }

      {flagScreen2 && 
        <div className="screenTwo h-full" style={{fontFamily: data ? data.font_family : "Gilroy"}}>
          <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
            <div className="backArrow">
              <button 
                onClick={() => {
                  setFlagScreen1(false);
                  setFlagScreenQR(true);
                  setFlagScreen2(false);
                  setFlagScreen3(false);
                  setFlagScreen4(false);
                  setFlagScreen5(false);
                  setFlagScreen10(false);
                  setFlagScreen11(false);
                  setFlagScreen12(false);
                  setFlagScreen13(false);
                  setFlagScreen14(false);
                  setFlagScreen16(false);
                  setFlagScreen17(false);
                  setFlagScreen17i(false);
                  setFlagScreen18(false);
                  setFlagScreen19(false);
                  setFlagScreen22(false);
                  setFlagScreen24(false);
                  setFlagScreen25(false);
                  setFlagScreen26(false);
                  setFlagScreen27(false);
                }}
              >
                <img src={backButtonImg} alt="backArrow" className="" />
              </button>
            </div>
            <div className="progressBar w-full py-3 px-12">
              <div className="relative lg:w-8/12 mx-auto">
                <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                  <div
                    style={{ width: "14%",backgroundColor:data ? data.progressbar_color:"#a10b1d" }}
                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center "
                  ></div>
                </div>
              </div>
            </div>
            <div className="crossArrow">
              <button onClick={handleCross}>
                <img src={closeButtonImg} alt="crossArrow" className="" />
              </button>
            </div>
          </div>

          <div class="mainContent">
            <div class="mainText">
              <h1>Select your language</h1>
            </div>
            <div class="languageSelection">
              <div className="globeDiv py-4 pb-4">
                <Select
                  onChange={handleChangeSelectScreen2}
                  className="globeSelect"
                  styles={customStylesScreen2}
                  options={optionsScreen2}
                />
              </div>
            </div>

            {messageScreen2 && (
              <section className="form-group">
                <section className="text-sm alert alert-danger text-center" role="alert">
                  {messageScreen2}
                </section>
              </section>
            )}
          </div>

          <div class="buttonAction">
            <button onClick={handleSubmitScreen2} style={{backgroundColor: data ? data.button_color : "#a10b1d"}}  class="action">Let's Start!</button>
          </div>

          <div class="footer">
            <img class="footerImg" src={footerImg} alt="footer" />
          </div>
        </div>
      }

      {flagScreen3 && 
        <div className="screenThree h-full" style={{fontFamily: data ? data.font_family : "Gilroy"}}>
          <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
            <div className="backArrow">
              <button 
                onClick={() => {
                  setFlagScreen1(false);
                  setFlagScreenQR(false);
                  setFlagScreen2(true);
                  setFlagScreen3(false);
                  setFlagScreen4(false);
                  setFlagScreen5(false);
                  setFlagScreen10(false);
                  setFlagScreen11(false);
                  setFlagScreen12(false);
                  setFlagScreen13(false);
                  setFlagScreen14(false);
                  setFlagScreen16(false);
                  setFlagScreen17(false);
                  setFlagScreen17i(false);
                  setFlagScreen18(false);
                  setFlagScreen19(false);
                  setFlagScreen22(false);
                  setFlagScreen24(false);
                  setFlagScreen25(false);
                  setFlagScreen26(false);
                  setFlagScreen27(false);
                }}
              >
                <img src={backButtonImg} alt="backArrow" className="" />
              </button>
            </div>
            <div className="progressBar w-full py-3 px-12">
              <div className="relative lg:w-8/12 mx-auto">
                <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                  <div
                    style={{ width: "20%", backgroundColor:data ? data.progressbar_color : "#a10b1d"}}
                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"
                  ></div>
                </div>
              </div>
            </div>
            <div className="crossArrow">
              <button  onClick={handleCross}>
                <img src={closeButtonImg} alt="crossArrow" className="" />
              </button>
            </div>
          </div>

          <div className="mainContent" >
            <div className="mainText">
              <h1>Personal / Basic Information</h1>
            </div>
            <Form className="informationSection mt-8">
              <div className="nameFields">
                  <div className="firstNameField firstField">
                    <div className="inputfield firstname">
                      <span className="label text-sm">First Name</span>
                      <Input 
                        type="text" 
                        name="first_name" 
                        className="input firstnameInput" 
                        value={formdataScreen3.first_name}
                        onChange={handleInputChangeScreen3}
                      />
                    </div>
                  </div>
                  <div className="lastNameField">
                    <div className="inputfield lastname">
                      <span className="label text-sm">Last Name</span>
                      <Input 
                        type="text" 
                        name="last_name" 
                        className="input lastnameInput" 
                        value={formdataScreen3.last_name}
                        onChange={handleInputChangeScreen3}
                      />
                    </div>
                  </div>
              </div>
              <div className="dateField">
                <div className="inputfield dob">
                  <span className="label text-sm">Date of Birth</span>
                  <DatePicker 
                    selected={formdataScreen3.dob} 
                    maxDate={addDays(new Date(), 0)}
                    onChange={(date) => handleInputChangeDateScreen3(date)}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="mm/dd/yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </div>

              </div>
              <div className="emailFields">
                <div className="inputfield email">
                  <span className="label text-sm">Email</span>
                  <Input 
                    type="email" 
                    name="email" 
                    className="input emailInput"
                    value={formdataScreen3.email}
                    onChange={handleInputChangeScreen3}
                  />
                </div>
              </div>
              <div className="nationalityField">
                <div className="inputfield dob">
                  <span className="label text-sm">Nationality</span>
                  <Select 
                    name="nationality"
                    className="nationalityInput"
                    style={{ all: "unset" }} 
                    placeholder="Search Country" 
                    options={optionsScreen3} 
                    value={formdataScreen3.nationality}
                    onChange={handleChangeSelectScreen3}
                    menuPortalTarget={document.body} 
                    styles={customStylesScreen3} 
                  />
                </div>
              </div>

              {messageScreen3 && (
                <section className="form-group">
                  <section
                    className="text-sm alert alert-danger"
                    role="alert"
                  >
                    {messageScreen3}
                  </section>
                </section>
              )}
            </Form>
          </div>

          <div className="buttonAction">
            <button 
              className="action"
              style={{
                backgroundColor: data ? data.button_color : "#a10b1d",
              }}
              onClick={handleSubmitScreen3}
            >
              Next
            </button>
          </div>

          <div className="footer" >
            <img className="footerImg" src={footerImg} alt="footer"/>
          </div>
        </div>
      }

      {flagScreen4 && 
        <div className="screenFour h-full" style={{fontFamily: data ? data.font_family : "Gilroy"}}>
          <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
            <div className="backArrow">
              <button 
                onClick={() => {
                  setFlagScreen1(false);
                  setFlagScreenQR(false);
                  setFlagScreen2(false);
                  setFlagScreen3(true);
                  setFlagScreen4(false);
                  setFlagScreen5(false);
                  setFlagScreen10(false);
                  setFlagScreen11(false);
                  setFlagScreen12(false);
                  setFlagScreen13(false);
                  setFlagScreen14(false);
                  setFlagScreen16(false);
                  setFlagScreen17(false);
                  setFlagScreen17i(false);
                  setFlagScreen18(false);
                  setFlagScreen19(false);
                  setFlagScreen22(false);
                  setFlagScreen24(false);
                  setFlagScreen25(false);
                  setFlagScreen26(false);
                  setFlagScreen27(false);
                }}
              >
                <img src={backButtonImg} alt="backArrow" className="" />
              </button>
            </div>
            <div className="progressBar w-full py-3 px-12">
              <div className="relative lg:w-8/12 mx-auto">
                <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                  <div
                    style={{ width: "20%",backgroundColor:data ? data.progressbar_color:"#a10b1d" }}
                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"
                  ></div>
                </div>
              </div>
            </div>
            <div className="crossArrow">
              <button onClick={handleCross}>
                <img src={closeButtonImg} alt="crossArrow" className="" />
              </button>
            </div>
          </div>

          <div className="mainContent" >
            <div className="mainImage">
              <img className="mainImg" src={mainImgScreen4} alt="main image" />
            </div>
            <div className="mainText">
              <h1>Begin video selfie with liveness detection</h1>
            </div>
            <div className="supportingText">
              <p>You will need to upload your identity document with a validity period of at least one month and scan your face for liveness detection</p>
            </div>
          </div>

          <div className="buttonAction">
            <button onClick={handleNextScreen4} style={{backgroundColor:data?data.button_color:"#a10b1d"}}  className="action">Lets do it!</button>
          </div>

          <div className="footer">
            <img className="footerImg" src={footerImg} alt="footer" />
          </div>
        </div>
      }
    </>
  );
}
