import React, { PureComponent, createRef, Component} from "react";
import { ForceGraph2D, ForceGraph3D, ForceGraphVR, ForceGraphAR } from 'react-force-graph';
import SpriteText from 'three-spritetext';
import { withSize } from "react-sizeme";
import { getGraphData } from "../../services/onchain/Onchain";

const withSizeHOC = withSize({monitorWidth:true, monitorHeight:false, noPlaceholder:true});
const NODE_R = 8;

class OnchainChart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      highlightNodes: new Set(),
      highlightLinks: new Set(),
      hoverNode: null,
    }
    this.containerRef = createRef()
  }
  
  updateHighlight = () => {
    this.setState({highlightNodes: this.state.highlightNodes});
    this.setState({highlightLinks: this.state.highlightLinks});
  };

  handleNodeHover = node => {
    this.state.highlightNodes.clear();
    this.state.highlightLinks.clear();
    if (node) {
      this.state.highlightNodes.add(node);
      node.neighbors.forEach(neighbor => this.state.highlightNodes.add(neighbor));
      node.links.forEach(link => this.state.highlightLinks.add(link));
    }

    this.setState({hoverNode: this.state.hoverNode || null});
    this.updateHighlight();
  };

  handleLinkHover = link => {
    this.state.highlightNodes.clear();
    this.state.highlightLinks.clear();

    if (link) {
      this.state.highlightLinks.add(link);
      this.state.highlightNodes.add(link.source);
      this.state.highlightNodes.add(link.target);
    }

    this.updateHighlight();
  };

  paintRing = (node, ctx) => {
    // add ring just for highlighted nodes
    ctx.beginPath();
    ctx.arc(node.x, node.y, NODE_R * 1.4, 0, 2 * Math.PI, false);
    ctx.fillStyle = node === this.state.hoverNode ? '#ebedf2' : '#a10b1d';
    ctx.fill();
  };

  connectNeighbours = (data) => {
    data.links.forEach(link => {
      var a, b;
      data.nodes.forEach(node => {
        if (link.source === node.id) {
          a = node;
        }
        if (link.target === node.id) {
          b = node;
        }
      });
      !a.neighbors && (a.neighbors = []);
      !b.neighbors && (b.neighbors = []);
      a.neighbors.push(b);
      b.neighbors.push(a);

      !a.links && (a.links = []);
      !b.links && (b.links = []);
      a.links.push(link);
      b.links.push(link);
    });

    return data;
  }

  connectNeighboursFinal = (data) => {
    data.links.forEach(link => {
      var a, b;
      data.nodes.forEach(node => {
        if (link.source.id === node.id) {
          a = node;
        }
        if (link.target.id === node.id) {
          b = node;
        }
      });
      !a.neighbors && (a.neighbors = []);
      !b.neighbors && (b.neighbors = []);
      a.neighbors.push(b);
      b.neighbors.push(a);

      !a.links && (a.links = []);
      !b.links && (b.links = []);
      a.links.push(link);
      b.links.push(link);
    });

    return data;
  }

  handleClick = (node) => {
    console.log("node: ", node);
    let formdata = {
      "inboundDepth": 1,
      "outboundDepth": 1,
      "limit": this.props.defaultValueLimit.value,
      "offset": 0,
      "address": node.id,
      "network": this.props.network,
      "currency": "ETH",
      // "date_from": this.props.dateRange[0].toISOString().split('T')[0],
      // "date_till": this.props.dateRange[1].toISOString().split('T')[0]
      "date_from": "2020-04-01",
      "date_till": "2023-04-12T23:59:59"
    };
    
    getGraphData(formdata).then((response) => {
      let data = {
        "links": response.data.links,
        "nodes": response.data.nodes
      };

      data = this.connectNeighbours(data);

      let prevData = this.props.myData;

      prevData.nodes.forEach(nodeold => {
        data.nodes.forEach((nodenew, ind) => {
          if (nodenew.id === nodeold.id) {
            data.nodes.splice(ind,1);
          }
        });
      });

      prevData.nodes.forEach(node => {
        data.nodes.push(node);
      });
      prevData.links.forEach(link => {
        data.links.push(link);
      });

      console.log("data: ", data);
      // let finaldata = this.connectNeighboursFinal(data);
      // console.log("finaldata: ", finaldata);
      
      this.props.setData(data);
    }).catch((error) => {
      console.log("error: ", error);
    })
  }

  render() {
    var width = this.props.size.width

    return (
      <ForceGraph2D
        ref={this.containerRef}
        graphData={this.props.myData}
        width={width}
        linkDirectionalArrowLength={3.5}
        linkDirectionalArrowRelPos={1}
        
        linkPositionUpdate={(sprite, { start, end }) => {
          const middlePos = Object.assign(...['x', 'y', 'z'].map(c => ({
            [c]: start[c] + (end[c] - start[c]) / 2 // calc middle point
          })));

          Object.assign(sprite.position, middlePos);
        }}

        onNodeDragEnd={node => {
          node.fx = node.x;
          node.fy = node.y;
          node.fz = node.z;
        }}
        onEngineStop={() => this.containerRef.current.zoomToFit(400)}

        autoPauseRedraw={false}
        linkWidth={link => this.state.highlightLinks.has(link) ? 5 : 1}
        linkDirectionalParticles={4}
        linkDirectionalParticleWidth={link => this.state.highlightLinks.has(link) ? 4 : 0}
        nodeCanvasObjectMode={node => this.state.highlightNodes.has(node) ? 'before' : undefined}
        nodeCanvasObject={this.paintRing}
        onNodeHover={this.handleNodeHover}
        onLinkHover={this.handleLinkHover}
        onNodeClick={this.handleClick}
      />
    )
  }
}

export default withSizeHOC(OnchainChart)