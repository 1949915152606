import React from 'react';
import { useTable, usePagination, useRowSelect } from 'react-table'
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import Select from 'react-select';
import 'react-circular-progressbar/dist/styles.css';

import ExportIcon from "../../assets/img/icons/exporticon.png";
import leftPag from "../../assets/img/leftPag.png";
import rightPag from "../../assets/img/rightPag.png";
import refresh from "../../assets/img/refresh.png";
import searchIcon from "../../assets/img/search-icon.png";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <div className="selectBox">
          <label className="inline-flex items-center cursor-pointer text-sm">
            <input
              ref={resolvedRef} {...rest}
              id="customCheckLogin"
              type="checkbox"
              className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-4 h-4 ease-linear transition-all duration-150 selectBoxStyle"
            />
          </label>
        </div>
      </>
    )
  }
)

const IndeterminateCheckboxHeader = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
          <input type="checkbox" id="test" ref={resolvedRef} {...rest} style={{display:"none"}} /><label for="test" className="cursor-pointer">Select All</label>
      </>
    )
  }
)

function Table({columns, data}) {
  const color = "light";

  const [pageNum, setPageNum] = React.useState(1);

  const allcats = [
    { label: "All Categories", value: 1 },
  ];
  const timeperiod = [
    { label: "Time Period", value: 1 },
  ];
  const documenttype = [
    { label: "Document Type", value: 1 },
  ];

  const defaultValueAllCats = allcats[0];
  const defaultValueTime = timeperiod[0];
  const defaultValueDocument = documenttype[0];


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          id: 'selection',
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckboxHeader {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  )

  const customStylesSelect = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#828282' : '#828282',
      backgroundColor: state.isSelected ? '#EBEDF2' : '#FFF',
      padding: 10,
      fontSize: '14px',
    }),
  }

  return (
    <>
      <div className="casemanagement flex flex-wrap">
        <div className="w-full xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
            <div
              className={
                "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg " +
                (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
              }
            >
              <div className="rounded-t mb-0 px-4 py-4 border-0">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full px-4 max-w-full flex-grow flex-1 mt-8">
                    <Form class="filtersTable" 
                      // onSubmit={(e) => handleLogin(e)} 
                      // ref={c => {form = c;}}
                    >
                      <div className="flex justify-between items-center justify-center flex-wrap">
                        <div className="flex justify-between items-center justify-center flex-wrap">
                          <div className="searchInputTables mr-3">
                            <div className="fieldSearchTables relative w-full mb-3">
                              <label
                                className="label block font-semibold text-black-600 text-sm mb-2"
                              >
                                <img className="searchIcon" src={searchIcon} />
                              </label>
                              <Input
                                type="text"
                                className="searchFilter input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                // placeholder="Search Documents"
                                // value={email}
                                // onChange={(e) => setEmail(e.target.value)}
                                // validations={[required, validEmail]}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center flex-wrap">
                          <h1 className="text-base font-bold items-center mr-3">Filter</h1>
                          <div className="mr-3">
                            <div className="field dropdown relative w-full mb-3">
                              <Select styles={customStylesSelect} options={ allcats } isSearchable={false} defaultValue={defaultValueAllCats} />
                            </div>
                          </div>
                          <div className="mr-3">
                            <div className="field dropdown relative w-full mb-3">
                              <Select styles={customStylesSelect} options={ timeperiod } isSearchable={false} defaultValue={defaultValueTime} />
                            </div>
                          </div>
                          <div className="mr-3">
                            <div className="field dropdown relative w-full mb-3">
                              <Select styles={customStylesSelect} options={ documenttype } isSearchable={false} defaultValue={defaultValueDocument} />
                            </div>
                          </div>

                          <div className="searchButton ml-6">
                            <button className="py-2 px-12 rounded-lg font-semibold text-white-400 buttonDarkGrey mb-2 text-xs">
                              Search
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
              <div className="pagination px-12 py-4 flex flex-row justify-between items-center mb-6">
                {/* <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  {'<<'}
                </button>{' '} */}
                <div className="flex flex-row items-center">
                  <button className="mr-2" onClick={() => previousPage()} disabled={!canPreviousPage}>
                    <img src={leftPag} alt="previous icon" style={{width:"20px", height:"20px"}} />
                  </button>
                  <span className="mr-2 text-xs">
                    Page
                    <strong className="ml-2">
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>
                  </span>
                  <button onClick={() => nextPage()} disabled={!canNextPage}>
                    <img src={rightPag} alt="previous icon" style={{width:"20px", height:"20px"}} />
                  </button>
                </div>
                <div className="flex flex-row items-center cursor-pointer">
                  <p className="font-bold text-xs mr-3">Go to page</p>
                  <input
                    type="number"
                    defaultValue={pageIndex + 1}
                    value={pageNum}
                    onChange={(e) => {
                      const value = Math.max(1, Math.min(pageOptions.length, Number(e.target.value)));
                      const page = value ? Number(value) - 1 : 0;
                      gotoPage(page);
                      setPageNum(value);
                    }}
                    className="cursor-pointer border-0 rounded text-blueGray-700 ml-1 mr-2 w-16 h-8 ease-linear transition-all duration-150"
                  />
                </div>
                <div className="flex flex-row items-center cursor-pointer">
                  <p className="font-bold text-xs mr-3">Records per page</p>
                  <input
                    type="number"
                    min={1}
                    value={pageSize}
                    onChange={e => {
                      if (e.target.value === "" || e.target.value === null || e.target.value < 1) {
                        setPageSize(Number(1));
                      } else {
                        setPageSize(Number(e.target.value));
                      }
                    }}
                    className="cursor-pointer border-0 rounded text-blueGray-700 ml-1 mr-2 w-16 h-8 ease-linear transition-all duration-150"
                  />
                  <button
                    // onClick={() => }
                    className="px-2 py-2 bg-white rounded-lg"
                  >
                    <img src={refresh} alt="refresh" style={{width:"13px", height:"13px"}} />
                  </button>
                </div>
              </div>
              <div className="block w-full overflow-x-auto">
                {/* Projects table */}
                <table {...getTableProps()} className="items-center w-full bg-transparent border-collapse">
                  <thead className="border border-solid border-t-0 border-l-0 border-r-0 border-blueGray-300">
                    {headerGroups.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                          <th 
                            {...column.getHeaderProps()}
                            className={
                              "px-6 align-middle py-3 text-xs whitespace-nowrap font-semibold text-center text-black-600"
                            }
                          >
                            {column.render('Header')}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                      prepareRow(row)
                      return (
                        <tr {...row.getRowProps()} className="cursor-pointer">
                          {row.cells.map(cell => {
                            return (
                              <td {...cell.getCellProps()} className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                                {cell.render('Cell')}
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function DataTableComponent() {
  const data = [
    {
      listName: 'Hdg_2123_reciept',
      listType: 'PDF',
      size: '20 MB',
      uploadedBy: 'Moderator',
      uploadedAt: '22/04/22 | 22:14',
      tag: 'Customer_list',
    },
    {
      listName: 'Hdg_2123_reciept',
      listType: 'PDF',
      size: '20 MB',
      uploadedBy: 'Moderator',
      uploadedAt: '22/04/22 | 22:14',
      tag: 'Customer_list',
    },
    {
      listName: 'Hdg_2123_reciept',
      listType: 'PDF',
      size: '20 MB',
      uploadedBy: 'Moderator',
      uploadedAt: '22/04/22 | 22:14',
      tag: 'Customer_list',
    },
    {
      listName: 'Hdg_2123_reciept',
      listType: 'PDF',
      size: '20 MB',
      uploadedBy: 'Moderator',
      uploadedAt: '22/04/22 | 22:14',
      tag: 'Customer_list',
    },
    {
      listName: 'Hdg_2123_reciept',
      listType: 'PDF',
      size: '20 MB',
      uploadedBy: 'Moderator',
      uploadedAt: '22/04/22 | 22:14',
      tag: 'Customer_list',
    },
    {
      listName: 'Hdg_2123_reciept',
      listType: 'PDF',
      size: '20 MB',
      uploadedBy: 'Moderator',
      uploadedAt: '22/04/22 | 22:14',
      tag: 'Customer_list',
    }
  ]

  const columns = [
    {
      Header: 'List Name',
      accessor: 'listName'
    },
    {
      Header: 'List Type',
      accessor: 'listType',
      Cell: tableProps => (
        <div className="w-auto max-w-100-px mx-auto font-bold" style={{ backgroundColor: "#a10b1d", color: "#FFF", padding: "10px 10px", borderRadius: "25px" }}>
          {tableProps.row.original.listType}
        </div>
      )
    },
    {
      Header: 'Size',
      accessor: 'size'
    }, 
    {
      Header: 'Uploaded By',
      accessor: 'uploadedBy'
    },
    {
      Header: 'Uploaded At',
      accessor: 'uploadedAt'
    },
    {
      Header: 'Tag',
      accessor: 'tag'
    }
  ]

  return (
      <Table data={data} columns={columns}/>
  )

}

export default DataTableComponent