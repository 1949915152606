import React, { useState, useEffect } from "react";
import Select from "react-select";
// import {Carousel} from '3d-react-carousal';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import "../../assets/styles/kycmobile.css";
import { savecustomizationmobile } from "../../services/settings/CustomizationMobile";
import ReactLoading from "react-loading";
import { getuniqueid } from "services/kyc/KycScreens";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";
import {QRCodeSVG} from 'qrcode.react';
import QrReader from 'react-web-qr-reader';

// importing images
import kycNext from "../../assets/img/kycNext.png";
import backButtonImg from " ../../assets/img/kycmobile/backBtn.png";
import closeButtonImg from " ../../assets/img/kycmobile/cross_white.png";
import footerLogo from "../../assets/img/footerLogo.png";
import blackImgCarousel from "../../assets/img/blackImgCarousel.png";

// screen 1 data
import mainImg from " ../../assets/img/kycmobile/frame_6.png";

// screen 2 data
import globe from "../../assets/img/kycmobile/selectBox.png";
import down from "../../assets/img/kycmobile/dropdown.png";

// screen 3 data
import countryList from "react-select-country-list";

// screen 4 data
import mainImg4 from "../../assets/img/kycmobile/rectangle.png";

// screen 5 data
import warningImage from " ../../assets/img/kycmobile/warningMessage.png";
import cameraFrameImg from " ../../assets/img/kycmobile/camera_frame.png";
import cameraButton from " ../../assets/img/kycmobile/camera_button.png";

// screen 10 data
import idCard from " ../../assets/img/kycmobile/idcard.png";
import passport from " ../../assets/img/kycmobile/passport.png";
import license from " ../../assets/img/kycmobile/license.png";

// screen 12 data
import cameraIcon from " ../../assets/img/kycmobile/cameraIcon.png";
import uploadIcon from " ../../assets/img/kycmobile/uploadIcon.png";

// screen 14 data
import black from " ../../assets/img/kycmobile/black.png";
import warning from " ../../assets/img/kycmobile/warning.png";
import loading from " ../../assets/img/kycmobile/loading.png";

// screen 16 data
import cameraFrameImg16 from " ../../assets/img/kycmobile/screen16Cam.png";

// screen 17i data
import screen17Rect from " ../../assets/img/kycmobile/screen17Rect.png";
import screen17Oval from " ../../assets/img/kycmobile/screen17Oval.png";

// screen 18 data
import map from " ../../assets/img/kycmobile/map_marker.png";

// screen 22 data
import cameraFrameImg22 from " ../../assets/img/kycmobile/22_cam.png";

// screen 23 data
import warningMessage from " ../../assets/img/kycmobile/warningMessage.png";

// screen 25 data
import edit from " ../../assets/img/kycmobile/edit.png";

// screen 26 data
import map26 from " ../../assets/img/kycmobile/screen26.svg";

// screen 27 data
import map27 from " ../../assets/img/kycmobile/screen27.png";

export default function Mobile(props) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [message, setMessage] = useState("");
  const [value, setValue] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const options = React.useMemo(() => countryList().getData(), []);
  options.forEach((ele) => {
    if(ele.label === "Czechia") {
      ele.label = "Czech Republic (Czechia)";
    }
  });
  var form, checkBtn;
  const delay = 500;

  const buttonColorMobile = props.buttonColorMobile;
  const progressColorMobile = props.progressColorMobile;
  const activeFontFamilyMobile = props.activeFontFamilyMobile;
  const logoFileMobile = props.logoFileMobile;

  const [screen1Flag, setScreen1Flag] = useState(props.screen1FlagMobile);
  const [screen2Flag, setScreen2Flag] = useState(props.screen2FlagMobile);
  const [screen3Flag, setScreen3Flag] = useState(props.screen3FlagMobile);
  const [screen4Flag, setScreen4Flag] = useState(props.screen4FlagMobile);
  const [screen10Flag, setScreen10Flag] = useState(props.screen10FlagMobile);
  const [screen11Flag, setScreen11Flag] = useState(props.screen11FlagMobile);
  const [screen12Flag, setScreen12Flag] = useState(props.screen12FlagMobile);
  const [screen18Flag, setScreen18Flag] = useState(props.screen18FlagMobile);
  const [screen19Flag, setScreen19Flag] = useState(props.screen19FlagMobile);
  const [screen24Flag, setScreen24Flag] = useState(props.screen24FlagMobile);
  const [screen25Flag, setScreen25Flag] = useState(props.screen25FlagMobile);
  const [screen26Flag, setScreen26Flag] = useState(props.screen26FlagMobile);
  const [screen27Flag, setScreen27Flag] = useState(props.screen27FlagMobile);

  const [urlId, setUrlId] = React.useState(null);

  // React.useEffect(() => {
  //   getuniqueid().then((response) => {
  //     if (response.data.success) {
  //       setUrlId(response.data.kyc_id);
  //     }
  //   }).catch((error) => {
  //     console.log("error: ", error);
  //   })
  // }, [])

  const changeHandler = (v) => {
    setValue(v);
  };

  const next = () => {
    setCurrentSlide(currentSlide + 1);
  };

  const prev = () => {
    setCurrentSlide(currentSlide - 1);
  };

  const updateCurrentSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  const optionsScreen2 = [
    { label: "English", value: 1 },
    { label: "French (France)", value: 3 },
    { label: "Arabic (Saudia Arabia)", value: 4 },
    { label: "German (Germany)", value: 5 },
    { label: "Urdu (Pakistan)", value: 6 },
  ];

  const optionsDocuments = [
    { label: "National ID Card", value: 1 },
    { label: "Passport", value: 2 },
    { label: "Driving License", value: 3 },
  ];

  const optionsAddressVerifications = [
    { label: "Utility Bill", value: 1 },
    { label: "Bank Statement", value: 2 },
    { label: "Insurance Document", value: 3 },
    { label: "Resident Permit", value: 4 },
    { label: "Mortgage Statement", value: 5 },
    { label: "Tax Bill", value: 6 },
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  const disableDates = () => {
    const today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (dd < 10) {
      dd = "0" + dd;
    }
    return yyyy + "-" + mm + "-" + dd;
  };

  const handleSubmit = () => {
    setLoading(true);
    let formdata = {
      buttonColor: buttonColorMobile,
      progressColor: progressColorMobile,
      activeFontFamily: activeFontFamilyMobile,
      logoFile: logoFileMobile,
      screen1: screen1Flag,
      screen2: screen2Flag,
      screen3: screen3Flag,
      screen4: screen4Flag,
      screen10: screen10Flag,
      screen11: screen11Flag,
      screen12: screen12Flag,
      screen18: screen18Flag,
      screen19: screen19Flag,
      screen24: screen24Flag,
      screen25: screen25Flag,
      screen26: screen26Flag,
      screen27: screen27Flag
    };
    // console.log("formdata: ", formdata);
    savecustomizationmobile(formdata).then((response) => {
      setLoading(false);
      if (response.data.success) {
        window.location.reload();
      }
    }).catch((error) => {
      setLoading(false);
      console.log("error", error);
    });
  }

  return (
    <>
      <div className="mt-8  flex flex-col items-center justify-center KYC-Screen">
        <div className=" flex flex-row items-center justify-center KYC-mobileAll">
          <div className="prevBtn">
            <button
              className="prevBtnKyc"
              onClick={prev}
              style={{ outline: "none" }}
            >
              <img
                src={kycNext}
                alt="previous"
                style={{ transform: "rotate(-180deg)" }}
              />
            </button>
          </div>
          <main
            className="bg-white mx-5 main "
            style={{ height: "690px", position: "unset" }}
          >
            <section className="kyc h-full">
              <div className=" carouselScreen row h-full lg:w-12/12 md:w-12/12 sm:w-12/12 relative shadow-2xl rounded-2xl bg-white">
                <Carousel
                  emulateTouch={true}
                  selectedItem={currentSlide}
                  onChange={updateCurrentSlide}
                  showArrows={false}
                  showIndicators={false}
                  showStatus={false}
                >
                  {/* screen1 */}
                  <div
                    className={
                      "screenOne h-full pt-4 " + (screen1Flag ? "" : "disabled")
                    }
                    style={{ height: "735px" }}
                  >
                    <div className="w-full flex flex-row justify-between p-4 pt-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img
                              src={backButtonImg}
                              alt="backArrow"
                              className=""
                              style={{ verticalAlign: "middle" }}
                            />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-12">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "5%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button onClick={() => setScreen1Flag(!screen1Flag)}>
                          <img
                            src={closeButtonImg}
                            alt="crossArrow"
                            className=""
                            style={{ verticalAlign: "middle", transform: screen1Flag ? 'rotate(0deg)' : 'rotate(45deg)' }}
                          />
                        </button>
                      </div>
                    </div>

                    <div class="mainContent" style={{ height: "78%" }}>
                      <div class="mainImage">
                        <img class="mainImg" src={mainImg} alt="main image" />
                      </div>
                      <div class="mainText">
                        <h2>Lets start your verification</h2>
                      </div>
                      <div class="supportingText">
                        <p class="supportingTextPara">
                          We'll guide you through a simple process to verify your identity.
                        </p>
                      </div>
                    </div>

                    <div class="buttonAction">
                      <Link onClick={next}>
                        <button class="action">Let's Start!</button>
                      </Link>
                    </div>
                  </div>

                  {/* screen1info */}
                  <div
                    className="screenOne screenInfoOne h-full flex flex-col"
                  >
                    <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img src={backButtonImg} alt="backArrow" className="" />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-12">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{
                                width: "10%",
                              }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button style={{ cursor: "unset" }}>
                          <img src={closeButtonImg} alt="crossArrow" className="" />
                        </button>
                      </div>
                    </div>

                    <div class="mainContent screen1info">
                      <div class="mainText">
                        <h2>Prepare for your verification</h2>
                      </div>
                      <div className="screen1Info-textdiv overflow-y-auto max-h-40-vh flex flex-col px-4 mb-6 mt-4">
                        <div className="textdiv mb-4">
                          <p className="mb-2 font-bold text-sm text-left">Questionnaire</p>
                          <p className="font-normal text-xs text-left" style={{ color: "#a8a8a8" }}>Fill out a questionnaire with your personal information, email address and nationality.</p>
                        </div>
                        <div className="textdiv mb-4">
                          <p className="mb-2 font-bold text-sm text-left">Identity document</p>
                          <p className="font-normal text-xs text-left" style={{ color: "#a8a8a8" }}>Provide your identity document (Passport, Driver's license, ID card) for visual scanning. Ensure that it is not expired or physically damaged.</p>
                        </div>
                        <div className="textdiv mb-4">
                          <p className="mb-2 font-bold text-sm text-left">Selfie</p>
                          <p className="font-normal text-xs text-left" style={{ color: "#a8a8a8" }}>Go through our liveness-detection check. This involves turning your head in front of a camera for a few minutes.</p>
                        </div>
                        <div className="textdiv mb-4">
                          <p className="mb-2 font-bold text-sm text-left">Proof of residence</p>
                          <p className="font-normal text-xs text-left" style={{ color: "#a8a8a8" }}>Provide a proof of address (not older than 3 months) for visual scanning. Ensure that your full name and address are on the document.</p>
                        </div>
                        <div className="textdiv">
                          <p className="mb-2 font-bold text-sm text-left">Wallet information</p>
                          <p className="font-normal text-xs text-left" style={{ color: "#a8a8a8" }}>Connect your ETH/BNB Wallet by entering manually or scanning wallet QR.</p>
                        </div>
                      </div>
                      <div class="supportingText">
                        <p class="supportingTextPara text-xs">
                        By clicking “Continue”, you acknowledge  to the Hedge Technologies <a href="https://www.the-hedge.io/privacy-policy" target="__blank">Privacy Policy</a>.
                        </p>
                      </div>
                    </div>

                    <div class="buttonAction">
                      <Link onClick={next}>
                        <button
                          class="action"
                        >
                          Continue
                        </button>
                      </Link>
                    </div>
                  </div>

                  {/* screen1i */}
                  <div
                    className="screenOne QRScreen h-full flex flex-col"
                    style={{ height: "735px" }}
                  >
                    <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img src={backButtonImg} alt="backArrow" className="" style={{ verticalAlign: "middle" }} />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-12">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "15%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button style={{ cursor: "unset" }}>
                          <img src={closeButtonImg} alt="crossArrow" className="" />
                        </button>
                      </div>
                    </div>

                    <div class="mainContent">
                      <div class="mainText mt-4">
                        <h2>Do you want to continue on the Phone?</h2>
                      </div>
                      <div class="mainImage mt-2">
                        <QRCodeSVG className="mx-auto" value={"#"} size="200"/>
                      </div>
                      <div class="mainText mt-4">
                        <h2>Scan QR with your phone's camera</h2>
                      </div>
                      <div class="supportingText">
                        <p class="supportingTextPara">
                          Point your phone's camera to this QR code and follow the link.
                        </p>
                      </div>
                    </div>

                    <div class="buttonAction">
                      <Link onClick={next}>
                        <button class="action">
                          Continue here
                        </button>
                      </Link>
                    </div>
                  </div>

                  {/* screen3 */}
                  <div
                    className={
                      "screenThree h-full " + (screen3Flag ? "" : "disabled")
                    }
                  >
                    <div className="w-full flex flex-row justify-between p-4 pt-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img
                              src={backButtonImg}
                              alt="backArrow"
                              className=""
                              style={{ verticalAlign: "middle" }}
                            />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-12">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "25%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button onClick={() => setScreen3Flag(!screen3Flag)}>
                          <img
                            src={closeButtonImg}
                            alt="crossArrow"
                            className=""
                            style={{ verticalAlign: "middle", transform: screen3Flag ? 'rotate(0deg)' : 'rotate(45deg)' }}
                          />
                        </button>
                      </div>
                    </div>

                    <div className="mainContent" style={{ height: "73%" }}>
                      <div className="mainText">
                        <h1 style={{ lineHeight: 1 }}>Please fill out with your personal information, email address and nationality.</h1>
                      </div>
                      <div className="informationSection mt-8" style={{ marginTop: "40px" }}>
                        <div className="nameFields">
                          <div className="firstNameField firstField">
                            <div className="inputfield firstname">
                              <span className="label text-sm">First Name</span>
                              <input
                                type="text"
                                name="firstname"
                                className="input firstnameInput"
                              />
                            </div>
                          </div>
                          <div className="lastNameField">
                            <div className="inputfield lastname">
                              <span className="label text-sm">Last Name</span>
                              <input
                                type="text"
                                name="lastname"
                                className="input lastnameInput"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="dateField">
                          <div className="inputfield dob">
                            <span className="label text-sm">Date of Birth</span>
                            <DatePicker
                              portalId="root-portal"
                              maxDate={addDays(new Date(), 0)}
                              className="input dateInput"
                            />
                          </div>
                        </div>
                        <div className="emailFields">
                          <div className="inputfield email">
                            <span className="label text-sm">Email</span>
                            <input
                              type="email"
                              name="email"
                              className="input emailInput"
                            />
                          </div>
                        </div>
                        <div className="nationalityField">
                          <div className="inputfield dob">
                            <span className="label text-sm">Nationality</span>
                            <Select
                              className=""
                              style={{ all: "unset" }}
                              placeholder="Search Country"
                              options={options}
                              value={value}
                              onChange={changeHandler}
                              menuPortalTarget={document.body}
                              styles={customStyles}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="buttonAction">
                      <Link onClick={next}>
                        <button className="action">Let's Start!</button>
                      </Link>
                    </div>
                  </div>

                  {/* screen4 */}
                  <div
                    className={
                      "screenFour h-full " + (screen4Flag ? "" : "disabled")
                    }
                  >
                    <div className="w-full flex flex-row justify-between p-4 pt-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img
                              src={backButtonImg}
                              alt="backArrow"
                              className=""
                              style={{ verticalAlign: "middle" }}
                            />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-12">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "30%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button onClick={() => setScreen4Flag(!screen4Flag)}>
                          <img
                            src={closeButtonImg}
                            alt="crossArrow"
                            className=""
                            style={{ verticalAlign: "middle", transform: screen4Flag ? 'rotate(0deg)' : 'rotate(45deg)' }}
                          />
                        </button>
                      </div>
                    </div>

                    <div className="mainContent">
                      <div className="mainImage">
                        <img
                          className="mainImg"
                          src={mainImg4}
                          alt="main image"
                        />
                      </div>
                      <div className="mainText">
                        <h1>Prepare for your selfie video</h1>
                      </div>
                      <div className="supportingText">
                        <p>
                        Position yourself in good lighting and follow instructions on the next screen.
                        </p>
                      </div>
                    </div>

                    <div className="buttonAction">
                      <Link onClick={next}>
                        <button className="action">Lets do it!</button>
                      </Link>
                    </div>
                  </div>

                  {/* screen5 */}
                  <div
                    className={
                      "CameraScreen h-full " + (screen4Flag ? "" : "disabled")
                    }
                    style={{ height: "700px" }}
                  >
                    <div className="w-full flex flex-row justify-between p-4 pt-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img
                              src={backButtonImg}
                              alt="backArrow"
                              className=""
                              style={{ verticalAlign: "middle" }}
                            />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-12">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "35%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button onClick={() => setScreen4Flag(!screen4Flag)}>
                          <img
                            src={closeButtonImg}
                            alt="crossArrow"
                            className=""
                            style={{ verticalAlign: "middle", transform: screen4Flag ? 'rotate(0deg)' : 'rotate(45deg)' }}
                          />
                        </button>
                      </div>
                    </div>

                    <div className="mainContent">
                      <div className="alignText">Align your face</div>
                      <div className="cameraFrame">
                        <img
                          className="cameraFrameImg mx-auto"
                          src={cameraFrameImg}
                          alt="camera_frame"
                        />
                      </div>
                      <div className="warningMessage">
                        <div className="warningImage">
                          <img
                            style={{ marginTop: "10px" }}
                            src={warningImage}
                            alt="warningImage"
                          />
                        </div>
                        <div className="message">
                          <p>Please try again!</p>
                        </div>
                      </div>
                    </div>

                    <div className="footer">
                      <Link onClick={next}>
                        <img
                          className="cameraButton mx-auto"
                          src={cameraButton}
                          alt="camera_button"
                          style={{ width: "auto" }}
                        />
                      </Link>
                    </div>
                  </div>

                  {/* screen10 */}
                  <div
                    className={
                      "screenTem h-full " + (screen10Flag ? "" : "disabled")
                    }
                  >
                    <div className="w-full flex flex-row justify-between p-4 pt-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img
                              src={backButtonImg}
                              alt="backArrow"
                              className=""
                              style={{ verticalAlign: "middle" }}
                            />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-12">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "40%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button onClick={() => setScreen10Flag(!screen10Flag)}>
                          <img
                            src={closeButtonImg}
                            alt="crossArrow"
                            className=""
                            style={{ verticalAlign: "middle", transform: screen10Flag ? 'rotate(0deg)' : 'rotate(45deg)' }}
                          />
                        </button>
                      </div>
                    </div>

                    <div className="mainContent">
                      <div className="mainText">
                        <h1>
                          To complete Identity verification you will need one of
                          the following
                        </h1>
                      </div>

                      <div className="documentList">
                        <div className="idCard gridDocumentList">
                          <div className="idCardImg documentImage">
                            <img src={idCard} alt="" />
                          </div>
                          <div className="idCardText mainText">
                            <h3 className="idCardTextHeading">
                              National ID Card
                            </h3>
                            <p className="idCardTextPara text-left">
                              You will need to add live picture of your ID card
                              for verification
                            </p>
                          </div>
                        </div>
                        <div className="passport gridDocumentList">
                          <div className="passportImg documentImage">
                            <img src={passport} alt="" />
                          </div>
                          <div className="passportText mainText">
                            <h3 className="passportTextHeading">Passport</h3>
                            <p className="passportTextPara text-left">
                              You will need to add live picture of your passport
                              for verification
                            </p>
                          </div>
                        </div>
                        <div className="drivingLicense gridDocumentList">
                          <div className="drivingLicenseImg documentImage">
                            <img src={license} alt="" />
                          </div>
                          <div className="drivingLicenseText mainText">
                            <h3 className="drivingLicenseTextHeading">
                              Driving License
                            </h3>
                            <p className="drivingLicenseTextPara text-left">
                              You will need to add live picture of your driving
                              license for verification
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="buttonAction">
                      <Link onClick={next}>
                        <button className="action">Got it!</button>
                      </Link>
                    </div>
                  </div>

                  {/* screen11 */}
                  <div
                    className={
                      "screenEleven h-full " + (screen11Flag ? "" : "disabled")
                    }
                  >
                    <div className="w-full flex flex-row justify-between p-4 pt-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img
                              src={backButtonImg}
                              alt="backArrow"
                              className=""
                              style={{ verticalAlign: "middle" }}
                            />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-12">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "45%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button onClick={() => setScreen11Flag(!screen11Flag)}>
                          <img
                            src={closeButtonImg}
                            alt="crossArrow"
                            className=""
                            style={{ verticalAlign: "middle", transform: screen11Flag ? 'rotate(0deg)' : 'rotate(45deg)' }}
                          />
                        </button>
                      </div>
                    </div>

                    <div className="mainContent">
                      <div className="mainText">
                        <h1>What country is your document from?</h1>
                        <p>Please select the country from the dropdown list and fill your identification number.</p>
                      </div>

                      <div className="informationSection">
                        <div className="nationalityField mainField">
                          <div className="inputfield dob">
                            <span className="label text-sm">Issuance Country</span>
                            <Select
                              className=""
                              style={{ all: "unset" }}
                              placeholder="Search Country"
                              options={options}
                              value={value}
                              onChange={changeHandler}
                              menuPortalTarget={document.body}
                              styles={customStyles}
                            />
                          </div>
                        </div>
                        <div className="idField mainField">
                          <div className="inputfield dob">
                            <span className="label text-sm">ID Number</span>
                            <input
                              name="id"
                              type="number"
                              className="inputSelect idInput"
                            />
                          </div>
                        </div>
                        <div className="nationalityField mainField">
                          <div className="inputfield dob">
                            <span className="label text-sm">Document Type</span>
                            <Select
                              styles={customStyles}
                              className="globeSelect"
                              options={optionsDocuments}
                              placeholder={"Please select the document type."}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="buttonAction">
                      <Link onClick={next}>
                        <button className="action">Next!</button>
                      </Link>
                    </div>
                  </div>

                  {/* screen14 */}
                  <div
                    className={
                      "screenFourteen h-full " + (screen12Flag ? "" : "disabled")
                    }
                  >
                    <div className="w-full flex flex-row justify-between p-4 pt-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img
                              src={backButtonImg}
                              alt="backArrow"
                              className=""
                              style={{ verticalAlign: "middle" }}
                            />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-12">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "55%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button onClick={() => setScreen12Flag(!screen12Flag)}>
                          <img
                            src={closeButtonImg}
                            alt="crossArrow"
                            className=""
                            style={{ verticalAlign: "middle", transform: screen12Flag ? 'rotate(0deg)' : 'rotate(45deg)' }}
                          />
                        </button>
                      </div>
                    </div>

                    <div className="mainContent">
                      <div className="blackImgBackground">
                        <div className="blackImage">
                          <img
                            className="black"
                            src={black}
                            alt=""
                            style={{ width: "auto" }}
                          />
                          <img
                            className="warning"
                            src={warning}
                            alt=""
                            style={{ width: "auto" }}
                          />
                        </div>
                        <div className="points">
                          <ul>
                            <li>Blur/focus Issue</li>
                            <li>Light is too high/low</li>
                            <li>Image size is too large/small</li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="buttonAction">
                      <Link onClick={next}>
                        <button className="action w-full mx-auto flex flex-row items-center text-center justify-center">
                          <img
                            src={loading}
                            alt=""
                            className="mr-2"
                            style={{ width: "auto" }}
                          />
                          Reupload
                        </button>
                      </Link>
                    </div>
                  </div>

                  {/* screen16 */}
                  <div
                    className={
                      "screenSixteen CameraScreen h-full " +
                      (screen12Flag ? "" : "disabled")
                    }
                    style={{ height: "700px" }}
                  >
                    <div className="w-full flex flex-row justify-between p-4 pt-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img
                              src={backButtonImg}
                              alt="backArrow"
                              className=""
                              style={{ verticalAlign: "middle" }}
                            />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-12">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "60%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button onClick={() => setScreen12Flag(!screen12Flag)}>
                          <img
                            src={closeButtonImg}
                            alt="crossArrow"
                            className=""
                            style={{ verticalAlign: "middle", transform: screen12Flag ? 'rotate(0deg)' : 'rotate(45deg)' }}
                          />
                        </button>
                      </div>
                    </div>

                    <div className="mainContent">
                      <div className="alignText">Capture the Front side</div>
                      <div className="cameraFrame">
                        <img
                          className="cameraFrameImg mx-auto"
                          src={cameraFrameImg16}
                          alt="camera_frame"
                        />
                      </div>
                      <div className="warningMessage">
                        <div className="message">
                          <p>Make sure the text is clearly visible</p>
                        </div>
                      </div>
                    </div>

                    <div className="footer">
                      <Link onClick={next}>
                        <img
                          className="cameraButton mx-auto"
                          src={cameraButton}
                          alt="camera_button"
                          style={{ width: "auto" }}
                        />
                      </Link>
                    </div>
                  </div>

                  {/* screen16 (back) */}
                  <div
                    className={
                      "screenSixteen CameraScreen h-full " +
                      (screen12Flag ? "" : "disabled")
                    }
                    style={{ height: "700px" }}
                  >
                    <div className="w-full flex flex-row justify-between p-4 pt-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img
                              src={backButtonImg}
                              alt="backArrow"
                              className=""
                              style={{ verticalAlign: "middle" }}
                            />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-12">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "65%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button onClick={() => setScreen12Flag(!screen12Flag)}>
                          <img
                            src={closeButtonImg}
                            alt="crossArrow"
                            className=""
                            style={{ verticalAlign: "middle", transform: screen12Flag ? 'rotate(0deg)' : 'rotate(45deg)' }}
                          />
                        </button>
                      </div>
                    </div>

                    <div className="mainContent">
                      <div className="alignText">Capture the Back side</div>
                      <div className="cameraFrame">
                        <img
                          className="cameraFrameImg mx-auto"
                          src={cameraFrameImg16}
                          alt="camera_frame"
                        />
                      </div>
                      <div className="warningMessage">
                        <div className="message">
                          <p>Make sure the text is clearly visible</p>
                        </div>
                      </div>
                    </div>

                    <div className="footer">
                      <Link onClick={next}>
                        <img
                          className="cameraButton mx-auto"
                          src={cameraButton}
                          alt="camera_button"
                          style={{ width: "auto" }}
                        />
                      </Link>
                    </div>
                  </div>

                  {/* screen18 */}
                  <div
                    className={
                      "screenEighteen h-full " + (screen18Flag ? "" : "disabled")
                    }
                  >
                    <div className="w-full flex flex-row justify-between p-4 pt-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img
                              src={backButtonImg}
                              alt="backArrow"
                              className=""
                              style={{ verticalAlign: "middle" }}
                            />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-12">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "70%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button onClick={() => setScreen18Flag(!screen18Flag)}>
                          <img
                            src={closeButtonImg}
                            alt="crossArrow"
                            className=""
                            style={{ verticalAlign: "middle", transform: screen18Flag ? 'rotate(0deg)' : 'rotate(45deg)' }}
                          />
                        </button>
                      </div>
                    </div>

                    <div className="mainContent">
                      <div className="mapImgBackground">
                        <div className="mapImage">
                          <img className="map" src={map} alt="" />
                        </div>
                        <div className="headingText">
                          <h2>We are almost there! Prepare to scan your proof of address.</h2>
                          <p className="py-16">
                          Turn to the front of your proof of address and continue on the next screen. Document should not be older than 3 months. Ensure that your full name and address are on the document.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="buttonAction">
                      <Link onClick={next}>
                        <button className="action w-full mx-auto flex flex-row items-center text-center justify-center">
                          Next
                        </button>
                      </Link>
                    </div>
                  </div>

                  {/* screen24 */}
                  <div
                    className={
                      "screenTwentyFour h-full " +
                      (screen24Flag ? "" : "disabled")
                    }
                  >
                    <div className="w-full flex flex-row justify-between p-4 pt-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img
                              src={backButtonImg}
                              alt="backArrow"
                              className=""
                              style={{ verticalAlign: "middle" }}
                            />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-12">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "75%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button onClick={() => setScreen24Flag(!screen24Flag)}>
                          <img
                            src={closeButtonImg}
                            alt="crossArrow"
                            className=""
                            style={{ verticalAlign: "middle", transform: screen24Flag ? 'rotate(0deg)' : 'rotate(45deg)' }}
                          />
                        </button>
                      </div>
                    </div>

                    <div className="mainContent" style={{ height: "75%" }}>
                      <div className="mainText">
                        <h1>Please enter your address details</h1>
                      </div>
                      <div className="informationSection">
                        <div className="nationalityField">
                          <div className="inputfield dob">
                            <span className="label text-sm">Country</span>
                            <Select
                              className=""
                              style={{ all: "unset" }}
                              placeholder="Search Country"
                              options={options}
                              value={value}
                              onChange={changeHandler}
                              menuPortalTarget={document.body}
                              styles={customStyles}
                            />
                          </div>
                        </div>
                        <div className="nameFields">
                          <div className="firstNameField firstField">
                            <div className="inputfield city">
                              <span className="label text-sm">City</span>
                              <input
                                type="text"
                                name="city"
                                className="input firstnameInput"
                              />
                            </div>
                          </div>
                          <div className="lastNameField">
                            <div className="inputfield postal">
                              <span className="label text-sm">Postal Code</span>
                              <input
                                type="text"
                                name="postal"
                                className="input lastnameInput"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="emailFields">
                          <div className="inputfield address">
                            <span className="label text-sm">Address</span>
                            <input
                              type="email"
                              name="address"
                              className="input emailInput"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="buttonAction">
                      <Link onClick={next}>
                        <button className="action">Next</button>
                      </Link>
                    </div>
                  </div>

                  {/* screen19 */}
                  <div
                    className={
                      "screenNineteen h-full " + (screen19Flag ? "" : "disabled")
                    }
                  >
                    <div className="w-full flex flex-row justify-between p-4 pt-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img
                              src={backButtonImg}
                              alt="backArrow"
                              className=""
                              style={{ verticalAlign: "middle" }}
                            />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-12">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "80%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button onClick={() => setScreen19Flag(!screen19Flag)}>
                          <img
                            src={closeButtonImg}
                            alt="crossArrow"
                            className=""
                            style={{ verticalAlign: "middle", transform: screen19Flag ? 'rotate(0deg)' : 'rotate(45deg)' }}
                          />
                        </button>
                      </div>
                    </div>

                    <div className="mainContent">
                      <div className="mainText">
                        <h1>Which proof of address document will you scan?</h1>
                        <p style={{ fontSize: "18px" }}>
                          Please select the document type from the dropdown list.
                        </p>
                      </div>

                      <div className="py-10 px-12 mt-10">
                        <Select
                          className="globeSelect"
                          styles={customStyles}
                          options={optionsAddressVerifications}
                        />
                      </div>

                      <div className="uploadButtons">
                        <Link onClick={next}>
                          <div className="openCameraButton">
                            <img
                              className="cameraIcon"
                              src={cameraIcon}
                              alt="camicon"
                              style={{ width: "auto", marginTop: "5px" }}
                            />
                            <p>Use Camera</p>
                          </div>
                        </Link>
                        <div className="uploadFileButton">
                          <img
                            className="uploadIcon"
                            src={uploadIcon}
                            alt="upicon"
                            style={{ width: "auto", marginTop: "8px" }}
                          />
                          <p>Upload a File</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* screen22 */}
                  <div
                    className={
                      "screenTwentyTwo CameraScreen h-full " +
                      (screen19Flag ? "" : "disabled")
                    }
                    style={{ height: "700px" }}
                  >
                    <div className="w-full flex flex-row justify-between p-4 pt-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img
                              src={backButtonImg}
                              alt="backArrow"
                              className=""
                              style={{ verticalAlign: "middle" }}
                            />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-12">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "85%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button onClick={() => setScreen19Flag(!screen19Flag)}>
                          <img
                            src={closeButtonImg}
                            alt="crossArrow"
                            className=""
                            style={{ verticalAlign: "middle", transform: screen19Flag ? 'rotate(0deg)' : 'rotate(45deg)' }}
                          />
                        </button>
                      </div>
                    </div>

                    <div className="mainContent">
                      <div className="cameraFrame">
                        <img
                          className="cameraFrameImg mx-auto"
                          src={cameraFrameImg22}
                          alt="camera_frame"
                        />
                      </div>
                      <div className="warningMessage">
                        <div className="message">
                          <p>Make sure the text is clearly visible</p>
                        </div>
                      </div>
                    </div>

                    <div className="footer">
                      <Link onClick={next}>
                        <img
                          className="cameraButton mx-auto"
                          src={cameraButton}
                          alt="camera_button"
                          style={{ width: "auto" }}
                        />
                      </Link>
                    </div>
                  </div>

                  {/* screen23 */}
                  <div
                    className={
                      "screenTwentyThree CameraScreen h-full " +
                      (screen19Flag ? "" : "disabled")
                    }
                    style={{ height: "700px" }}
                  >
                    <div className="w-full flex flex-row justify-between p-4 pt-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img
                              src={backButtonImg}
                              alt="backArrow"
                              className=""
                              style={{ verticalAlign: "middle" }}
                            />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-12">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "90%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button onClick={() => setScreen19Flag(!screen19Flag)}>
                          <img
                            src={closeButtonImg}
                            alt="crossArrow"
                            className=""
                            style={{ verticalAlign: "middle", transform: screen19Flag ? 'rotate(0deg)' : 'rotate(45deg)' }}
                          />
                        </button>
                      </div>
                    </div>

                    <div className="mainContent">
                      <div className="cameraFrame">
                        <img
                          className="cameraFrameImg mx-auto"
                          src={cameraFrameImg22}
                          alt="camera_frame"
                        />
                      </div>
                      <div class="warningMessage">
                        <div class="warningImage">
                          <img
                            style={{ marginTop: "10px" }}
                            src={warningMessage}
                            alt="warningImage"
                          />
                        </div>
                        <div class="message">
                          <p>Blur document! Try again</p>
                        </div>
                      </div>
                    </div>

                    <div className="footer">
                      <Link onClick={next}>
                        <img
                          className="cameraButton mx-auto"
                          src={cameraButton}
                          alt="camera_button"
                          style={{ width: "auto" }}
                        />
                      </Link>
                    </div>
                  </div>

                  {/* screen3iQR */}
                  <div className="screenTwo screenThree h-full">
                    <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img src={backButtonImg} alt="backArrow" className="" />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-12">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "90%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button style={{ cursor: "unset" }}>
                          <img src={closeButtonImg} alt="crossArrow" className="" />
                        </button>
                      </div>
                    </div>

                    <div class="mainContent mt-0">
                      <div class="mainText">
                        <h1 style={{ height: "40px", fontSize: "16px" }}>Connect your ETH/BNB Wallet</h1>
                        <p className="text-xs mb-4 text-center">Scan QR or manually enter below.</p>
                      </div>

                      <form className="informationSection">
                        <div className="emailFields">
                          <div className="firstNameField firstField">
                            <div className="inputfield firstname">
                              <span className="label text-sm">Wallet Address</span>
                              <input 
                                type="text" 
                                name="wallet_address" 
                                className="input firstnameInput"
                              />
                            </div>
                          </div>
                        </div>
                        {/* <div class="selectboxlabel">
                          <h1 className="text-sm text-left">TGE Contribution Selection</h1>
                        </div>
                        <div className="mb-1">
                          <div className="firstNameField firstField">
                            <div className="firstname flex flex-row items-center">
                              <input 
                                type="radio" 
                                name="contribution"
                                className="firstnameInput mr-2 cursor-pointer" 
                                style={{ borderRadius: "5px" }}
                              />
                              <span className="label text-sm">Stablecoin</span>
                            </div>
                          </div>
                          <div className="lastNameField">
                            <div className="lastname flex flex-row items-center">
                              <input 
                                type="radio" 
                                name="contribution"
                                className="lastnameInput mr-2 cursor-pointer" 
                                style={{ borderRadius: "5px" }}
                              />
                              <span className="label text-sm">Ecosystem Token (AGIX/SDAO)</span>
                            </div>
                          </div>
                        </div>

                        <div class="selectboxlabel mb-2 text-left">
                          <h1 className="text-xs">SDAO Allocations accept SDAO/Stable</h1>
                          <h1 className="text-xs">AGIX Allocations accept AGIX/Stable</h1>
                        </div> */}
                      </form>

                      <div class="languageSelection" style={{ width: "200px" }}>
                        <img src={blackImgCarousel} />
                      </div>
                    </div>

                    <div className="buttonAction">
                      {/* <p onClick={next} className="colorPrimary text-center font-bold text-sm mb-2 cursor-pointer">Link ADA wallet aswell?</p> */}
                      
                      <button 
                        className="action"
                        onClick={next}
                      >
                        Continue
                      </button>
                    </div>
                  </div>

                  {/* screen3iManual */}
                  {/* <div className="screenTwo screenThree h-full">
                    <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img src={backButtonImg} alt="backArrow" className="" />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-12">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "95%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button style={{ cursor: "unset" }}>
                          <img src={closeButtonImg} alt="crossArrow" className="" />
                        </button>
                      </div>
                    </div>

                    <div class="mainContent mt-0">
                      <div class="mainText">
                        <h1 style={{ height: "40px", fontSize: "16px" }}>Connect your Cardano Wallet</h1>
                        <p className="text-xs mb-4 text-center">Scan QR or manually enter below.</p>
                      </div>

                      <form className="informationSection">
                        <div className="emailFields">
                          <div className="firstNameField firstField">
                            <div className="inputfield firstname">
                              <span className="label text-sm">Wallet Address</span>
                              <input 
                                type="text" 
                                name="wallet_address" 
                                className="input firstnameInput"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="selectboxlabel">
                          <h1 className="text-sm text-left">Select Eligible Tokens</h1>
                        </div>
                        <div className="nameFields">
                          <div className="firstNameField firstField">
                            <div className="firstname flex flex-row items-center">
                              <input 
                                type="checkbox" 
                                name="check_1" 
                                className="firstnameInput mr-2 cursor-pointer" 
                                style={{ borderRadius: "5px" }}
                              />
                              <span className="label text-sm">AGIX</span>
                            </div>
                          </div>
                          <div className="lastNameField">
                            <div className="lastname flex flex-row items-center">
                              <input 
                                type="checkbox" 
                                name="check_2" 
                                className="lastnameInput mr-2 cursor-pointer" 
                                style={{ borderRadius: "5px" }}
                              />
                              <span className="label text-sm">SDAO</span>
                            </div>
                          </div>
                        </div>
                      </form>

                      <div class="languageSelection" style={{ width: "200px" }}>
                        <img src={blackImgCarousel} />
                      </div>
                    </div>

                    <div className="buttonAction">
                      <button 
                        className="action"
                        onClick={next}
                      >
                        Continue
                      </button>
                    </div>
                  </div> */}

                  {/* screen25 */}
                  <div
                    className={
                      "screenTwentyFive h-full " +
                      (screen25Flag ? "" : "disabled")
                    }
                  >
                    <div className="w-full flex flex-row justify-between p-4 pt-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img
                              src={backButtonImg}
                              alt="backArrow"
                              className=""
                              style={{ verticalAlign: "middle" }}
                            />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-12">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "98%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button onClick={() => setScreen25Flag(!screen25Flag)}>
                          <img
                            src={closeButtonImg}
                            alt="crossArrow"
                            className=""
                            style={{ verticalAlign: "middle", transform: screen25Flag ? 'rotate(0deg)' : 'rotate(45deg)' }}
                          />
                        </button>
                      </div>
                    </div>

                    <div className="mainContent" style={{ height: "72%" }}>
                      <div className="mainText">
                        <h1>Review your Information</h1>
                        <p className="text-center" style={{ color: "#a8a8a8" }}>If all good! Please click submit for verification.</p>
                      </div>
                      <div className="informationSection">
                        <div className="block mb-6">
                          <div className="headingText">
                            <h5>Personal/Basic Information</h5>
                            <img
                              src={edit}
                              alt="edit"
                              className="editButton"
                              style={{ width: "auto" }}
                            />
                          </div>
                          <div className="fields">
                            <p className="title text-left">Email</p>
                            <p className="value" id="email">
                              johnsmith@gmail.com
                            </p>
                          </div>
                          <div className="fields">
                            <p className="title text-left">Full Name</p>
                            <p className="value" id="name">
                              John Smith
                            </p>
                          </div>
                          <div className="fields">
                            <p className="title text-left">Date of Birth</p>
                            <p className="value" id="dob">
                              09/24/1996
                            </p>
                          </div>
                          <div className="fields">
                            <p className="title text-left">Nationality</p>
                            <p className="value" id="nationality">
                              Pakistani
                            </p>
                          </div>
                        </div>
                        <div className="block">
                          <div className="headingText">
                            <h5>Personal/Basic Information</h5>
                            <img
                              src={edit}
                              alt="edit"
                              className="editButton"
                              style={{ width: "auto" }}
                            />
                          </div>
                          <div className="fields">
                            <p className="title text-left">Email</p>
                            <p className="value" id="email">
                              johnsmith@gmail.com
                            </p>
                          </div>
                          <div className="fields">
                            <p className="title text-left">Full Name</p>
                            <p className="value" id="name">
                              John Smith
                            </p>
                          </div>
                          <div className="fields">
                            <p className="title text-left">Date of Birth</p>
                            <p className="value" id="dob">
                              09/24/1996
                            </p>
                          </div>
                          <div className="fields">
                            <p className="title text-left">Gender</p>
                            <p className="value" id="gender">
                              Male
                            </p>
                          </div>
                          <div className="fields">
                            <p className="title text-left">Nationality</p>
                            <p className="value" id="nationality">
                              Pakistani
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="buttonAction">
                      <Link onClick={next}>
                        <button className="action">Submit</button>
                      </Link>
                    </div>
                  </div>

                  {/* screen26 */}
                  <div
                    className={
                      "screenTwentySix h-full " + (screen26Flag ? "" : "disabled")
                    }
                  >
                    <div className="w-full flex flex-row justify-between p-4 pt-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img
                              src={backButtonImg}
                              alt="backArrow"
                              className=""
                              style={{ verticalAlign: "middle" }}
                            />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-12">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "100%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button onClick={() => setScreen26Flag(!screen26Flag)}>
                          <img
                            src={closeButtonImg}
                            alt="crossArrow"
                            className=""
                            style={{ verticalAlign: "middle", transform: screen26Flag ? 'rotate(0deg)' : 'rotate(45deg)' }}
                          />
                        </button>
                      </div>
                    </div>

                    <div className="mainContent">
                      <div className="mapImgBackground">
                        <div className="mapImage">
                          <img className="map" src={map26} alt="" />
                        </div>
                        <div className="headingText">
                          <h2>Thank you for letting us know you.</h2>
                          <p>
                            We will review your information and get back to you
                            shortly.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="buttonAction">
                      <Link onClick={next}>
                        <button className="action">Finish</button>
                      </Link>
                    </div>
                  </div>

                  {/* screen27 */}
                  <div
                    className={
                      "screenTwentySeven h-full " +
                      (screen27Flag ? "" : "disabled")
                    }
                  >
                    <div className="w-full flex flex-row justify-between p-4 pt-4">
                      <div className="backArrow">
                        <Link onClick={prev}>
                          <button>
                            <img
                              src={backButtonImg}
                              alt="backArrow"
                              className=""
                              style={{ verticalAlign: "middle" }}
                            />
                          </button>
                        </Link>
                      </div>
                      <div className="progressBar w-full py-3 px-12">
                        <div className="relative lg:w-8/12 mx-auto">
                          <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                            <div
                              style={{ width: "100%" }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center pinkBg"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="crossArrow">
                        <button onClick={() => setScreen27Flag(!screen27Flag)}>
                          <img
                            src={closeButtonImg}
                            alt="crossArrow"
                            className=""
                            style={{ verticalAlign: "middle", transform: screen27Flag ? 'rotate(0deg)' : 'rotate(45deg)' }}
                          />
                        </button>
                      </div>
                    </div>

                    <div className="mainContent">
                      <div className="mapImgBackground">
                        <div className="mapImage">
                          <img className="map" src={map27} alt="" />
                        </div>
                        <div className="headingText">
                          <h2>Unable to submit the information</h2>
                          <p>
                            The information you provided is incorrect. Please try
                            again.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="buttonAction">
                      <Link onClick={next}>
                        <button className="action">Go Back</button>
                      </Link>
                    </div>
                  </div>
                </Carousel>
              </div>
            </section>
          </main>
          <div className="prevBtn">
            <button
              className="prevBtnKyc"
              onClick={next}
              style={{ outline: "none" }}
            >
              <img src={kycNext} alt="previous" />
            </button>
          </div>
        </div>
        <img class="footerImg" src={footerLogo} alt="footer" style={{width:"150px",zIndex:"10"}}/>
      </div>

      
      <div className="mt-16 px-12 flex flex-row items-center justify-end">
        {loading && 
          <ReactLoading
            className="margin-auto mt-3 mb-3"
            type={"bars"}
            color={"#a10b1d"}
            height={"10%"}
            width={"10%"}
          />
        }
        <button onClick={handleSubmit} type="button" className="buttonPink px-6 py-2 rounded-lg text-sm font-bold mr-4 z-10" style={{ background: "#ebedf2", color: "#a10b1d" }}>Save Changes</button>
        <Link to={"/verify/start"} target="_blank" className="z-10 mr-4">
          <button className="buttonPink px-6 py-2 rounded-lg text-sm font-bold ">Generate Link</button>
        </Link>
        <Link to={"/kyb/start"} target="_blank" className="z-10">
          <button className="buttonPink px-6 py-2 rounded-lg text-sm font-bold ">Generate Link KYB</button>
        </Link>
      </div>
    </>
  );
}
