import React from "react";
import { Link } from "react-router-dom";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import Select from 'react-select';
// import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

// components
// import TableDropdown from "components/Dropdowns/TableDropdown.js";

// images
import ExportIcon from "../../assets/img/icons/exporticon.png";
import searchIcon from "../../assets/img/search-icon.png";
import rightArrow from "../../assets/img/rightArrowBlack.png";
// import ElipsesOne from "../../assets/img/event_elp_1.png";
// import ElipsesTwo from "../../assets/img/event_elp_2.png";
// import ElipsesError from "../../assets/img/event_elp_error.png";
// import VerticalLine from "../../assets/img/verticalLine.png";
// import statusAccountGreen from "../../assets/img/statusAccountGreen.png";
// import statusLocGreen from "../../assets/img/statusLocGreen.png";
// import statusIDGreen from "../../assets/img/statusIDGreen.png";
// import statusMapGreen from "../../assets/img/statusMapGreen.png";
// import statusAccountRed from "../../assets/img/statusAccountRed.png";
// import statusLocRed from "../../assets/img/statusLocRed.png";
// import statusIDRed from "../../assets/img/statusIDRed.png";
// import statusMapRed from "../../assets/img/statusMapRed.png";

export default function AMLScreening() {
  // const color = "light";
  const [individualActive, setIndividualActive]  = React.useState(true);
  const [corporateActive, setCorporateActive]  = React.useState(false);

  // const circularBarColorAccepted = "#15E49A";
  // const circularBarColorPending = "#FFC102";
  // const circularBarColorDeclined = "#FA364C";

  function setIndivActive() {
    setIndividualActive(true);
    setCorporateActive(false);
  }

  function setCorpActive() {
    setIndividualActive(false);
    setCorporateActive(true);
  }

  const searchby = [
    { label: "Search By", value: 1 },
    { label: "Search By Name", value: 2 },
  ];

  const defaultValueSearchBy = searchby[0];

  const customStylesSelect = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#828282' : '#828282',
      backgroundColor: state.isSelected ? '#EBEDF2' : '#FFF',
      padding: 10,
      fontSize: '14px',
    }),
  }

  return (
    <>
      <div className="amlscreening flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
            <div className="rounded-lg flex justify-between bg-white px-12 py-6 border-0 w-full mb-6 shadow-lg">
              <div className="flex">
                <div 
                  className="rounded-lg px-4 py-2 mr-4 cursor-pointer" 
                  style={individualActive ? { backgroundColor: "#EBEDF2" } : {backgroundColor: "#FFF"}}
                  onClick={setIndivActive}
                >
                  <h4 className="font-semibold text-sm">Individual Screening</h4>
                </div>
                <div 
                  className="rounded-lg px-4 py-2 cursor-pointer" 
                  style={corporateActive ? { backgroundColor: "#EBEDF2" } : {backgroundColor: "#FFF"}}
                  onClick={setCorpActive}
                >
                  <h4 className="font-semibold text-sm">Batch Screening</h4>
                </div>
              </div>
              <div className="flex px-4 py-2 cursor-pointer">
                <img src={ExportIcon} alt="export icon" className="mr-3" style={{width:"20px", height:"20px"}} />
                <h4 className="font-semibold text-sm" style={{color: "#a10b1d"}}>Export CSV</h4>
              </div>
            </div>
            <div className="w-full flex flex-row">
              <div className="lg:w-9/12 h-full relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white mr-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 bg-white">
                  <div className="rounded-t mb-0 px-4 py-4 border-0">
                    <div className="flex flex-wrap items-center">
                      <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                        <Form class="filtersTable aml" 
                          // onSubmit={(e) => handleLogin(e)} 
                          // ref={c => {form = c;}}
                        >
                          <div className="flex items-center">
                            <div className="searchInputTables mr-4">
                              <div className="fieldSearch relative w-full mb-3">
                                <img className="amlScreeningSearchIcon absolute" src={searchIcon} alt="searchicon" />
                                <Input
                                  type="text"
                                  className="searchFilter input-field border-0 px-3 py-3 pl-10 placeholder-blueGray-300 text-black-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  // placeholder="Search by name"
                                  // value={email}
                                  // onChange={(e) => setEmail(e.target.value)}
                                  // validations={[required, validEmail]}
                                />
                              </div>
                            </div>
                            <div className="flex">
                              <div className="mr-3">
                                <div className="field dropdown relative w-full mb-3">
                                  <Select styles={customStylesSelect} options={ searchby } isSearchable={false} defaultValue={defaultValueSearchBy} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <p className="text-sm font-semibold">15 Matches Found...</p>
                        </Form>
                      </div>
                    </div>
                  </div>
                  <div className="block w-full overflow-x-auto">
                    {/* Projects table */}
                    <table className="items-center w-full bg-transparent border-collapse">
                      <thead className="border border-solid border-t-0 border-l-0 border-r-0 border-blueGray-300">
                        <tr>
                          <th
                            className={
                              "px-6 align-middle py-3 text-base whitespace-nowrap font-normal text-left text-black-600"
                            }
                          >
                            Select all
                          </th>
                          <th
                            className={
                              "px-6 align-middle py-3 text-base whitespace-nowrap font-semibold text-left text-black-600"
                            }
                          >
                            Name
                          </th>
                          <th
                            className={
                              "px-6 align-middle py-3 text-base whitespace-nowrap font-semibold text-left text-black-600"
                            }
                          >
                            Category
                          </th>
                          <th
                            className={
                              "px-6 align-middle py-3 text-base whitespace-nowrap font-semibold text-left text-black-600"
                            }
                          >
                            Country
                          </th>
                          <th
                            className={
                              "px-6 align-middle py-3 text-base whitespace-nowrap font-semibold text-left text-black-600"
                            }
                          >
                            Match score
                          </th>
                          <th
                            className={
                              "px-6 align-middle py-3 text-base whitespace-nowrap font-semibold text-left text-black-600"
                            }
                          >
                            Strength
                          </th>
                          <th
                            className={
                              "px-6 align-middle py-3 text-base whitespace-nowrap font-semibold text-left text-black-600"
                            }
                          >
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="cursor-pointer active-row">
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            <div className="selectBox">
                              <div>
                                <label className="inline-flex items-center cursor-pointer">
                                  <input
                                    id="customCheckLogin"
                                    type="checkbox"
                                    className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
                                  />
                                </label>
                              </div>
                            </div>
                          </td>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-left flex flex-col items-left">
                            <Link to="/aml/amlscreeningdetail">
                              <img
                                alt="..."
                                src={require("assets/img/ProfilePicture.png").default}
                                className="shadow-xl rounded-full h-auto align-middle border-none max-w-100-px mb-2"
                                style={{ maxWidth: "80px" }}
                              />
                              <span
                                className={
                                  "text-base font-bold text-black-600"
                                }
                              >
                                John Snow
                              </span>
                            </Link>
                          </td>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            <div className="statusGrid flex flex-col">
                              <button className="w-full amlbuttonsSmall px-4 rounded-lg font-semibold text-white-400 uppercase buttonRed mb-2">
                                PEP
                              </button>
                              <button className="w-full amlbuttonsSmall px-4 rounded-lg font-semibold text-white-400 uppercase buttonYellow mb-2">
                                SAN
                              </button>
                              <button className="w-full amlbuttonsSmall px-4 rounded-lg font-semibold text-white-400 uppercase buttonGreen">
                                AM
                              </button>
                            </div>
                          </td>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4">
                            <div className="flex items-center">
                              <div className="relative w-full">
                                <p className="text-sm font-normal">Pakistan</p>
                              </div>
                            </div>
                          </td>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4">
                            <div className="relative pt-1" style={{ maxWidth: "120px" }}>
                              <div className="overflow-hidden h-5 mb-4 text-xs flex rounded greyBg">
                                <div style={{width: "30%"}} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bgYellow"></div>
                              </div>
                              <p className="text-right font-bold text-sm">30%</p>
                            </div>
                          </td>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            <div className="flex items-center">
                              <div className="relative w-full">
                                <p className="text-sm font-normal">Potential Match</p>
                              </div>
                            </div>
                          </td>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                            <div className="flex items-right">
                              <img className="" src={rightArrow} alt="nextIcon" />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="lg:w-3/12 relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full px-4 flex justify-center">
                    <div className="relative mt-12">
                      <img
                        alt="..."
                        src={require("assets/img/ProfilePicture.png").default}
                        className="shadow-xl rounded-full h-auto align-middle border-none max-w-180-px"
                      />
                    </div>
                  </div>
                </div>
                <div className="px-12 py-4 mx-4 text-left mt-10">
                  <table className="w-full table-fixed">
                    <tbody>
                      <tr>
                        <td className="font-light text-xs pb-2">First Name</td>
                        <td className="font-semibold text-xs pb-2">John</td>
                      </tr>
                      <tr>
                        <td className="font-light text-xs pb-2">Last Name</td>
                        <td className="font-semibold text-xs pb-2">Snow</td>
                      </tr>
                      <tr>
                        <td className="font-light text-xs pb-2">Date of Birth</td>
                        <td className="font-semibold text-xs pb-2">12/5/1994</td>
                      </tr>
                      <tr>
                        <td className="font-light text-xs pb-2">Nationality</td>
                        <td className="font-semibold text-xs pb-2">Pakistani</td>
                      </tr>
                      <tr>
                        <td className="font-light text-xs pb-2">ID Number</td>
                        <td className="font-semibold text-xs pb-2">xxxxx-xxxxxxx-x</td>
                      </tr>
                      <tr>
                        <td className="font-light text-xs pb-2">Expiration Date</td>
                        <td className="font-semibold text-xs pb-2">12/4/2022</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="flex flex-row w-full p-5">
                  <div className="adverseMedia lg:w-4/12 mr-2">
                    <button className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 buttonYellow mb-2 text-sm">
                      Adverse Media
                    </button>
                  </div>
                  <div className="sanction lg:w-4/12 mr-2">
                    <button className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 buttonGreen mb-2 text-sm">
                      Sanction
                    </button>
                  </div>
                  <div className="pep lg:w-4/12">
                    <button className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 buttonRed mb-2 text-sm">
                      PEP Class
                    </button>
                  </div>
                </div>
                <div className="flex flex-col w-full p-5 mt-20">
                  <div className="falseMatch w-full">
                    <button className="w-full py-4 px-4 rounded-lg font-bold text-white-400 buttonYellow mb-2 text-lg">
                      False Match
                    </button>
                  </div>
                  <div className="posMatch w-full">
                    <button className="w-full py-4 px-4 rounded-lg font-bold text-white-400 buttonRed mb-2 text-lg">
                      Positive Match
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
