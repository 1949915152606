import React from "react";
import "react-circular-progressbar/dist/styles.css";
import { Link as Link1 } from "react-scroll";
import "react-image-gallery/styles/css/image-gallery.css";
import { useHistory, useParams } from "react-router";
import ImageGallery from "react-image-gallery";
import countryList from "react-select-country-list";
import ReactTooltip from "react-tooltip";
import ReactImageZoom from 'react-image-zoom';
import {
  viewdetail,
  saveassigned,
  updatestatus,
  getevents,
  revisesection
} from "services/casemanagement/Onboarding";
import Select from "react-select";
import ReactLoading from "react-loading";
import Moment from "moment";
import { Link as Link2 } from "react-router-dom";
import { showallmembers } from "services/settings/Team";
import { saveevent } from "services/kyc/KycScreens";

import { PDFViewer } from "@react-pdf/renderer";
import {
  Document,
  Image,
  Note,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Modal from 'react-modal';

// components
import { MemoizedMap } from "components/Maps/MapExample.js";

// images
import ExportIcon from "../../assets/img/icons/exporticon.png";
import downloadIcon from "../../assets/img/icons/downloadIcon.png";
import ElipsesOne from "../../assets/img/event_elp_1.png";
import ElipsesTwo from "../../assets/img/event_elp_2.png";
import ElipsesError from "../../assets/img/event_elp_error.png";
import ElipsesYellow from "../../assets/img/event_elp_yellow.png";
import VerticalLine from "../../assets/img/verticalLine.png";
import dropDownIcon from "../../assets/img/dropdown.png";
import idVerRectImg from "../../assets/img/idVerRectImg.png";
import greenWarning from "../../assets/img/greenWarning.png";
import leftArrow from "../../assets/img/leftarrow.png";
import rightArrow from "../../assets/img/rightarrow.png";
import longVertLine from "../../assets/img/longVertLine.png";
import smallVertLine from "../../assets/img/smallVertLine.png";
import locIcon from "../../assets/img/locIcon.png";
import hedgelogo from "../../assets/img/innreg-logo.png";
import EyeIcon from "../../assets/img/icons/eyeicon.png";

export default function CaseManagementDetailOnboarding(props) {
  const { inq_id } = useParams();
  const [images, setImages] = React.useState([]);
  const history = useHistory();
  const countryoptions = React.useMemo(() => countryList().getData(), []);
  countryoptions.forEach((ele) => {
    if(ele.label === "Czechia") {
      ele.label = "Czech Republic (Czechia)";
    }
  });
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [matches, setMatches] = React.useState(0);
  const [assignedTo, setAssignedTo] = React.useState(null);
  const [inquiryStatus, setInquiryStatus] = React.useState(null);
  const [users, setUsers] = React.useState([]);
  const [matchesArray, setMatchesArray] = React.useState([]);
  const [livenessData, setLivenessData] = React.useState([]);
  const [onboardingData, setOnboardingData] = React.useState([]);
  const [sanctionData, setSanctionData] = React.useState([]);
  const [onboardingEventData, setOnboardingEventData] = React.useState([]);
  const [onboardingEventDataLength, setOnboardingEventDataLength] = React.useState(0);
  const [aiData, setAiData] = React.useState([]);
  const [checks, setChecks] = React.useState([]);

  const [checksId, setChecksId] = React.useState([]);
  const [checksLiveess, setChecksLiveess] = React.useState([]);
  const [checksAddress, setChecksAddress] = React.useState([]);
  const [checksSanction, setChecksSanction] = React.useState([]);

  const [idVerActive, setIdVerActive] = React.useState(true);
  const [walletVerActive, setWalletVerActive] = React.useState(false);
  const [livenessActive, setLivenessActive] = React.useState(false);
  const [addressVerActive, setAddressVerActive] = React.useState(false);
  const [screeningActive, setScreeningActive] = React.useState(false);
  const [eventTimeActive, setEventTimeActive] = React.useState(false);
  const [imgFrontActive, setImgFrontActive] = React.useState(true);
  const [imgBackActive, setImgBackActive] = React.useState(false);
  const [imgWithDocActive, setImgWithDocActive] = React.useState(false);

  const [idShow, setIdShow] = React.useState(true);
  const [walletShow, setWalletShow] = React.useState(true);
  const [liveShow, setLiveShow] = React.useState(true);
  const [addShow, setAddShow] = React.useState(true);
  const [screenShow, setScreenShow] = React.useState(true);
  const [eventShow, setEventShow] = React.useState(true);

  const [potMatchArr, setPotMatchArray] = React.useState(matchesArray);
  
  const [modalIsOpen1, setIsOpen1] = React.useState(false);
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [modalIsOpen3, setIsOpen3] = React.useState(false);
  const [modalIsOpen4, setIsOpen4] = React.useState(false);
  const [modalIsOpenFront, setIsOpenFront] = React.useState(false);
  const [modalIsOpenBack, setIsOpenBack] = React.useState(false);
  const [modalIsOpenAddress, setIsOpenAddress] = React.useState(false);

  const [walletDetails, setWalletDetails] = React.useState(null);
  const [ocrData, setOcrData] = React.useState(null);

  const [revisit, setRevisit] = React.useState(false);
  const [revisitSection, setRevisitSection] = React.useState(null);
  const [revisitNotes, setRevisitNotes] = React.useState(null);
  const [revisionSent, setRevisionSent] = React.useState(false);

  const [propsImgFront, setPropsImgFront] = React.useState({});
  const [propsImgBack, setPropsImgBack] = React.useState({});
  const [propsAddressImg, setPropsAddressImg] = React.useState({});

  let storage = window.localStorage;
  var mapLatitude = storage.getItem("latitude");
  var mapLongitude = storage.getItem("longitude");

  // google maps script include
  React.useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://maps.googleapis.com/maps/api/js?key="+process.env.REACT_APP_GOOGLE_API_KEY;
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, []);

  const submitRevisit = () => {
    let formdata = {
      inq_id: inq_id,
      section: revisitSection,
      note: revisitNotes
    };

    revisesection(formdata).then((response) => {
      // console.log("response: ", response);
      if (response.data.success) {
        setRevisit(false);
        setRevisitSection(null);
        setRevisitNotes(null);
        setRevisionSent(true);
        let timeout = setTimeout(() => setRevisionSent(false), 2000);
        return () => clearTimeout(timeout);
        getevents(inq_id)
          .then((response) => {
            // console.log("response: ", response);
            if (response.data.success) {
              setOnboardingEventData(response.data.event);
              if (response.data.event.length > 0) {
                setOnboardingEventDataLength(response.data.event.length);
              }
            }
          })
          .catch((error) => {
            console.log("error: ", error);
          });
      }
    }).catch((error) => {
      console.log("error: ", error);
    });
  }

  const customStylesPopup = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const openModal1 = () => {
    setIsOpen1(true);
  }
  const openModal2 = () => {
    setIsOpen2(true);
  }
  const openModal3 = () => {
    setIsOpen3(true);
  }
  const openModal4 = () => {
    setIsOpen4(true);
  }
  const openModalImageFront = () => {
    setIsOpenFront(true);
  }
  const openModalImageBack = () => {
    setIsOpenBack(true);
  }
  const openModalImageAddress = () => {
    setIsOpenAddress(true);
  }

  const afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  const closeModal1 = () => {
    setIsOpen1(false);
  }
  const closeModal2 = () => {
    setIsOpen2(false);
  }
  const closeModal3 = () => {
    setIsOpen3(false);
  }
  const closeModal4 = () => {
    setIsOpen4(false);
  }
  const closeModalImageFront = () => {
    setIsOpenFront(false);
  }
  const closeModalImageBack = () => {
    setIsOpenBack(false);
  }
  const closeModalImageAddress = () => {
    setIsOpenAddress(false);
  }

  let screeningChecksAll = JSON.stringify([
    "sanction",
    "debarment",
    "fin.bank",
    "role.oligarch",
    "role.pep",
    "role.rca",
    "crime",
    "crime.boss",
    "crime.fin",
    "crime.terror",
    "crime.theft",
    "crime.war",
  ]);

  const customStylesSelect = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#828282" : "#828282",
      backgroundColor: state.isSelected ? "#EBEDF2" : "#FFF",
      border: 0,
      padding: 10,
      fontSize: "14px",
    }),
  };

  React.useEffect(() => {
    if (!inq_id) {
      history.push("/case/casemanagement/all");
      window.location.reload();
    } else {
      viewdetail(inq_id)
        .then((response) => {
          if (response.data.success) {
            setLivenessData(response.data.liveness);
            setOnboardingData(response.data.onboardings);
            setInquiryStatus(response.data.onboardings.status);
            setAssignedTo(response.data.onboardings.assigned_to);
            setChecks(response.data.checks);
            setAiData(response.data.dataAI);
            setWalletDetails(response.data.WalletDetails);
            setOcrData(response.data.ocr);
            
            setPropsImgFront({
              // width: "100%", 
              height: 300, 
              img: response.data.onboardings.idverification.path_front ? response.data.onboardings.idverification.path_front : idVerRectImg,
              zoomPosition: "original",
            });

            setPropsImgBack({
              // width: "100%", 
              height: 300, 
              img: response.data.onboardings.idverification.path_back ? response.data.onboardings.idverification.path_back : idVerRectImg,
              zoomPosition: "original",
            });

            setPropsAddressImg({
              // width: "100%", 
              height: 300, 
              img: response.data.onboardings.addressverification.ext === "pdf" ? idVerRectImg : (response.data.onboardings.addressverification.path_doc ? response.data.onboardings.addressverification.path_doc : idVerRectImg),
              zoomPosition: "original",
            });
            
            if (response.data.checks != null) {
              setChecksId(response.data.checks[1]);
              setChecksLiveess(response.data.checks[2]);
              setChecksAddress(response.data.checks[3]);
              setChecksSanction(response.data.checks[4]);
            }

            setSanctionData(response.data.sanction);
            if (response.data.sanction.results[0].length > 0) {
              setMatches(response.data.sanction.matches);
              setMatchesArray(
                response.data.sanction.results[0].map((ele, ind) => [false])
              );
            }
            if (response.data.liveness.length > 0) {
              setImages(
                response.data.liveness.map((ele) => ({
                  original: ele.path ? ele.path : idVerRectImg,
                  originalWidth: "200px",
                  originalHeight: "200px",
                  thumbnail: ele.path ? ele.path : idVerRectImg,
                }))
              );
            } else {
              setImages([
                {
                  original: idVerRectImg,
                  originalWidth: "200px",
                  originalHeight: "200px",
                  thumbnail: idVerRectImg,
                },
              ]);
            }
            matchesArray[0] = true;
            setDataLoaded(true);
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });

      getevents(inq_id)
        .then((response) => {
          // console.log("response: ", response);
          if (response.data.success) {
            setOnboardingEventData(response.data.event);
            if (response.data.event.length > 0) {
              setOnboardingEventDataLength(response.data.event.length);
            }
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });

      showallmembers()
        .then((response) => {
          // console.log("response: ", response);
          if (response.data.members.length > 0) {
            let userarray = response.data.members.map((ele) => ({
              label: ele.member,
              value: ele.id,
            }));
            setUsers(userarray);
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    }
  }, []);

  function setActivePotMatchCardLeft(id) {
    if (id > 1) {
      let items = [...potMatchArr];
      items[id - 1] = false;
      items[id - 2] = true;
      setPotMatchArray(items);
    }
  }

  function setActivePotMatchCardRight(id) {
    if (id < matches) {
      let items = [...potMatchArr];
      items[id - 1] = false;
      items[id] = true;
      setPotMatchArray(items);
    }
  }

  function setIdActive() {
    setIdVerActive(true);
    setWalletVerActive(false);
    setLivenessActive(false);
    setAddressVerActive(false);
    setScreeningActive(false);
    setEventTimeActive(false);
  }
  function setWalletActive() {
    setIdVerActive(false);
    setWalletVerActive(true);
    setLivenessActive(false);
    setAddressVerActive(false);
    setScreeningActive(false);
    setEventTimeActive(false);
  }
  function setLiveActive() {
    setIdVerActive(false);
    setWalletVerActive(false);
    setLivenessActive(true);
    setAddressVerActive(false);
    setScreeningActive(false);
    setEventTimeActive(false);
  }
  function setAddActive() {
    setIdVerActive(false);
    setWalletVerActive(false);
    setLivenessActive(false);
    setAddressVerActive(true);
    setScreeningActive(false);
    setEventTimeActive(false);
  }
  function setScreenActive() {
    setIdVerActive(false);
    setWalletVerActive(false);
    setLivenessActive(false);
    setAddressVerActive(false);
    setScreeningActive(true);
    setEventTimeActive(false);
  }
  function setEvTActive() {
    setIdVerActive(false);
    setWalletVerActive(false);
    setLivenessActive(false);
    setAddressVerActive(false);
    setScreeningActive(false);
    setEventTimeActive(true);
  }

  function setFrontActive() {
    setImgFrontActive(true);
    setImgBackActive(false);
    setImgWithDocActive(false);
  }
  function setBackActive() {
    setImgFrontActive(false);
    setImgBackActive(true);
    setImgWithDocActive(false);
  }
  function setWithDocActive() {
    setImgFrontActive(false);
    setImgBackActive(false);
    setImgWithDocActive(true);
  }

  const handleChangeAssignedTo = (event, { name }) => {
    let formdata = {
      inq_id: inq_id,
      assigned_to: event.value,
    };
    saveassigned(formdata)
      .then((response) => {
        setAssignedTo(event.value);
        // console.log("response: ", response);
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  const setStatus = (status) => {
    let formdata = {
      inq_id: inq_id,
      status: status,
    };
    updatestatus(formdata)
      .then((response) => {
        // console.log("response: ", response);
        if (response.data.success) {
          const storage = window.localStorage;
          const username = storage.getItem("user_name");
          let formdata1 = {
            inq_id: inq_id,
            event_desc: "",
            passed: 0,
          };
          if (status === "approve") {
            formdata1 = {
              inq_id: inq_id,
              event_desc: "Inquiry approved by " + username,
              passed: 1,
            };
          } else if (status === "review") {
            formdata1 = {
              inq_id: inq_id,
              event_desc: "Inquiry under review by " + username,
              passed: 2,
            };
          } else if (status === "decline") {
            formdata1 = {
              inq_id: inq_id,
              event_desc: "Inquiry declined by " + username,
              passed: 3,
            };
          }
          saveevent(formdata1)
            .then((response) => {
              if (response.data.success) {
                setInquiryStatus(status);
                history.push("/case/casemanagement/all");
                window.location.reload();
              }
            })
            .catch((error) => {
              console.log("error: ", error);
            });
        }
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  const styles = StyleSheet.create({
    page: {
      backgroundColor: "#ffffff",
      padding: "50px 30px",
    },
    textSm: {
      fontSize: "11px",
      fontWeight: "normal",
      lineHeight: "1px",
      letterSpacing: "0.4px",
    },
    textSmGray: {
      fontSize: "11px",
      fontWeight: "normal",
      color: "#808080",
      marginLeft: "10px",
      lineHeight: "1px",
      letterSpacing: "0.4px",
    },
    textGray: {
      fontSize: "11px",
      fontWeight: "normal",
      color: "#808080",
      marginTop: "5px",
      letterSpacing: "0.4px",
    },
    textSmCol: {
      width: "40%",
      fontSize: "11px",
      fontWeight: "normal",
      lineHeight: "1px",
      letterSpacing: "0.4px",
    },
  });

  const PDFFile = (props) => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "2px solid #a10b1d",
            paddingBottom: "10px",
          }}
        >
          <View>
            <Text>Identity Verification</Text>
            <Text
              style={{
                color: "#808080",
                fontSize: "12px",
                marginTop: "10px",
              }}
            >
              {onboardingData.inq_id ? onboardingData.inq_id : "N/A"}
            </Text>
          </View>
          <View>
            {inquiryStatus === "approve" && (
              <View
                style={{
                  borderRadius: "15px",
                  padding: "6px",
                  display: "flex",
                  backgroundColor: "rgba(21, 228, 154, 0.5)",
                }}
              >
                <Text style={{ fontSize: "9px" }}>APPROVE</Text>
              </View>
            )}

            {inquiryStatus === "decline" && (
              <View
                style={{
                  borderRadius: "15px",
                  padding: "6px",
                  display: "flex",
                  backgroundColor: "rgba(250, 54, 76, 0.5)",
                }}
              >
                <Text style={{ fontSize: "9px" }}>DECLINE</Text>
              </View>
            )}

            {inquiryStatus === "review" && (
              <View
                style={{
                  borderRadius: "15px",
                  padding: "6px",
                  display: "flex",
                  backgroundColor: "rgb(255, 193, 2,0.5)",
                  textAlign: "center",
                }}
              >
                <Text style={{ fontSize: "9px" }}>REVIEW</Text>
              </View>
            )}

            {onboardingEventData.map((element) => {
              return element.event_desc == "Inquiry completed" ? (
                <Text style={{ fontSize: "12px", marginTop: "10px" }}>
                  {Moment(element.created_at).format("MM-DD-YYYY")}
                </Text>
              ) : null;
            })}
          </View>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            width: "70%",
            marginTop: "20px",
            justifyContent: "space-between",
          }}
        >
          <View style={{ display: "flex", flexDirection: "row" }}>
            <Text style={styles.textSm}>ID Type: </Text>
            {props.onboardingData.idverification.type_of_doc === "id" && (
              <Text style={styles.textSmGray}>ID Card</Text>
            )}
            {props.onboardingData.idverification.type_of_doc === "passport" && (
              <Text style={styles.textSmGray}>Passport</Text>
            )}
            {props.onboardingData.idverification.type_of_doc === "license" && (
              <Text style={styles.textSmGray}>Driving License</Text>
            )}
          </View>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <Text style={styles.textSm}>Capture Method:</Text>
            <Text style={styles.textSmGray}>
              {props.onboardingData.idverification.capture_method}
            </Text>
          </View>
        </View>
        <View
          style={{
            marginTop: "20px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Image
            style={{ width: "40%", height: "150px", marginRight: "20px" }}
            src={
              props.onboardingData.idverification.path_back
                ? {uri: props.onboardingData.idverification.path_back, method: "GET", headers: { "Cache-Control": "no-cache" }, body: ""}
                : idVerRectImg
            }
          />
          <Image
            style={{ width: "40%", height: "150px" }}
            src={
              props.onboardingData.idverification.path_front
                ? {uri: props.onboardingData.idverification.path_front, method: "GET", headers: { "Cache-Control": "no-cache" }, body: ""}
                : idVerRectImg
            }
          />
          {/* {console.log(onboardingData.idverification.path_front)} */}
        </View>
        <View style={{ marginTop: "20px" }}>
          <View style={{ width: "50%" }}>
            <Text style={{ marginBottom: "10px" }}>Manual Fields</Text>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "10px",
              }}
            >
              <Text style={styles.textSmCol}>Full Name:</Text>
              {props.onboardingData.basicinfo.first_name ||
              props.onboardingData.basicinfo.last_name ? (
                <Text style={styles.textSmGray}>
                  {props.onboardingData.basicinfo.first_name +
                    " " +
                    props.onboardingData.basicinfo.last_name}
                </Text>
              ) : (
                <Text style={styles.textSmGray}>N/A</Text>
              )}
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "10px",
              }}
            >
              <Text style={styles.textSmCol}>Full Address:</Text>
              {props.onboardingData.addressverification.address &&
              props.onboardingData.addressverification.city &&
              props.onboardingData.addressverification.country ? (
                <Text
                  style={{
                    fontSize: "12px",
                    fontWeight: "normal",
                    color: "#808080",
                    marginLeft: "10px",
                    width: "60%",
                  }}
                >
                  {props.onboardingData.addressverification.address +
                    ", " +
                    props.onboardingData.addressverification.city +
                    ", " +
                    props.onboardingData.addressverification.country}
                </Text>
              ) : (
                <Text style={styles.textSmGray}>N/A</Text>
              )}
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "5px",
              }}
            >
              <Text style={styles.textSmCol}>Issue Date:</Text>
              <Text style={styles.textSmGray}>N/A</Text>
            </View>
          </View>
          <View style={{ width: "50%" }}>
            <Text style={{ marginBottom: "10px", marginTop: "15px" }}>
              Document OCR
            </Text>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "10px",
              }}
            >
              <Text style={styles.textSmCol}>Full Name:</Text>
              <Text style={styles.textSmGray}>N/A</Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "10px",
              }}
            >
              <Text style={styles.textSmCol}>Full Address:</Text>
              <Text style={styles.textSmGray}>N/A</Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "10px",
              }}
            >
              <Text style={styles.textSmCol}>Issue Date:</Text>
              <Text style={styles.textSmGray}>N/A</Text>
            </View>
          </View>
        </View>
        <View
          style={{
            position: "absolute",
            bottom: 20,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingHorizontal: 40,
            alignItems: "center",
          }}
        >
          <Image style={{ width: "100px" }} src={hedgelogo} />
          <Text
            style={{ fontSize: "12px" }}
            render={({ pageNumber }) => `${pageNumber}`}
          />
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "2px solid #a10b1d",
            paddingBottom: "10px",
          }}
        >
          <View>
            <Text>Self Liveness</Text>
            <Text
              style={{
                color: "#808080",
                fontSize: "12px",
                marginTop: "10px",
              }}
            >
              {onboardingData.inq_id ? onboardingData.inq_id : "N/A"}
            </Text>
          </View>
          <View>
            {inquiryStatus === "approve" && (
              <View
                style={{
                  borderRadius: "15px",
                  padding: "6px",
                  display: "flex",
                  backgroundColor: "rgba(21, 228, 154, 0.5)",
                }}
              >
                <Text style={{ fontSize: "9px" }}>APPROVE</Text>
              </View>
            )}

            {inquiryStatus === "decline" && (
              <View
                style={{
                  borderRadius: "15px",
                  padding: "6px",
                  display: "flex",
                  backgroundColor: "rgba(250, 54, 76, 0.5)",
                }}
              >
                <Text style={{ fontSize: "9px" }}>DECLINE</Text>
              </View>
            )}

            {inquiryStatus === "review" && (
              <View
                style={{
                  borderRadius: "15px",
                  padding: "6px",
                  display: "flex",
                  backgroundColor: "rgb(255, 193, 2,0.5)",
                  textAlign: "center",
                }}
              >
                <Text style={{ fontSize: "9px" }}>REVIEW</Text>
              </View>
            )}

            {onboardingEventData.map((element) => {
              return element.event_desc == "Inquiry completed" ? (
                <Text style={{ fontSize: "12px", marginTop: "10px" }}>
                  {Moment(element.created_at).format("MM-DD-YYYY")}
                </Text>
              ) : null;
            })}
          </View>
        </View>
        <View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              marginTop: "10px",
            }}
          >
            {props.images.map((image) => (
              <Image
                style={{
                  width: "160px",
                  height: "auto",
                  marginRight: "10px",
                }}
                src={{uri: image.thumbnail, method: "GET", headers: { "Cache-Control": "no-cache" }, body: ""}}
              />
            ))}
          </View>
        </View>
        <View
          style={{
            position: "absolute",
            bottom: 20,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingHorizontal: 40,
            alignItems: "center",
          }}
        >
          <Image style={{ width: "100px" }} src={hedgelogo} />
          <Text
            style={{ fontSize: "12px" }}
            render={({ pageNumber }) => `${pageNumber}`}
          />
        </View>
      </Page>
      {props.checks.length > 0 && (
        <>
          <Page size="A4" style={styles.page}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                borderBottom: "2px solid #a10b1d",
                paddingBottom: "10px",
              }}
            >
              <View>
                <Text>Checks</Text>
                <Text
                  style={{
                    color: "#808080",
                    fontSize: "12px",
                    marginTop: "10px",
                  }}
                >
                  {onboardingData.inq_id ? onboardingData.inq_id : "N/A"}
                </Text>
              </View>
              <View>
                {inquiryStatus === "approve" && (
                  <View
                    style={{
                      borderRadius: "15px",
                      padding: "6px",
                      display: "flex",
                      backgroundColor: "rgba(21, 228, 154, 0.5)",
                    }}
                  >
                    <Text style={{ fontSize: "9px" }}>APPROVE</Text>
                  </View>
                )}

                {inquiryStatus === "decline" && (
                  <View
                    style={{
                      borderRadius: "15px",
                      padding: "6px",
                      display: "flex",
                      backgroundColor: "rgba(250, 54, 76, 0.5)",
                    }}
                  >
                    <Text style={{ fontSize: "9px" }}>DECLINE</Text>
                  </View>
                )}

                {inquiryStatus === "review" && (
                  <View
                    style={{
                      borderRadius: "15px",
                      padding: "6px",
                      display: "flex",
                      backgroundColor: "rgb(255, 193, 2,0.5)",
                      textAlign: "center",
                    }}
                  >
                    <Text style={{ fontSize: "9px" }}>REVIEW</Text>
                  </View>
                )}

                {onboardingEventData.map((element) => {
                  return element.event_desc == "Inquiry completed" ? (
                    <Text style={{ fontSize: "12px", marginTop: "10px" }}>
                      {Moment(element.created_at).format("MM-DD-YYYY")}
                    </Text>
                  ) : null;
                })}
              </View>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                marginTop: "20px",
              }}
            >
              {props.checks.map((ele, index) => (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "30px",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <Image
                    style={{ width: "20px", marginRight: "10px" }}
                    src={ele.status ? greenWarning : ElipsesError}
                  />
                  <Text style={styles.textSm}>
                    {ele.globalchecks.description}
                  </Text>
                </View>
              ))}
            </View>
            <View
              style={{
                position: "absolute",
                bottom: 20,
                left: 0,
                right: 0,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingHorizontal: 40,
                alignItems: "center",
              }}
            >
              <Image style={{ width: "100px" }} src={hedgelogo} />
              <Text
                style={{ fontSize: "12px" }}
                render={({ pageNumber }) => `${pageNumber}`}
              />
            </View>
          </Page>
        </>
      )}
      <Page size="A4" style={styles.page}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "2px solid #a10b1d",
            paddingBottom: "10px",
          }}
        >
          <View>
            <Text>Address Verification</Text>
            <Text
              style={{
                color: "#808080",
                fontSize: "12px",
                marginTop: "10px",
              }}
            >
              {onboardingData.inq_id ? onboardingData.inq_id : "N/A"}
            </Text>
          </View>
          <View>
            {inquiryStatus === "approve" && (
              <View
                style={{
                  borderRadius: "15px",
                  padding: "6px",
                  display: "flex",
                  backgroundColor: "rgba(21, 228, 154, 0.5)",
                }}
              >
                <Text style={{ fontSize: "9px" }}>APPROVE</Text>
              </View>
            )}

            {inquiryStatus === "decline" && (
              <View
                style={{
                  borderRadius: "15px",
                  padding: "6px",
                  display: "flex",
                  backgroundColor: "rgba(250, 54, 76, 0.5)",
                }}
              >
                <Text style={{ fontSize: "9px" }}>DECLINE</Text>
              </View>
            )}

            {inquiryStatus === "review" && (
              <View
                style={{
                  borderRadius: "15px",
                  padding: "6px",
                  display: "flex",
                  backgroundColor: "rgb(255, 193, 2,0.5)",
                  textAlign: "center",
                }}
              >
                <Text style={{ fontSize: "9px" }}>REVIEW</Text>
              </View>
            )}

            {onboardingEventData.map((element) => {
              return element.event_desc == "Inquiry completed" ? (
                <Text style={{ fontSize: "12px", marginTop: "10px" }}>
                  {Moment(element.created_at).format("MM-DD-YYYY")}
                </Text>
              ) : null;
            })}
          </View>
        </View>
        <View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "20px",
            }}
          >
            <Text style={styles.textSm}>Document Type: </Text>
            {props.onboardingData.addressverification.type_of_doc ==
              "utilitybill" && (
              <Text style={styles.textSmGray}>Utility Bill</Text>
            )}
            {props.onboardingData.addressverification.type_of_doc ==
              "bankstatement" && (
              <Text style={styles.textSmGray}>Bank Statement</Text>
            )}
            {props.onboardingData.addressverification.type_of_doc ==
              "insurancedoc" && (
              <Text style={styles.textSmGray}>Insurance Document</Text>
            )}
            {props.onboardingData.addressverification.type_of_doc ==
              "residentpermit" && (
              <Text style={styles.textSmGray}>Resident Permit</Text>
            )}
            {props.onboardingData.addressverification.type_of_doc ==
              "mortstatement" && (
              <Text style={styles.textSmGray}>Mortgage Statement</Text>
            )}
            {props.onboardingData.addressverification.type_of_doc ===
              "taxbill" && <Text style={styles.textSmGray}>Tax Bill</Text>}
          </View>
          <Image
            style={{ width: "200px", marginTop: "10px" }}
            src={
              props.onboardingData.addressverification.path_doc
                ? {uri: props.onboardingData.addressverification.path_doc, method: "GET", headers: { "Cache-Control": "no-cache" }, body: ""}
                : idVerRectImg
            }
          />
        </View>
        <View style={{ marginTop: "20px" }}>
          <View style={{ width: "50%" }}>
            <Text style={{ marginBottom: "10px" }}>Manual Fields</Text>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "10px",
              }}
            >
              <Text style={styles.textSmCol}>Full Name:</Text>
              {props.onboardingData.basicinfo.first_name ||
              props.onboardingData.basicinfo.last_name ? (
                <Text style={styles.textSmGray}>
                  {props.onboardingData.basicinfo.first_name +
                    " " +
                    props.onboardingData.basicinfo.last_name}
                </Text>
              ) : (
                <Text style={styles.textSmGray}>N/A</Text>
              )}
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "10px",
              }}
            >
              <Text style={styles.textSmCol}>Full Address:</Text>
              {props.onboardingData.addressverification.address &&
              props.onboardingData.addressverification.city &&
              props.onboardingData.addressverification.country ? (
                <Text
                  style={{
                    fontSize: "12px",
                    fontWeight: "normal",
                    color: "#808080",
                    marginLeft: "10px",
                    wordBreak: "break-all",
                    width: "60%",
                  }}
                >
                  {props.onboardingData.addressverification.address +
                    ", " +
                    props.onboardingData.addressverification.city +
                    ", " +
                    props.onboardingData.addressverification.country}
                </Text>
              ) : (
                <Text style={styles.textSmGray}>N/A</Text>
              )}
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "10px",
              }}
            >
              <Text style={styles.textSmCol}>Issue Date:</Text>
              <Text style={styles.textSmGray}>N/A</Text>
            </View>
          </View>
          <View style={{ width: "50%" }}>
            <Text style={{ marginBottom: "10px", marginTop: "20px" }}>
              Document OCR
            </Text>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "10px",
              }}
            >
              <Text style={styles.textSmCol}>Full Name:</Text>
              <Text style={styles.textSmGray}>N/A</Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "10px",
              }}
            >
              <Text style={styles.textSmCol}>Full Address:</Text>
              <Text style={styles.textSmGray}>N/A</Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "10px",
              }}
            >
              <Text style={styles.textSmCol}>Issue Date:</Text>
              <Text style={styles.textSmGray}>N/A</Text>
            </View>
          </View>
        </View>
        <View
          style={{
            position: "absolute",
            bottom: 20,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingHorizontal: 40,
            alignItems: "center",
          }}
        >
          <Image style={{ width: "100px" }} src={hedgelogo} />
          <Text
            style={{ fontSize: "12px" }}
            render={({ pageNumber }) => `${pageNumber}`}
          />
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "2px solid #a10b1d",
            paddingBottom: "10px",
          }}
        >
          <View>
            <Text>Screening</Text>
            <Text
              style={{
                color: "#808080",
                fontSize: "12px",
                marginTop: "10px",
              }}
            >
              {onboardingData.inq_id ? onboardingData.inq_id : "N/A"}
            </Text>
          </View>
          <View>
            {inquiryStatus === "approve" && (
              <View
                style={{
                  borderRadius: "15px",
                  padding: "6px",
                  display: "flex",
                  backgroundColor: "rgba(21, 228, 154, 0.5)",
                }}
              >
                <Text style={{ fontSize: "9px" }}>APPROVE</Text>
              </View>
            )}

            {inquiryStatus === "decline" && (
              <View
                style={{
                  borderRadius: "15px",
                  padding: "6px",
                  display: "flex",
                  backgroundColor: "rgba(250, 54, 76, 0.5)",
                }}
              >
                <Text style={{ fontSize: "9px" }}>DECLINE</Text>
              </View>
            )}

            {inquiryStatus === "review" && (
              <View
                style={{
                  borderRadius: "15px",
                  padding: "6px",
                  display: "flex",
                  backgroundColor: "rgb(255, 193, 2,0.5)",
                  textAlign: "center",
                }}
              >
                <Text style={{ fontSize: "9px" }}>REVIEW</Text>
              </View>
            )}

            {onboardingEventData.map((element) => {
              return element.event_desc == "Inquiry completed" ? (
                <Text style={{ fontSize: "12px", marginTop: "10px" }}>
                  {Moment(element.created_at).format("MM-DD-YYYY")}
                </Text>
              ) : null;
            })}
          </View>
        </View>
        <View style={{ marginTop: "30px" }}>
          {props.livenessData && (
            <Image
              style={{
                height: "100px",
                width: "100px",
                objectFit: "cover",
              }}
              src={
                props.livenessData.length > 0
                  ? {uri: props.livenessData[0].path, method: "GET", headers: { "Cache-Control": "no-cache" }, body: ""}
                  : require("assets/img/ProfilePicture.png").default
              }
            />
          )}
        </View>
        <View style={{ marginTop: "10px", width: "50%" }}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "10px",
            }}
          >
            <Text style={styles.textSmCol}>Full Name</Text>
            {props.onboardingData.basicinfo.first_name ||
            props.onboardingData.basicinfo.last_name ? (
              <Text style={styles.textSmGray}>
                {props.onboardingData.basicinfo.first_name +
                  " " +
                  props.onboardingData.basicinfo.last_name}
              </Text>
            ) : (
              <Text style={styles.textSmGray}>N/A</Text>
            )}
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "10px",
            }}
          >
            <Text style={styles.textSmCol}>ID Number</Text>
            <Text style={styles.textSmGray}>
              {props.onboardingData.idverification.id_nnum
                ? props.onboardingData.idverification.id_nnum
                : "N/A"}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "10px",
            }}
          >
            <Text style={styles.textSmCol}>Date of Birth</Text>
            <Text style={styles.textSmGray}>
              {props.onboardingData.basicinfo.dob
                ? props.onboardingData.basicinfo.dob.includes("T") ?
                  Moment(props.onboardingData.basicinfo.dob.split("T")[0]).format(
                    "MM-DD-YYYY"
                  )
                  :
                  (Moment(props.onboardingData.basicinfo.dob, "DD/MM/YYYY").format("MM-DD-YYYY") === "Invalid date")
                    ? Moment(props.onboardingData.basicinfo.dob).format(
                      "MM-DD-YYYY"
                    ) : Moment(props.onboardingData.basicinfo.dob, "DD/MM/YYYY").format(
                      "MM-DD-YYYY"
                    )
                : 
                "N/A"
              }
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "10px",
            }}
          >
            <Text style={styles.textSmCol}>Full Address</Text>
            {props.onboardingData.addressverification.address &&
            props.onboardingData.addressverification.city &&
            props.onboardingData.addressverification.country ? (
              <Text
                style={{
                  fontSize: "12px",
                  fontWeight: "normal",
                  color: "#808080",
                  marginLeft: "10px",
                  wordBreak: "break-all",
                  width: "60%",
                }}
              >
                {props.onboardingData.addressverification.address +
                  ", " +
                  props.onboardingData.addressverification.city +
                  ", " +
                  props.onboardingData.addressverification.country}
              </Text>
            ) : (
              <Text style={styles.textSmGray}>N/A</Text>
            )}
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "10px",
            }}
          >
            <Text style={styles.textSmCol}>Nationality</Text>
            <Text style={styles.textSmGray}>
              {props.onboardingData.basicinfo.nationality
                ? props.onboardingData.basicinfo.nationality
                : "N/A"}
            </Text>
          </View>
        </View>
        <View style={{ marginTop: "20px" }}>
          {props.matches === 0 ? (
            <Text>No match found</Text>
          ) : (
            <Text>{props.matches}</Text>
          )}
          <Text style={styles.textSmGray}>
            {" "}
            (Match against {props.sanctionData.list_count} lists)
          </Text>
        </View>
        <View
          style={{
            position: "absolute",
            bottom: 20,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingHorizontal: 40,
            alignItems: "center",
          }}
        >
          <Image style={{ width: "100px" }} src={hedgelogo} />
          <Text
            style={{ fontSize: "12px" }}
            render={({ pageNumber }) => `${pageNumber}`}
          />
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "2px solid #a10b1d",
            paddingBottom: "10px",
          }}
        >
          <View>
            <Text>Event Timeline</Text>
            <Text
              style={{
                color: "#808080",
                fontSize: "12px",
                marginTop: "10px",
              }}
            >
              {onboardingData.inq_id ? onboardingData.inq_id : "N/A"}
            </Text>
          </View>
          <View>
            {inquiryStatus === "approve" && (
              <View
                style={{
                  borderRadius: "15px",
                  padding: "6px",
                  display: "flex",
                  backgroundColor: "rgba(21, 228, 154, 0.5)",
                }}
              >
                <Text style={{ fontSize: "9px" }}>APPROVE</Text>
              </View>
            )}

            {inquiryStatus === "decline" && (
              <View
                style={{
                  borderRadius: "15px",
                  padding: "6px",
                  display: "flex",
                  backgroundColor: "rgba(250, 54, 76, 0.5)",
                }}
              >
                <Text style={{ fontSize: "9px" }}>DECLINE</Text>
              </View>
            )}

            {inquiryStatus === "review" && (
              <View
                style={{
                  borderRadius: "15px",
                  padding: "6px",
                  display: "flex",
                  backgroundColor: "rgb(255, 193, 2,0.5)",
                  textAlign: "center",
                }}
              >
                <Text style={{ fontSize: "9px" }}>REVIEW</Text>
              </View>
            )}

            {onboardingEventData.map((element) => {
              return element.event_desc == "Inquiry completed" ? (
                <Text style={{ fontSize: "12px", marginTop: "10px" }}>
                  {Moment(element.created_at).format("MM-DD-YYYY")}
                </Text>
              ) : null;
            })}
          </View>
        </View>
        {props.onboardingEventData && (
          <View style={{ marginTop: "30px" }}>
            {props.onboardingEventData.map((element, ind) => (
              <View style={{ marginBottom: "30px" }}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {element.passed === 0 && (
                    <View>
                      <Image
                        src={ElipsesOne}
                        style={{ width: "15px", marginRight: "5px" }}
                      />
                    </View>
                  )}
                  {element.passed === 1 && (
                    <View>
                      <Image
                        src={ElipsesTwo}
                        style={{ width: "15px", marginRight: "5px" }}
                      />
                    </View>
                  )}
                  {element.passed === 2 && (
                    <View>
                      <Image
                        src={ElipsesYellow}
                        style={{ width: "15px", marginRight: "5px" }}
                      />
                    </View>
                  )}
                  {element.passed === 3 && (
                    <View>
                      <Image
                        src={ElipsesError}
                        style={{ width: "15px", marginRight: "5px" }}
                      />
                    </View>
                  )}
                  <Text style={styles.textSm}>{element.event_desc}</Text>
                </View>

                <View>
                  {props.onboardingEventDataLength - 1 === ind && (
                    <Text style={styles.textGray}>
                      {Moment(element.created_at).format("MM-DD-YYYY")} |{" "}
                      {Moment(element.created_at).format("hh:mm a")}
                    </Text>
                  )}
                </View>

                <View>
                  <View>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <Image src={locIcon} style={{ width: "15px" }} />
                      <Text style={styles.textSmGray}>
                        {element.ip_address}
                      </Text>
                    </View>
                    {ind === 0 && (
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          marginTop: "30px",
                        }}
                      >
                        <View
                          style={{
                            marginRight: "30px",
                            marginBottom: "20px",
                          }}
                        >
                          <Text style={styles.textSm}>IP Address</Text>
                          <Text style={styles.textGray}>
                            {element.ip_address ? element.ip_address : "N/A"}
                          </Text>
                        </View>
                        <View
                          style={{
                            marginRight: "30px",
                            marginBottom: "20px",
                          }}
                        >
                          <Text style={styles.textSm}>
                            Latitude
                          </Text>
                          <Text style={styles.textGray}>
                            {props.onboardingData.latitude
                              ? props.onboardingData.latitude
                              : "N/A"}
                          </Text>
                        </View>
                        <View
                          style={{
                            marginRight: "30px",
                            marginBottom: "20px",
                          }}
                        >
                          <Text style={styles.textSm}>Longitude</Text>
                          <Text style={styles.textGray}>
                            {props.onboardingData.longitude
                              ? props.onboardingData.longitude
                              : "N/A"}
                          </Text>
                        </View>
                        <View
                          style={{
                            marginRight: "30px",
                            marginBottom: "20px",
                          }}
                        >
                          <Text style={styles.textSm}>First Seen</Text>
                          <Text style={styles.textGray}>
                            {props.onboardingData.first_seen
                              ? Moment(props.onboardingData.first_seen).format("MM-DD-YYYY")
                              : "N/A"}
                          </Text>
                        </View>
                        <View
                          style={{
                            marginRight: "30px",
                            marginBottom: "20px",
                          }}
                        >
                          <Text style={styles.textSm}>Last Seen</Text>
                          <Text style={styles.textGray}>
                            {props.onboardingData.last_seen
                              ? Moment(props.onboardingData.last_seen).format("MM-DD-YYYY")
                              : "N/A"}
                          </Text>
                        </View>
                        <View
                          style={{
                            marginRight: "30px",
                            marginBottom: "20px",
                          }}
                        >
                          <Text style={styles.textSm}>Device OS</Text>
                          <Text style={styles.textGray}>
                            {props.onboardingData.device_os
                              ? props.onboardingData.device_os
                              : "N/A"}
                          </Text>
                        </View>
                        <View
                          style={{
                            marginRight: "30px",
                            marginBottom: "20px",
                          }}
                        >
                          <Text style={styles.textSm}>Browser</Text>
                          <Text style={styles.textGray}>
                            {props.onboardingData.browser
                              ? props.onboardingData.browser
                              : "N/A"}
                          </Text>
                        </View>
                        <View
                          style={{
                            marginRight: "30px",
                            marginBottom: "20px",
                          }}
                        >
                          <Text style={styles.textSm}>Browser Version</Text>
                          <Text style={styles.textGray}>
                            {props.onboardingData.browser_version
                              ? props.onboardingData.browser_version
                              : "N/A"}
                          </Text>
                        </View>
                        <View
                          style={{
                            marginRight: "30px",
                            marginBottom: "20px",
                          }}
                        >
                          <Text style={styles.textSm}>Incognito</Text>
                          <Text style={styles.textGray}>
                            {props.onboardingData.incognito
                              ? (props.onboardingData.incognito == "0" ? "No" : "Yes")
                              : "N/A"}
                          </Text>
                        </View>
                        <View
                          style={{
                            marginRight: "30px",
                            marginBottom: "20px",
                          }}
                        >
                          <Text style={styles.textSm}>Browser Fingerprint</Text>
                          <Text style={styles.textGray}>
                            {props.onboardingData.visitor_id
                              ? props.onboardingData.visitor_id
                              : "N/A"}
                          </Text>
                        </View>
                      </View>
                    )}
                  </View>
                </View>
              </View>
            ))}
          </View>
        )}
        <View
          style={{
            position: "absolute",
            bottom: 20,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingHorizontal: 40,
            alignItems: "center",
          }}
        >
          <Image style={{ width: "100px" }} src={hedgelogo} />
          <Text
            style={{ fontSize: "12px" }}
            render={({ pageNumber }) => `${pageNumber}`}
          />
        </View>
      </Page>
    </Document>
  );

  return (
    <>
      {dataLoaded && (
        <div className="casemanagement casemanagementdetail flex flex-wrap">
          <div className="w-full lg:w-9/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
              <div className="rounded-lg flex justify-between items-center bg-white px-6 py-6 border-0 w-full mb-6 shadow-lg">
                <div className="flex items-center flex-wrap justify-between">
                  <div
                    className="px-4 cursor-pointer"
                    style={{ paddingLeft: "0" }}
                  >
                    <h4 className="borderDefault summaryHeading pb-1 font-bold text-base">
                      Summary
                    </h4>
                  </div>
                </div>

                {/* {dataLoaded && (
                  <>
                    {onboardingEventData.length > 0 && (
                      <PDFDownloadLink
                        document={
                          <PDFFile
                            onboardingData={onboardingData}
                            images={images}
                            livenessData={livenessData}
                            matches={matches}
                            sanctionData={sanctionData}
                            onboardingEventData={onboardingEventData}
                            checks={checks}
                          />
                        }
                        fileName={onboardingData.inq_id ? onboardingData.inq_id : "N/A"}
                      >
                        {({ loading }) =>
                          loading ? (
                            <div className="flex px-4 py-2 cursor-pointer">
                              <h4
                                className="font-semibold text-xs"
                                style={{ color: "#a10b1d", fontSize: "12px" }}
                              >
                                Loading...
                              </h4>
                            </div>
                          ) : (
                            <div className="flex px-4 py-2 cursor-pointer">
                              <img
                                src={ExportIcon}
                                alt="export icon"
                                className="mr-3"
                                style={{ width: "18px", height: "18px" }}
                              />
                              <h4
                                className="font-semibold text-xs"
                                style={{ color: "#a10b1d" }}
                              >
                                Export PDF
                              </h4>
                            </div>
                          )
                        }
                      </PDFDownloadLink>
                    )}
                  </>
                )} */}
              </div>

              <div className="rounded-lg flex justify-between items-center bg-white px-6 py-6 border-0 w-full mb-6 shadow-lg">
                <div className="flex flex-wrap">
                  <Link1 to="idRef" spy={true} smooth={true}>
                    <div
                      className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                      style={
                        idVerActive
                          ? { backgroundColor: "#EBEDF2" }
                          : { backgroundColor: "#FFF" }
                      }
                      onClick={setIdActive}
                    >
                      <h4 className="font-semibold text-xs">
                        Identity Verification
                      </h4>
                    </div>
                  </Link1>
                  <Link1 to="livenessRef" spy={true} smooth={true}>
                    <div
                      className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                      style={
                        livenessActive
                          ? { backgroundColor: "#EBEDF2" }
                          : { backgroundColor: "#FFF" }
                      }
                      onClick={setLiveActive}
                    >
                      <h4 className="font-semibold text-xs">
                      Liveness Verification
                      </h4>
                    </div>
                  </Link1>
                  <Link1 to="addressRef" spy={true} smooth={true}>
                    <div
                      className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                      style={
                        addressVerActive
                          ? { backgroundColor: "#EBEDF2" }
                          : { backgroundColor: "#FFF" }
                      }
                      onClick={setAddActive}
                    >
                      <h4 className="font-semibold text-xs">
                        Address Verification
                      </h4>
                    </div>
                  </Link1>
                  <Link1 to="scrRef" spy={true} smooth={true}>
                    <div
                      className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                      style={
                        screeningActive
                          ? { backgroundColor: "#EBEDF2" }
                          : { backgroundColor: "#FFF" }
                      }
                      onClick={setScreenActive}
                    >
                      <h4 className="font-semibold text-xs">Screening</h4>
                    </div>
                  </Link1>
                  <Link1 to="walletRef" spy={true} smooth={true}>
                    <div
                      className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                      style={
                        walletVerActive
                          ? { backgroundColor: "#EBEDF2" }
                          : { backgroundColor: "#FFF" }
                      }
                      onClick={setWalletActive}
                    >
                      <h4 className="font-semibold text-xs">
                        Wallet Info
                      </h4>
                    </div>
                  </Link1>
                  <Link1 to="evtRef" spy={true} smooth={true}>
                    <div
                      className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                      style={
                        eventTimeActive
                          ? { backgroundColor: "#EBEDF2" }
                          : { backgroundColor: "#FFF" }
                      }
                      onClick={setEvTActive}
                    >
                      <h4 className="font-semibold text-xs">
                        Event Timeline
                      </h4>
                    </div>
                  </Link1>
                </div>
              </div>

              <div id="idRef" className="id-verification relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
                <div className="block w-full overflow-x-auto pb-6">
                  <div
                    className="rounded-lg flex justify-between items-center px-12 py-6 border-0 w-full cursor-pointer"
                    onClick={() => setIdShow(!idShow)}
                  >
                    <div className="flex">
                      <div
                        className="px-4 mr-4 cursor-pointer"
                        style={{ paddingLeft: "0" }}
                      >
                        <h4 className="borderDefault summaryHeading py-2 font-bold text-base">
                          Identity Verification
                        </h4>
                      </div>
                    </div>
                    <div className="flex px-4 py-2 cursor-pointer">
                      {!idShow && (
                        <img
                          src={dropDownIcon}
                          alt="down icon"
                          className="mr-3"
                          style={{ width: "15px", height: "10px" }}
                        />
                      )}
                      {idShow && (
                        <img
                          src={dropDownIcon}
                          alt="down icon"
                          className="mr-3 dropDownIconRotated"
                          style={{ width: "15px", height: "10px" }}
                        />
                      )}
                    </div>
                  </div>
                  {idShow && (
                    <div className="flex justify-between flex-wrap w-full px-12">
                      <div className="left_col px-4 sm:px-4 xl:w-6/12 lg:w-12/12 md:w-12/12 sm:w-12/12 mb-4">
                        <div className="flex flex-col idType">
                          <div className="row flex flex-row justify-between items-center">
                            <div className="col-md-6 flex flex-col">
                              <span className="text-stone-500 uppercase text-xs font-bold">
                                ID Type
                              </span>
                              {onboardingData.idverification.type_of_doc ==
                                "id" && (
                                <span className="text-sm font-bold ">
                                  ID Card
                                </span>
                              )}
                              {onboardingData.idverification.type_of_doc ==
                                "passport" && (
                                <span className="text-sm font-bold ">
                                  Passport
                                </span>
                              )}
                              {onboardingData.idverification.type_of_doc ==
                                "license" && (
                                <span className="text-sm font-bold ">
                                  Driving License
                                </span>
                              )}
                            </div>
                            <div className="col-md-6 flex flex-col text-right">
                              <span className="text-stone-500 uppercase text-xs font-bold">
                                Capture Method
                              </span>
                              <span className="text-sm font-bold ">
                                {onboardingData.idverification.capture_method}
                              </span>
                            </div>
                          </div>
                          <div className="idImage relative mb-3" style={{ cursor: "zoom-in" }}>
                            {imgFrontActive && (
                              // <ReactImageZoom {...propsImgFront} onClick={openModal1} />
                              <img
                                className="py-4 w-full"
                                src={
                                  onboardingData.idverification.path_front
                                    ? onboardingData.idverification.path_front
                                    : idVerRectImg
                                }
                                onClick={openModalImageFront}
                                alt="idImage"
                                width={"200px"}
                                height={"200px"}
                                style={{
                                  maxHeight: "400px",
                                  objectFit: "cover",
                                }}
                              />
                            )}
                            {imgBackActive && (
                              // <ReactImageZoom {...propsImgBack} onClick={openModal2} />
                              <img
                                className="py-4 w-full"
                                src={
                                  onboardingData.idverification.path_back
                                    ? onboardingData.idverification.path_back
                                    : idVerRectImg
                                }
                                alt="idImage"
                                onClick={openModalImageBack}
                                width={"200px"}
                                height={"200px"}
                                style={{
                                  maxHeight: "400px",
                                  objectFit: "cover",
                                }}
                              />
                            )}

                            <div className="flex justify-between items-center" style={{ cursor: "pointer" }}>
                              {imgFrontActive && (
                                <Link2
                                  to={{
                                    pathname: onboardingData.idverification
                                      .path_front
                                      ? onboardingData.idverification.path_front
                                      : "#",
                                  }}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <div className="flex flex-row imgDownloadButton cursor-pointer imgOverButtons absolute text-sm">
                                    <img
                                      src={downloadIcon}
                                      alt="downloadicon"
                                    />
                                  </div>
                                </Link2>
                              )}
                              {imgBackActive && (
                                <Link2
                                  to={{
                                    pathname: onboardingData.idverification
                                      .path_back
                                      ? onboardingData.idverification.path_back
                                      : "#",
                                  }}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <div className="flex flex-row imgDownloadButton cursor-pointer imgOverButtons absolute text-sm">
                                    <img
                                      src={downloadIcon}
                                      alt="downloadicon"
                                    />
                                  </div>
                                </Link2>
                              )}
                              {imgWithDocActive && (
                                <Link2
                                  to={{
                                    pathname: onboardingData.idverification
                                      .path_with_doc
                                      ? onboardingData.idverification.path_with_doc
                                      : "#",
                                  }}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <div className="flex flex-row imgDownloadButton cursor-pointer imgOverButtons absolute text-sm">
                                    <img
                                      src={downloadIcon}
                                      alt="downloadicon"
                                    />
                                  </div>
                                </Link2>
                              )}
                              <div className="actionButtonsImage text-white">
                                <span
                                  className="rounded-lg px-4 py-2 cursor-pointer"
                                  style={
                                    imgFrontActive
                                      ? {
                                          backgroundColor:
                                            "rgba(253, 254, 254, 0.7)",
                                        }
                                      : { backgroundColor: "#000" }
                                  }
                                  onClick={setFrontActive}
                                >
                                  Front
                                </span>
                                {onboardingData.idverification.type_of_doc != "passport" &&
                                  <span
                                    className="rounded-lg px-4 py-2 cursor-pointer"
                                    style={
                                      imgBackActive
                                        ? {
                                            backgroundColor:
                                              "rgba(253, 254, 254, 0.7)",
                                          }
                                        : { backgroundColor: "#000" }
                                    }
                                    onClick={setBackActive}
                                  >
                                    Back
                                  </span>
                                }
                              </div>
                            </div>
                          </div>
                          <div className="ai-modal-tag xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 pb-2">
                            <p className="text-xs text-right w-full cursor-pointer font-italic" onClick={imgFrontActive ? openModal1 : openModal2}>Show ELA/Metadata</p>
                          </div>
                          <div className="documentOCR xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 pb-4">
                            <h4 className="font-bold text-sm mb-2 flex flex-row">
                              <p className="w-full">Document OCR</p>
                              <p className="text-xs text-right w-full cursor-pointer font-italic" onClick={openModal3}>(Show raw)</p>
                            </h4>
                            <table className="w-full table-fixed">
                              <tbody>
                                <tr>
                                  <td className="font-light text-xs">
                                    Full Name
                                  </td>
                                  <td className="font-semibold text-xs text-right">
                                    {ocrData ? (ocrData.full_name ? ocrData.full_name : "N/A") : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">
                                    Date of Birth
                                  </td>
                                  <td className="font-semibold text-xs text-right">
                                    {ocrData ? (ocrData.dob ? ocrData.dob : "N/A") : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">
                                    ID Number
                                  </td>
                                  <td className="font-semibold text-xs text-right">
                                    {ocrData ? (ocrData.id_number ? ocrData.id_number : "N/A") : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">
                                    Full Address
                                  </td>
                                  <td className="font-semibold text-xs text-right">
                                    {ocrData ? (ocrData.full_address ? ocrData.full_address : "N/A") : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">
                                    Issue Date
                                  </td>
                                  <td className="font-semibold text-xs text-right">
                                    {ocrData ? (ocrData.issue_date ? ocrData.issue_date : "N/A") : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">
                                    Issue Country
                                  </td>
                                  <td className="font-semibold text-xs text-right">
                                    {ocrData ? (ocrData.issue_country ? ocrData.issue_country : "N/A") : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">
                                    Nationality
                                  </td>
                                  <td className="font-semibold text-xs text-right">
                                    {ocrData ? (ocrData.nationality ? ocrData.nationality : "N/A") : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">
                                    Expiry Date
                                  </td>
                                  <td className="font-semibold text-xs text-right">
                                    {ocrData ? (ocrData.expiry_date ? ocrData.expiry_date : "N/A") : "N/A"}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="right_col px-4 sm:px-4 xl:w-6/12 lg:w-12/12 md:w-12/12 sm:w-12/12">
                        <div className="flex flex-col idType">
                          <div className="w-full justify-between items-center">
                            <div className="manualFieldsOCR flex flex-col flex-wrap justify-between ">
                              <div className="manualFields xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12">
                                <h4 className="font-bold text-sm mb-3">
                                  Checks
                                </h4>
                                <table className="w-full table-fixed">
                                  <tbody>
                                    {checksId.length > 0 && (
                                      <>
                                        {checksId.map((item, ind) => (
                                          <>
                                            <tr key={ind}>
                                              <td className="font-light text-xs cursor-pointer" data-tip data-for={item.check.replaceAll(' ', '')}>
                                                {item.check}
                                              </td>
                                              <ReactTooltip id={item.check.replaceAll(' ', '')} place="top" effect="solid">
                                                <span>{item.detail}</span>
                                              </ReactTooltip>
                                              <td className="font-semibold text-xs cursor-pointer">
                                                {item.status == 0 && 
                                                  <>
                                                    <img
                                                      className="mb-2 ml-auto"
                                                      style={{ width: "20px" }}
                                                      src={ElipsesError}
                                                      alt="icon"
                                                    />
                                                  </>
                                                }
                                                {item.status == 1 && 
                                                  <>
                                                    <img
                                                      className="mb-2 ml-auto"
                                                      style={{ width: "20px" }}
                                                      src={greenWarning}
                                                      alt="icon"
                                                    />
                                                  </>
                                                }
                                                {item.status == 2 && 
                                                  <>
                                                    <img
                                                      className="mb-2 ml-auto"
                                                      style={{ width: "20px" }}
                                                      src={ElipsesYellow}
                                                      alt="icon"
                                                    />
                                                  </>
                                                }
                                              </td>
                                            </tr>
                                          </>
                                        ))}
                                      </>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div id="livenessRef" className="id-verification relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
                <div className="block w-full overflow-x-auto pb-6">
                  <div
                    className="rounded-lg flex justify-between items-center px-12 py-6 border-0 w-full cursor-pointer"
                    onClick={() => setLiveShow(!liveShow)}
                  >
                    <div className="flex">
                      <div
                        className="px-4 mr-4 cursor-pointer"
                        style={{ paddingLeft: "0" }}
                      >
                        <h4 className="borderDefault summaryHeading py-2 font-bold text-base">
                          Liveness Verification
                        </h4>
                      </div>
                    </div>
                    <div className="flex px-4 py-2 cursor-pointer">
                      {!liveShow && (
                        <img
                          src={dropDownIcon}
                          alt="down icon"
                          className="mr-3"
                          style={{ width: "15px", height: "10px" }}
                        />
                      )}
                      {liveShow && (
                        <img
                          src={dropDownIcon}
                          alt="down icon"
                          className="mr-3 dropDownIconRotated"
                          style={{ width: "15px", height: "10px" }}
                        />
                      )}
                    </div>
                  </div>
                  {liveShow && (
                    <div className="flex justify-between flex-wrap w-full px-12">
                      <div className="left_col px-4 sm:px-4 xl:w-6/12 lg:w-12/12 md:w-12/12 sm:w-12/12 mb-4">
                        <div className="flex flex-col idType">
                          <span className="text-sm font-bold mb-3">
                            Self Liveness
                          </span>
                          {images && (
                            <div className="imagevideogallery relative">
                              <ImageGallery
                                items={images}
                                showPlayButton={false}
                                showFullscreenButton={true}
                                showNav={true}
                                disableSwipe={false}
                                disableThumbnailSwipe={false}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="right_col px-4 sm:px-4 xl:w-6/12 lg:w-12/12 md:w-12/12 sm:w-12/12">
                        <div className="flex flex-col idType">
                          <>
                            {aiData.attrAnalysis != undefined && 
                              <div className="flex flex-row w-full justify-between items-center">
                                <div className="documentOCR xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 pb-4">
                                  <h4 className="font-bold text-sm mb-3">
                                    Attribute Analysis
                                  </h4>
                                  <table className="w-full table-fixed">
                                    <tbody>
                                      <tr>
                                        <td className="font-light text-xs">
                                          Age
                                        </td>
                                        <td className="font-semibold text-xs text-right">
                                          {aiData.attrAnalysis.age ? aiData.attrAnalysis.age : "N/A"}
                                        </td>
                                      </tr>
                                      {/* <tr>
                                        <td className="font-light text-xs">
                                          Gender
                                        </td>
                                        <td className="font-semibold text-xs text-right">
                                          {aiData.attrAnalysis.gender ? aiData.attrAnalysis.gender : "N/A"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="font-light text-xs">
                                          Race
                                        </td>
                                        <td className="font-semibold text-xs text-right">
                                          {aiData.attrAnalysis.race ? aiData.attrAnalysis.race : "N/A"}
                                        </td>
                                      </tr> */}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            }
                            <div className="manualFields xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12">
                              <h4 className="font-bold text-sm mb-3">
                                Checks
                              </h4>
                              <table className="w-full table-fixed">
                                <tbody>
                                  {checksLiveess.length > 0 && (
                                    <>
                                      {checksLiveess.map((item, ind) => (
                                        <>
                                          <tr key={ind}>
                                            <td className="font-light text-xs cursor-pointer" data-tip data-for={item.check.replaceAll(' ', '')}>
                                              {item.check}
                                            </td>
                                            <ReactTooltip id={item.check.replaceAll(' ', '')} place="top" effect="solid">
                                              <span>{item.detail}</span>
                                            </ReactTooltip>
                                            <td className="font-semibold text-xs cursor-pointer text-right">
                                              {item.status == 0 && 
                                                <>
                                                  <img
                                                    className="mb-2 ml-auto"
                                                    style={{ width: "20px" }}
                                                    src={ElipsesError}
                                                    alt="icon"
                                                  />
                                                </>
                                              }
                                              {item.status == 1 && 
                                                <>
                                                  <img
                                                    className="mb-2 ml-auto"
                                                    style={{ width: "20px" }}
                                                    src={greenWarning}
                                                    alt="icon"
                                                  />
                                                </>
                                              }
                                              {item.status == 2 && 
                                                <>
                                                  <img
                                                    className="mb-2 ml-auto"
                                                    style={{ width: "20px" }}
                                                    src={ElipsesYellow}
                                                    alt="icon"
                                                  />
                                                </>
                                              }
                                            </td>
                                          </tr>
                                        </>
                                      ))}
                                    </>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div id="addressRef" className="address-verification relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
                <div className="block w-full overflow-x-auto pb-6">
                  <div
                    className="rounded-lg flex justify-between items-center px-12 py-6 border-0 w-full cursor-pointer"
                    onClick={() => setAddShow(!addShow)}
                  >
                    <div className="flex">
                      <div
                        className="px-4 mr-4 cursor-pointer"
                        style={{ paddingLeft: "0" }}
                      >
                        <h4 className="borderDefault summaryHeading py-2 font-bold text-base">
                          Address Verification
                        </h4>
                      </div>
                    </div>
                    <div className="flex px-4 py-2 cursor-pointer">
                      {!addShow && (
                        <img
                          src={dropDownIcon}
                          alt="down icon"
                          className="mr-3"
                          style={{ width: "15px", height: "10px" }}
                        />
                      )}
                      {addShow && (
                        <img
                          src={dropDownIcon}
                          alt="down icon"
                          className="mr-3 dropDownIconRotated"
                          style={{ width: "15px", height: "10px" }}
                        />
                      )}
                    </div>
                  </div>
                  {addShow && (
                    <div className="flex flex-wrap w-full px-12">
                      <div className="col-1 px-4 xl:w-6/12 lg:w-12/12 md:w-12/12 sm:w-12/12">
                        <div className="flex flex-col idType mb-3">
                          <span className="text-stone-500 uppercase text-xs font-bold">
                            Document Type
                          </span>
                          {onboardingData.addressverification.type_of_doc ==
                            1 && (
                            <span className="text-sm font-bold ">
                              Utility Bill
                            </span>
                          )}
                          {onboardingData.addressverification.type_of_doc ==
                            2 && (
                            <span className="text-sm font-bold ">
                              Bank Statement
                            </span>
                          )}
                          {onboardingData.addressverification.type_of_doc ==
                            3 && (
                            <span className="text-sm font-bold ">
                              Insurance Document
                            </span>
                          )}
                          {onboardingData.addressverification.type_of_doc ==
                            4 && (
                            <span className="text-sm font-bold ">
                              Resident Permit
                            </span>
                          )}
                          {onboardingData.addressverification.type_of_doc ==
                            5 && (
                            <span className="text-sm font-bold ">
                              Mortgage Statement
                            </span>
                          )}
                          {onboardingData.addressverification.type_of_doc ==
                            6 && (
                            <span className="text-sm font-bold ">Tax Bill</span>
                          )}
                          <div className="idImage relative">
                            {onboardingData.addressverification.ext === "pdf" ? 
                              <embed 
                                src={onboardingData.addressverification.path_doc} 
                                type="application/pdf" 
                                width={"200px"}
                                height={"200px"} 
                                style={{width: "100%"}}
                              />
                              :
                              // <div style={{ cursor: "zoom-in" }}>
                              //   <ReactImageZoom {...propsAddressImg} onClick={openModal4} />
                              // </div>
                              <img
                                className="py-4 pb-0 w-full"
                                src={
                                  onboardingData.addressverification.path_doc
                                    ? onboardingData.addressverification.path_doc
                                    : idVerRectImg
                                }
                                alt="addressverifimg"
                                width={"200px"}
                                height={"200px"}
                                onClick={openModalImageAddress}
                                style={{ maxHeight: "240px",objectFit: "cover", cursor: "zoom-in" }}
                              />
                            }
                            <div className="flex justify-between items-center">
                              <Link2
                                to={{
                                  pathname: onboardingData.addressverification
                                    .path_doc
                                    ? onboardingData.addressverification
                                        .path_doc
                                    : "#",
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <div className="flex flex-row imgDownloadButton cursor-pointer imgOverButtons absolute text-sm">
                                  <img
                                    src={downloadIcon}
                                    alt="downloadicon"
                                  />
                                </div>
                              </Link2>
                            </div>
                          </div>
                        </div>
                        <div className="ai-modal-tag xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 pb-2">
                          <p className="text-xs text-right w-full cursor-pointer font-italic" onClick={openModal4}>Show ELA/Metadata</p>
                        </div>
                        <div className="manualFields mt-0">
                          <h4 className="font-bold text-sm mb-3">
                            Manual Fields
                          </h4>
                          <table className="w-full table-fixed">
                            <tbody>
                              <tr>
                                <td className="font-light text-xs" style={{ verticalAlign: "top" }}>
                                  Full Address
                                </td>
                                {onboardingData.addressverification.address &&
                                onboardingData.addressverification.city &&
                                onboardingData.addressverification.country ? (
                                  <td
                                    className="font-semibold text-xs text-right"
                                    width={"70%"}
                                  >
                                    {onboardingData.addressverification
                                        .address +
                                        ", "}
                                    <br/>
                                    {onboardingData.addressverification
                                      .city +
                                    ", " +
                                    onboardingData.addressverification
                                      .country}
                                  </td>
                                ) : (
                                  <td className="font-semibold text-xs text-right" width={"70%"}>N/A</td>
                                )}
                              </tr>
                              <tr>
                                <td className="font-light text-xs" style={{ verticalAlign: "top" }}>
                                  City
                                </td>
                                {onboardingData.addressverification.city ? (
                                  <td className="font-semibold text-xs text-right" width={"70%"}>
                                    {onboardingData.addressverification.city}
                                  </td>
                                ) : (
                                  <td className="font-semibold text-xs text-right" width={"70%"}>N/A</td>
                                )}
                              </tr>
                              <tr>
                                <td className="font-light text-xs" style={{ verticalAlign: "top" }}>
                                  Country
                                </td>
                                {onboardingData.addressverification.country ? (
                                  <td className="font-semibold text-xs text-right" width={"70%"}>
                                    {onboardingData.addressverification.country}
                                  </td>
                                ) : (
                                  <td className="font-semibold text-xs text-right" width={"70%"}>N/A</td>
                                )}
                              </tr>
                              <tr>
                                <td className="font-light text-xs" style={{ verticalAlign: "top" }}>
                                  Postal Code
                                </td>
                                {onboardingData.addressverification.postal ? (
                                  <td className="font-semibold text-xs text-right" width={"70%"}>
                                    {onboardingData.addressverification.postal}
                                  </td>
                                ) : (
                                  <td className="font-semibold text-xs text-right" width={"70%"}>N/A</td>
                                )}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-1 px-4 xl:w-6/12 lg:w-12/12 md:w-12/12 sm:w-12/12">
                        <div className="manualFields xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12">
                          <h4 className="font-bold text-sm mb-3">
                            Checks
                          </h4>
                          <table className="w-full table-fixed">
                            <tbody>
                              {checksAddress.length > 0 && (
                                <>
                                  {checksAddress.map((item, ind) => (
                                    <>
                                      <tr key={ind}>
                                        <td className="font-light text-xs cursor-pointer" data-tip data-for={item.check.replaceAll(' ', '')}>
                                          {item.check}
                                        </td>
                                        <ReactTooltip id={item.check.replaceAll(' ', '')} place="top" effect="solid">
                                          <span>{item.detail}</span>
                                        </ReactTooltip>
                                        <td className="font-semibold text-xs cursor-pointer">
                                          {item.status == 0 && 
                                            <>
                                              <img
                                                className="mb-2 ml-auto"
                                                style={{ width: "20px" }}
                                                src={ElipsesError}
                                                alt="icon"
                                              />
                                            </>
                                          }
                                          {item.status == 1 && 
                                            <>
                                              <img
                                                className="mb-2 ml-auto"
                                                style={{ width: "20px" }}
                                                src={greenWarning}
                                                alt="icon"
                                              />
                                            </>
                                          }
                                          {item.status == 2 && 
                                            <>
                                              <img
                                                className="mb-2 ml-auto"
                                                style={{ width: "20px" }}
                                                src={ElipsesYellow}
                                                alt="icon"
                                              />
                                            </>
                                          }
                                        </td>
                                      </tr>
                                    </>
                                  ))}
                                </>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div id="scrRef" className="screening relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
                <div className="block w-full overflow-x-auto pb-6">
                  <div
                    className="rounded-lg flex justify-between items-center px-12 py-6 border-0 w-full cursor-pointer"
                    onClick={() => setScreenShow(!screenShow)}
                  >
                    <div className="flex">
                      <div
                        className="px-4 mr-4 cursor-pointer"
                        style={{ paddingLeft: "0" }}
                      >
                        <h4 className="borderDefault summaryHeading py-2 font-bold text-base">
                          Screening
                        </h4>
                      </div>
                    </div>
                    <div className="flex px-4 py-2 cursor-pointer">
                      {!screenShow && (
                        <img
                          src={dropDownIcon}
                          alt="down icon"
                          className="mr-3"
                          style={{ width: "15px", height: "10px" }}
                        />
                      )}
                      {screenShow && (
                        <img
                          src={dropDownIcon}
                          alt="down icon"
                          className="mr-3 dropDownIconRotated"
                          style={{ width: "15px", height: "10px" }}
                        />
                      )}
                    </div>
                  </div>
                  {screenShow && (
                    <div className="flex flex-wrap w-full px-12">
                      <div className="col-1 px-4 xl:w-6/12 lg:w-12/12 md:w-12/12 sm:w-12/12 mb-4">
                        <div className="flex flex-col idType">
                          <div className="idImage relative">
                            <div className="flex items-center">
                              {livenessData && (
                                <div className="flex flex-row imgDownloadButton cursor-pointer imgOverButtons mr-4">
                                  <img
                                    alt="..."
                                    style={{
                                      height: "100px",
                                      width: "100px",
                                      objectFit: "cover",
                                    }}
                                    src={
                                      livenessData.length > 0
                                        ? livenessData[0].path
                                        : require("assets/img/ProfilePicture.png")
                                            .default
                                    }
                                    className="shadow-xl rounded-full h-auto align-middle border-none max-w-100-px"
                                  />
                                </div>
                              )}
                              <table className="w-full table-fixed ml-3 max-w-210-px">
                                <tbody>
                                  <tr>
                                    <td className="font-light text-xs" style={{ verticalAlign: "top" }}>
                                      Full Name
                                    </td>
                                    {onboardingData.basicinfo.first_name ||
                                    onboardingData.basicinfo.last_name ? (
                                      <td className="font-semibold text-xs text-right">
                                        {onboardingData.basicinfo.first_name +
                                          " " +
                                          onboardingData.basicinfo.last_name}
                                      </td>
                                    ) : (
                                      <td className="font-semibold text-xs text-right">
                                        N/A
                                      </td>
                                    )}
                                  </tr>
                                  <tr>
                                    <td className="font-light text-xs" style={{ verticalAlign: "top" }}>
                                      ID Number
                                    </td>
                                    <td className="font-semibold text-xs text-right">
                                      {onboardingData.idverification.id_nnum
                                        ? onboardingData.idverification.id_nnum
                                        : "N/A"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-light text-xs" style={{ verticalAlign: "top" }}>
                                      Date of Birth
                                    </td>
                                    <td className="font-semibold text-xs text-right">
                                      {onboardingData.basicinfo.dob
                                        ? onboardingData.basicinfo.dob.includes("T") ?
                                          Moment(onboardingData.basicinfo.dob.split("T")[0]).format(
                                            "MM-DD-YYYY"
                                          )
                                          :
                                          (Moment(onboardingData.basicinfo.dob, "DD/MM/YYYY").format("MM-DD-YYYY") === "Invalid date")
                                            ? Moment(onboardingData.basicinfo.dob).format(
                                              "MM-DD-YYYY"
                                            ) : Moment(onboardingData.basicinfo.dob, "DD/MM/YYYY").format(
                                              "MM-DD-YYYY"
                                            )
                                        : 
                                        "N/A"
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-light text-xs" style={{ verticalAlign: "top" }}>
                                      Full Address
                                    </td>
                                    {onboardingData.addressverification
                                      .address &&
                                    onboardingData.addressverification.city &&
                                    onboardingData.addressverification
                                      .country ? (
                                      <td
                                        className="font-semibold text-xs text-right"
                                      >
                                        {onboardingData.addressverification
                                        .address +
                                        ", "}
                                        <br/>
                                        {onboardingData.addressverification
                                          .city +
                                        ", " +
                                        onboardingData.addressverification
                                          .country}
                                      </td>
                                    ) : (
                                      <td className="font-semibold text-xs text-right">
                                        N/A
                                      </td>
                                    )}
                                  </tr>
                                  <tr>
                                    <td className="font-light text-xs" style={{ verticalAlign: "top" }}>
                                      Nationality
                                    </td>
                                    <td className="font-semibold text-xs text-right">
                                      {onboardingData.basicinfo.nationality
                                        ? onboardingData.basicinfo.nationality
                                        : "N/A"}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-1 px-4 xl:w-6/12 lg:w-12/12 md:w-12/12 sm:w-12/12">
                        <div className="manualFields xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12">
                          <h4 className="font-bold text-sm mb-3">
                            Checks
                          </h4>
                          <table className="w-full table-fixed">
                            <tbody>
                              {checksSanction.length > 0 && (
                                <>
                                  {checksSanction.map((item, ind) => (
                                    <>
                                      <tr key={ind}>
                                        <td className="font-light text-xs cursor-pointer" data-tip data-for={item.check.replaceAll(' ', '')}>
                                          {item.check}
                                        </td>
                                        <ReactTooltip id={item.check.replaceAll(' ', '')} place="top" effect="solid">
                                          <span>{item.detail}</span>
                                        </ReactTooltip>
                                        <td className="font-semibold text-xs cursor-pointer">
                                          {item.status == 0 && 
                                            <>
                                              <img
                                                className="mb-2 ml-auto"
                                                style={{ width: "20px" }}
                                                src={ElipsesError}
                                                alt="icon"
                                              />
                                            </>
                                          }
                                          {item.status == 1 && 
                                            <>
                                              <img
                                                className="mb-2 ml-auto"
                                                style={{ width: "20px" }}
                                                src={greenWarning}
                                                alt="icon"
                                              />
                                            </>
                                          }
                                          {item.status == 2 && 
                                            <>
                                              <img
                                                className="mb-2 ml-auto"
                                                style={{ width: "20px" }}
                                                src={ElipsesYellow}
                                                alt="icon"
                                              />
                                            </>
                                          }
                                        </td>
                                      </tr>
                                    </>
                                  ))}
                                </>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="col-1 px-4 xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12">
                        <div className="documentOCR casemanagement-screening">
                          <div className="w-full flex items-center mb-2">
                            {matches === 0 ? (
                              <h4 className="text-base mr-2">
                                <b>No match found</b>
                              </h4>
                            ) : (
                              <Link2 to={{
                                pathname: "/aml/amlscreeninglistlogs",
                                data: [{
                                  name: onboardingData.basicinfo.first_name + " " + onboardingData.basicinfo.last_name,
                                  nationality: "",
                                  birthDate: "",
                                  identifier: "",
                                  country: ""
                                }],
                                checks: screeningChecksAll,
                                individualscreening: true,
                                batchscreening: false,
                              }}>
                                <div className="text-sm matchesfoundpill">
                                  <b>{matches}</b> matches found
                                </div>
                              </Link2>
                            )}
                            {/* <h4 className="text-xs">
                              {" "}
                              (Match against {sanctionData.list_count} lists)
                            </h4> */}
                          </div>
                          {sanctionData.results[0].length > 0 &&
                            sanctionData.results[0].map((sanction, index) => (
                              <div key={index}>
                                {potMatchArr[index] && (
                                  <div>
                                    <div className="w-full flex justify-between items-center redbg whiteText rounded-lg p-5">
                                      <div className="md:w-6/12">
                                        <h4
                                          className="text-sm"
                                          style={{
                                            whiteSpace: "nowrap",
                                            maxWidth: "100%",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          {sanction.list}
                                        </h4>
                                      </div>
                                      <div className="md:w-6/12 navigator flex justify-between items-center">
                                        <div
                                          className="cursor-pointer"
                                          onClick={() =>
                                            setActivePotMatchCardLeft(index + 1)
                                          }
                                        >
                                          <img
                                            className=""
                                            src={leftArrow}
                                            alt="leftarrow"
                                          />
                                        </div>
                                        <div className="text-sm px-4">
                                          {index + 1} of {matches}
                                        </div>
                                        <div
                                          className="cursor-pointer"
                                          onClick={() =>
                                            setActivePotMatchCardRight(
                                              index + 1
                                            )
                                          }
                                        >
                                          <img
                                            className=""
                                            src={rightArrow}
                                            alt="rightarrow"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="rounded-lg mt-2 greybg p-5">
                                      <table className="w-full table-fixed">
                                        <tbody>
                                          <tr>
                                            <td className="font-light text-xs">
                                              Name
                                            </td>
                                            <td className="font-semibold text-xs text-right">
                                              {sanction.name
                                                ? sanction.name
                                                : "N/A"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="font-light text-xs">
                                              Date of Birth
                                            </td>
                                            <td className="font-semibold text-xs text-right">
                                              {sanction.birthDate
                                                ? Moment(
                                                    sanction.birthDate
                                                  ).format("MM-DD-YYYY")
                                                : "N/A"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="font-light text-xs">
                                              ID Number
                                            </td>
                                            <td className="font-semibold text-xs text-right">
                                              {sanction.identifier
                                                ? sanction.identifier
                                                : "N/A"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="font-light text-xs">
                                              Country
                                            </td>
                                            {sanction.country ? (
                                              <>
                                                {countryoptions.map(
                                                  (country) => (
                                                    <>
                                                      {country.value.toLowerCase() ===
                                                        sanction.country && (
                                                        <td className="font-semibold text-xs text-right">
                                                          {country.label}
                                                        </td>
                                                      )}
                                                    </>
                                                  )
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                {sanction.nationality ? (
                                                  <>
                                                    {countryoptions.map(
                                                      (country) => (
                                                        <>
                                                          {country.value.toLowerCase() ===
                                                            sanction.country && (
                                                            <td className="font-semibold text-xs">
                                                              {country.label}
                                                            </td>
                                                          )}
                                                        </>
                                                      )
                                                    )}
                                                  </>
                                                ) : (
                                                  <td className="font-semibold text-xs text-right">
                                                    {"N/A"}
                                                  </td>
                                                )}
                                              </>
                                            )}
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div id="walletRef" className="id-verification relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
                <div className="block w-full overflow-x-auto pb-6">
                  <div
                    className="rounded-lg flex justify-between items-center px-12 py-6 border-0 w-full cursor-pointer"
                    onClick={() => setWalletShow(!walletShow)}
                  >
                    <div className="flex">
                      <div
                        className="px-4 mr-4 cursor-pointer"
                        style={{ paddingLeft: "0" }}
                      >
                        <h4 className="borderDefault summaryHeading py-2 font-bold text-base">
                          Wallet Information
                        </h4>
                      </div>
                    </div>
                    <div className="flex px-4 py-2 cursor-pointer">
                      {!walletShow && (
                        <img
                          src={dropDownIcon}
                          alt="down icon"
                          className="mr-3"
                          style={{ width: "15px", height: "10px" }}
                        />
                      )}
                      {walletShow && (
                        <img
                          src={dropDownIcon}
                          alt="down icon"
                          className="mr-3 dropDownIconRotated"
                          style={{ width: "15px", height: "10px" }}
                        />
                      )}
                    </div>
                  </div>
                  {walletShow && (
                    <div className="flex justify-between flex-wrap w-full px-12">
                      <div className="left_col px-4 sm:px-4 xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 mb-4">
                        <div className="flex flex-col idType">
                          <div className="manualFields xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 pb-4">
                            {walletDetails.ETH && 
                              <>
                                <h4 className="font-bold text-sm mb-3">
                                  Ethereum Wallet
                                </h4>
                                <table className="w-full table-fixed">
                                  <tbody>
                                    <tr>
                                      <td className="font-light text-xs" style={{ verticalAlign: "top" }}>
                                        Wallet Address
                                      </td>
                                      {walletDetails.ETH.address ? (
                                        <td className="font-semibold text-xs text-right" width={"70%"}>
                                          {walletDetails.ETH.address}
                                        </td>
                                      ) : (
                                        <td className="font-semibold text-xs text-right" width={"70%"}>
                                          N/A
                                        </td>
                                      )}
                                    </tr>
                                    {/* <tr>
                                      <td className="font-light text-xs" style={{ verticalAlign: "top" }}>
                                        TGE Contribution
                                      </td>
                                      {walletDetails.ETH.tge_contribution ? (
                                        <td
                                          className="font-semibold text-xs text-right" width={"70%"}
                                        >
                                          {walletDetails.ETH.tge_contribution}
                                        </td>
                                      ) : (
                                        <td className="font-semibold text-xs text-right" width={"70%"}>
                                          N/A
                                        </td>
                                      )}
                                    </tr> */}
                                    <tr>
                                      <td className="font-light text-xs" style={{ verticalAlign: "top" }}>
                                        AGIX Balance
                                      </td>
                                      {walletDetails.ETH.agix_balance ? (
                                        <td
                                          className="font-semibold text-xs text-right" width={"70%"}
                                        >
                                          {walletDetails.ETH.agix_balance}
                                        </td>
                                      ) : (
                                        <td className="font-semibold text-xs text-right" width={"70%"}>
                                          N/A
                                        </td>
                                      )}
                                    </tr>
                                    <tr>
                                      <td className="font-light text-xs" style={{ verticalAlign: "top" }}>
                                        USDC Balance
                                      </td>
                                      {walletDetails.ETH.usdc_balance ? (
                                        <td
                                          className="font-semibold text-xs text-right" width={"70%"}
                                        >
                                          {walletDetails.ETH.usdc_balance}
                                        </td>
                                      ) : (
                                        <td className="font-semibold text-xs text-right" width={"70%"}>
                                          N/A
                                        </td>
                                      )}
                                    </tr>
                                    <tr>
                                      <td className="font-light text-xs" style={{ verticalAlign: "top" }}>
                                        USDT Balance
                                      </td>
                                      {walletDetails.ETH.usdt_balance ? (
                                        <td
                                          className="font-semibold text-xs text-right" width={"70%"}
                                        >
                                          {walletDetails.ETH.usdt_balance}
                                        </td>
                                      ) : (
                                        <td className="font-semibold text-xs text-right" width={"70%"}>
                                          N/A
                                        </td>
                                      )}
                                    </tr>
                                    <tr>
                                      <td className="font-light text-xs" style={{ verticalAlign: "top" }}>
                                        SDAO Balance
                                      </td>
                                      {walletDetails.ETH.sdao_balance ? (
                                        <td
                                          className="font-semibold text-xs text-right" width={"70%"}
                                        >
                                          {walletDetails.ETH.sdao_balance}
                                        </td>
                                      ) : (
                                        <td className="font-semibold text-xs text-right" width={"70%"}>
                                          N/A
                                        </td>
                                      )}
                                    </tr>
                                  </tbody>
                                </table>
                              </>
                            }
                          </div>
                          {/* <div className="manualFields xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 pb-4">
                            {walletDetails.ADA && 
                              <>
                                <h4 className="font-bold text-sm mb-3">
                                  Cardano Wallet
                                </h4>
                                <table className="w-full table-fixed">
                                  <tbody>
                                    <tr>
                                      <td className="font-light text-xs" style={{ verticalAlign: "top" }}>
                                        Wallet Address
                                      </td>
                                      {walletDetails.ADA.address ? (
                                        <td className="font-semibold text-xs text-right" width={"70%"}>
                                          {walletDetails.ADA.address}
                                        </td>
                                      ) : (
                                        <td className="font-semibold text-xs text-right" width={"70%"}>
                                          N/A
                                        </td>
                                      )}
                                    </tr>
                                    <tr>
                                      <td className="font-light text-xs" style={{ verticalAlign: "top" }}>
                                        AGIX Eligible
                                      </td>
                                      {walletDetails.ADA.eligibility.agix ? (
                                        <td
                                          className="font-semibold text-xs text-right" width={"70%"}
                                        >
                                          {(walletDetails.ADA.eligibility.agix === 1) ? "Yes" : "No"}
                                        </td>
                                      ) : (
                                        <td className="font-semibold text-xs text-right" width={"70%"}>
                                          N/A
                                        </td>
                                      )}
                                    </tr>
                                    <tr>
                                      <td className="font-light text-xs" style={{ verticalAlign: "top" }}>
                                        AGIX Balance
                                      </td>
                                      {walletDetails.ADA.agix_balance ? (
                                        <td
                                          className="font-semibold text-xs text-right" width={"70%"}
                                        >
                                          {walletDetails.ADA.agix_balance}
                                        </td>
                                      ) : (
                                        <td className="font-semibold text-xs text-right" width={"70%"}>
                                          N/A
                                        </td>
                                      )}
                                    </tr>
                                    <tr>
                                      <td className="font-light text-xs" style={{ verticalAlign: "top" }}>
                                        SDAO Eligible
                                      </td>
                                      {walletDetails.ADA.eligibility.sdao ? (
                                        <td
                                          className="font-semibold text-xs text-right" width={"70%"}
                                        >
                                          {(walletDetails.ADA.eligibility.sdao === 1) ? "Yes" : "No"}
                                        </td>
                                      ) : (
                                        <td className="font-semibold text-xs text-right" width={"70%"}>
                                          N/A
                                        </td>
                                      )}
                                    </tr>
                                    <tr>
                                      <td className="font-light text-xs" style={{ verticalAlign: "top" }}>
                                        SDAO Balance
                                      </td>
                                      {walletDetails.ADA.sdao_balance ? (
                                        <td
                                          className="font-semibold text-xs text-right" width={"70%"}
                                        >
                                          {walletDetails.ADA.sdao_balance}
                                        </td>
                                      ) : (
                                        <td className="font-semibold text-xs text-right" width={"70%"}>
                                          N/A
                                        </td>
                                      )}
                                    </tr>
                                  </tbody>
                                </table>
                              </>
                            }
                          </div> */}
                        </div>
                      </div>
                      <div className="right_col px-4 sm:px-4 xl:w-6/12 lg:w-12/12 md:w-12/12 sm:w-12/12">
                        <div className="flex flex-col idType">
                          <div className="w-full justify-between items-center">
                            <div className="manualFieldsOCR flex flex-col flex-wrap justify-between ">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div id="evtRef" className="event-timeline relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
                <div className="block w-full overflow-x-auto pb-6">
                  <div
                    className="rounded-lg flex justify-between items-center px-12 py-6 border-0 w-full cursor-pointer"
                    onClick={() => setEventShow(!eventShow)}
                  >
                    <div className="flex">
                      <div
                        className="px-4 mr-4 cursor-pointer"
                        style={{ paddingLeft: "0" }}
                      >
                        <h4 className="borderDefault summaryHeading py-2 font-bold text-base">
                          Event Timeline
                        </h4>
                      </div>
                    </div>
                    <div className="flex px-4 py-2 cursor-pointer">
                      {!eventShow && (
                        <img
                          src={dropDownIcon}
                          alt="down icon"
                          className="mr-3"
                          style={{ width: "15px", height: "10px" }}
                        />
                      )}
                      {eventShow && (
                        <img
                          src={dropDownIcon}
                          alt="down icon"
                          className="mr-3 dropDownIconRotated"
                          style={{ width: "15px", height: "10px" }}
                        />
                      )}
                    </div>
                  </div>
                  {eventShow && (
                    <div className="flex flex-row w-full p-12">
                      {onboardingEventData && (
                        <div className="p-12 md:w-12/12">
                          <div className="text-left">
                            <div className="flex flex-wrap">
                              <div
                                className="w-full px-12 mt-2 fields block"
                                style={{
                                  maxHeight: "500px",
                                  height: "500px",
                                  overflow: "auto",
                                }}
                              >
                                {onboardingEventData.map((element, ind) => (
                                  <>
                                    {element.event_desc && 
                                      <>
                                        <div className="status mt-2">
                                          <div
                                            className="w-full mt-2"
                                            style={{
                                              display: "grid",
                                              gridTemplateColumns: "20% 80%",
                                            }}
                                          >
                                            {element.passed === 0 && (
                                              <div className="imgTag">
                                                <img src={ElipsesOne} alt="created" />
                                              </div>
                                            )}
                                            {element.passed === 1 && (
                                              <div className="imgTag">
                                                <img src={ElipsesTwo} alt="created" />
                                              </div>
                                            )}
                                            {element.passed === 2 && (
                                              <div className="imgTag">
                                                <img
                                                  src={ElipsesYellow}
                                                  alt="created"
                                                />
                                              </div>
                                            )}
                                            {element.passed === 3 && (
                                              <div className="imgTag">
                                                <img
                                                  src={ElipsesError}
                                                  alt="created"
                                                />
                                              </div>
                                            )}
                                            <div className="text text-sm font-semibold">
                                              {element.event_desc}
                                              {onboardingEventDataLength - 1 ==
                                                ind && (
                                                <p className="text-xs createdDate font-normal mt-2">
                                                  {Moment(element.created_at).format(
                                                    "MM-DD-YYYY"
                                                  )}{" "}
                                                  |{" "}
                                                  {Moment(element.created_at).format(
                                                    "hh:mm a"
                                                  )}
                                                </p>
                                              )}
                                            </div>
                                          </div>

                                          <div
                                            className="w-full items-center justify-center"
                                            style={{
                                              display: "grid",
                                              gridTemplateColumns: "20% 80%",
                                            }}
                                          >
                                            {onboardingEventDataLength - 1 !== ind ? (
                                              <div className="imgTag px-2">
                                                <img
                                                  src={
                                                    ind === 0
                                                      ? longVertLine
                                                      : smallVertLine
                                                  }
                                                  alt="created"
                                                />
                                              </div>
                                            ) : (
                                              <div className="imgTag px-2"></div>
                                            )}
                                            <div className="text text-xs font-semibold">
                                              {onboardingEventDataLength - 1 !==
                                                ind && (
                                                <p className="createdDate font-normal mt-2">
                                                  {Moment(element.created_at).format(
                                                    "MM-DD-YYYY"
                                                  )}{" "}
                                                  |{" "}
                                                  {Moment(element.created_at).format(
                                                    "hh:mm a"
                                                  )}
                                                </p>
                                              )}
                                              <div className="createdDate text-sm font-normal mt-2 flex">
                                                <img
                                                  src={locIcon}
                                                  alt="loc icon"
                                                  className="mr-2"
                                                  style={{ width: "20px" }}
                                                />
                                                <p className="createdDate text-xs font-normal">
                                                  {element.ip_address}
                                                </p>
                                              </div>
                                              {ind === 0 && (
                                                <div className="w-full items-center justify-center mt-8">
                                                  <table className="w-full table-fixed">
                                                    <tbody>
                                                      <tr>
                                                        <td className="pb-4" style={{ maxWidth: "100px" }}>
                                                          <h4 className="font-light text-xs">
                                                            IP Address
                                                          </h4>
                                                          <h4 className="font-semibold text-xs">
                                                            {element.ip_address
                                                              ? element.ip_address
                                                              : "N/A"}
                                                          </h4>
                                                        </td>
                                                        <td className="pb-4" style={{ maxWidth: "100px" }}>
                                                          <h4 className="font-light text-xs">
                                                            Latitude
                                                          </h4>
                                                          <h4 className="font-semibold text-xs">
                                                            {onboardingData.latitude
                                                              ? onboardingData.latitude
                                                              : "N/A"}
                                                          </h4>
                                                        </td>
                                                        <td className="pb-4" style={{ maxWidth: "100px" }}>
                                                          <h4 className="font-light text-xs">
                                                            Longitude
                                                          </h4>
                                                          <h4 className="font-semibold text-xs">
                                                            {onboardingData.longitude
                                                              ? onboardingData.longitude
                                                              : "N/A"}
                                                          </h4>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td className="pb-4" style={{ maxWidth: "100px" }}>
                                                          <h4 className="font-light text-xs">
                                                            First Seen
                                                          </h4>
                                                          <h4 className="font-semibold text-xs">
                                                            {onboardingData.first_seen
                                                              ? Moment(onboardingData.first_seen).format("MM-DD-YYYY")
                                                              : "N/A"}
                                                          </h4>
                                                        </td>
                                                        <td className="pb-4" style={{ maxWidth: "100px" }}>
                                                          <h4 className="font-light text-xs">
                                                            Last Seen
                                                          </h4>
                                                          <h4 className="font-semibold text-xs">
                                                            {onboardingData.last_seen
                                                              ? Moment(onboardingData.last_seen).format("MM-DD-YYYY")
                                                              : "N/A"}
                                                          </h4>
                                                        </td>
                                                        <td className="pb-4" style={{ maxWidth: "100px" }}>
                                                          <h4 className="font-light text-xs">
                                                            Device OS
                                                          </h4>
                                                          <h4 className="font-semibold text-xs">
                                                            {onboardingData.device_os
                                                              ? onboardingData.device_os
                                                              : "N/A"}
                                                          </h4>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td className="pb-4" style={{ maxWidth: "100px" }}>
                                                          <h4 className="font-light text-xs">
                                                            Browser
                                                          </h4>
                                                          <h4 className="font-semibold text-xs">
                                                            {onboardingData.browser
                                                              ? onboardingData.browser
                                                              : "N/A"}
                                                          </h4>
                                                        </td>
                                                        <td className="pb-4" style={{ maxWidth: "100px" }}>
                                                          <h4 className="font-light text-xs">
                                                            Browser Version
                                                          </h4>
                                                          <h4 className="font-semibold text-xs">
                                                            {onboardingData.browser_version
                                                              ? onboardingData.browser_version
                                                              : "N/A"}
                                                          </h4>
                                                        </td>
                                                        <td className="pb-4" style={{ maxWidth: "100px" }}>
                                                          <h4 className="font-light text-xs">
                                                            Incognito
                                                          </h4>
                                                          <h4 className="font-semibold text-xs">
                                                            {onboardingData.incognito
                                                              ? (onboardingData.incognito == "0" ? "No" : "Yes")
                                                              : "N/A"}
                                                          </h4>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td className="pb-4" style={{ maxWidth: "100px" }}>
                                                          <h4 className="font-light text-xs">
                                                            Browser Fingerprint
                                                          </h4>
                                                          <h4 className="font-semibold text-xs">
                                                            {onboardingData.visitor_id
                                                              ? onboardingData.visitor_id
                                                              : "N/A"}
                                                          </h4>
                                                        </td>
                                                        <td className="pb-4" style={{ maxWidth: "100px" }}>
                                                          <h4 className="font-light text-xs">
                                                            OS Version
                                                          </h4>
                                                          <h4 className="font-semibold text-xs">
                                                            {onboardingData.os_version
                                                              ? onboardingData.os_version
                                                              : "N/A"}
                                                          </h4>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    }
                                  </>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="p-12 md:w-12/12">
                        <div className="flex flex-wrap">
                          <div className="w-full px-4">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                              {/* {(mapLatitude !== null && mapLongitude !== null) && */}
                                <MemoizedMap
                                  location={{
                                    lat: onboardingData.latitude,
                                    long: onboardingData.longitude,
                                  }}
                                />
                              {/* } */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="profileright w-full lg:w-3/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg mt-16">
              <div className="flex flex-wrap justify-center">
                {livenessData && (
                  <div className="w-full px-4 flex justify-center">
                    <div className="relative">
                      <img
                        style={{
                          height: "100px",
                          width: "100px",
                          objectFit: "cover",
                        }}
                        alt="..."
                        src={
                          livenessData.length > 0
                            ? livenessData[0].path
                            : require("assets/img/ProfilePicture.png").default
                        }
                        className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-12 lg:-ml-12 max-w-100-px"
                      />
                    </div>
                  </div>
                )}
                <div className="w-full px-4 text-center mt-12">
                  <div className="flex justify-center py-4 lg:pt-4 pt-8">
                    {onboardingData.basicinfo.first_name ||
                    onboardingData.basicinfo.last_name ? (
                      <h3 className="text-xl font-semibold leading-normal mb-2 text-black-700 mb-2">
                        {onboardingData.basicinfo.first_name +
                          " " +
                          onboardingData.basicinfo.last_name}
                      </h3>
                    ) : (
                      <h3 className="text-xl font-semibold leading-normal mb-2 text-black-700 mb-2">
                        N/A
                      </h3>
                    )}
                  </div>
                </div>
              </div>
              <div className="text-center">
                <div className="action-buttons text-base leading-normal mt-0 mb-2 text-white-400 font-bold">
                  <button
                    onClick={() => setStatus("approve")}
                    className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 buttonGreen"
                    style={
                      inquiryStatus === "approve"
                        ? { background: "#15e49a" }
                        : { background: "#15e49a80" }
                    }
                  >
                    Approve
                  </button>
                  <button
                    onClick={() => setStatus("decline")}
                    className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 buttonRed"
                    style={
                      inquiryStatus === "decline"
                        ? { background: "#fa364c" }
                        : { background: "#fa364c80" }
                    }
                  >
                    Decline
                  </button>
                  <button
                    onClick={() => setStatus("review")}
                    className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 buttonYellow"
                    style={
                      inquiryStatus === "review"
                        ? { background: "#ffc102" }
                        : { background: "#ffc10280" }
                    }
                  >
                    Review
                  </button>
                  <button
                    onClick={() => setStatus("duplicate")}
                    className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 buttonBlack"
                    style={
                      inquiryStatus === "duplicate"
                        ? { background: "#000000" }
                        : { background: "#00000080" }
                    }
                  >
                    Duplicate
                  </button>
                  <button
                    onClick={() => setRevisit(!revisit)}
                    className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 buttonGreen"
                    style={revisit ? { background: "#a10b1d" } : { background: "#a10b1d80" }}
                  >
                    Revise
                  </button>
                </div>
              </div>

              {revisit && 
                <div className="py-4 text-left">
                  <div className="flex flex-wrap">
                    <div className="flex items-center justify-between font-semibold w-full">
                      <p className="lg:w-4/12 md:w-2/12 sm:w-2/12 text-sm">
                        Revise Section
                      </p>
                      <div
                        className="lg:w-8/12 md:w-10/12 sm:w-10/12"
                        style={{ height: "0", border: "1px solid #D3D3D3" }}
                      ></div>
                    </div>
                    <div className="mt-2 fields block filtersTable w-full">
                      <div
                        className="enquiryId mt-2"
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          alignItems: "center",
                        }}
                      >
                        <p className="text-xs">Section</p>
                        <div className="field dropdown relative w-full mb-3">
                          <Select
                            styles={customStylesSelect}
                            options={[
                              {label: "Basic Info", value: "basic"},
                              {label: "Liveness", value: "liveness"},
                              {label: "ID Verification", value: "idverif"},
                              {label: "Address Verification", value: "addressverif"},
                              {label: "Wallet Verification", value: "wallet"}
                            ]}
                            style={{ width: "100%" }}
                            isSearchable={false}
                            onChange={(e) => setRevisitSection(e.value)}
                          />
                        </div>
                      </div>
                      <div
                        className="enquiryId mt-2"
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          alignItems: "center",
                        }}
                      >
                        <p className="text-xs">Notes</p>
                        <div className="field dropdown relative w-full mb-3">
                          <textarea onChange={(e) => setRevisitNotes(e.target.value)}></textarea>
                        </div>
                      </div>
                      {revisitSection && 
                        <button className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 buttonPink text-sm mt-2" onClick={submitRevisit}>Submit</button>
                      }

                    </div>
                  </div>
                </div>
              }
              {revisionSent && 
                <p className="text-xs mt-2">Revision email sent successfully!</p>
              }

              <div className="py-4 text-left">
                <div className="flex flex-wrap">
                  <div className="flex items-center justify-between font-semibold w-full">
                    <p className="lg:w-4/12 md:w-2/12 sm:w-2/12 text-sm">
                      Managers
                    </p>
                    <div
                      className="lg:w-8/12 md:w-10/12 sm:w-10/12"
                      style={{ height: "0", border: "1px solid #D3D3D3" }}
                    ></div>
                  </div>
                  <div className="mt-2 fields block filtersTable w-full">
                    <div
                      className="enquiryId mt-2"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        alignItems: "center",
                      }}
                    >
                      <p className="text-xs">Assigned To</p>
                      {users && (
                        <div className="field dropdown relative w-full mb-3">
                          <Select
                            styles={customStylesSelect}
                            options={users}
                            isSearchable={false}
                            value={users.filter(
                              (obj) => obj.value === assignedTo
                            )}
                            onChange={handleChangeAssignedTo}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="py-4 text-left">
                <div className="flex flex-wrap">
                  <div className="flex items-center justify-center font-semibold w-full">
                    <p className="lg:w-2/12 md:w-2/12 sm:w-2/12 text-sm">
                      Info
                    </p>
                    <div
                      className="lg:w-10/12 md:w-10/12 sm:w-10/12"
                      style={{ height: "0", border: "1px solid #D3D3D3" }}
                    ></div>
                  </div>
                  <div className="mt-2 fields block">
                    <div
                      className="enquiryId mt-2"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                      }}
                    >
                      <p className="text-xs">Inquiry ID</p>
                      <b className="text-xs">
                        {onboardingData.inq_id ? onboardingData.inq_id : "N/A"}
                      </b>
                    </div>
                    <div
                      className="IdNo mt-2"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        wordBreak: "break-all",
                      }}
                    >
                      <p className="text-xs">ID Number</p>
                      <b className="text-xs">
                        {onboardingData.idverification.id_nnum
                          ? onboardingData.idverification.id_nnum
                          : "N/A"}
                      </b>
                    </div>
                    <div
                      className="dob mt-2"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                      }}
                    >
                      <p className="text-xs">Date of Birth</p>
                      <b className="text-xs">
                        {onboardingData.basicinfo.dob
                          ? onboardingData.basicinfo.dob.includes("T") ?
                            Moment(onboardingData.basicinfo.dob.split("T")[0]).format(
                              "MM-DD-YYYY"
                            )
                            :
                            (Moment(onboardingData.basicinfo.dob, "DD/MM/YYYY").format("MM-DD-YYYY") === "Invalid date")
                              ? Moment(onboardingData.basicinfo.dob).format(
                                "MM-DD-YYYY"
                              ) : Moment(onboardingData.basicinfo.dob, "DD/MM/YYYY").format(
                                "MM-DD-YYYY"
                              )
                          : 
                          "N/A"
                        }
                      </b>
                    </div>
                    <div
                      className="nationality mt-2"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                      }}
                    >
                      <p className="text-xs">Nationality</p>
                      <b className="text-xs">
                        {onboardingData.basicinfo.nationality
                          ? onboardingData.basicinfo.nationality
                          : "N/A"}
                      </b>
                    </div>
                    <div
                      className="nationality mt-2"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                      }}
                    >
                      <p className="text-xs">Email</p>
                      <b className="text-xs">
                        {onboardingData.basicinfo.email
                          ? onboardingData.basicinfo.email
                          : "N/A"}
                      </b>
                    </div>
                  </div>
                </div>
              </div>

              {onboardingEventData && (
                <div className="py-4 text-left">
                  <div className="flex flex-wrap">
                    <div className="flex items-center justify-center font-semibold w-full">
                      <p className="lg:w-2/12 md:w-2/12 sm:w-2/12 text-sm">
                        Event
                      </p>
                      <div
                        className="lg:w-10/12 md:w-10/12 sm:w-10/12"
                        style={{ height: "0", border: "1px solid #D3D3D3" }}
                      ></div>
                    </div>
                    <div className="w-full mt-2 fields block eventTimeline">
                      {onboardingEventData.map((element, ind) => (
                        <>
                          {element.event_desc && 
                            <>
                              <div data-index={ind} className="status mt-2">
                                <div
                                  className="w-full mt-2"
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "20% 80%",
                                  }}
                                >
                                  {element.passed === 0 && (
                                    <div className="imgTag">
                                      <img src={ElipsesOne} alt="created" />
                                    </div>
                                  )}
                                  {element.passed === 1 && (
                                    <div className="imgTag">
                                      <img src={ElipsesTwo} alt="created" />
                                    </div>
                                  )}
                                  {element.passed === 2 && (
                                    <div className="imgTag">
                                      <img src={ElipsesYellow} alt="created" />
                                    </div>
                                  )}
                                  {element.passed === 3 && (
                                    <div className="imgTag">
                                      <img src={ElipsesError} alt="created" />
                                    </div>
                                  )}
                                  <div className="text text-xs font-semibold">
                                    <p data-tip data-for={element.event_desc.replaceAll(' ', '')}>
                                      {element.event_desc}
                                    </p>
                                    {element.notes && 
                                      <ReactTooltip id={element.event_desc.replaceAll(' ', '')} place="top" effect="solid">
                                        <span>{element.notes}</span>
                                      </ReactTooltip>
                                    }
                                    <p className="createdDate font-normal mt-2">
                                      {Moment(element.created_at).format(
                                        "MM-DD-YYYY"
                                      )}{" "}
                                      | {Moment(element.created_at).format("hh:mm a")}
                                    </p>
                                  </div>
                                </div>
                                {onboardingEventDataLength - 1 !== ind && (
                                  <div
                                    className="w-full items-center justify-center"
                                    style={{
                                      display: "grid",
                                      gridTemplateColumns: "20% 80%",
                                    }}
                                  >
                                    <div className="imgTag px-2">
                                      <img
                                        className="ml-1"
                                        src={VerticalLine}
                                        alt="created"
                                      />
                                    </div>
                                    <div className="text text-xs font-semibold"></div>
                                  </div>
                                )}
                              </div>
                            </>
                          }
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {!dataLoaded && (
        <>
          <ReactLoading
            className="margin-auto mt-3 mb-3 absolute"
            type={"bars"}
            style={{
              zIndex: "100000",
              top: "30%",
              left: "39%",
              width: "8%",
              height: "8%",
              fill: "#a10b1d",
            }}
          />
          <div
            id="overlay"
            className="fixed w-full h-full top-0 left-0 right-0 bottom-0 cursor-pointer"
            style={{ backgroundColor: "rgba(0,0,0,0.5)", zIndex: "10000" }}
          ></div>
        </>
      )}

      <Modal
        isOpen={modalIsOpen1}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal1}
        style={customStylesPopup}
        contentLabel="Example Modal 1"
      >
        {(!Array.isArray(aiData.front) && aiData.front != undefined) && 
          <div className="flex flex-col w-full justify-between items-center px-2" style={{ maxHeight: "500px", overflow: "auto", maxWidth: "500px" }}>
            <div className="documentOCR xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 py-4">
              <h4 className="font-bold text-sm mb-3">
                ELA
              </h4>
              <table className="w-full table-fixed">
                <tbody>
                  <tr>
                    <td className="font-light text-xs">
                      Percentage
                    </td>
                    <td className="font-semibold text-xs text-right">
                      {aiData.front.ela ? aiData.front.ela.percentage : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-light text-xs">
                      Type
                    </td>
                    <td className="font-semibold text-xs text-right">
                      {aiData.front.ela ? aiData.front.ela.type : "N/A"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {aiData.front.metadata.values != "false" &&
              <div className="documentOCR xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 py-4">
                <h4 className="font-bold text-sm mb-3">
                  Metadata
                </h4>
                <table className="w-full table-fixed">
                  <tbody>
                    {Object.keys(JSON.parse(aiData.front.metadata.values)).map((key) => (
                      <>
                        <tr key={key}>
                          {key == "flash" ? 
                            <>
                              <td className="font-light text-xs">
                                {key}
                              </td>
                              <td className="font-semibold text-xs text-right">
                                {JSON.parse(aiData.front.metadata.values)[key]["__value__"] ? JSON.parse(aiData.front.metadata.values)[key]["__value__"] : "N/A"}
                              </td>
                            </>
                            :
                            <>
                              <td className="font-light text-xs">
                                {key}
                              </td>
                              <td className="font-semibold text-xs text-right">
                                {JSON.parse(aiData.front.metadata.values)[key] ? JSON.parse(aiData.front.metadata.values)[key] : "N/A"}
                              </td>
                            </>
                          }
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            }

            {aiData.front.mrz != undefined && aiData.front.mrz.values != "null" &&
              <div className="documentOCR xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 py-4">
                <h4 className="font-bold text-sm mb-3">
                  MRZ
                </h4>
                <table className="w-full table-fixed">
                  <tbody>
                    {Object.keys(JSON.parse(aiData.front.mrz.values)).map((key) => (
                      <>
                        <tr key={key}>
                          {key == "aux" ? 
                            <>
                              <td className="font-light text-xs">
                                {key}
                              </td>
                              <td className="font-semibold text-xs text-right">
                                {JSON.parse(aiData.front.mrz.values)[key]["text"] ? JSON.parse(aiData.front.mrz.values)[key]["text"] : "N/A"}
                              </td>
                            </>
                            : key == "valid_check_digits" ?
                            <>
                              <td className="font-light text-xs">
                                {key}
                              </td>
                              <td className="font-semibold text-xs text-right">
                                {JSON.parse(aiData.front.mrz.values)[key][0] ? (JSON.parse(aiData.front.mrz.values)[key][0] ? "Yes" : "No") : "N/A"}
                              </td>
                            </>
                            : key == "valid_line_lengths" ?
                            <>
                              <td className="font-light text-xs">
                                {key}
                              </td>
                              <td className="font-semibold text-xs text-right">
                                {JSON.parse(aiData.front.mrz.values)[key][0] ? (JSON.parse(aiData.front.mrz.values)[key][0] ? "Yes" : "No") : "N/A"}
                              </td>
                            </>
                            : key == "valid_misc" ?
                            <>
                              <td className="font-light text-xs">
                                {key}
                              </td>
                              <td className="font-semibold text-xs text-right">
                                {JSON.parse(aiData.front.mrz.values)[key][0] ? (JSON.parse(aiData.front.mrz.values)[key][0] ? "Yes" : "No") : "N/A"}
                              </td>
                            </>
                            : key == "valid" ?
                            <>
                              <td className="font-light text-xs">
                                {key}
                              </td>
                              <td className="font-semibold text-xs text-right">
                                {JSON.parse(aiData.front.mrz.values)[key] ? "Yes" : "N/A"}
                              </td>
                            </>
                            : key == "valid_number" ?
                            <>
                              <td className="font-light text-xs">
                                {key}
                              </td>
                              <td className="font-semibold text-xs text-right">
                                {JSON.parse(aiData.front.mrz.values)[key] ? "Yes" : "N/A"}
                              </td>
                            </>
                            : key == "valid_date_of_birth" ?
                            <>
                              <td className="font-light text-xs">
                                {key}
                              </td>
                              <td className="font-semibold text-xs text-right">
                                {JSON.parse(aiData.front.mrz.values)[key] ? "Yes" : "N/A"}
                              </td>
                            </>
                            : key == "valid_expiration_date" ?
                            <>
                              <td className="font-light text-xs">
                                {key}
                              </td>
                              <td className="font-semibold text-xs text-right">
                                {JSON.parse(aiData.front.mrz.values)[key] ? "Yes" : "N/A"}
                              </td>
                            </>
                            : key == "valid_composite" ?
                            <>
                              <td className="font-light text-xs">
                                {key}
                              </td>
                              <td className="font-semibold text-xs text-right">
                                {JSON.parse(aiData.front.mrz.values)[key] ? "Yes" : "N/A"}
                              </td>
                            </>
                            : key == "valid_personal_number" ?
                            <>
                              <td className="font-light text-xs">
                                {key}
                              </td>
                              <td className="font-semibold text-xs text-right">
                                {JSON.parse(aiData.front.mrz.values)[key] ? "Yes" : "N/A"}
                              </td>
                            </>
                            :
                            <>
                              <td className="font-light text-xs">
                                {key}
                              </td>
                              <td className="font-semibold text-xs text-right">
                                {JSON.parse(aiData.front.mrz.values)[key] ? JSON.parse(aiData.front.mrz.values)[key] : "N/A"}
                              </td>
                            </>
                          }
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            }
          </div>
        }
      </Modal>

      <Modal
        isOpen={modalIsOpen2}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal2}
        style={customStylesPopup}
        contentLabel="Example Modal 2"
      >
        {(!Array.isArray(aiData.back) && aiData.back != undefined) && 
          <div className="flex flex-col w-full justify-between items-center px-2" style={{ maxHeight: "500px", overflow: "auto", maxWidth: "500px" }}>
            <div className="documentOCR xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 py-4">
              <h4 className="font-bold text-sm mb-3">
                ELA
              </h4>
              <table className="w-full table-fixed">
                <tbody>
                  <tr>
                    <td className="font-light text-xs">
                      Percentage
                    </td>
                    <td className="font-semibold text-xs text-right">
                      {aiData.back.ela ? aiData.back.ela.percentage : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-light text-xs">
                      Type
                    </td>
                    <td className="font-semibold text-xs text-right">
                      {aiData.back.ela ? aiData.back.ela.type : "N/A"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {aiData.back.metadata.values != "false" &&
              <div className="documentOCR xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 py-4">
                <h4 className="font-bold text-sm mb-3">
                  Metadata
                </h4>
                <table className="w-full table-fixed">
                  <tbody>
                    {Object.keys(JSON.parse(aiData.back.metadata.values)).map((key) => (
                      <>
                        <tr key={key}>
                          {key == "flash" ? 
                            <>
                              <td className="font-light text-xs">
                                {key}
                              </td>
                              <td className="font-semibold text-xs text-right">
                                {JSON.parse(aiData.back.metadata.values)[key]["__value__"] ? JSON.parse(aiData.back.metadata.values)[key]["__value__"] : "N/A"}
                              </td>
                            </>
                            :
                            <>
                              <td className="font-light text-xs">
                                {key}
                              </td>
                              <td className="font-semibold text-xs text-right">
                                {JSON.parse(aiData.back.metadata.values)[key] ? JSON.parse(aiData.back.metadata.values)[key] : "N/A"}
                              </td>
                            </>
                          }
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            }
          </div>
        }
      </Modal>

      <Modal
        isOpen={modalIsOpen3}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal3}
        style={customStylesPopup}
        contentLabel="OCR Raw Data"
      >
        {(!Array.isArray(ocrData) && ocrData != undefined) && 
          <div className="flex flex-col w-full justify-between items-center px-2" style={{ maxHeight: "500px", overflow: "auto", maxWidth: "500px" }}>
            <div className="documentOCR xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 py-4">
              <table className="w-full table-fixed">
                {imgFrontActive ? 
                  <>
                    {ocrData.raw.front && 
                      <tbody>
                        {Object.keys(JSON.parse(ocrData.raw.front)).map((key) => (
                          <>
                            <tr key={key}>
                              <td className="font-light text-xs">
                                {key}
                              </td>
                              <td className="font-semibold text-xs text-right">
                                {JSON.parse(ocrData.raw.front)[key]["value"] ? JSON.parse(ocrData.raw.front)[key]["value"] : "N/A"}
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    }
                  </>
                  :
                  <>
                    {ocrData.raw.back && 
                      <tbody>
                        {Object.keys(JSON.parse(ocrData.raw.back)).map((key) => (
                          <>
                            <tr key={key}>
                              <td className="font-light text-xs">
                                {key}
                              </td>
                              <td className="font-semibold text-xs text-right">
                                {JSON.parse(ocrData.raw.back)[key]["value"] ? JSON.parse(ocrData.raw.back)[key]["value"] : "N/A"}
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    }
                  </>
                }
              </table>
            </div>
          </div>
        }
      </Modal>

      <Modal
        isOpen={modalIsOpen4}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal4}
        style={customStylesPopup}
        contentLabel="Example Modal 4"
      >
        {(!Array.isArray(aiData.address) && aiData.address != undefined) && 
          <div className="flex flex-col w-full justify-between items-center px-2" style={{ maxHeight: "500px", overflow: "auto", maxWidth: "500px" }}>
            <div className="documentOCR xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 py-4">
              <h4 className="font-bold text-sm mb-3">
                ELA
              </h4>
              <table className="w-full table-fixed">
                <tbody>
                  <tr>
                    <td className="font-light text-xs">
                      Percentage
                    </td>
                    <td className="font-semibold text-xs text-right">
                      {aiData.address.ela ? aiData.address.ela.percentage : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-light text-xs">
                      Type
                    </td>
                    <td className="font-semibold text-xs text-right">
                      {aiData.address.ela ? aiData.address.ela.type : "N/A"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {aiData.address.metadata.values != "false" &&
              <div className="documentOCR xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 py-4">
                <h4 className="font-bold text-sm mb-3">
                  Metadata
                </h4>
                <table className="w-full table-fixed">
                  <tbody>
                    {Object.keys(JSON.parse(aiData.address.metadata.values)).map((key) => (
                      <>
                        <tr key={key}>
                          {key == "flash" ? 
                            <>
                              <td className="font-light text-xs">
                                {key}
                              </td>
                              <td className="font-semibold text-xs text-right">
                                {JSON.parse(aiData.address.metadata.values)[key]["__value__"] ? JSON.parse(aiData.address.metadata.values)[key]["__value__"] : "N/A"}
                              </td>
                            </>
                            :
                            <>
                              <td className="font-light text-xs">
                                {key}
                              </td>
                              <td className="font-semibold text-xs text-right">
                                {JSON.parse(aiData.address.metadata.values)[key] ? JSON.parse(aiData.address.metadata.values)[key] : "N/A"}
                              </td>
                            </>
                          }
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            }
          </div>
        }
      </Modal>

      <Modal
        isOpen={modalIsOpenFront}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModalImageFront}
        style={customStylesPopup}
        contentLabel="Example Modal 4"
      >
        {dataLoaded && 
          <div className="flex w-full justify-between items-center px-2" style={{ overflow: "auto", maxWidth: "700px", width: "700px" }}>
            <img
              className="py-4 w-full"
              src={
                onboardingData.idverification.path_front
                  ? onboardingData.idverification.path_front
                  : idVerRectImg
              }
              alt="idImage"
              width={"500px"}
              height={"500px"}
              style={{
                maxHeight: "500px",
                objectFit: "cover",
                // transform: "scale(1.7)"
              }}
            />
          </div>
        }
      </Modal>

      <Modal
        isOpen={modalIsOpenBack}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModalImageBack}
        style={customStylesPopup}
        contentLabel="Example Modal 4"
      >
        {dataLoaded && 
          <div className="flex w-full justify-between items-center px-2" style={{ overflow: "auto", maxWidth: "700px", width: "700px" }}>
            <img
              className="py-4 w-full"
              src={
                onboardingData.idverification.path_back
                  ? onboardingData.idverification.path_back
                  : idVerRectImg
              }
              alt="idImage"
              width={"500px"}
              height={"500px"}
              style={{
                maxHeight: "500px",
                objectFit: "cover",
                // transform: "scale(1.7)"
              }}
            />
          </div>
        }
      </Modal>

      <Modal
        isOpen={modalIsOpenAddress}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModalImageAddress}
        style={customStylesPopup}
        contentLabel="Example Modal 4"
      >
        {dataLoaded && 
          <div className="flex w-full justify-between items-center px-2" style={{ maxHeight: "500px", maxWidth: "700px", width: "700px", overflow: "hidden" }}>
            <img
              className="py-4 w-full"
              src={
                onboardingData.addressverification.path_doc
                  ? onboardingData.addressverification.path_doc
                  : idVerRectImg
              }
              alt="idImage"
              style={{
                maxHeight: "100%",
                objectFit: "none",
                // transform: "scale(1.7)"
              }}
            />
          </div>
        }
      </Modal>
    </>
  );
}
