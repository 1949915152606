import React from "react";
import { Link, useParams } from "react-router-dom";
import { useTable, usePagination, useRowSelect } from "react-table";
import ReactApexChart from "react-apexcharts";
import { getMethodsData } from "../../services/onchain/Onchain";
import ReactLoading from "react-loading";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";

export default function OnchainMethods(props) {
  const { id, network, typename } = useParams();
  let stateData = props.location.state;
  const [dateRange, setDateRange] = React.useState(stateData["dateRange"]);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [graphSeries, setGraphSeries] = React.useState([]);
  const [graphOptions, setGraphOptions] = React.useState({
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          total: {
            enabled: true,
            offsetX: 0,
            style: {
              fontSize: '13px',
              fontWeight: 900
            }
          }
        }
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    xaxis: {
      categories: [],
      labels: {
        formatter: function (val) {
          return val + "K"
        }
      }
    },
    yaxis: {
      title: {
        text: undefined
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val
        }
      }
    },
    fill: {
      opacity: 1
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetX: 40
    }
  });
  const [methodsData, setMethodsData] = React.useState([]);
  const [methodsParamData, setMethodsParamData] = React.useState([]);

  const [optionsLimit, setOptionsLimit] = React.useState([
    {label: "10", value: 10},
    {label: "15", value: 15},
    {label: "20", value: 20},
  ]);
  const [defaultValueLimit, setDefaultValueLimit] = React.useState(optionsLimit[0]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 })
  }

  const bitcoinWalletArray = [
    "bitcoin",
    "litecoin",
    "dogecoin",
    "dash",
    "zcash",
    "bitcash",
    "bitcoinsv",
    "ethereum",
    "ethclassic",
    "ethclassic_reorg",
    "ethpow",
    "algorand",
    "bsc",
    "celo_rc1",
    "conflux_hydra",
    "eos",
    "tron",
    "solana",
    "matic",
    "velas",
    "klaytn",
    "elrond",
    "avalanche",
    "fantom",
    "moonbeam",
    "cronos",
    "flow",
    "everscale",
    "cardano",
    "algorand_testnet",
    "algorand_beatnet",
    "celo_alfajores",
    "bsc_testnet",
    "goerli",
    "celo_baklava",
    "conflux_oceanus",
    "medalla",
    "velas_testnet",
    "crypto_testnet",
    "binance",
    "consmoshub",
    "crypto_mainnet",
    "hemidall",
    "terra",
    "diem_testnet",
    "libra_testnet",
    "stellar",
    "ripple",
    "hedera",
    "tezos",
    "eth2",
    "filecoin",
  ];

  const methodsColumn = [
    {
      Header: "Method",
      accessor: "method",
    },
    {
      Header: "Method Signature",
      accessor: "methodSign",
    },
    {
      Header: "Gas Cost/Call",
      accessor: "gasCost",
    },
    {
      Header: "Latest Date",
      accessor: "latestDate",
    },
    {
      Header: "TX Senders",
      accessor: "txSender",
    },
    {
      Header: "Internal Calls",
      accessor: "internalCalls",
    },
    {
      Header: "External Calls",
      accessor: "externalCalls",
    },
    {
      Header: "Calls Count",
      accessor: "callsCount",
    }
  ];

  const methodsParamColumn = [
    {
      Header: "Address",
      accessor: "address",
    },
    {
      Header: "Argument",
      accessor: "argument",
    },
    {
      Header: "Method",
      accessor: "method",
    },
    {
      Header: "Calls Count",
      accessor: "callCount",
    }
  ];

  React.useEffect(() => {
    if (id && network) {
      let formdata = {
        "address": id,
        "network": network,
        "date_from": dateRange[0].toISOString().split('T')[0],
        "date_till": dateRange[1].toISOString().split('T')[0],
        "limit": defaultValueLimit.value,
        "nativeCurrency": "string",
        "offset": 0
      };

      getMethodsData(formdata).then((response) => {
        if (response.data.success) {
          if (response.data.response.Distribution_of_Calls_Count_by_Methods.data) {
            let data = response.data.response.Distribution_of_Calls_Count_by_Methods.data;

            let seriesData = [
              {
                name: 'External Calls',
                data: data.ethereum.smartContractCalls.map((ele) => {
                  return ele.external_count
                })
              }, 
              {
                name: 'Internal Calls',
                data: data.ethereum.smartContractCalls.map((ele) => {
                  return ele.internal_count
                })
              }
            ];

            let optionsData = {
              chart: {
                type: 'bar',
                height: 350,
                stacked: true,
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                  dataLabels: {
                    total: {
                      enabled: true,
                      offsetX: 0,
                      style: {
                        fontSize: '13px',
                        fontWeight: 900
                      }
                    }
                  }
                },
              },
              stroke: {
                width: 1,
                colors: ['#fff']
              },
              xaxis: {
                categories: data.ethereum.smartContractCalls.map((ele) => {
                  return ele.smartContractMethod.name
                }),
                labels: {
                  formatter: function (val) {
                    return val
                  }
                }
              },
              yaxis: {
                title: {
                  text: undefined
                },
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return val
                  }
                }
              },
              fill: {
                opacity: 1
              },
              legend: {
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40
              }
            };

            setGraphSeries(seriesData);
            setGraphOptions(optionsData);
          }

          if (response.data.response.Smart_Contract_Methods.data) {
            let data = response.data.response.Smart_Contract_Methods.data;

            if (data.ethereum.smartContractCalls.length > 0) {
              let methodsTableData = data.ethereum.smartContractCalls.map((ele) => (
                {
                  method: ele.smartContractMethod.name,
                  methodSign: ele.smartContractMethod.signature,
                  gasCost: ele.gasValue,
                  latestDate: ele.max_date,
                  txSender: ele.senders,
                  internalCalls: ele.internal_count,
                  externalCalls: ele.external_count,
                  callsCount: ele.count
                }
              ));

              setMethodsData(methodsTableData);
            }
          }

          if (response.data.response.Addresses_used_in_Method_Parameters.data) {
            let data = response.data.response.Addresses_used_in_Method_Parameters.data;

            if (data.ethereum.arguments.length > 0) {
              let addressMethodsTableData = data.ethereum.arguments.map((ele) => (
                {
                  address: ele.address.address,
                  argument: ele.argument.name,
                  method: ele.smartContractSignature.name,
                  callCount: ele.count
                }
              ));

              setMethodsParamData(addressMethodsTableData);
            }
          }

          setDataLoaded(true);
        }
      }).catch((error) => {
        setDataLoaded(true);
        console.log("error: ", error);
      });
    }
  }, [id, network]);

  const updateResults = (date) => {
    setDateRange(date);
    if (date[0] && date[1]) {
      setDataLoaded(false);
      let formdata = {
        "address": id,
        "network": network,
        "date_from": date[0].toISOString().split('T')[0],
        "date_till": date[1].toISOString().split('T')[0],
        "limit": defaultValueLimit.value,
        "nativeCurrency": "string",
        "offset": 0
      };

      getMethodsData(formdata).then((response) => {
        if (response.data.success) {
          if (response.data.response.Distribution_of_Calls_Count_by_Methods.data) {
            let data = response.data.response.Distribution_of_Calls_Count_by_Methods.data;

            let seriesData = [
              {
                name: 'External Calls',
                data: data.ethereum.smartContractCalls.map((ele) => {
                  return ele.external_count
                })
              }, 
              {
                name: 'Internal Calls',
                data: data.ethereum.smartContractCalls.map((ele) => {
                  return ele.internal_count
                })
              }
            ];

            let optionsData = {
              chart: {
                type: 'bar',
                height: 350,
                stacked: true,
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                  dataLabels: {
                    total: {
                      enabled: true,
                      offsetX: 0,
                      style: {
                        fontSize: '13px',
                        fontWeight: 900
                      }
                    }
                  }
                },
              },
              stroke: {
                width: 1,
                colors: ['#fff']
              },
              xaxis: {
                categories: data.ethereum.smartContractCalls.map((ele) => {
                  return ele.smartContractMethod.name
                }),
                labels: {
                  formatter: function (val) {
                    return val
                  }
                }
              },
              yaxis: {
                title: {
                  text: undefined
                },
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return val
                  }
                }
              },
              fill: {
                opacity: 1
              },
              legend: {
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40
              }
            };

            setGraphSeries(seriesData);
            setGraphOptions(optionsData);
          }

          if (response.data.response.Smart_Contract_Methods.data) {
            let data = response.data.response.Smart_Contract_Methods.data;

            if (data.ethereum.smartContractCalls.length > 0) {
              let methodsTableData = data.ethereum.smartContractCalls.map((ele) => (
                {
                  method: ele.smartContractMethod.name,
                  methodSign: ele.smartContractMethod.signature,
                  gasCost: ele.gasValue,
                  latestDate: ele.max_date,
                  txSender: ele.senders,
                  internalCalls: ele.internal_count,
                  externalCalls: ele.external_count,
                  callsCount: ele.count
                }
              ));

              setMethodsData(methodsTableData);
            }
          }

          if (response.data.response.Addresses_used_in_Method_Parameters.data) {
            let data = response.data.response.Addresses_used_in_Method_Parameters.data;

            if (data.ethereum.arguments.length > 0) {
              let addressMethodsTableData = data.ethereum.arguments.map((ele) => (
                {
                  address: ele.address.address,
                  argument: ele.argument.name,
                  method: ele.smartContractSignature.name,
                  callCount: ele.count
                }
              ));

              setMethodsParamData(addressMethodsTableData);
            }
          }

          setDataLoaded(true);
        }
      }).catch((error) => {
        setDataLoaded(true);
        console.log("error: ", error);
      });
    }
  }

  const handleSelectChange = (e) => {
    setDefaultValueLimit(e);
    setDataLoaded(false);

    let formdata = {
      "address": id,
      "network": network,
      "date_from": dateRange[0].toISOString().split('T')[0],
      "date_till": dateRange[1].toISOString().split('T')[0],
      "limit": e.value,
      "nativeCurrency": "string",
      "offset": 0
    };

    getMethodsData(formdata).then((response) => {
      if (response.data.success) {
        if (response.data.response.Distribution_of_Calls_Count_by_Methods.data) {
          let data = response.data.response.Distribution_of_Calls_Count_by_Methods.data;

          let seriesData = [
            {
              name: 'External Calls',
              data: data.ethereum.smartContractCalls.map((ele) => {
                return ele.external_count
              })
            }, 
            {
              name: 'Internal Calls',
              data: data.ethereum.smartContractCalls.map((ele) => {
                return ele.internal_count
              })
            }
          ];

          let optionsData = {
            chart: {
              type: 'bar',
              height: 350,
              stacked: true,
            },
            plotOptions: {
              bar: {
                horizontal: true,
                dataLabels: {
                  total: {
                    enabled: true,
                    offsetX: 0,
                    style: {
                      fontSize: '13px',
                      fontWeight: 900
                    }
                  }
                }
              },
            },
            stroke: {
              width: 1,
              colors: ['#fff']
            },
            xaxis: {
              categories: data.ethereum.smartContractCalls.map((ele) => {
                return ele.smartContractMethod.name
              }),
              labels: {
                formatter: function (val) {
                  return val
                }
              }
            },
            yaxis: {
              title: {
                text: undefined
              },
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return val
                }
              }
            },
            fill: {
              opacity: 1
            },
            legend: {
              position: 'top',
              horizontalAlign: 'left',
              offsetX: 40
            }
          };

          setGraphSeries(seriesData);
          setGraphOptions(optionsData);
        }

        if (response.data.response.Smart_Contract_Methods.data) {
          let data = response.data.response.Smart_Contract_Methods.data;

          if (data.ethereum.smartContractCalls.length > 0) {
            let methodsTableData = data.ethereum.smartContractCalls.map((ele) => (
              {
                method: ele.smartContractMethod.name,
                methodSign: ele.smartContractMethod.signature,
                gasCost: ele.gasValue,
                latestDate: ele.max_date,
                txSender: ele.senders,
                internalCalls: ele.internal_count,
                externalCalls: ele.external_count,
                callsCount: ele.count
              }
            ));

            setMethodsData(methodsTableData);
          }
        }

        if (response.data.response.Addresses_used_in_Method_Parameters.data) {
          let data = response.data.response.Addresses_used_in_Method_Parameters.data;

          if (data.ethereum.arguments.length > 0) {
            let addressMethodsTableData = data.ethereum.arguments.map((ele) => (
              {
                address: ele.address.address,
                argument: ele.argument.name,
                method: ele.smartContractSignature.name,
                callCount: ele.count
              }
            ));

            setMethodsParamData(addressMethodsTableData);
          }
        }

        setDataLoaded(true);
      }
    }).catch((error) => {
      setDataLoaded(true);
      console.log("error: ", error);
    });
  }

  return (
    <>
      <div className="rounded-lg flex justify-between bg-white px-12 py-6 border-0 w-full mb-6 shadow-lg">
        <div className="flex flex-wrap">
          <Link
            to={{
              pathname: "/onchain/smartcontract/"+id+"/"+network+"/"+typename,
              state: {
                  dateRange: dateRange,
              },
            }} 
          >
            <div
              className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
              style={
                window.location.href.indexOf("/onchain/smartcontract") !== -1
                  ? { backgroundColor: "#EBEDF2" }
                  : { backgroundColor: "#FFF" }
              }
            >
              {typename == "SmartContract" && 
                <h4 className="font-semibold text-xs">Smart Contract</h4>
              }
              {typename == "Address" && 
                <h4 className="font-semibold text-xs">Address</h4>
              }
            </div>
          </Link>
          {network != "cardano" && 
            <Link
              to={{
                pathname: "/onchain/transactions/"+id+"/"+network+"/"+typename,
                state: {
                    dateRange: dateRange,
                },
              }}
            >
              <div
                className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                style={
                  window.location.href.indexOf("/onchain/transaction") !== -1
                    ? { backgroundColor: "#EBEDF2" }
                    : { backgroundColor: "#FFF" }
                }
              >
                <h4 className="font-semibold text-xs">Transcations</h4>
              </div>
            </Link>
          }
          {network != "cardano" && 
            <>
              {typename == "SmartContract" && 
                <Link
                  to={{
                    pathname: "/onchain/methods/"+id+"/"+network+"/"+typename,
                    state: {
                        dateRange: dateRange,
                    },
                  }}
                >
                  <div
                    className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                    style={
                      window.location.href.indexOf("/onchain/methods") !== -1
                        ? { backgroundColor: "#EBEDF2" }
                        : { backgroundColor: "#FFF" }
                    }
                  >
                    <h4 className="font-semibold text-xs">Methods</h4>
                  </div>
                </Link>
              }
            </>
          }
          {network != "cardano" && 
            <Link
              to={{
                pathname: "/onchain/events/"+id+"/"+network+"/"+typename,
                state: {
                    dateRange: dateRange,
                },
              }}
            >
              <div
                className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                style={
                  window.location.href.indexOf("/onchain/events") !== -1
                    ? { backgroundColor: "#EBEDF2" }
                    : { backgroundColor: "#FFF" }
                }
              >
                <h4 className="font-semibold text-xs">Events</h4>
              </div>
            </Link>
          }
          <Link
            to={{
              pathname: "/onchain/inflow/"+id+"/"+network+"/"+typename,
              state: {
                  dateRange: dateRange,
              },
            }}
          >
            <div
              className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
              style={
                window.location.href.indexOf("/onchain/inflow") !== -1
                  ? { backgroundColor: "#EBEDF2" }
                  : { backgroundColor: "#FFF" }
              }
            >
              <h4 className="font-semibold text-xs">Inflow</h4>
            </div>
          </Link>
          <Link
            to={{
              pathname: "/onchain/outflow/"+id+"/"+network+"/"+typename,
              state: {
                  dateRange: dateRange,
              },
            }}
          >
            <div
              className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
              style={
                window.location.href.indexOf("/onchain/outflow") !== -1
                  ? { backgroundColor: "#EBEDF2" }
                  : { backgroundColor: "#FFF" }
              }
            >
              <h4 className="font-semibold text-xs">Outflow</h4>
            </div>
          </Link>
          {network != "cardano" && 
            <Link
              to={{
                pathname: "/onchain/callcontracts/"+id+"/"+network+"/"+typename,
                state: {
                    dateRange: dateRange,
                },
              }}
            >
              <div
                className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                style={
                  window.location.href.indexOf("/onchain/callcontracts") !== -1
                    ? { backgroundColor: "#EBEDF2" }
                    : { backgroundColor: "#FFF" }
                }
              >
                <h4 className="font-semibold text-xs">Call Contracts</h4>
              </div>
            </Link>
          }
          <Link
            to={{
              pathname: "/onchain/graph/"+id+"/"+network+"/"+typename,
              state: {
                  dateRange: dateRange,
              },
            }}
          >
            <div
              className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
              style={
                window.location.href.indexOf("/onchain/graph") !== -1
                  ? { backgroundColor: "#EBEDF2" }
                  : { backgroundColor: "#FFF" }
              }
            >
              <h4 className="font-semibold text-xs">Graph</h4>
            </div>
          </Link>
        </div>
      </div>

      {!dataLoaded ? 
        <div className="rounded-lg flex justify-between bg-white px-12 py-6 border-0 w-full mb-6 shadow-lg">
          <div className="lg:w-12/12 md:w-12/12 sm:w-6/12">
            <ReactLoading
              className="margin-auto mt-3 mb-3"
              type={"bars"}
              color={"#a10b1d"}
              height={"50px"}
              width={"50px"}
            />
          </div>
        </div>
        :
        <>
          <div className="methodsCards">
            <div className="rounded-lg flex justify-between items-center bg-white px-12 py-6 border-0 w-full mb-6 shadow-lg">
              <div className="flex flex-row searchContract" style={{ maxWidth: "70%" }}>
                {(bitcoinWalletArray.indexOf(network) > -1) && 
                  <img style={{ width: "20px", height: "20px", marginRight: "10px" }} src={require(`assets/img/onchain/${network}.svg`).default} />
                }
                <span className="capitalize text-sm flex flex-row"><b className="mr-2">{network ? network : "N/A"}</b> - <p className="ml-2" style={{ maxWidth: "70%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{id ? id : "N/A"}</p></span>
              </div>
              <div className="filtersTable searchInputTables linechartfilter">
                <div className="fieldSearch relative w-full mb-3">
                  <label className="label block font-semibold text-black-600 text-xs mb-2">
                    Date range
                  </label>
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    portalId="root-portal"
                    maxDate={new Date()}
                    selectsRange={true}
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    onChange={(update) => {
                      updateResults(update)
                    }}
                    peekNextMonth
                    renderCustomHeader={({
                      monthDate,
                      customHeaderCount,
                      decreaseMonth,
                      increaseMonth,
                    }) => (
                      <div>
                        <button
                          aria-label="Previous Month"
                          className={
                            "react-datepicker__navigation react-datepicker__navigation--previous"
                          }
                          style={customHeaderCount === 1 ? { visibility: "hidden" } : null}
                          onClick={decreaseMonth}
                        >
                          <span
                            className={
                              "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                            }
                          >
                            {"<"}
                          </span>
                        </button>
                        <span className="react-datepicker__current-month">
                          {monthDate.toLocaleString("en-US", {
                            month: "long",
                            year: "numeric",
                          })}
                        </span>
                        <button
                          aria-label="Next Month"
                          className={
                            "react-datepicker__navigation react-datepicker__navigation--next"
                          }
                          style={customHeaderCount === 0 ? { visibility: "hidden" } : null}
                          onClick={increaseMonth}
                        >
                          <span
                            className={
                              "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                            }
                          >
                            {">"}
                          </span>
                        </button>
                      </div>
                    )}
                    monthsShown={2}
                  />
                </div>
              </div>
              <div className="filtersTable limitChangeField">
                <div className="limitField mainField mb-3">
                  <div className="inputfield limit">
                    <label className="label block font-semibold text-black-600 text-xs mb-2">Limit</label>
                    <Select
                      styles={customStyles}
                      onChange={handleSelectChange}
                      className="globeSelect"
                      options={optionsLimit}
                      defaultValue={defaultValueLimit}
                      menuPortalTarget={document.body}
                      isSearchable={false}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className=" xl:w-12/12 lg:w-12/12 xl:w-12/12 xl:w-12/12 mb-12 xl:mb-0 rounded-lg">
              <div className="relative min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white px-8 py-6 cardLineChart ">
                <div className="w-full flex  items-center">
                    <h4
                      className="borderDefault summaryHeading pb-2 font-bold text-base"
                      style={{ borderBottom: "2px solid #a10b1d" }}
                    >
                      Distribution of Calls Count by Methods
                    </h4>
                </div>
                <ReactApexChart options={graphOptions} series={graphSeries} type="bar" height={350} />
              </div>
            </div>
            <div className=" xl:w-12/12 lg:w-12/12 xl:w-12/12 xl:w-12/12 mb-12 xl:mb-0 rounded-lg ">
              <div className="relative flex flex-row flex-wrap min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white px-8 py-6 cardLineChart ">
                <div className="w-full flex  items-center">
                    <h4
                      className="borderDefault summaryHeading pb-2 font-bold text-base"
                      style={{ borderBottom: "2px solid #a10b1d" }}
                    >
                      Smart Contract Methods
                    </h4>
                </div>
                <Table data={methodsData} columns={methodsColumn}/>

              </div>
            </div>
            <div className=" xl:w-12/12 lg:w-12/12 xl:w-12/12 xl:w-12/12 mb-12 xl:mb-0 rounded-lg ">
              <div className="relative flex flex-row flex-wrap min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white px-8 py-6 cardLineChart ">
                <div className="w-full flex  items-center">
                    <h4
                      className="borderDefault summaryHeading pb-2 font-bold text-base"
                      style={{ borderBottom: "2px solid #a10b1d" }}
                    >
                      Addresses Used in Method Parameters
                    </h4>
                </div>
                <Table data={methodsParamData} columns={methodsParamColumn}/>
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
}

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination,
    useRowSelect
  );
  return (
    <>
      <div className="block w-full overflow-x-auto">
        {/* Projects table */}
        <table
          {...getTableProps()}
          className="items-center w-full bg-transparent border-collapse"
        >
          <thead className="border border-solid border-t-0 border-l-0 border-r-0 border-blueGray-300">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    className={
                      "px-6 align-middle py-3 text-xs whitespace-nowrap font-semibold text-center text-black-600"
                    }
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="cursor-pointer">
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center"
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

