import React, { useState,useContext } from "react";
import { Link,useHistory } from "react-router-dom";
import { saveevent } from "services/kyc/KycScreens";
import { savecompanykycs } from "services/kyb/KybScreens";
import {KycMobContext} from "../../layouts/KYCMob";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";

import backButtonImg from "../../assets/img/kycmobile/backBtn.png";
import closeButtonImg from "../../assets/img/kycmobile/cross_white.png";
import footerImg from "../../assets/img/kycmobile/footer_trademark.png";
import createRule from "../../assets/img/createRule.png";

export default function Screen4() {
  const [disable, setDisable] = React.useState(false);
  const {data} = useContext(KycMobContext);
  const [message, setMessage] = useState("");
  const history= useHistory();
  const url = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

  const storage = window.localStorage;
  const [screenStates, setScreenStates] = React.useState(JSON.parse(storage.getItem("screenmeta")));

  React.useEffect(() => {
    if (screenStates) {
      if (!screenStates.Screen4) {
        const redirecturl = "/kyb/f/"+url;
        history.push(redirecturl);
        window.location.reload();
      }
    } else {
      const redirecturl = "/kyb/f/"+url;
      history.push(redirecturl);
      window.location.reload();
    }
  }, [screenStates]);

  const [formData, setFormData] = React.useState([
    {
      first_name: "",
      last_name: "",
      designation: "",
      email: ""
    }
  ]);

  const addRow = () => {
    const newGroup = [ ...formData ];
    newGroup.push({
      first_name: "",
      last_name: "",
      designation: "",
      email: ""
    });

    setFormData(newGroup);
  }

  const removeRow = () => {
    const newGroup = [ ...formData ];
    if (newGroup.length > 1) {
      newGroup.pop();
    }

    setFormData(newGroup);
  }

  const handleInputChange = (e, index) => {
    setMessage("");
    const newGroup = [ ...formData ];

    if (e.target.name === "first_name") {
      newGroup[index].first_name = e.target.value;
    } else if (e.target.name === "last_name") {
      newGroup[index].last_name = e.target.value;
    } else if (e.target.name === "designation") {
      newGroup[index].designation = e.target.value;
    } else if (e.target.name === "email") {
      newGroup[index].email = e.target.value;
    }

    setFormData(newGroup);
  }

  const handleNext = () => {
    let invalid = false;
    formData.forEach((ele) => {
      if (ele.first_name === "") {
        setMessage("All fields are mandatory!");
        invalid = true;
        return;
      }
      if (ele.last_name === "") {
        setMessage("All fields are mandatory!")
        invalid = true;
        return;
      }
      if (ele.designation === "") {
        setMessage("All fields are mandatory!")
        invalid = true;
        return;
      }
      if (ele.email === "") {
        setMessage("All fields are mandatory!")
        invalid = true;
        return;
      }
    });

    if (invalid) {
      return;
    }

    setDisable(true);
    let data_to_send = {
      kycs: JSON.stringify(formData),
      inq_id: url,
    };
    savecompanykycs(data_to_send)
      .then((response) => {
        if (response.data.success) {
          let formdata3 = {
            inq_id: url,
            event_desc: "Company KYC Referrals Saved",
            passed: 1,
          };
          saveevent(formdata3)
            .then((response) => {
              if (response.data.success) {
                let temp = screenStates;
                temp.Screen26 = true;
                storage.setItem("screenmeta", JSON.stringify(temp));
                const redirecturl = "/kyb/h/" + url;
                history.push(redirecturl);
                window.location.reload();
              }
            })
            .catch((error) => {
              console.log("error: ", error);
              setDisable(false);
            });
        }
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  }

  const handleCross = () => {
    var ua = window.navigator.userAgent;

    if (ua.indexOf("iPhone") > 0) {
      window.close();
    }
    window.close();
  };

  return (
    <>
      <div className="screenFour screenThree screenThreeDocs h-full" style={{fontFamily: data ? data.font_family : "Gilroy"}}>
        <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
          <div className="backArrow">
            <Link to={"/kyb/e/"+url}>
              <button>
                <img src={backButtonImg} alt="backArrow" className="" />
              </button>
            </Link>
          </div>
          <div className="progressBar w-full py-3 px-12">
            <div className="relative lg:w-8/12 mx-auto">
              <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                <div
                  style={{ width: "30%",backgroundColor:data ? data.progressbar_color:"#a10b1d" }}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"
                ></div>
              </div>
            </div>
          </div>
          <div className="crossArrow">
            <button onClick={handleCross}>
              <img src={closeButtonImg} alt="crossArrow" className="" />
            </button>
          </div>
        </div>

        <div className="mainContent">
          <div className="mainText">
            <h1 style={{ lineHeight: 1 }}>Add Company Members for KYC</h1>
          </div>
          <Form className="informationSection mt-8">
            <div className="duplicationFields" style={{ height: "270px", overflowY: "scroll" }}>
              {formData.map((ele, ind) => (
                <>
                  <div data-index={ind}>
                    <div className="nameFields mt-2">
                      <div className="firstNameField firstField">
                        <div className="inputfield firstname">
                          <span className="label text-xs">First Name</span>
                          <Input 
                            type="text" 
                            name="first_name" 
                            className="input firstnameInput"
                            value={ele.first_name}
                            onChange={e => handleInputChange(e, ind)}
                          />
                        </div>
                      </div>
                      <div className="lastNameField">
                        <div className="inputfield lastname">
                          <span className="label text-xs">Last Name</span>
                          <Input 
                            type="text" 
                            name="last_name" 
                            className="input firstnameInput"
                            value={ele.last_name}
                            onChange={e => handleInputChange(e, ind)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="nameFields">
                      <div className="firstNameField firstField">
                        <div className="inputfield firstname">
                          <span className="label text-xs">Email Address</span>
                          <Input 
                            type="text" 
                            name="email" 
                            className="input firstnameInput"
                            value={ele.email}
                            onChange={e => handleInputChange(e, ind)}
                          />
                        </div>
                      </div>
                      <div className="lastNameField">
                        <div className="inputfield lastname">
                          <span className="label text-xs">Designation</span>
                          <Input 
                            type="text" 
                            name="designation" 
                            className="input firstnameInput"
                            value={ele.designation}
                            onChange={e => handleInputChange(e, ind)}
                          />
                        </div>
                      </div>
                    </div>

                    <hr style={{ marginBottom: "20px" }} />
                  </div>
                </>
              ))}
            </div>

            {message && (
              <section className="form-group mt-2">
                <section
                  className="text-xs alert alert-danger"
                  role="alert"
                >
                  {message}
                </section>
              </section>
            )}

            <button type="button" className="action text-xs duplicateBtn flex flex-row mt-4" onClick={addRow}>
              <img src={createRule} style={{ width: "15px" }} className="mr-2" />
              Add person
            </button>
            <button type="button" className="action text-xs duplicateBtn flex flex-row mt-4" onClick={removeRow}>
              <img src={createRule} style={{ width: "15px", transform: "rotate(45deg)" }} className="mr-2" />
              Remove person
            </button>
          </Form>
        </div>

        <div className="buttonAction">
          {/* <Link to="/kyb/screen5"> */}
          <button onClick={handleNext} disabled={disable} style={{backgroundColor:data?data.button_color:"#a10b1d"}}  className="action">Submit</button>
          {/* </Link> */}
        </div>

        <div className="footer">
          <img className="footerImg" src={footerImg} alt="footer" />
        </div>
      </div>
    </>
  );
}
  
