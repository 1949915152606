import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { KycMobContext } from "../../layouts/KYCMob";
import { savelanguage } from "services/kyb/KybScreens";
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import platform from "platform";
import {isMobile} from 'react-device-detect';

import backButtonImg from " ../../assets/img/kycmobile/backBtn.png";
import closeButtonImg from " ../../assets/img/kycmobile/cross_white.png";
import footerImg from " ../../assets/img/kycmobile/footer_trademark.png";

export default function Screen1info() {
  const { data } = useContext(KycMobContext);
  // console.log(data);

  const [disable, setDisable] = React.useState(false);
  const url = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
  const [urlId, setUrlId] = React.useState(url);
  const history = useHistory();
  const [language, setLanguage] = useState(null);
  const [deviceType, setDeviceType] = React.useState(platform.manufacturer);
  const [deviceOs, setDeviceOs] = React.useState(platform.os.family);
  const [browser, setBrowser] = React.useState(platform.name);
  const [latitude, setLatitude] = React.useState(null);
  const [longitude, setLongitude] = React.useState(null);
  var fpPromise = null;
  var result_fingerprint = null;
  const storage = window.localStorage;
  const [screenStates, setScreenStates] = React.useState(JSON.parse(storage.getItem("screenmeta")));

  React.useEffect(() => {
    if (screenStates) {
      if (!screenStates.Screen1info) {
        const redirecturl = "/kyb/a/"+urlId;
        history.push(redirecturl);
        window.location.reload();
      }
    } else {
      const redirecturl = "/kyb/a/"+urlId;
      history.push(redirecturl);
      window.location.reload();
    }
  }, [screenStates]);

  function showPosition(position) {
    setLatitude(position.coords.latitude);
    setLongitude(position.coords.longitude);
  }

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    }
  }

  React.useEffect(()=>{
    getLocation();
  },[]);

  const handleSubmit = () => {
    setDisable(true);
    fpPromise = FingerprintJS.load({ apiKey: 'cAI4XJPsx0MaxCh5XnTK' });
    ;(async () => {
      // Get the visitor identifier when you need it.
      const fp = await fpPromise;
      result_fingerprint = await fp.get({extendedResult: true});
      
      let visitor_id = result_fingerprint.visitorId;
      let os = result_fingerprint.os;
      let os_version = result_fingerprint.osVersion;
      let first_seen = result_fingerprint.firstSeenAt.global;
      let last_seen = result_fingerprint.lastSeenAt.global;
      let ip = result_fingerprint.ip;
      let browser_name = result_fingerprint.browserName;
      let browser_version = result_fingerprint.browserVersion;
      let incognito = result_fingerprint.incognito;

      let formdata = {
        inq_id: urlId,
        language: null,
        browser_name: browser_name,
        browser_version: browser_version,
        os: os,
        os_version: os_version,
        visitor_id: visitor_id,
        first_seen: first_seen,
        last_seen: last_seen,
        ip: ip,
        incognito: incognito,
        latitude: latitude,
        longitude: longitude
      };
  
      savelanguage(formdata).then((response) => {
        if (response.data.success) {
          if (isMobile) {
            let temp = screenStates;
            temp.Screen3 = true;
            storage.setItem("screenmeta", JSON.stringify(temp));
            const redirecturl = "/kyb/e/"+urlId;
            history.push(redirecturl);
            window.location.reload();
          } else {
            let temp = screenStates;
            temp.Screen1i = true;
            storage.setItem("screenmeta", JSON.stringify(temp));
            const redirecturl = "/kyb/c/"+urlId;
            history.push(redirecturl);
            window.location.reload();
          }
        }
      }).catch((error) => {
        console.log("error: ", error)
      });
    })();
  }

  const handleCross = () => {
    var ua = window.navigator.userAgent;

    if (ua.indexOf("iPhone") > 0) {
      window.close();
    }
    window.close();
  };

  return (
    <>
      <div
        className="screenOne screenInfoOne h-full flex flex-col"
        style={{ fontFamily: data ? data.font_family : "Gilroy" }}
      >
        <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
          <div className="backArrow">
            <Link to={"/kyb/a/" + urlId}>
              <button>
                <img src={backButtonImg} alt="backArrow" className="" />
              </button>
            </Link>
          </div>
          <div className="progressBar w-full py-3 px-12">
            <div className="relative lg:w-8/12 mx-auto">
              <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                <div
                  style={{
                    width: "10%",
                    backgroundColor: data ? data.progressbar_color : "#a10b1d",
                  }}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center "
                ></div>
              </div>
            </div>
          </div>
          <div className="crossArrow">
            <button onClick={handleCross}>
              <img src={closeButtonImg} alt="crossArrow" className="" />
            </button>
          </div>
        </div>

        <div class="mainContent screen1info">
          <div class="mainText">
            <h2>Prepare for your company verification</h2>
          </div>
          <div className="screen1Info-textdiv overflow-y-auto max-h-40-vh flex flex-col px-4 mb-6 mt-4">
            <div className="textdiv mb-4">
              <p className="mb-2 font-bold text-sm">Questionnaire</p>
              <p className="font-normal text-xs" style={{ color: "#a8a8a8" }}>Fill out a questionnaire with your company Legal Name, Entity Type, Jurisdiction, Registered Address, Date of Incorporation, Company Website and Registration Number / Tax ID.</p>
            </div>
            <div className="textdiv mb-4">
              <p className="mb-2 font-bold text-sm">Corporate documents</p>
              <p className="font-normal text-xs" style={{ color: "#a8a8a8" }}>Provide a Certificate of Incorporation. You can also provide Article of Association / Memorandum of Association / Bylaws or Authorization Letter if KYC is performed by any Non-Key Member of the company. Ensure that company legal name are on the document.</p>
            </div>
            <div className="textdiv mb-4">
              <p className="mb-2 font-bold text-sm">Add Company Members for KYC</p>
              <p className="font-normal text-xs" style={{ color: "#a8a8a8" }}>Please add 1 or more Key Member of the company to complete their individual KYC.</p>
            </div>
          </div>
          <div class="supportingText">
            <p class="supportingTextPara text-xs">
              By clicking “Continue”, you acknowledge  to the Hedge Technologies <a href="https://www.the-hedge.io/privacy-policy" target="__blank">Privacy Policy</a>.
            </p>
          </div>
        </div>

        <div class="buttonAction">
          {/* <Link to={"/kyb/c/" + urlId}> */}
          <button
            style={{ backgroundColor: data ? data.button_color : "#a10b1d" }}
            class="action"
            onClick={handleSubmit}
            disabled={disable}
          >
            Continue
          </button>
          {/* </Link> */}
        </div>

        <div class="footer">
          <img class="footerImg" src={footerImg} alt="footer" />
        </div>
      </div>
    </>
  );
}
