import React from "react";
import { Link } from "react-router-dom";
import Form from "react-validation/build/form";
import Select from "react-select";
import countryList from "react-select-country-list";
import {
  saveacceptablecountries,
  getacceptablecountries,
  deleteacceptablecountries,
} from "../../services/settings/Acceptable";
import ReactLoading from "react-loading";

import backSetting from "../../assets/img/backSetting.png";
import countryDropdownGlobe from "../../assets/img/countryDropdownGlobe.png";

export default function AcceptedCountries() {
  const [isShown, setIsShown] = React.useState("");

  const [message, setMessage] = React.useState("");
  const [value, setValue] = React.useState([]);
  const [countries, setCountries] = React.useState({});
  const options = React.useMemo(() => countryList().getData(), []);
  options.forEach((ele) => {
    if(ele.label === "Czechia") {
      ele.label = "Czech Republic (Czechia)";
    }
  });
  const [acceptedcountries, setAcceptablecountries] = React.useState(null);
  const [dataLoaded, setDataLoaded] = React.useState(false);

  React.useEffect(() => {
    getacceptablecountries()
      .then((response) => {
        if (response.data.success) {
          let countryvalues = [];
          setAcceptablecountries(response.data.acceptable_countries);
          if (options && response.data.acceptable_countries) {
            options.forEach(ele => {
              response.data.acceptable_countries.forEach(ele2 => {
                if (ele.label === ele2.country) {
                  countryvalues.push(ele);
                }
              })
            })
          }

          setValue(countryvalues);
          setDataLoaded(true);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  const changeHandler = (v) => {
    if (!value.includes(v)) {
      setValue((current) => [v, ...current]);
      setCountries((values) => ({ ...values, [v.label]: v.label }));

      saveacceptablecountries({ country: {[v.label]: v.label} }).then((response) => {
        setMessage("");
        if (response.data.success) {
          // window.location.reload();
        }
      }).catch((error) => {
        console.log("error", error);
        setMessage("There was an error, please try again!");
      });
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
    }),
  };

  const handleSubmit = () => {
    saveacceptablecountries({ country: countries }).then((response) => {
      setMessage("");
      if (response.data.success) {
        window.location.reload();
      }
    }).catch((error) => {
      console.log("error", error);
      setMessage("There was an error, please try again!");
    });
  };

  const handleDelete = (country) => {
    deleteacceptablecountries(country)
      .then((response) => {
        // console.log("response: ", response);
        if (response.data.success) {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        setMessage("There was an error, please try again!");
      });
  };

  return (
    <>
      <div className="amlscreening flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
            <div className="rounded-lg flex justify-between bg-white border-0 w-full mb-6 shadow-lg resSidebar">
              <div
                className="lg:w-2/12 md:w-12/12 sm:w-12/12 nav-bg-grey rounded-lg shadow-2xl customizationSidebar"
                style={{
                  borderTopRightRadius: "0",
                  borderBottomRightRadius: "0",
                }}
              >
                <ul className="py-16 pt-8 md:flex-col md:min-w-full flex flex-col list-none leftSidebarNavListMain rounded-lg">
                  <li className="backbtn py-10 px-6 ">
                    <Link to="/settings/profile">
                      <button>
                        <img style={{ minWidth: "40px" }} src={backSetting} />
                      </button>
                    </Link>
                  </li>
                  <li className="items-center mb-3 uppercase text-xs font-bold block px-12 greyTxt hiddenSetting">
                    Theme Editor
                  </li>
                  <li className="items-center mb-3">
                    <Link
                      className={
                        "text-xs py-3 font-bold block px-12 customizationLinks " +
                        (window.location.href.indexOf("/settings/kyc") !== -1 ||
                        isShown === "branding"
                          ? "text-black-500 hover:text-black-600 colorPrimary nav-bg-white"
                          : "text-black-700 hover:text-black-500")
                      }
                      onMouseEnter={() => setIsShown("branding")}
                      onMouseLeave={() => setIsShown("")}
                      to="/settings/kyc"
                    >
                      <p style={{ letterSpacing: "1px" }}>Branding</p>
                    </Link>
                  </li>

                  <li className="items-center mb-3 mt-8 uppercase text-xs font-bold block px-12 greyTxt hiddenSetting">
                    Configurations
                  </li>
                  <li className="items-center mb-3">
                    <Link
                      className={
                        "text-xs py-3 font-bold block px-12 customizationLinks " +
                        (window.location.href.indexOf(
                          "/settings/acceptedcountries"
                        ) !== -1 || isShown === "acceptCountry"
                          ? "text-black-500 hover:text-black-600 colorPrimary nav-bg-white"
                          : "text-black-700 hover:text-black-500")
                      }
                      to="/settings/acceptedcountries"
                      onMouseEnter={() => setIsShown("acceptCountry")}
                      onMouseLeave={() => setIsShown("")}
                    >
                      <p style={{ letterSpacing: "1px" }}>Accepted Countries</p>
                    </Link>
                  </li>
                  <li className="items-center mb-3">
                    <Link
                      className={
                        "text-xs py-3 font-bold block px-12 customizationLinks " +
                        (window.location.href.indexOf(
                          "/settings/acceptableids"
                        ) !== -1 || isShown === "acceptId"
                          ? "text-black-500 hover:text-black-600 colorPrimary nav-bg-white"
                          : "text-black-700 hover:text-black-500")
                      }
                      to="/settings/acceptableids"
                      onMouseEnter={() => setIsShown("acceptId")}
                      onMouseLeave={() => setIsShown("")}
                    >
                      <p style={{ letterSpacing: "1px" }}>
                        Acceptable ID Types
                      </p>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="lg:w-10/12 md:w-12/12 sm:w-12/12 px-16 py-16 acceptCountryMain">
                <div
                  className="w-full flex flex-row items-center pb-16 mb-8"
                  style={{ borderBottom: "1px solid #EFF1F6" }}
                >
                  <Form class="">
                    <div className="flex flex-col items-center">
                      <div className="flex w-full flex-row">
                        <div
                          className="lg:w-12/12 md:w-12/12 sm:w-12/12 relative"
                          style={{ minWidth: "300px", maxWidth: "300px" }}
                        >
                          <img
                            src={countryDropdownGlobe}
                            alt="globe"
                            className="absolute globeimagesettings"
                          />
                          <Select
                            className="globeSelect"
                            options={options}
                            value={value}
                            styles={customStyles}
                            onChange={changeHandler}
                          />
                        </div>
                      </div>

                      {/* <div className="form-submit button-action flex w-full mt-4 flex-row">
                        <button
                          type="button"
                          onClick={handleSubmit}
                          className="py-3 px-12 rounded-lg font-semibold text-white-400 buttonPink text-xs"
                        >
                          Add
                        </button>
                      </div> */}
                    </div>
                  </Form>
                </div>

                {message && (
                  <div className="flex w-full form-group">
                    <div
                      className="alert alert-danger text-xs mb-8"
                      role="alert"
                    >
                      {message}
                    </div>
                  </div>
                )}

                {!dataLoaded ? 
                  <ReactLoading
                    className="margin-auto mt-3 mb-3"
                    type={"bars"}
                    color={"#a10b1d"}
                    height={"50px"}
                    width={"50px"}
                  />
                :
                  <>
                    {value && (
                      <div
                        className="flex flex-wrap w-full items-center mb-12 pb-16"
                        // style={{ borderBottom: "1px solid #EFF1F6" }}
                      >
                        {value.map((item, key) => (
                          <p
                            className="mr-8 px-4 py-2 mt-4 text-xs font-normal rounded-lg"
                            style={{ background: "#EFF1F6" }}
                            data-index={key}
                          >
                            {item.label}
                            <button
                              onClick={() => handleDelete(item.label)}
                              style={{
                                all: "unset",
                                cursor: "pointer",
                                marginLeft: "5px",
                                fontWeight: "bold",
                              }}
                            >
                              x
                            </button>
                          </p>
                        ))}
                      </div>
                    )}
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
