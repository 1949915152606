import React from "react";
import { Link } from "react-router-dom";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import Select from "react-select";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  viewall,
  viewsingleright,
  getevents,
} from "services/casemanagement/Onboarding";
import Moment from "moment";

// components
import TableDropdown from "components/Dropdowns/TableDropdown.js";

// images
import ExportIcon from "../../assets/img/icons/exporticon.png";
import ElipsesOne from "../../assets/img/event_elp_1.png";
import ElipsesTwo from "../../assets/img/event_elp_2.png";
import ElipsesError from "../../assets/img/event_elp_error.png";
import ElipsesYellow from "../../assets/img/event_elp_yellow.png";
import VerticalLine from "../../assets/img/verticalLine.png";
import statusAccountGreen from "../../assets/img/statusAccountGreen.png";
import statusLocGreen from "../../assets/img/statusLocGreen.png";
import statusIDGreen from "../../assets/img/statusIDGreen.png";
import statusMapGreen from "../../assets/img/statusMapGreen.png";
import statusAccountRed from "../../assets/img/statusAccountRed.png";
import statusLocRed from "../../assets/img/statusLocRed.png";
import statusIDRed from "../../assets/img/statusIDRed.png";
import statusMapRed from "../../assets/img/statusMapRed.png";

export default function CaseManagementOnboarding() {

  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize",handleResize);
    };
  }, []);


  const renderTooltipID = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div
        className="popoverHover px-4 py-2 rounded-lg font-bold text-sm"
        style={{ background: "#a10b1d", color: "#FFF" }}
      >
        Identity Verification
      </div>
    </Tooltip>
  );
  const renderTooltipGovt = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div
        className="popoverHover px-4 py-2 rounded-lg font-bold text-sm"
        style={{ background: "#a10b1d", color: "#FFF" }}
      >
        Government ID Verification
      </div>
    </Tooltip>
  );
  const renderTooltipAddress = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div
        className="popoverHover px-4 py-2 rounded-lg font-bold text-sm"
        style={{ background: "#a10b1d", color: "#FFF" }}
      >
        Address Verification
      </div>
    </Tooltip>
  );
  const renderTooltipAml = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div
        className="popoverHover px-4 py-2 rounded-lg font-bold text-sm"
        style={{ background: "#a10b1d", color: "#FFF" }}
      >
        AML Screening
      </div>
    </Tooltip>
  );

  const color = "light";
  const [individualActive, setIndividualActive] = React.useState(true);
  const [corporateActive, setCorporateActive] = React.useState(false);
  const [onboardingData, setOnboardingData] = React.useState([]);
  const [onboardingEventData, setOnboardingEventData] = React.useState([]);
  const [onboardingEventDataLength, setOnboardingEventDataLength] =
    React.useState(0);
  const [onboardingRightData, setOnboardingRightData] = React.useState(null);
  const [onboardingRightImage, setOnboardingRightImage] = React.useState(null);
  const [openRight, setOpenRight] = React.useState(null);
  const [rightId, setRightId] = React.useState(null);


  const [filteredData, setFilteredData] = React.useState([]);
  const handleFilter = (word) => {
  if(word==""){
    setFilteredData(onboardingData)
    return
  }
    const newFilter = onboardingData.filter((value) => {
      if(value.basicinfo.first_name || value.basicinfo.last_name){
      return (value.basicinfo.first_name +(" ") + value.basicinfo.last_name).toLowerCase().includes(word.toLowerCase());}
    });
    setFilteredData(newFilter);
  };

  const circularBarColorAccepted = "#15E49A";
  const circularBarColorPending = "#FFC102";
  const circularBarColorDeclined = "#FA364C";

  function setIndivActive() {
    setIndividualActive(true);
    setCorporateActive(false);
  }
  function setCorpActive() {
    setIndividualActive(false);
    setCorporateActive(true);
  }

  const risklevel = [{ label: "Risk Level", value: 1 }];
  const countrywise = [{ label: "Country Wise", value: 1 }];
  const status = [
    { label: "Status", value: 1 },
    { label: "Active", value: 2 },
    { label: "Inactive", value: 3 },
  ];
  const timeperiod = [{ label: "Time Period", value: 1 }];
  const tags = [{ label: "Tags", value: 1 }];

  const defaultValueRiskLevel = risklevel[0];
  const defaultValueCountryWise = countrywise[0];
  const defaultValueStatus = status[0];
  const defaultValueTime = timeperiod[0];
  const defaultValueTags = tags[0];

  const customStylesSelect = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#828282" : "#828282",
      backgroundColor: state.isSelected ? "#EBEDF2" : "#FFF",
      padding: 10,
      fontSize: "12px",
    }),
  };

  React.useEffect(() => {
    viewall()
      .then((response) => {
        // console.log("response: ", response);
        if (response.data.success) {
          setOnboardingData(response.data.onboardings);
          setFilteredData(response.data.onboardings)
        }
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  }, []);

  React.useEffect(() => {
    if (openRight === false) {
      viewsingleright(rightId)
        .then((response) => {
          // console.log("response: ", response);
          if (response.data.success) {
            setOnboardingRightData(response.data.onboardingsingle);
            setOnboardingRightImage(response.data.image);
            getevents(rightId)
              .then((response) => {
                // console.log("response: ", response);
                if (response.data.success) {
                  setOnboardingEventData(response.data.event);
                  if (response.data.event.length > 0) {
                    setOnboardingEventDataLength(response.data.event.length);
                  }
                  setOpenRight(!openRight);
                }
              })
              .catch((error) => {
                console.log("error: ", error);
              });
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    }
  }, [openRight, rightId]);

  const openRightSide = (id) => {
    // setOpenRight(false);
    if (rightId !== null) {
      if (id === rightId) {
        setOpenRight(null);
        setRightId(null);
        return;
      } else {
        if(width>=1280){window.location.href = "#"}
        else{window.location.href = "#right"}
        setOpenRight(false);
        setRightId(id);
      }
    }
    if (rightId === null) {
      if(width>=1280){window.location.href = "#"}
      else{window.location.href = "#right"}
      setRightId(id);
      setOpenRight(false);
      return;
    }
  };

  return (
    <>
      <div className="casemanagement flex flex-wrap ">
        <div
          className={
            "w-full px-1 " +
            (openRight
              ? "xl:w-9/12 lg:w-12/12 md:w-12/12 sm:w-12/12"
              : "xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12")
          }
          style={{ transition: "all 0.25s ease-in-out" }}
        >
          <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
            <div className="rounded-lg flex justify-between bg-white px-12 py-6 border-0 w-full mb-6 shadow-lg">
              <div className="flex">
                <div
                  className="rounded-lg px-4 py-2 mr-4 cursor-pointer"
                  style={
                    individualActive
                      ? { backgroundColor: "#EBEDF2" }
                      : { backgroundColor: "#FFF" }
                  }
                  onClick={setIndivActive}
                >
                  <h4 className="font-semibold text-xs">Individual</h4>
                </div>
                {/* <div 
                  className="rounded-lg px-4 py-2 cursor-pointer" 
                  style={corporateActive ? { backgroundColor: "#EBEDF2" } : {backgroundColor: "#FFF"}}
                  onClick={setCorpActive}
                >
                  <h4 className="font-semibold text-sm">Corporate</h4>
                </div> */}
              </div>
              <div className="flex px-4 py-2 cursor-pointer">
                <img
                  src={ExportIcon}
                  alt="export icon"
                  className="mr-3"
                  style={{ width: "20px", height: "20px" }}
                />
                <h4
                  className="font-semibold text-xs"
                  style={{ color: "#a10b1d" }}
                >
                  Export CSV
                </h4>
              </div>
            </div>
            <div
              className={
                "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg " +
                (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
              }
            >
              <div className="rounded-t mb-0 px-4 py-4 border-0">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full max-w-full flex-grow flex-1">
                    <Form
                      class="filtersTable"
                      // onSubmit={(e) => handleLogin(e)}
                      // ref={c => {form = c;}}
                    >
                      <div className="flex justify-between items-center justify-center flex-wrap">
                        <div className="searchInputTables">
                          <div className="fieldSearch relative w-full mb-3">
                            <label className="label block font-semibold text-black-600 text-xs mb-2">
                              Search
                            </label>
                            <Input
                              type="text"
                              className="searchFilter input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-xs shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              onChange={(e) => handleFilter(e.target.value)}
                              // placeholder="Search by name, reference ID or inquiry ID"
                              // value={email}
                              // onChange={(e) => setEmail(e.target.value)}
                              // validations={[required, validEmail]}
                            />
                          </div>
                        </div>
                        <div className="flex flex-wrap">
                          <div className="mr-3">
                            <div className="field dropdown relative w-full mb-3">
                              <Select
                                styles={customStylesSelect}
                                options={timeperiod}
                                isSearchable={false}
                                defaultValue={defaultValueTime}
                              />
                            </div>
                          </div>
                          <div className="mr-3">
                            <div className="field dropdown relative w-full mb-3">
                              <Select
                                styles={customStylesSelect}
                                options={risklevel}
                                isSearchable={false}
                                defaultValue={defaultValueRiskLevel}
                              />
                            </div>
                          </div>
                          <div className="mr-3">
                            <div className="field dropdown relative w-full mb-3">
                              <Select
                                styles={customStylesSelect}
                                options={status}
                                isSearchable={false}
                                defaultValue={defaultValueStatus}
                              />
                            </div>
                          </div>
                          <div className="mr-3">
                            <div className="field dropdown relative w-full mb-3">
                              <Select
                                styles={customStylesSelect}
                                options={countrywise}
                                isSearchable={false}
                                defaultValue={defaultValueCountryWise}
                              />
                            </div>
                          </div>
                          <div>
                            <div className="field dropdown relative w-full mb-3">
                              <Select
                                styles={customStylesSelect}
                                options={tags}
                                isSearchable={false}
                                defaultValue={defaultValueTags}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
              <div className="block w-full overflow-x-auto">
                {/* Projects table */}
                <table className="items-center w-full bg-transparent border-collapse">
                  <thead className="border border-solid border-t-0 border-l-0 border-r-0 border-blueGray-300">
                    <tr>
                      <th
                        className={
                          "px-6 align-middle py-3 text-xs capitalize whitespace-nowrap font-semibold text-center text-black-600"
                        }
                      >
                        Name
                      </th>
                      <th
                        className={
                          "px-6 align-middle py-3 text-xs capitalize whitespace-nowrap font-semibold text-center text-black-600"
                        }
                      >
                        KYC Status
                      </th>
                      <th
                        className={
                          "px-6 align-middle py-3 text-xs capitalize whitespace-nowrap font-semibold text-center text-black-600"
                        }
                      >
                        Risk Level
                      </th>
                      <th
                        className={
                          "px-6 align-middle py-3 text-xs capitalize whitespace-nowrap font-semibold text-center text-black-600"
                        }
                      >
                        Assigned To
                      </th>
                      <th
                        className={
                          "px-6 align-middle py-3 text-xs capitalize whitespace-nowrap font-semibold text-center text-black-600"
                        }
                      >
                        Reviewed By
                      </th>
                      <th
                        className={
                          "px-6 align-middle py-3 text-xs capitalize whitespace-nowrap font-semibold text-center text-black-600"
                        }
                      >
                        Inquiry Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                        <tr>
                          <th
                            className="border-t-0 px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-left flex flex-col items-left"
                            
                          >
                           <Link to="/case/casemanagementdetail/1">
                              <span
                                className={
                                  "text-sm font-bold text-black-600 capitalize"
                                }
                              >
                                Nauman Abuzar
                              </span>
                              
                            
                            <div className="text-xs flex flex-col otherDetailsName">
                              <span>Inquiry ID: inq_128710343324adawkm</span>
                              <span>
                                Creation Date:{" "}
                                07/23/2022
                              </span>
                            </div>
                            </Link>
                          </th>
                          <td className="border-t-0 px-6 align-middle align-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            <div className="statusGrid flex flex-row justify-center ">
                              <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltipID}
                              >
                                <img
                                  className="account mr-3"
                                  src={statusAccountGreen}
                                  alt="status-account"
                                />
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltipGovt}
                              >
                                <img
                                  className="id mr-3"
                                  src={statusIDGreen}
                                  alt="status-id"
                                />
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltipAddress}
                              >
                                <img
                                  className="loc mr-3"
                                  src={statusLocGreen}
                                  alt="status-loc"
                                />
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltipAml}
                              >
                                <img
                                  className="map"
                                  src={statusMapGreen}
                                  alt="status-map"
                                />
                              </OverlayTrigger>
                            </div>
                          </td>
                          <td className="border-t-0 px-10 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            <div className="circularProgress mx-auto">
                              <CircularProgressbar
                                value={80}
                                strokeWidth={25}
                                styles={buildStyles({
                                  strokeLinecap: "butt",
                                  pathColor: `${circularBarColorAccepted}`,
                                  trailColor: "#FFF",
                                })}
                              />
                            </div>
                          </td>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4">
                            <div className="flex items-center">
                              <div className="relative w-full">
                                <p className="text-sm font-normal">
                                </p>
                              </div>
                            </div>
                          </td>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4">
                            <div className="flex items-center">
                              <div className="relative w-full">
                                <p className="text-sm font-normal">
                                  
                                </p>
                              </div>
                            </div>
                          </td>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                            {/* <TableDropdown /> */}
                            <div className="buttonAction">
                             
                                <button className="w-full py-3 px-4 rounded-lg font-semibold text-white-400 buttonYellow">
                                  Review
                                </button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th
                            className="border-t-0 px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-left flex flex-col items-left"
                       >
                           <Link to="/case/casemanagementdetail/2">
                              <span
                                className={
                                  "text-sm font-bold text-black-600 capitalize"
                                }
                              >
                                Zarmy Lyn Diezmo Entia
                              </span>
                              
                            
                            <div className="text-xs flex flex-col otherDetailsName">
                              <span>Inquiry ID: inq_128710343324adawkm</span>
                              <span>
                                Creation Date:{" "}
                                07/23/2022
                              </span>
                            </div>
                            </Link>
                          </th>
                          <td className="border-t-0 px-6 align-middle align-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            <div className="statusGrid flex flex-row justify-center ">
                              <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltipID}
                              >
                                <img
                                  className="account mr-3"
                                  src={statusAccountGreen}
                                  alt="status-account"
                                />
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltipGovt}
                              >
                                <img
                                  className="id mr-3"
                                  src={statusIDGreen}
                                  alt="status-id"
                                />
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltipAddress}
                              >
                                <img
                                  className="loc mr-3"
                                  src={statusLocGreen}
                                  alt="status-loc"
                                />
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltipAml}
                              >
                                <img
                                  className="map"
                                  src={statusMapGreen}
                                  alt="status-map"
                                />
                              </OverlayTrigger>
                            </div>
                          </td>
                          <td className="border-t-0 px-10 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            <div className="circularProgress mx-auto">
                              <CircularProgressbar
                                value={80}
                                strokeWidth={25}
                                styles={buildStyles({
                                  strokeLinecap: "butt",
                                  pathColor: `${circularBarColorAccepted}`,
                                  trailColor: "#FFF",
                                })}
                              />
                            </div>
                          </td>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4">
                            <div className="flex items-center">
                              <div className="relative w-full">
                                <p className="text-sm font-normal">
                                </p>
                              </div>
                            </div>
                          </td>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4">
                            <div className="flex items-center">
                              <div className="relative w-full">
                                <p className="text-sm font-normal">
                                  
                                </p>
                              </div>
                            </div>
                          </td>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                            {/* <TableDropdown /> */}
                            <div className="buttonAction">
                             
                                <button className="w-full py-3 px-4 rounded-lg font-semibold text-white-400 buttonYellow">
                                  Review
                                </button>
                            </div>
                          </td>
                        </tr>
                    {
                      filteredData.map((item, index) => (
                        <tr
                          data-index={index}
                          className={
                            "cursor-pointer " +
                            (rightId === item.inq_id ? "activeRow" : "")
                          }
                        >
                          <th
                            className="border-t-0 px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-left flex flex-col items-left"
                            onClick={() => openRightSide(item.inq_id)}
                          >
                            {item.basicinfo ? 
                              <span
                                className={
                                  "text-sm font-bold text-black-600 capitalize"
                                }
                              >
                                {item.basicinfo.first_name +
                                  " " +
                                  item.basicinfo.last_name}
                              </span>
                              :
                              <span
                                className={
                                  "text-sm font-bold text-black-600 capitalize"
                                }
                              >
                                N/A
                              </span>
                            }
                            <div className="text-xs flex flex-col otherDetailsName">
                              <span>Inquiry ID: {item.inq_id}</span>
                              <span>
                                Creation Date:{" "}
                                {Moment(item.created_at).format("MM-DD-YYYY")}
                              </span>
                            </div>
                          </th>
                          <td className="border-t-0 px-6 align-middle align-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            <div className="statusGrid flex flex-row justify-center ">
                              <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltipID}
                              >
                                <img
                                  className="account mr-3"
                                  src={statusAccountGreen}
                                  alt="status-account"
                                />
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltipGovt}
                              >
                                <img
                                  className="id mr-3"
                                  src={statusIDGreen}
                                  alt="status-id"
                                />
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltipAddress}
                              >
                                <img
                                  className="loc mr-3"
                                  src={statusLocGreen}
                                  alt="status-loc"
                                />
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltipAml}
                              >
                                <img
                                  className="map"
                                  src={statusMapGreen}
                                  alt="status-map"
                                />
                              </OverlayTrigger>
                            </div>
                          </td>
                          <td className="border-t-0 px-10 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            <div className="circularProgress mx-auto">
                              <CircularProgressbar
                                value={80}
                                strokeWidth={25}
                                styles={buildStyles({
                                  strokeLinecap: "butt",
                                  pathColor: `${circularBarColorAccepted}`,
                                  trailColor: "#FFF",
                                })}
                              />
                            </div>
                          </td>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4">
                            <div className="flex items-center">
                              <div className="relative w-full">
                                <p className="text-sm font-normal">
                                  {item.assigned_to}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4">
                            <div className="flex items-center">
                              <div className="relative w-full">
                                <p className="text-sm font-normal">
                                  {item.reviewed_by}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                            {/* <TableDropdown /> */}
                            <div className="buttonAction">
                              {item.status === "review" && (
                                <button className="w-full py-3 px-4 rounded-lg font-semibold text-white-400 buttonYellow">
                                  Review
                                </button>
                              )}
                              {item.status === "approve" && (
                                <button className="w-full py-3 px-4 rounded-lg font-semibold text-white-400 buttonGreen">
                                  Approve
                                </button>
                              )}
                              {item.status === "decline" && (
                                <button className="w-full py-3 px-4 rounded-lg font-semibold text-white-400 buttonRed">
                                  Decline
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {openRight && (
          <div className="w-full xl:w-3/12 lg:w-12/12 md:w-12/12 sm:w-12/12 px-4" id="right">
            {onboardingRightData && (
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg mt-16">
                <div className="px-6">
                  <div className="flex flex-wrap justify-center">
                    <div className="w-full px-4 flex justify-center">
                      <div className="relative">
                        <img
                          alt="..."
                          src={
                            onboardingRightImage
                              ? onboardingRightImage
                              : require("assets/img/ProfilePicture.png").default
                          }
                          style={{height:"100px", width:"100px", objectFit:"cover"}}
                          className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-12 lg:-ml-12 max-w-100-px"
                        />
                      </div>
                    </div>
                    <div className="w-full px-4 text-center mt-12">
                      <div className="flex justify-center py-4 lg:pt-4 pt-8">
                        {onboardingRightData.basicinfo ? 
                          <h3 className="text-xl font-semibold leading-normal mb-2 text-black-700 mb-2 capitalize">
                            {onboardingRightData.basicinfo.first_name +
                              " " +
                              onboardingRightData.basicinfo.last_name}
                          </h3>
                          :
                          <h3 className="text-xl font-semibold leading-normal mb-2 text-black-700 mb-2 capitalize">
                            N/A
                          </h3>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <div className="text-base leading-normal mt-0 mb-2 text-white-400 font-bold uppercase">
                      {onboardingRightData.status === "review" && (
                        <div className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 uppercase buttonYellow text-xs">
                          Review
                        </div>
                      )}
                      {onboardingRightData.status === "approve" && (
                        <div className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 uppercase buttonGreen text-xs">
                          Approve
                        </div>
                      )}
                      {onboardingRightData.status === "decline" && (
                        <div className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 uppercase buttonRed text-xs">
                          Decline
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="text-center">
                    <div className="text-base leading-normal mt-0 mb-2 text-white-400 font-bold uppercase">
                      <Link
                        to={`/case/casemanagementdetail/${onboardingRightData.inq_id}`}
                      >
                        <button
                          className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 uppercase buttonGreen text-xs"
                          style={{ background: "#a10b1d" }}
                        >
                          Open Case
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="py-4 text-left">
                    <div className="flex flex-wrap">
                      <div className="flex items-center justify-center font-semibold w-full">
                        <p className="lg:w-2/12 md:w-2/12 sm:w-2/12 text-sm">
                          Info
                        </p>
                        <div
                          className="lg:w-10/12"
                          style={{ height: "0", border: "1px solid #D3D3D3" }}
                        ></div>
                      </div>
                      <div className="mt-2 fields block">
                        <div
                          className="enquiryId mt-2"
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                          }}
                        >
                          <p className="text-xs">Inquiry ID</p>
                          <b className="text-xs">
                            {onboardingRightData.inq_id}
                          </b>
                        </div>
                        <div
                          className="IdNo mt-2"
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            wordBreak:"break-all"                         }}
                        >
                          <p className="text-xs">ID Number</p>
                          <b className="text-xs " >
                            {onboardingRightData.idverification.id_nnum && onboardingRightData.idverification.id_nnum}
                          </b>
                        </div>
                        <div
                          className="dob mt-2"
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                          }}
                        >
                          <p className="text-xs">Date of Birth</p>
                          <b className="text-xs">
                            {Moment(onboardingRightData.basicinfo.dob).format(
                              "MM-DD-YYYY"
                            )}
                          </b>
                        </div>
                        <div
                          className="nationality mt-2"
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                          }}
                        >
                          <p className="text-xs">Nationality</p>
                          <b className="text-xs">
                            {onboardingRightData.basicinfo.nationality}
                          </b>
                        </div>
                      </div>
                    </div>
                  </div>

                  {onboardingEventData && (
                    <div className="py-4 text-left">
                      <div className="flex flex-wrap">
                        <div className="flex items-center justify-center font-semibold w-full">
                          <p className="lg:w-2/12 md:w-2/12 sm:w-2/12 text-sm">
                            Event
                          </p>
                          <div
                            className="lg:w-10/12"
                            style={{ height: "0", border: "1px solid #D3D3D3" }}
                          ></div>
                        </div>
                        <div className="w-full mt-2 fields block eventTimeline">
                          {onboardingEventData.map((element, ind) => (
                            <div data-index={ind} className="status mt-2">
                              <div
                                className="w-full mt-2"
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "20% 80%",
                                }}
                              >
                                {element.passed === 0 && (
                                  <div className="imgTag">
                                    <img src={ElipsesOne} alt="created" />
                                  </div>
                                )}
                                {element.passed === 1 && (
                                  <div className="imgTag">
                                    <img src={ElipsesTwo} alt="created" />
                                  </div>
                                )}
                                {element.passed === 2 && (
                                  <div className="imgTag">
                                    <img src={ElipsesYellow} alt="created" />
                                  </div>
                                )}
                                {element.passed === 3 && (
                                  <div className="imgTag">
                                    <img src={ElipsesError} alt="created" />
                                  </div>
                                )}
                                <div className="text text-xs font-semibold">
                                  {element.event_desc}
                                  <p className="createdDate font-normal mt-2">
                                    {Moment(element.created_at).format(
                                      "MM-DD-YYYY"
                                    )}{" "}
                                    |{" "}
                                    {Moment(element.created_at).format(
                                      "hh:mm a"
                                    )}
                                  </p>
                                </div>
                              </div>
                              {onboardingEventDataLength - 1 !== ind && (
                                <div
                                  className="w-full items-center justify-center"
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "20% 80%",
                                  }}
                                >
                                  <div className="imgTag px-2">
                                    <img
                                      className="ml-1"
                                      src={VerticalLine}
                                      alt="created"
                                    />
                                  </div>
                                  <div className="text text-xs font-semibold"></div>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
