import React from "react";
import { Link } from "react-router-dom";
import Form from "react-validation/build/form";
import { SketchPicker } from "react-color";
import FontPicker from "font-picker-react";
import Desktop from "../../components/Kyc/Desktop";
import Mobile from "../../components/Kyc/Mobile";
import ReactLoading from "react-loading";
import {
  getcustomoptionsdesktop,
  getcustomoptionsmobile,
} from "services/kyc/KycScreens";

import backSetting from "../../assets/img/backSetting.png";
import uploadIcon from "../../assets/img/icons/exporticon.png";

export default function Kyc() {
  const [mobileActive, setMobileActive] = React.useState(true);
  const [desktopActive, setDesktopActive] = React.useState(false);
  const [displayColorPicker1, setDisplayColorPicker1] = React.useState(false);
  const [displayColorPicker2, setDisplayColorPicker2] = React.useState(false);

  const [buttonColorMobile, setButtonColorMobile] = React.useState("#a10b1d");
  const [buttonColorDesktop, setButtonColorDesktop] = React.useState("#a10b1d");

  const [progressColorMobile, setProgressColorMobile] = React.useState("#a10b1d");
  const [progressColorDesktop, setProgressColorDesktop] = React.useState("#a10b1d");

  const [activeFontFamilyMobile, setActiveFontFamilyMobile] = React.useState("Gilroy");
  const [activeFontFamilyDesktop, setActiveFontFamilyDesktop] = React.useState("Gilroy");


  const [logoMobile, setLogoMobile] = React.useState("hedgelogo.png");
  const [logoDesktop, setLogoDesktop] = React.useState("hedgelogo.png");

  const [logoFileMobile, setLogoFileMobile] = React.useState(null);
  const [logoFileDesktop, setLogoFileDesktop] = React.useState(null);

  const [fontPickerState,setFontPickerState] = React.useState(false)

  const hiddenFileInput = React.useRef(null);

  const wrapperRef = React.useRef(null);

  const [isShown, setIsShown] = React.useState("");

  const [custScreenLoaded, setCustScreenLoaded] = React.useState(false);

  // Desktop flags
  const [screen1FlagDesktop, setScreen1FlagDesktop] = React.useState(true);
  const [screen2FlagDesktop, setScreen2FlagDesktop] = React.useState(true);
  const [screen3FlagDesktop, setScreen3FlagDesktop] = React.useState(true);
  const [screen4FlagDesktop, setScreen4FlagDesktop] = React.useState(true);
  const [screen10FlagDesktop, setScreen10FlagDesktop] = React.useState(true);
  const [screen11FlagDesktop, setScreen11FlagDesktop] = React.useState(true);
  const [screen12FlagDesktop, setScreen12FlagDesktop] = React.useState(true);
  const [screen19FlagDesktop, setScreen19FlagDesktop] = React.useState(true);
  const [screen25FlagDesktop, setScreen25FlagDesktop] = React.useState(true);
  const [screen26FlagDesktop, setScreen26FlagDesktop] = React.useState(true);
  const [screen27FlagDesktop, setScreen27FlagDesktop] = React.useState(true);
  const [screen28FlagDesktop, setScreen28FlagDesktop] = React.useState(true);

  // Mobile flags
  const [screen1FlagMobile, setScreen1FlagMobile] = React.useState(true);
  const [screen2FlagMobile, setScreen2FlagMobile] = React.useState(true);
  const [screen3FlagMobile, setScreen3FlagMobile] = React.useState(true);
  const [screen4FlagMobile, setScreen4FlagMobile] = React.useState(true);
  const [screen10FlagMobile, setScreen10FlagMobile] = React.useState(true);
  const [screen11FlagMobile, setScreen11FlagMobile] = React.useState(true);
  const [screen12FlagMobile, setScreen12FlagMobile] = React.useState(true);
  const [screen18FlagMobile, setScreen18FlagMobile] = React.useState(true);
  const [screen19FlagMobile, setScreen19FlagMobile] = React.useState(true);
  const [screen24FlagMobile, setScreen24FlagMobile] = React.useState(true);
  const [screen25FlagMobile, setScreen25FlagMobile] = React.useState(true);
  const [screen26FlagMobile, setScreen26FlagMobile] = React.useState(true);
  const [screen27FlagMobile, setScreen27FlagMobile] = React.useState(true);

  // google maps script include
  React.useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://maps.googleapis.com/maps/api/js?key="+process.env.REACT_APP_GOOGLE_API_KEY;
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, []);

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        closeAll();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleButtonColorChangeMobile = (color) => {
    setButtonColorMobile(color.hex);
    setDisplayColorPicker1(false);
    setDisplayColorPicker2(false);
  };

  const handleButtonColorChangeDesktop = (color) => {
    setButtonColorDesktop(color.hex);
    setDisplayColorPicker1(false);
    setDisplayColorPicker2(false);
  };

  const handleProgressColorChangeMobile = (color) => {
    setProgressColorMobile(color.hex);
    setDisplayColorPicker2(false);
  };

  const handleProgressColorChangeDesktop = (color) => {
    setProgressColorDesktop(color.hex);
    setDisplayColorPicker2(false);
  };

  const handleUploadClick = () => {
    // hiddenFileInput.current.click();
  };

  const handleInputLogoMobile = (e) => {
    setLogoMobile(e.target.files[0].name);
    setLogoFileMobile(e.target.files[0]);
  };

  const handleInputLogoDesktop = (e) => {
    setLogoDesktop(e.target.files[0].name);
    setLogoFileDesktop(e.target.files[0]);
  };

  function closeAll() {
    setDisplayColorPicker1(false);
    setDisplayColorPicker2(false);
  }

  function handleSubmit(e) {
    e.preventDefault();
  }

  React.useEffect(() => {
    setCustScreenLoaded(false);
    getcustomoptionsdesktop()
      .then((response) => {
        // console.log("response:D ", response.data);
        if (response.data.success) {
          if (response.data.cust_options !== null) {
            setButtonColorDesktop(response.data.cust_options.button_color);
            setProgressColorDesktop(
              response.data.cust_options.progressbar_color
            );
            setActiveFontFamilyDesktop(response.data.cust_options.font_family);
            setLogoDesktop(response.data.cust_options.logo);

            setScreen1FlagDesktop(response.data.cust_options.screen_1==1 ? true : false);
            setScreen2FlagDesktop(response.data.cust_options.screen_2==1 ? true : false);
            setScreen3FlagDesktop(response.data.cust_options.screen_3==1 ? true : false);
            setScreen4FlagDesktop(response.data.cust_options.screen_4==1 ? true : false);
            setScreen10FlagDesktop(response.data.cust_options.screen_10==1 ? true : false);
            setScreen11FlagDesktop(response.data.cust_options.screen_11==1 ? true : false);
            setScreen12FlagDesktop(response.data.cust_options.screen_12==1 ? true : false);
            setScreen19FlagDesktop(response.data.cust_options.screen_19==1 ? true : false);
            setScreen25FlagDesktop(response.data.cust_options.screen_25==1 ? true : false);
            setScreen26FlagDesktop(response.data.cust_options.screen_26==1 ? true : false);
            setScreen27FlagDesktop(response.data.cust_options.screen_27==1 ? true : false);
            setScreen28FlagDesktop(response.data.cust_options.screen_28==1 ? true : false);
          }
        }
      })
      .catch((error) => {
        console.log("error: ", error);
      });

    getcustomoptionsmobile()
      .then((response) => {
        // console.log("response:M ", response.data);
        if (response.data.success) {
          if (response.data.cust_options !== null) {
            setButtonColorMobile(response.data.cust_options.button_color);
            setProgressColorMobile(
              response.data.cust_options.progressbar_color
            );
            
            setActiveFontFamilyMobile(response.data.cust_options.font_family);
            setLogoMobile(response.data.cust_options.logo);

            setScreen1FlagMobile(response.data.cust_options.screen_1==1 ? true : false);
            setScreen2FlagMobile(response.data.cust_options.screen_2==1 ? true : false);
            setScreen3FlagMobile(response.data.cust_options.screen_3==1 ? true : false);
            setScreen4FlagMobile(response.data.cust_options.screen_4==1 ? true : false);
            setScreen10FlagMobile(response.data.cust_options.screen_10==1 ? true : false);
            setScreen11FlagMobile(response.data.cust_options.screen_11==1 ? true : false);
            setScreen12FlagMobile(response.data.cust_options.screen_12==1 ? true : false);
            setScreen18FlagMobile(response.data.cust_options.screen_18==1 ? true : false);
            setScreen19FlagMobile(response.data.cust_options.screen_19==1 ? true : false);
            setScreen24FlagMobile(response.data.cust_options.screen_24==1 ? true : false);
            setScreen25FlagMobile(response.data.cust_options.screen_25==1 ? true : false);
            setScreen26FlagMobile(response.data.cust_options.screen_26==1 ? true : false);
            setScreen27FlagMobile(response.data.cust_options.screen_27==1 ? true : false);
          }
          setCustScreenLoaded(true);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  }, []);

  const handleFontClick = () =>{
    setFontPickerState(!fontPickerState)

    if(fontPickerState) {
      document.getElementById("font-picker").classList.add("expanded")
    }
    else {
      document.getElementById("font-picker").classList.remove("expanded")
    }
  }

  return (
    <>
      <div className="amlscreening flex flex-wrap overflow-x-hidden">
        <div className="w-full lg:w-12/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
            <div className="rounded-lg flex justify-between bg-white border-0 w-full mb-6 shadow-lg resSidebar">
              <div
                className="lg:w-2/12 md:w-12/12 sm:w-12/12 nav-bg-grey rounded-lg shadow-2xl customizationSidebar"
                style={{
                  borderTopRightRadius: "0",
                  borderBottomRightRadius: "0",
                }}
              >
                <ul className="py-16 pt-8 md:flex-col md:min-w-full flex flex-col list-none leftSidebarNavListMain rounded-lg">
                  <li className="backbtn py-10 px-6 ">
                    <Link to="/settings/profile">
                      <button>
                        <img style={{ minWidth: "40px" }} src={backSetting} />
                      </button>
                    </Link>
                  </li>
                  <li className="items-center mb-3 uppercase text-xs font-bold block px-12 greyTxt hiddenSetting">
                    Theme Editor
                  </li>
                  <li className="items-center mb-3">
                    <Link
                      className={
                        "text-xs py-3 font-bold block px-12 customizationLinks " +
                        (window.location.href.indexOf("/settings/kyc") !== -1 ||
                        isShown === "branding"
                          ? "text-black-500 hover:text-black-600 colorPrimary nav-bg-white"
                          : "text-black-700 hover:text-black-500")
                      }
                      onMouseEnter={() => setIsShown("branding")}
                      onMouseLeave={() => setIsShown("")}
                      to="/settings/kyc"
                    >
                      <p style={{ letterSpacing: "1px" }}>Branding</p>
                    </Link>
                  </li>

                  <li className="items-center mb-3 mt-8 uppercase text-xs font-bold block px-12 greyTxt hiddenSetting">
                    Configurations
                  </li>
                  <li className="items-center mb-3">
                    <Link
                      className={
                        "text-xs py-3 font-bold block px-12 customizationLinks  " +
                        (window.location.href.indexOf(
                          "/settings/acceptedcountries"
                        ) !== -1 || isShown === "acceptCountry"
                          ? "text-black-500 hover:text-black-600 colorPrimary nav-bg-white"
                          : "text-black-700 hover:text-black-500")
                      }
                      to="/settings/acceptedcountries"
                      onMouseEnter={() => setIsShown("acceptCountry")}
                      onMouseLeave={() => setIsShown("")}
                    >
                      <p style={{ letterSpacing: "1px" }}>Accepted Countries</p>
                    </Link>
                  </li>
                  <li className="items-center mb-3">
                    <Link
                      className={
                        "text-xs py-3 font-bold block px-12 customizationLinks  " +
                        (window.location.href.indexOf(
                          "/settings/acceptableids"
                        ) !== -1 || isShown === "acceptId"
                          ? "text-black-500 hover:text-black-600 colorPrimary nav-bg-white"
                          : "text-black-700 hover:text-black-500")
                      }
                      to="/settings/acceptableids"
                      onMouseEnter={() => setIsShown("acceptId")}
                      onMouseLeave={() => setIsShown("")}
                    >
                      <p style={{ letterSpacing: "1px" }}>
                        Acceptable ID Types
                      </p>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className=" lg:w-12/12 md:w-12/12 sm:w-12/12 px-12 py-16 KYC-MobileSection">
                {mobileActive && (
                  <>
                    {custScreenLoaded ? 
                      <>
                        <div className="kycthemesettings px-12 ">
                          <Form onSubmit={(e) => handleSubmit(e)}>
                            <div className="flex flex-row w-full lg:w-12/12 md:w-12/12 sm:w-12/12 colorRow">
                              <div className="justify-between items-center lg:w-6/12 md:w-6/12 sm:w-12/12 flex flex-row relative mr-8 colorBlock">
                                <label className="label block text-xs font-bold mr-8 colorHeading">
                                  Button Color
                                </label>
                                <div
                                  className="flex flex-row items-center px-12"
                                  onClick={() => {
                                    setDisplayColorPicker1(true);
                                    setDisplayColorPicker2(false);
                                  }}
                                >
                                  <div
                                    className="colorpickerbutton rounded mr-8"
                                    style={{ background: `${buttonColorMobile}` }}
                                  ></div>
                                  <p className="colorCode text-sm">
                                    {" "}
                                    {buttonColorMobile}
                                  </p>
                                  {displayColorPicker1 && (
                                    <div ref={wrapperRef}>
                                      <SketchPicker
                                        className="absolute"
                                        color={buttonColorMobile}
                                        onChangeComplete={
                                          handleButtonColorChangeMobile
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="justify-between items-center lg:w-6/12 md:w-6/12 sm:w-12/12 flex flex-row relative colorBlock">
                                <label className="label block text-xs font-bold  colorHeading">
                                  Progress Bar Color
                                </label>
                                <div
                                  className="flex flex-row items-center px-12"
                                  onClick={() => {
                                    setDisplayColorPicker1(false);
                                    setDisplayColorPicker2(true);
                                  }}
                                >
                                  <div
                                    className="colorpickerbutton rounded mr-8"
                                    style={{ background: `${progressColorMobile}` }}
                                  ></div>
                                  <p className="colorCode text-sm">
                                    {" "}
                                    {progressColorMobile}
                                  </p>
                                  {displayColorPicker2 && (
                                    <div ref={wrapperRef}>
                                      <SketchPicker
                                        className="absolute"
                                        color={progressColorMobile}
                                        onChangeComplete={
                                          handleProgressColorChangeMobile
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-row w-full lg:w-12/12 md:w-12/12 sm:w-12/12 mt-16 colorRow">
                              <div className="justify-between items-center lg:w-6/12 md:w-6/12 sm:w-12/12 flex flex-row relative mr-8 colorBlock">
                                <label className="label block text-xs font-bold colorHeading">
                                  Font Family
                                </label>
                                <div className="flex items-center">
                                <FontPicker
                                  apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                  onChange={(nextFont) =>
                                    setActiveFontFamilyMobile(nextFont.family)
                                  }
                                />
                                <p onClick={handleFontClick} style={{marginLeft:"-90px",marginRight:"30px",zIndex:10, cursor:"pointer"}}>{activeFontFamilyMobile}</p>

                                </div>
                              </div>
                              <div className="justify-between items-center lg:w-6/12 md:w-6/12 sm:w-12/12 flex flex-row relative colorBlock">
                                <label className="label block text-xs font-bold  colorHeading">
                                  Replace Logo
                                </label>
                                <div className="logoreplace flex flex-row">
                                  <div className="flex flex-col mr-8">
                                    <p className="text-base font-normal underline mr-4">
                                      {logoMobile}
                                    </p>
                                    <small className="font-light text-xs greyTxt no-underline mt-2">
                                      *Logo file size should be less than 1mb
                                    </small>
                                  </div>
                                  <button
                                    onClick={handleUploadClick}
                                    style={{ outline: "none" }}
                                  >
                                    <img
                                      style={{ width: "30px" }}
                                      src={uploadIcon}
                                    />
                                  </button>
                                  <input
                                    type="file"
                                    accept=".jpg,.png"
                                    ref={hiddenFileInput}
                                    onChange={handleInputLogoMobile}
                                    style={{ display: "none" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </Form>
                        </div>
                        <Mobile
                          buttonColorMobile={buttonColorMobile}
                          progressColorMobile={progressColorMobile}
                          activeFontFamilyMobile={activeFontFamilyMobile}
                          logoFileMobile={logoFileMobile}
                          
                          screen1FlagMobile={screen1FlagMobile}
                          screen2FlagMobile={screen2FlagMobile}
                          screen3FlagMobile={screen3FlagMobile}
                          screen4FlagMobile={screen4FlagMobile}
                          screen10FlagMobile={screen10FlagMobile}
                          screen11FlagMobile={screen11FlagMobile}
                          screen12FlagMobile={screen12FlagMobile}
                          screen18FlagMobile={screen18FlagMobile}
                          screen19FlagMobile={screen19FlagMobile}
                          screen24FlagMobile={screen24FlagMobile}
                          screen25FlagMobile={screen25FlagMobile}
                          screen26FlagMobile={screen26FlagMobile}
                          screen27FlagMobile={screen27FlagMobile}
                        />
                      </>
                      :
                      <ReactLoading
                        className="margin-auto mt-3 mb-3"
                        type={"bars"}
                        color={"#a10b1d"}
                        height={"10%"}
                        width={"10%"}
                      />
                    }
                  </>
                )}
                {desktopActive && (
                  <>
                    {custScreenLoaded ?
                      <>
                        <div
                          className="kycthemesettings mt-8 px-12 "
                          style={{ width: "80%" }}
                        >
                          <Form onSubmit={(e) => handleSubmit(e)}>
                            <div className="flex flex-row w-full lg:w-12/12 md:w-12/12 sm:w-12/12 colorRow">
                              <div className="justify-between items-center lg:w-6/12 md:w-6/12 sm:w-12/12 flex flex-row relative mr-8 colorBlock">
                                <label className="label block text-xs font-bold mr-8 colorHeading">
                                  Button Color
                                </label>
                                <div
                                  className="flex flex-row items-center px-12"
                                  onClick={() => {
                                    setDisplayColorPicker1(true);
                                    setDisplayColorPicker2(false);
                                  }}
                                >
                                  <div
                                    className="colorpickerbutton rounded mr-8"
                                    style={{ background: `${buttonColorDesktop}` }}
                                  ></div>
                                  <p className="colorCode text-sm">
                                    {" "}
                                    {buttonColorDesktop}
                                  </p>
                                  {displayColorPicker1 && (
                                    <div ref={wrapperRef}>
                                      <SketchPicker
                                        className="absolute"
                                        color={buttonColorDesktop}
                                        onChangeComplete={
                                          handleButtonColorChangeDesktop
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="justify-between items-center lg:w-6/12 md:w-6/12 sm:w-12/12 flex flex-row relative colorBlock">
                                <label className="label block text-xs font-bold  colorHeading">
                                  Progress Bar Color
                                </label>
                                <div
                                  className="flex flex-row items-center px-12"
                                  onClick={() => {
                                    setDisplayColorPicker1(false);
                                    setDisplayColorPicker2(true);
                                  }}
                                >
                                  <div
                                    className="colorpickerbutton rounded mr-8"
                                    style={{
                                      background: `${progressColorDesktop}`,
                                    }}
                                  ></div>
                                  <p className="colorCode text-xs">
                                    {" "}
                                    {progressColorDesktop}
                                  </p>
                                  {displayColorPicker2 && (
                                    <div ref={wrapperRef}>
                                      <SketchPicker
                                        className="absolute"
                                        color={progressColorDesktop}
                                        onChangeComplete={
                                          handleProgressColorChangeDesktop
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-row w-full lg:w-12/12 md:w-12/12 sm:w-12/12 mt-16 colorRow">
                              <div className="justify-between items-center lg:w-6/12 md:w-6/12 sm:w-12/12 flex flex-row relative mr-8 colorBlock">
                                <label className="label block text-xs font-bold colorHeading">
                                  Font Family
                                </label>
                                <div className="flex items-center " >
                                
                                <FontPicker
                                  apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                  onChange={(nextFont) =>
                                    setActiveFontFamilyDesktop(nextFont.family)
                                  }
                                />
                                <p onClick={handleFontClick} style={{marginLeft:"-90px",marginRight:"30px",zIndex:10,cursor:"pointer"}}>{activeFontFamilyDesktop}</p>
                                </div>
                              </div>
                              <div className="justify-between items-center lg:w-6/12 md:w-6/12 sm:w-12/12 flex flex-row relative colorBlock">
                                <label className="label block text-xs font-bold  colorHeading">
                                  Replace Logo
                                </label>
                                <div className="logoreplace flex flex-row">
                                  <div className="flex flex-col mr-8">
                                    <p className="text-base font-normal underline mr-4">
                                      {logoDesktop}
                                    </p>
                                    <small className="font-light text-xs greyTxt no-underline mt-2">
                                      *Logo file size should be less than 1mb
                                    </small>
                                  </div>
                                  <button
                                    onClick={handleUploadClick}
                                    style={{ outline: "none" }}
                                  >
                                    <img
                                      style={{ width: "30px" }}
                                      src={uploadIcon}
                                    />
                                  </button>
                                  <input
                                    type="file"
                                    accept=".jpg,.png"
                                    ref={hiddenFileInput}
                                    onChange={handleInputLogoDesktop}
                                    style={{ display: "none" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </Form>
                        </div>
                      
                        <Desktop
                          buttonColorDesktop={buttonColorDesktop}
                          progressColorDesktop={progressColorDesktop}
                          activeFontFamilyDesktop={activeFontFamilyDesktop}
                          logoFileDesktop={logoFileDesktop}

                          screen1FlagDesktop={screen1FlagDesktop}
                          screen2FlagDesktop={screen2FlagDesktop}
                          screen3FlagDesktop={screen3FlagDesktop}
                          screen4FlagDesktop={screen4FlagDesktop}
                          screen10FlagDesktop={screen10FlagDesktop}
                          screen11FlagDesktop={screen11FlagDesktop}
                          screen12FlagDesktop={screen12FlagDesktop}
                          screen19FlagDesktop={screen19FlagDesktop}
                          screen25FlagDesktop={screen25FlagDesktop}
                          screen26FlagDesktop={screen26FlagDesktop}
                          screen27FlagDesktop={screen27FlagDesktop}
                          screen28FlagDesktop={screen28FlagDesktop}
                        />
                      </>
                      :
                      <ReactLoading
                        className="margin-auto mt-3 mb-3"
                        type={"bars"}
                        color={"#a10b1d"}
                        height={"10%"}
                        width={"10%"}
                      />
                    }
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
