import React, { useState,useContext } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import {KycMobContext} from "../../layouts/KYCMob"
import { useHistory } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { saveaddressverifications } from "../../services/kyc/KycScreens";
import { saveevent } from "services/kyc/KycScreens";

import backButtonImg from "../../assets/img/kycmobile/backBtn.png";
import closeButtonImg from "../../assets/img/kycmobile/cross_white.png";
import footerImg from "../../assets/img/kycmobile/footer_trademark.png";
import cameraIcon from "../../assets/img/kycmobile/cameraIcon.png";
import uploadIcon from "../../assets/img/kycmobile/uploadIcon.png";

export default function ScreenExistsReviewAdd2(props) {
  const {data} = useContext(KycMobContext)

  let stateData = props.location.state;
  var country = stateData["country"];
  var city = stateData["city"];
  var postal = stateData["postal"];
  var address = stateData["address"];
  const url = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
  const inputFile = React.useRef(null);
  const [dataLoaded, setDataLoaded] = React.useState(true);
  const history = useHistory();
  const [name, setName] = React.useState(null);
  const [fileType, setFileType] = React.useState(null);
  const [message, setMessage] = React.useState("");

  const fileUpload = () => {
    inputFile.current.click();
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  const optionsAddressVerifications = [
    { label: "Utility Bill", value: 1 },
    { label: "Bank Statement", value: 2 },
    { label: "Insurance Document", value: 3 },
    { label: "Resident Permit", value: 4 },
    { label: "Mortgage Statement", value: 5 },
    { label: "Tax Bill", value: 6 },
  ];

  const handleSelectChange = (e) => {
    // console.log("label: ", e.label);
    // console.log("value: ", e.value);
    setFileType(e.value);
  }

  const handleChange = (e) => {
    setDataLoaded(false);
    setMessage("");
    const [f] = e.target.files;
    let MAX_FILE_SIZE = 10240;

    const fileSizeKiloBytes = f.size / 1024;

    if(fileSizeKiloBytes > MAX_FILE_SIZE){
      setMessage("File size is greater than maximum limit");
      setDataLoaded(true);
      return
    }

    let data = {
      fileType: fileType,
      captureMethod: "Upload",
      doc: f,
      country: country,
      city: city,
      postal: postal,
      address: address,
      inq_id: url,
    };

    // console.log("data: ", data);

    saveaddressverifications(data)
      .then((response) => {
        // console.log("response: ", response);
        setDataLoaded(true);
        if (response.data.success) {
          let formdata1 = {
            inq_id: url,
            event_desc: "Document verification submitted",
            passed: 0,
          };
          saveevent(formdata1)
            .then((response) => {
              if (response.data.success) {
                let formdata2 = {
                  inq_id: url,
                  event_desc: "Document verification passed",
                  passed: 1,
                };
                saveevent(formdata2)
                  .then((response) => {
                    if (response.data.success) {
                      history.push("/verify/exists/b/" + url);
                      window.location.reload();
                    }
                  })
                  .catch((error) => {
                    console.log("error: ", error);
                  });
              }
            })
            .catch((error) => {
              console.log("error: ", error);
            });
        } else {
          setMessage(response.data.message);
          setDataLoaded(true);
          return
        }
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  const handleNextCamera = () => {
    history.push({
      pathname: '/verify/exists/add/c/'+url,
      state: {
        fileType: fileType,
        captureMethod: "Live Capture",
        country: country,
        city: city,
        postal: postal,
        address: address,
      }
    });
    window.location.reload();
  }

  const handleCross = () => {
    var ua = window.navigator.userAgent;

    if (ua.indexOf("iPhone") > 0) {
      window.close();
    }
    window.close();
  };

  return (
    <>
      <div className="screenNineteen h-full" style={{fontFamily: data ? data.font_family : "Gilroy"}}>
        <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
          <div className="backArrow">
            <Link to={"/verify/exists/add/a/"+url}>
              <button>
                <img src={backButtonImg} alt="backArrow" className="" />
              </button>
            </Link>
          </div>
          <div className="progressBar w-full py-3 px-12">
            <div className="relative lg:w-8/12 mx-auto">
              <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                <div
                  style={{ width: "85%",backgroundColor:data ? data.progressbar_color:"#a10b1d" }}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center "
                ></div>
              </div>
            </div>
          </div>
          <div className="crossArrow">
            <button onClick={handleCross}>
              <img src={closeButtonImg} alt="crossArrow" className="" />
            </button>
          </div>
        </div>

        {!dataLoaded &&
          <>
            <ReactLoading className="margin-auto mt-3 mb-3 absolute" type={"bars"} style={{ zIndex: "100000", top: "30%", left: "39%", width: "22%", height: "22%", fill: "#a10b1d" }} />
            <div id="overlay" className="fixed w-full h-full top-0 left-0 right-0 bottom-0 cursor-pointer" style={{ backgroundColor: "rgba(0,0,0,0.5)", zIndex: "10000" }}></div>
          </> 
        }

        <div className="mainContent"  >
          <div className="mainText">
            <h1>Which proof of address document will you scan?</h1>
            <p>Please select the document type from the dropdown list.</p>
          </div>

          <div className="py-10 px-12 mt-10">
            <Select
              className="globeSelect"
              styles={customStyles}
              onChange={handleSelectChange}
              options={optionsAddressVerifications}
            />
          </div>

          {message && (
            <section className="form-group mt-1">
              <section className="text-sm alert alert-danger text-center" role="alert">
                {message}
              </section>
            </section>
          )}

          {fileType && 
            <div className="uploadButtons">
              {/* <Link to={{
                pathname: "/kycreturning/addressverif/e/"+url,
                state: {
                  fileType: fileType,
                  captureMethod: "Live Capture",
                  country: country,
                  city: city,
                  postal: postal,
                  address: address,
                },
              }}> */}
                <div className="openCameraButton" onClick={handleNextCamera}>
                  <img className="cameraIcon" src={cameraIcon} alt="camicon" />
                  <p>Use Camera</p>
                </div>
              {/* </Link> */}
              <input type='file' id='file' ref={inputFile} style={{display: 'none'}} onChange={handleChange}/>
              <div className="uploadFileButton" onClick={fileUpload}>
                <img className="uploadIcon" src={uploadIcon} alt="upicon" />
                <p>Upload a File</p>
              </div>
              <p style={{ textAlign: "center", fontSize: "12px", color: "#a8a8a8" }}>(Max file size 10MB)</p>
            </div>
          }
        </div>

        <div className="footer">
          <img className="footerImg" src={footerImg} alt="footer" />
        </div>
      </div>
    </>
  );
}
  
