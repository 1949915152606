import React from "react";
import DataTableNetworkCase from "../../components/Datatables/DataTableNetworkCase";

export default function NetworkCaseManagement() {

  return (
    <>
      <DataTableNetworkCase />
    </>
  );
}
