import React, { useState } from "react";
import { useTable, usePagination, useRowSelect } from "react-table";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import Select from "react-select";
import "react-circular-progressbar/dist/styles.css";
import { Link } from "react-router-dom";
import { showallmembers } from "services/settings/Team";
import ReactLoading from "react-loading";

import createRule from "../../assets/img/createRule.png";
import leftPag from "../../assets/img/leftPag.png";
import rightPag from "../../assets/img/rightPag.png";
import refresh from "../../assets/img/refresh.png";
import searchIcon from "../../assets/img/search-icon.png";
import closeButtonImg from " ../../assets/img/kycmobile/cross_white.png";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <div className="selectBox">
          <label className="inline-flex items-center cursor-pointer text-sm">
            <input
              ref={resolvedRef}
              {...rest}
              id="customCheckLogin"
              type="checkbox"
              className="cursor-pointer form-checkbox border-0 rounded text-blueGray-700 ml-1 mr-2 w-5 h-5 ease-linear transition-all duration-150 selectBoxStyle"
            />
          </label>
        </div>
      </>
    );
  }
);

const IndeterminateCheckboxHeader = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input
          type="checkbox"
          id="test"
          ref={resolvedRef}
          {...rest}
          style={{ display: "none" }}
        />
        <label for="test" className="cursor-pointer">
          Select All
        </label>
      </>
    );
  }
);

function Table({ columns, data }) {
  const color = "light";
  const [filteredData, setFilteredData] = useState(data);
  const [searchWord, setSearchWord] = useState("");
  const [pageNum, setPageNum] = React.useState(1);
  // console.log(filteredData);

  const handleFilter = () => {
    const newFilter = data.filter((value) => {
      return (
        value.member.toLowerCase().includes(searchWord.toLowerCase()) ||
        value.email.toLowerCase().includes(searchWord.toLowerCase())
      );
    });
    setFilteredData(newFilter);
  };

  const handleCross = () => {
    setFilteredData(data);
    setSearchWord("");
  };

  const status = [
    { label: "Status", value: 1 },
    { label: "Online", value: 2 },
    { label: "Offline", value: 3 },
  ];
  const roles = [
    { label: "Roles", value: 1 },
    { label: "Admin", value: 2 },
    { label: "Moderator", value: 3 },
  ];

  const defaultValueStatus = status[0];
  const defaultValueRoles = roles[0];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data: filteredData,
      initialState: { pageIndex: 0 },
    },
    usePagination,
    useRowSelect,
    (hooks) => {}
  );

  const customStylesSelect = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#828282" : "#828282",
      backgroundColor: state.isSelected ? "#EBEDF2" : "#FFF",
      padding: 10,
      fontSize: "14px",
    }),
  };

  return (
    <>
      <div className="casemanagement flex flex-wrap">
        <div className="w-full xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 px-4 teamsTableDiv">
          <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
            <div
              className={
                "relative flex flex-col min-w-0 break-words w-full mb-6 " +
                (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
              }
            >
              <div className="rounded-t mb-0 py-4 border-0">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full max-w-full flex-grow flex-1 mt-8">
                    <Form
                      class="filtersTable"
                      // onSubmit={(e) => handleLogin(e)}
                      // ref={c => {form = c;}}
                    >
                      <div className="flex justify-between items-start justify-center flex-wrap teamSearchBar">
                        <div className="flex justify-between items-center justify-center flex-wrap">
                          <div className="searchInputTables mr-3">
                            <div className="flex items-center">
                              <div className="fieldSearchTables relative w-full mb-3">
                                <label className="label block font-semibold text-black-600 text-sm mb-2">
                                  <img
                                    className="searchIcon"
                                    src={searchIcon}
                                  />
                                </label>
                                <Input
                                  type="text"
                                  value={searchWord}
                                  style={{ paddingRight: "40px" }}
                                  onChange={(e) =>
                                    setSearchWord(e.target.value)
                                  }
                                  className="searchFilter input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-xs shadow focus:outline-none focus:ring  ease-linear transition-all duration-150"
                                  // placeholder="Search by name or email"
                                  // value={email}
                                  // onChange={(e) => setEmail(e.target.value)}
                                  // validations={[required, validEmail]}
                                />
                              </div>
                              <div
                                className="label block font-semibold text-black-600 text-sm mb-2 cursor-pointer"
                                style={{ marginLeft: "-30px", zIndex: "10" }}
                                onClick={handleCross}
                              >
                                <img src={closeButtonImg} style={{width:"80%"}} alt="Delete Person" />
                              </div>
                            </div>
                            <div className="searchButton mb-1">
                              <button
                                type="button"
                                className="py-2 px-12 rounded-lg font-semibold text-white-400 buttonPink mb-2 text-xs"
                                onClick={handleFilter}
                              >
                                Search
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="flex items-start flex-wrap">
                          <div className="mr-3">
                            <div className="field dropdown relative w-full mb-3">
                              <Select
                                styles={customStylesSelect}
                                options={status}
                                isSearchable={false}
                                defaultValue={defaultValueStatus}
                              />
                            </div>
                          </div>
                          <div className="mr-3">
                            <div className="field dropdown relative w-full mb-3">
                              <Select
                                styles={customStylesSelect}
                                options={roles}
                                isSearchable={false}
                                defaultValue={defaultValueRoles}
                              />
                            </div>
                          </div>
                          <div className="flex px-4 py-2 cursor-pointer">
                            <img
                              src={createRule}
                              alt="export icon"
                              className="mr-3"
                              style={{ width: "15px", height: "15px" }}
                            />
                            <Link to="/settings/team/add">
                              <h4
                                className="font-semibold text-xs"
                                style={{ color: "#a10b1d" }}
                              >
                                Add team member
                              </h4>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
              <div className="pagination px-12 py-4 flex flex-row justify-between items-center mb-6">
                {/* <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  {'<<'}
                </button>{' '} */}
                <div className="flex flex-row items-center">
                  <button
                    className="mr-2"
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    <img
                      src={leftPag}
                      alt="previous icon"
                      style={{ width: "20px", height: "20px",outline:"none" }}
                    />
                  </button>
                  <span className="mr-2 text-xs">
                    Page
                    <strong className="ml-2">
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>
                  </span>
                  <button onClick={() => nextPage()} disabled={!canNextPage}>
                    <img
                      src={rightPag}
                      alt="previous icon"
                      style={{ width: "20px", height: "20px",outline:"none" }}
                    />
                  </button>
                </div>
                <div className="flex flex-row items-center cursor-pointer">
                  <p className="font-bold text-xs mr-3">Go to page</p>
                  <input
                    type="number"
                    defaultValue={pageIndex + 1}
                    value={pageNum}
                    onChange={(e) => {
                      const value = Math.max(1, Math.min(pageOptions.length, Number(e.target.value)));
                      const page = value ? Number(value) - 1 : 0;
                      gotoPage(page);
                      setPageNum(value);
                    }}
                    className="cursor-pointer border-0 rounded text-blueGray-700 ml-1 mr-2 w-16 h-8 ease-linear transition-all duration-150"
                  />
                </div>
                <div className="flex flex-row items-center cursor-pointer">
                  <p className="font-bold text-xs mr-3">Records per page</p>
                  <input
                    type="number"
                    min={1}
                    value={pageSize}
                    onChange={(e) => {
                      if (e.target.value === "" || e.target.value === null || e.target.value < 1) {
                        setPageSize(Number(1));
                      } else {
                        setPageSize(Number(e.target.value));
                      }
                    }}
                    className="cursor-pointer border-0 rounded text-blueGray-700 ml-1 mr-2 w-16 h-8 ease-linear transition-all duration-150"
                  />
                  <button
                    // onClick={() => }
                    className="px-2 py-2 bg-white rounded-lg"
                    onClick={() => window.location.reload()}
                  >
                    <img
                      src={refresh}
                      alt="refresh"
                      style={{ width: "15px", height: "15px" }}
                    />
                  </button>
                </div>
              </div>

              <div className="block w-full overflow-x-auto">
                {/* Projects table */}
                <table
                  {...getTableProps()}
                  className="items-center w-full bg-transparent border-collapse"
                >
                  <thead className="border border-solid border-t-0 border-l-0 border-r-0 border-blueGray-300">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps()}
                            className={
                              "px-6 align-middle py-3 text-xs whitespace-nowrap font-semibold text-center text-black-600 teamSettingTableheading"
                            }
                          >
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} className="cursor-pointer  ">
                          {row.cells.map((cell) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center teamSettingTable"
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function DataTableComponent() {
  const [data, setData] = React.useState([]);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  
  React.useEffect(() => {
    showallmembers()
      .then((response) => {
        setData(response.data.members);
        setDataLoaded(true);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  const columns = [
    {
      Header: "Member",
      accessor: "member",
      Cell: (tableProps) => (
        <>
          <Link
            to={{
              pathname: "/settings/team/edit",
              param: tableProps.row.original.id,
            }}
          >
            <div className="flex flex-col">
              <img
                alt="..."
                src={(tableProps.row.original.image !== "") ? tableProps.row.original.image : require("assets/img/ProfilePicture.png").default}
                className="rounded-full h-auto align-middle border-none max-w-100-px mx-auto"
                style={
                  tableProps.row.original.status.includes("Online")
                    ? { border: "4px solid #15E49A",width:"60%" }
                    : { border: "0",width:"60%" }
                    
                }
              />
              <span className={"text-xs font-bold text-black-600"}>
                {tableProps.row.original.member}
              </span>
            </div>
          </Link>
        </>
      ),
    },
    {
      Header: "Role",
      accessor: "role",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Last Login",
      accessor: "lastLogin",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (tableProps) => (
        <div className="statusGrid flex flex-col max-w-120-px mx-auto">
          {tableProps.row.original.status.includes("Online") && (
            <button className="w-full px-4 py-2 rounded-lg font-semibold text-white-400 buttonGreen">
              Online
            </button>
          )}
          {tableProps.row.original.status.includes("Offline") && (
            <button className="w-full px-4 py-2 rounded-lg font-semibold text-white-400 buttonDarkGrey">
              Offline
            </button>
          )}
        </div>
      ),
    },
  ];

  return <>
    {dataLoaded ? 
      <Table data={data} columns={columns} />
      :
      <ReactLoading
        className="margin-auto mt-3 mb-3"
        type={"bars"}
        color={"#a10b1d"}
        height={"50px"}
        width={"50px"}
      />
    }
    </>;
}

export default DataTableComponent;
