import React from "react";
import { Link, useParams } from "react-router-dom";
import { useTable, usePagination, useRowSelect } from 'react-table'
import ReactApexChart from "react-apexcharts";
import { getSmartContractData } from "services/onchain/Onchain";
import ReactLoading from "react-loading";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";

export default function OnchainSmartContract(props) {
  const { id, network, typename } = useParams();
  const [dataLoaded, setDataLoaded] = React.useState(false);
  let stateData = props.location.state;
  let today = new Date();
  let today_temp = new Date();
  let lastMonth = new Date(today_temp.setMonth(today_temp.getMonth() - 1));
  const [dateRange, setDateRange] = React.useState(stateData ? stateData["dateRange"] : [lastMonth, today]);
  const [smartStatsData, setSmartStatsData] = React.useState([]);
  const [addressBalanceData, setAddressBalanceData] = React.useState([]);
  const [callCountByDateGraphSeries, setCallCountByDateGraphSeries] = React.useState([]);
  const [callCountByDateGraphOptions, setCallCountByDateGraphOptions] = React.useState({
    chart: {
      height: 350,
      type: 'bar',
      stacked: true,
    },
    stroke: {
      width: [0, 4]
    },
      dataLabels: {
      enabled: true,
      enabledOnSeries: [1]
    },
    labels: ["2021-06-08", "2021-06-09", "2021-06-10", "2021-06-11", "2021-06-12", "2021-06-13"],
    xaxis: {
      type: 'datetime'
    },
    yaxis: [{
      title: {
        text: 'Uniq Callers',
      },
    }]
  });
  const [uniqueCallersByDateGraphSeries, setUniqueCallersByDateGraphSeries] = React.useState([]);
  const [uniqueCallersByDateGraphOptions, setUniqueCallersByDateGraphOptions] = React.useState({
    chart: {
      height: 350,
      type: 'bar',
      stacked: true,
    },
    stroke: {
      width: [0, 4]
    },
      dataLabels: {
      enabled: true,
      enabledOnSeries: [1]
    },
    labels: ["2021-06-08", "2021-06-09", "2021-06-10", "2021-06-11", "2021-06-12", "2021-06-13"],
    xaxis: {
      type: 'datetime'
    },
    yaxis: [{
      title: {
        text: 'Uniq Callers',
      },
    }]
  });
  const [currencyData, setCurrencyData] = React.useState([]);

  const smartStatsColumn = [
    {
      Header: 'Metric',
      accessor: 'metric'
    }, 
    {
      Header: 'Value',
      accessor: 'value'
    }, 
  ];

  const addressBalanceColumn = [
    {
      Header: 'Currency',
      accessor: 'currency'
    }, 
    {
      Header: 'Balance',
      accessor: 'balance'
    }, 
  ];
  
  const currencyColumn=[
    {
      Header: 'Currency',
      accessor: 'currency'
    },
    {
      Header: 'Receive',
      accessor: 'receive'
    },
    {
      Header: 'Receice ($)',
      accessor: 'receive_usd'
    },
    {
      Header: 'Tx\'s',
      accessor: 'tx_in'
    },
    {
      Header: 'Send',
      accessor: 'send'
    },
    {
      Header: 'Send ($)',
      accessor: 'send_usd'
    },
    {
      Header: 'Tx\'s',
      accessor: 'tx_out'
    }
  ];

  const bitcoinWalletArray = [
    "bitcoin",
    "litecoin",
    "dogecoin",
    "dash",
    "zcash",
    "bitcash",
    "bitcoinsv",
    "ethereum",
    "ethclassic",
    "ethclassic_reorg",
    "ethpow",
    "algorand",
    "bsc",
    "celo_rc1",
    "conflux_hydra",
    "eos",
    "tron",
    "solana",
    "matic",
    "velas",
    "klaytn",
    "elrond",
    "avalanche",
    "fantom",
    "moonbeam",
    "cronos",
    "flow",
    "everscale",
    "cardano",
    "algorand_testnet",
    "algorand_beatnet",
    "celo_alfajores",
    "bsc_testnet",
    "goerli",
    "celo_baklava",
    "conflux_oceanus",
    "medalla",
    "velas_testnet",
    "crypto_testnet",
    "binance",
    "consmoshub",
    "crypto_mainnet",
    "hemidall",
    "terra",
    "diem_testnet",
    "libra_testnet",
    "stellar",
    "ripple",
    "hedera",
    "tezos",
    "eth2",
    "filecoin",
  ];

  const [optionsLimit, setOptionsLimit] = React.useState([
    {label: "10", value: 10},
    {label: "15", value: 15},
    {label: "20", value: 20},
  ]);
  const [defaultValueLimit, setDefaultValueLimit] = React.useState(optionsLimit[0]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 })
  }

  React.useEffect(() => {
    if (id && network && typename) {
      let formdata = {
        "type_name": typename,
        "address": id,
        "network": network,
        "date_from": dateRange[0].toISOString().split('T')[0],
        "date_till": dateRange[1].toISOString().split('T')[0],
        "limit": defaultValueLimit.value,
        "nativeCurrency": "string",
        "offset": 0
      };

      getSmartContractData(formdata).then((response) => {
        if (response.data.success) {
          if (network == "cardano") {
            if (typename == "Address") {
              if (response.data.response.In_Outbound_ADA_by_time.data) {
                let data = response.data.response.In_Outbound_ADA_by_time.data;
                let statsData = [
                  {
                    metric: "Inputs in Transactions",
                    value: data.cardano.inputs[0].count
                  },
                  {
                    metric: "Outputs in Transactions",
                    value: data.cardano.outputs[0].count
                  },
                  {
                    metric: "First transaction date",
                    value: data.cardano.inputs[0].min_date
                  },
                  {
                    metric: "Last transaction date",
                    value: data.cardano.inputs[0].max_date
                  },
                  {
                    metric: "Received in Outputs",
                    value: data.cardano.outputs[0].value
                  },
                  {
                    metric: "Received in Outputs (USD)",
                    value: data.cardano.outputs[0].value_usd
                  },
                  {
                    metric: "Spent to Inputs",
                    value: data.cardano.inputs[0].value
                  },
                  {
                    metric: "Spent to Inputs (USD)",
                    value: data.cardano.inputs[0].value_usd
                  },
                  {
                    metric: "Balance (unspent outputs)",
                    value: data.cardano.outputs[0].value - data.cardano.inputs[0].value
                  }
                ];
    
                setSmartStatsData(statsData);
              }

              if (response.data.response.Address_Statistics.data) {
                let data = response.data.response.Address_Statistics.data;
    
                let seriesData = [
                  {
                    name: 'Receive',
                    type: 'column',
                    data: data.cardano.outputs.map((ele) => {
                      return ele.value
                    })
                  },
                  {
                    name: 'Send',
                    type: 'column',
                    data: data.cardano.inputs.map((ele) => {
                      return ele.value
                    })
                  }
                ];
    
                let optionsData = {
                  chart: {
                    height: 350,
                    type: 'bar',
                    stacked: true,
                  },
                  stroke: {
                    width: [0, 4]
                  },
                    dataLabels: {
                    enabled: true,
                    enabledOnSeries: [1]
                  },
                  labels: data.cardano.inputs.map((ele) => {
                    return ele.date.date
                  }),
                  xaxis: {
                    type: 'datetime'
                  },
                  yaxis: [{
                    title: {
                      text: 'Amount, ADA',
                    },
                  }]
                };
    
                setUniqueCallersByDateGraphSeries(seriesData);
                setUniqueCallersByDateGraphOptions(optionsData);
              }
            }
          } else if (network == "ethereum") {
            if (typename == "SmartContract") {
              if (response.data.response.Smart_Contract_Statistics.data) {
                let data = response.data.response.Smart_Contract_Statistics.data;
                let statsData = [
                  {
                    metric: "Calls Count",
                    value: data.ethereum.smartContractCalls[0].count
                  },
                  {
                    metric: "External calls",
                    value: data.ethereum.smartContractCalls[0].external_calls
                  },
                  {
                    metric: "Internal calls",
                    value: data.ethereum.smartContractCalls[0].internal_calls
                  },
                  {
                    metric: "Transactions count",
                    value: data.ethereum.smartContractCalls[0].txs
                  },
                  {
                    metric: "Uniq Callers",
                    value: data.ethereum.smartContractCalls[0].callers
                  },
                  {
                    metric: "Days contract called",
                    value: data.ethereum.smartContractCalls[0].days
                  },
                  {
                    metric: "Last transaction time",
                    value: data.ethereum.smartContractCalls[0].max_time
                  },
                  {
                    metric: "Created",
                    value: data.ethereum.smartContractCalls[0].created + " in block " + data.ethereum.smartContractCalls[0].created_block
                  },
                  {
                    metric: "Create TX",
                    value: data.ethereum.smartContractCalls[0].created_tx
                  },
                  {
                    metric: "Creator",
                    value: data.ethereum.smartContractCalls[0].created_by
                  },
                ];
    
                setSmartStatsData(statsData);
              }
    
              if (response.data.response.Address_balance_by_currencies.data) {
                let data = response.data.response.Address_balance_by_currencies.data;
    
                if (data.ethereum.address[0].balances) {
                  let addCurData = data.ethereum.address[0].balances.map((ele) => (
                    {
                      currency: ele.currency.symbol,
                      balance: ele.value
                    }
                  ));
    
                  setAddressBalanceData(addCurData);
                }
              }
    
              if (response.data.response.Calls_Count_By_Date.data) {
                let data = response.data.response.Calls_Count_By_Date.data;
    
                let seriesData = [
                  {
                    name: 'External Calls',
                    type: 'column',
                    data: data.ethereum.smartContractCalls.map((ele) => {
                      return ele.external_calls
                    })
                  },
                  {
                    name: 'Internal Calls',
                    type: 'column',
                    data: data.ethereum.smartContractCalls.map((ele) => {
                      return ele.internal_calls
                    })
                  },  
                  {
                    name: 'Tx Coun',
                    type: 'line',
                    data: data.ethereum.smartContractCalls.map((ele) => {
                      return ele.txs
                    })
                  }
                ];
    
                let optionsData = {
                  chart: {
                    height: 350,
                    type: 'bar',
                    stacked: true,
                  },
                  stroke: {
                    width: [0, 4]
                  },
                    dataLabels: {
                    enabled: true,
                    enabledOnSeries: [1]
                  },
                  labels: data.ethereum.smartContractCalls.map((ele) => {
                    return ele.date.date
                  }),
                  xaxis: {
                    type: 'datetime'
                  },
                  yaxis: [{
                    title: {
                      text: 'Uniq Callers',
                    },
                  }]
                };
    
                setCallCountByDateGraphSeries(seriesData);
                setCallCountByDateGraphOptions(optionsData);
              }
    
              if (response.data.response.Unique_Callers_By_Date.data) {
                let data = response.data.response.Unique_Callers_By_Date.data;
    
                let seriesData = [
                  {
                    name: 'External Calls',
                    type: 'column',
                    data: data.ethereum.smartContractCalls.map((ele) => {
                      return ele.external_calls
                    })
                  },
                  {
                    name: 'Internal Calls',
                    type: 'column',
                    data: data.ethereum.smartContractCalls.map((ele) => {
                      return ele.internal_calls
                    })
                  },  
                  {
                    name: 'Tx Count',
                    type: 'line',
                    data: data.ethereum.smartContractCalls.map((ele) => {
                      return ele.txs
                    })
                  }
                ];
    
                let optionsData = {
                  chart: {
                    height: 350,
                    type: 'bar',
                    stacked: true,
                  },
                  stroke: {
                    width: [0, 4]
                  },
                    dataLabels: {
                    enabled: true,
                    enabledOnSeries: [1]
                  },
                  labels: data.ethereum.smartContractCalls.map((ele) => {
                    return ele.date.date
                  }),
                  xaxis: {
                    type: 'datetime'
                  },
                  yaxis: [{
                    title: {
                      text: 'Uniq Callers',
                    },
                  }]
                };
    
                setUniqueCallersByDateGraphSeries(seriesData);
                setUniqueCallersByDateGraphOptions(optionsData);
              }
    
              if (response.data.response.Currencies_sent_received.data) {
                let data = response.data.response.Currencies_sent_received.data;
    
                if (data.ethereum.transfers.length > 0) {
                  let curData = data.ethereum.transfers.map((ele) => (
                    {
                      currency: ele.currency.symbol,
                      receive: ele.sum_in,
                      receive_usd: ele.sum_in_usd,
                      tx_in: ele.count_in,
                      send: ele.sum_out,
                      send_usd: ele.sum_out_usd,
                      tx_out: ele.count_out,
                    }
                  ));
    
                  setCurrencyData(curData);
                }
              }
            } else if (typename == "Address") {
              if (response.data.response.Currencies_sent_received.data) {
                let data = response.data.response.Currencies_sent_received.data;
    
                if (data.ethereum.transfers.length > 0) {
                  let curData = data.ethereum.transfers.map((ele) => (
                    {
                      currency: ele.currency.symbol,
                      receive: ele.sum_in,
                      receive_usd: ele.sum_in_usd,
                      tx_in: ele.count_in,
                      send: ele.sum_out,
                      send_usd: ele.sum_out_usd,
                      tx_out: ele.count_out,
                    }
                  ));
    
                  setCurrencyData(curData);
                }
              }
  
              if (response.data.response.Address_balance_by_currencies.data) {
                let data = response.data.response.Address_balance_by_currencies.data;
    
                if (data.ethereum.address[0].balances) {
                  let addCurData = data.ethereum.address[0].balances.map((ele) => (
                    {
                      currency: ele.currency.symbol,
                      balance: ele.value
                    }
                  ));
    
                  setAddressBalanceData(addCurData);
                }
              }
  
              if (response.data.response.In_Outbound_transfer_count_by_date.data) {
                let data = response.data.response.In_Outbound_transfer_count_by_date.data;
    
                let seriesData = [
                  {
                    name: 'Receive Count',
                    type: 'column',
                    data: data.ethereum.transfers.map((ele) => {
                      return ele.in_count
                    })
                  },
                  {
                    name: 'Send Count',
                    type: 'column',
                    data: data.ethereum.transfers.map((ele) => {
                      return ele.out_count
                    })
                  },
                ];
    
                let optionsData = {
                  chart: {
                    height: 350,
                    type: 'bar',
                    stacked: true,
                  },
                  stroke: {
                    width: [0, 4]
                  },
                    dataLabels: {
                    enabled: true,
                    enabledOnSeries: [1]
                  },
                  labels: data.ethereum.transfers.map((ele) => {
                    return ele.date.date
                  }),
                  xaxis: {
                    type: 'datetime'
                  },
                  yaxis: [{
                    title: {
                      text: 'Transfer Count',
                    },
                  }]
                };
    
                setCallCountByDateGraphSeries(seriesData);
                setCallCountByDateGraphOptions(optionsData);
              }
  
              if (response.data.response.Smart_contract_calls_by_date.data) {
                let data = response.data.response.Smart_contract_calls_by_date.data;
    
                let seriesData = [
                  {
                    name: 'Calls Count',
                    type: 'column',
                    data: data.ethereum.smartContractCalls.map((ele) => {
                      return ele.count
                    })
                  },
                ];
    
                let optionsData = {
                  chart: {
                    height: 350,
                    type: 'bar',
                    stacked: true,
                  },
                  stroke: {
                    width: [0, 4]
                  },
                    dataLabels: {
                    enabled: true,
                    enabledOnSeries: [1]
                  },
                  labels: data.ethereum.smartContractCalls.map((ele) => {
                    return ele.date.date
                  }),
                  xaxis: {
                    type: 'datetime'
                  },
                  yaxis: [{
                    title: {
                      text: 'Calls Count',
                    },
                  }]
                };
    
                setUniqueCallersByDateGraphSeries(seriesData);
                setUniqueCallersByDateGraphOptions(optionsData);
              }
            }
          }

          setDataLoaded(true);
        }
      }).catch((error) => {
        setDataLoaded(true);
        console.log("error: ", error);
      });
    }
  }, [id, network, typename]);

  const updateResults = (date) => {
    setDateRange(date);
    if (date[0] && date[1]) {
      setDataLoaded(false);
      let formdata = {
        "type_name": typename,
        "address": id,
        "network": network,
        "date_from": date[0].toISOString().split('T')[0],
        "date_till": date[1].toISOString().split('T')[0],
        "limit": defaultValueLimit.value,
        "nativeCurrency": "string",
        "offset": 0
      };

      getSmartContractData(formdata).then((response) => {
        if (response.data.success) {
          if (network == "cardano") {
            if (typename == "Address") {
              if (response.data.response.In_Outbound_ADA_by_time.data) {
                let data = response.data.response.In_Outbound_ADA_by_time.data;
                let statsData = [
                  {
                    metric: "Inputs in Transactions",
                    value: data.cardano.inputs[0].count
                  },
                  {
                    metric: "Outputs in Transactions",
                    value: data.cardano.outputs[0].count
                  },
                  {
                    metric: "First transaction date",
                    value: data.cardano.inputs[0].min_date
                  },
                  {
                    metric: "Last transaction date",
                    value: data.cardano.inputs[0].max_date
                  },
                  {
                    metric: "Received in Outputs",
                    value: data.cardano.outputs[0].value
                  },
                  {
                    metric: "Received in Outputs (USD)",
                    value: data.cardano.outputs[0].value_usd
                  },
                  {
                    metric: "Spent to Inputs",
                    value: data.cardano.inputs[0].value
                  },
                  {
                    metric: "Spent to Inputs (USD)",
                    value: data.cardano.inputs[0].value_usd
                  },
                  {
                    metric: "Balance (unspent outputs)",
                    value: data.cardano.outputs[0].value - data.cardano.inputs[0].value
                  }
                ];
    
                setSmartStatsData(statsData);
              }

              if (response.data.response.Address_Statistics.data) {
                let data = response.data.response.Address_Statistics.data;
    
                let seriesData = [
                  {
                    name: 'Receive',
                    type: 'column',
                    data: data.cardano.outputs.map((ele) => {
                      return ele.value
                    })
                  },
                  {
                    name: 'Send',
                    type: 'column',
                    data: data.cardano.inputs.map((ele) => {
                      return ele.value
                    })
                  }
                ];
    
                let optionsData = {
                  chart: {
                    height: 350,
                    type: 'bar',
                    stacked: true,
                  },
                  stroke: {
                    width: [0, 4]
                  },
                    dataLabels: {
                    enabled: true,
                    enabledOnSeries: [1]
                  },
                  labels: data.cardano.inputs.map((ele) => {
                    return ele.date.date
                  }),
                  xaxis: {
                    type: 'datetime'
                  },
                  yaxis: [{
                    title: {
                      text: 'Amount, ADA',
                    },
                  }]
                };
    
                setUniqueCallersByDateGraphSeries(seriesData);
                setUniqueCallersByDateGraphOptions(optionsData);
              }
            }
          } else if (network == "ethereum") {
            if (typename == "SmartContract") {
              if (response.data.response.Smart_Contract_Statistics.data) {
                let data = response.data.response.Smart_Contract_Statistics.data;
                let statsData = [
                  {
                    metric: "Calls Count",
                    value: data.ethereum.smartContractCalls[0].count
                  },
                  {
                    metric: "External calls",
                    value: data.ethereum.smartContractCalls[0].external_calls
                  },
                  {
                    metric: "Internal calls",
                    value: data.ethereum.smartContractCalls[0].internal_calls
                  },
                  {
                    metric: "Transactions count",
                    value: data.ethereum.smartContractCalls[0].txs
                  },
                  {
                    metric: "Uniq Callers",
                    value: data.ethereum.smartContractCalls[0].callers
                  },
                  {
                    metric: "Days contract called",
                    value: data.ethereum.smartContractCalls[0].days
                  },
                  {
                    metric: "Last transaction time",
                    value: data.ethereum.smartContractCalls[0].max_time
                  },
                  {
                    metric: "Created",
                    value: data.ethereum.smartContractCalls[0].created + " in block " + data.ethereum.smartContractCalls[0].created_block
                  },
                  {
                    metric: "Create TX",
                    value: data.ethereum.smartContractCalls[0].created_tx
                  },
                  {
                    metric: "Creator",
                    value: data.ethereum.smartContractCalls[0].created_by
                  },
                ];
    
                setSmartStatsData(statsData);
              }
    
              if (response.data.response.Address_balance_by_currencies.data) {
                let data = response.data.response.Address_balance_by_currencies.data;
    
                if (data.ethereum.address[0].balances) {
                  let addCurData = data.ethereum.address[0].balances.map((ele) => (
                    {
                      currency: ele.currency.symbol,
                      balance: ele.value
                    }
                  ));
    
                  setAddressBalanceData(addCurData);
                }
              }
    
              if (response.data.response.Calls_Count_By_Date.data) {
                let data = response.data.response.Calls_Count_By_Date.data;
    
                let seriesData = [
                  {
                    name: 'External Calls',
                    type: 'column',
                    data: data.ethereum.smartContractCalls.map((ele) => {
                      return ele.external_calls
                    })
                  },
                  {
                    name: 'Internal Calls',
                    type: 'column',
                    data: data.ethereum.smartContractCalls.map((ele) => {
                      return ele.internal_calls
                    })
                  },  
                  {
                    name: 'Tx Coun',
                    type: 'line',
                    data: data.ethereum.smartContractCalls.map((ele) => {
                      return ele.txs
                    })
                  }
                ];
    
                let optionsData = {
                  chart: {
                    height: 350,
                    type: 'bar',
                    stacked: true,
                  },
                  stroke: {
                    width: [0, 4]
                  },
                    dataLabels: {
                    enabled: true,
                    enabledOnSeries: [1]
                  },
                  labels: data.ethereum.smartContractCalls.map((ele) => {
                    return ele.date.date
                  }),
                  xaxis: {
                    type: 'datetime'
                  },
                  yaxis: [{
                    title: {
                      text: 'Uniq Callers',
                    },
                  }]
                };
    
                setCallCountByDateGraphSeries(seriesData);
                setCallCountByDateGraphOptions(optionsData);
              }
    
              if (response.data.response.Unique_Callers_By_Date.data) {
                let data = response.data.response.Unique_Callers_By_Date.data;
    
                let seriesData = [
                  {
                    name: 'External Calls',
                    type: 'column',
                    data: data.ethereum.smartContractCalls.map((ele) => {
                      return ele.external_calls
                    })
                  },
                  {
                    name: 'Internal Calls',
                    type: 'column',
                    data: data.ethereum.smartContractCalls.map((ele) => {
                      return ele.internal_calls
                    })
                  },  
                  {
                    name: 'Tx Count',
                    type: 'line',
                    data: data.ethereum.smartContractCalls.map((ele) => {
                      return ele.txs
                    })
                  }
                ];
    
                let optionsData = {
                  chart: {
                    height: 350,
                    type: 'bar',
                    stacked: true,
                  },
                  stroke: {
                    width: [0, 4]
                  },
                    dataLabels: {
                    enabled: true,
                    enabledOnSeries: [1]
                  },
                  labels: data.ethereum.smartContractCalls.map((ele) => {
                    return ele.date.date
                  }),
                  xaxis: {
                    type: 'datetime'
                  },
                  yaxis: [{
                    title: {
                      text: 'Uniq Callers',
                    },
                  }]
                };
    
                setUniqueCallersByDateGraphSeries(seriesData);
                setUniqueCallersByDateGraphOptions(optionsData);
              }
    
              if (response.data.response.Currencies_sent_received.data) {
                let data = response.data.response.Currencies_sent_received.data;
    
                if (data.ethereum.transfers.length > 0) {
                  let curData = data.ethereum.transfers.map((ele) => (
                    {
                      currency: ele.currency.symbol,
                      receive: ele.sum_in,
                      receive_usd: ele.sum_in_usd,
                      tx_in: ele.count_in,
                      send: ele.sum_out,
                      send_usd: ele.sum_out_usd,
                      tx_out: ele.count_out,
                    }
                  ));
    
                  setCurrencyData(curData);
                }
              }
            } else if (typename == "Address") {
              if (response.data.response.Currencies_sent_received.data) {
                let data = response.data.response.Currencies_sent_received.data;
    
                if (data.ethereum.transfers.length > 0) {
                  let curData = data.ethereum.transfers.map((ele) => (
                    {
                      currency: ele.currency.symbol,
                      receive: ele.sum_in,
                      receive_usd: ele.sum_in_usd,
                      tx_in: ele.count_in,
                      send: ele.sum_out,
                      send_usd: ele.sum_out_usd,
                      tx_out: ele.count_out,
                    }
                  ));
    
                  setCurrencyData(curData);
                }
              }
  
              if (response.data.response.Address_balance_by_currencies.data) {
                let data = response.data.response.Address_balance_by_currencies.data;
    
                if (data.ethereum.address[0].balances) {
                  let addCurData = data.ethereum.address[0].balances.map((ele) => (
                    {
                      currency: ele.currency.symbol,
                      balance: ele.value
                    }
                  ));
    
                  setAddressBalanceData(addCurData);
                }
              }
  
              if (response.data.response.In_Outbound_transfer_count_by_date.data) {
                let data = response.data.response.In_Outbound_transfer_count_by_date.data;
    
                let seriesData = [
                  {
                    name: 'Receive Count',
                    type: 'column',
                    data: data.ethereum.transfers.map((ele) => {
                      return ele.in_count
                    })
                  },
                  {
                    name: 'Send Count',
                    type: 'column',
                    data: data.ethereum.transfers.map((ele) => {
                      return ele.out_count
                    })
                  },
                ];
    
                let optionsData = {
                  chart: {
                    height: 350,
                    type: 'bar',
                    stacked: true,
                  },
                  stroke: {
                    width: [0, 4]
                  },
                    dataLabels: {
                    enabled: true,
                    enabledOnSeries: [1]
                  },
                  labels: data.ethereum.transfers.map((ele) => {
                    return ele.date.date
                  }),
                  xaxis: {
                    type: 'datetime'
                  },
                  yaxis: [{
                    title: {
                      text: 'Transfer Count',
                    },
                  }]
                };
    
                setCallCountByDateGraphSeries(seriesData);
                setCallCountByDateGraphOptions(optionsData);
              }
  
              if (response.data.response.Smart_contract_calls_by_date.data) {
                let data = response.data.response.Smart_contract_calls_by_date.data;
    
                let seriesData = [
                  {
                    name: 'Calls Count',
                    type: 'column',
                    data: data.ethereum.smartContractCalls.map((ele) => {
                      return ele.count
                    })
                  },
                ];
    
                let optionsData = {
                  chart: {
                    height: 350,
                    type: 'bar',
                    stacked: true,
                  },
                  stroke: {
                    width: [0, 4]
                  },
                    dataLabels: {
                    enabled: true,
                    enabledOnSeries: [1]
                  },
                  labels: data.ethereum.smartContractCalls.map((ele) => {
                    return ele.date.date
                  }),
                  xaxis: {
                    type: 'datetime'
                  },
                  yaxis: [{
                    title: {
                      text: 'Calls Count',
                    },
                  }]
                };
    
                setUniqueCallersByDateGraphSeries(seriesData);
                setUniqueCallersByDateGraphOptions(optionsData);
              }
            }
          }

          setDataLoaded(true);
        }
      }).catch((error) => {
        setDataLoaded(true);
        console.log("error: ", error);
      });
    }
  }

  const handleSelectChange = (e) => {
    setDefaultValueLimit(e);
    setDataLoaded(false);
    let formdata = {
      "type_name": typename,
      "address": id,
      "network": network,
      "date_from": dateRange[0].toISOString().split('T')[0],
      "date_till": dateRange[1].toISOString().split('T')[0],
      "limit": e.value,
      "nativeCurrency": "string",
      "offset": 0
    };

    getSmartContractData(formdata).then((response) => {
      if (response.data.success) {
        if (network == "cardano") {
          if (typename == "Address") {
            if (response.data.response.In_Outbound_ADA_by_time.data) {
              let data = response.data.response.In_Outbound_ADA_by_time.data;
              let statsData = [
                {
                  metric: "Inputs in Transactions",
                  value: data.cardano.inputs[0].count
                },
                {
                  metric: "Outputs in Transactions",
                  value: data.cardano.outputs[0].count
                },
                {
                  metric: "First transaction date",
                  value: data.cardano.inputs[0].min_date
                },
                {
                  metric: "Last transaction date",
                  value: data.cardano.inputs[0].max_date
                },
                {
                  metric: "Received in Outputs",
                  value: data.cardano.outputs[0].value
                },
                {
                  metric: "Received in Outputs (USD)",
                  value: data.cardano.outputs[0].value_usd
                },
                {
                  metric: "Spent to Inputs",
                  value: data.cardano.inputs[0].value
                },
                {
                  metric: "Spent to Inputs (USD)",
                  value: data.cardano.inputs[0].value_usd
                },
                {
                  metric: "Balance (unspent outputs)",
                  value: data.cardano.outputs[0].value - data.cardano.inputs[0].value
                }
              ];
  
              setSmartStatsData(statsData);
            }

            if (response.data.response.Address_Statistics.data) {
              let data = response.data.response.Address_Statistics.data;
  
              let seriesData = [
                {
                  name: 'Receive',
                  type: 'column',
                  data: data.cardano.outputs.map((ele) => {
                    return ele.value
                  })
                },
                {
                  name: 'Send',
                  type: 'column',
                  data: data.cardano.inputs.map((ele) => {
                    return ele.value
                  })
                }
              ];
  
              let optionsData = {
                chart: {
                  height: 350,
                  type: 'bar',
                  stacked: true,
                },
                stroke: {
                  width: [0, 4]
                },
                  dataLabels: {
                  enabled: true,
                  enabledOnSeries: [1]
                },
                labels: data.cardano.inputs.map((ele) => {
                  return ele.date.date
                }),
                xaxis: {
                  type: 'datetime'
                },
                yaxis: [{
                  title: {
                    text: 'Amount, ADA',
                  },
                }]
              };
  
              setUniqueCallersByDateGraphSeries(seriesData);
              setUniqueCallersByDateGraphOptions(optionsData);
            }
          }
        } else if (network == "ethereum") {
          if (typename == "SmartContract") {
            if (response.data.response.Smart_Contract_Statistics.data) {
              let data = response.data.response.Smart_Contract_Statistics.data;
              let statsData = [
                {
                  metric: "Calls Count",
                  value: data.ethereum.smartContractCalls[0].count
                },
                {
                  metric: "External calls",
                  value: data.ethereum.smartContractCalls[0].external_calls
                },
                {
                  metric: "Internal calls",
                  value: data.ethereum.smartContractCalls[0].internal_calls
                },
                {
                  metric: "Transactions count",
                  value: data.ethereum.smartContractCalls[0].txs
                },
                {
                  metric: "Uniq Callers",
                  value: data.ethereum.smartContractCalls[0].callers
                },
                {
                  metric: "Days contract called",
                  value: data.ethereum.smartContractCalls[0].days
                },
                {
                  metric: "Last transaction time",
                  value: data.ethereum.smartContractCalls[0].max_time
                },
                {
                  metric: "Created",
                  value: data.ethereum.smartContractCalls[0].created + " in block " + data.ethereum.smartContractCalls[0].created_block
                },
                {
                  metric: "Create TX",
                  value: data.ethereum.smartContractCalls[0].created_tx
                },
                {
                  metric: "Creator",
                  value: data.ethereum.smartContractCalls[0].created_by
                },
              ];
  
              setSmartStatsData(statsData);
            }
  
            if (response.data.response.Address_balance_by_currencies.data) {
              let data = response.data.response.Address_balance_by_currencies.data;
  
              if (data.ethereum.address[0].balances) {
                let addCurData = data.ethereum.address[0].balances.map((ele) => (
                  {
                    currency: ele.currency.symbol,
                    balance: ele.value
                  }
                ));
  
                setAddressBalanceData(addCurData);
              }
            }
  
            if (response.data.response.Calls_Count_By_Date.data) {
              let data = response.data.response.Calls_Count_By_Date.data;
  
              let seriesData = [
                {
                  name: 'External Calls',
                  type: 'column',
                  data: data.ethereum.smartContractCalls.map((ele) => {
                    return ele.external_calls
                  })
                },
                {
                  name: 'Internal Calls',
                  type: 'column',
                  data: data.ethereum.smartContractCalls.map((ele) => {
                    return ele.internal_calls
                  })
                },  
                {
                  name: 'Tx Coun',
                  type: 'line',
                  data: data.ethereum.smartContractCalls.map((ele) => {
                    return ele.txs
                  })
                }
              ];
  
              let optionsData = {
                chart: {
                  height: 350,
                  type: 'bar',
                  stacked: true,
                },
                stroke: {
                  width: [0, 4]
                },
                  dataLabels: {
                  enabled: true,
                  enabledOnSeries: [1]
                },
                labels: data.ethereum.smartContractCalls.map((ele) => {
                  return ele.date.date
                }),
                xaxis: {
                  type: 'datetime'
                },
                yaxis: [{
                  title: {
                    text: 'Uniq Callers',
                  },
                }]
              };
  
              setCallCountByDateGraphSeries(seriesData);
              setCallCountByDateGraphOptions(optionsData);
            }
  
            if (response.data.response.Unique_Callers_By_Date.data) {
              let data = response.data.response.Unique_Callers_By_Date.data;
  
              let seriesData = [
                {
                  name: 'External Calls',
                  type: 'column',
                  data: data.ethereum.smartContractCalls.map((ele) => {
                    return ele.external_calls
                  })
                },
                {
                  name: 'Internal Calls',
                  type: 'column',
                  data: data.ethereum.smartContractCalls.map((ele) => {
                    return ele.internal_calls
                  })
                },  
                {
                  name: 'Tx Count',
                  type: 'line',
                  data: data.ethereum.smartContractCalls.map((ele) => {
                    return ele.txs
                  })
                }
              ];
  
              let optionsData = {
                chart: {
                  height: 350,
                  type: 'bar',
                  stacked: true,
                },
                stroke: {
                  width: [0, 4]
                },
                  dataLabels: {
                  enabled: true,
                  enabledOnSeries: [1]
                },
                labels: data.ethereum.smartContractCalls.map((ele) => {
                  return ele.date.date
                }),
                xaxis: {
                  type: 'datetime'
                },
                yaxis: [{
                  title: {
                    text: 'Uniq Callers',
                  },
                }]
              };
  
              setUniqueCallersByDateGraphSeries(seriesData);
              setUniqueCallersByDateGraphOptions(optionsData);
            }
  
            if (response.data.response.Currencies_sent_received.data) {
              let data = response.data.response.Currencies_sent_received.data;
  
              if (data.ethereum.transfers.length > 0) {
                let curData = data.ethereum.transfers.map((ele) => (
                  {
                    currency: ele.currency.symbol,
                    receive: ele.sum_in,
                    receive_usd: ele.sum_in_usd,
                    tx_in: ele.count_in,
                    send: ele.sum_out,
                    send_usd: ele.sum_out_usd,
                    tx_out: ele.count_out,
                  }
                ));
  
                setCurrencyData(curData);
              }
            }
          } else if (typename == "Address") {
            if (response.data.response.Currencies_sent_received.data) {
              let data = response.data.response.Currencies_sent_received.data;
  
              if (data.ethereum.transfers.length > 0) {
                let curData = data.ethereum.transfers.map((ele) => (
                  {
                    currency: ele.currency.symbol,
                    receive: ele.sum_in,
                    receive_usd: ele.sum_in_usd,
                    tx_in: ele.count_in,
                    send: ele.sum_out,
                    send_usd: ele.sum_out_usd,
                    tx_out: ele.count_out,
                  }
                ));
  
                setCurrencyData(curData);
              }
            }

            if (response.data.response.Address_balance_by_currencies.data) {
              let data = response.data.response.Address_balance_by_currencies.data;
  
              if (data.ethereum.address[0].balances) {
                let addCurData = data.ethereum.address[0].balances.map((ele) => (
                  {
                    currency: ele.currency.symbol,
                    balance: ele.value
                  }
                ));
  
                setAddressBalanceData(addCurData);
              }
            }

            if (response.data.response.In_Outbound_transfer_count_by_date.data) {
              let data = response.data.response.In_Outbound_transfer_count_by_date.data;
  
              let seriesData = [
                {
                  name: 'Receive Count',
                  type: 'column',
                  data: data.ethereum.transfers.map((ele) => {
                    return ele.in_count
                  })
                },
                {
                  name: 'Send Count',
                  type: 'column',
                  data: data.ethereum.transfers.map((ele) => {
                    return ele.out_count
                  })
                },
              ];
  
              let optionsData = {
                chart: {
                  height: 350,
                  type: 'bar',
                  stacked: true,
                },
                stroke: {
                  width: [0, 4]
                },
                  dataLabels: {
                  enabled: true,
                  enabledOnSeries: [1]
                },
                labels: data.ethereum.transfers.map((ele) => {
                  return ele.date.date
                }),
                xaxis: {
                  type: 'datetime'
                },
                yaxis: [{
                  title: {
                    text: 'Transfer Count',
                  },
                }]
              };
  
              setCallCountByDateGraphSeries(seriesData);
              setCallCountByDateGraphOptions(optionsData);
            }

            if (response.data.response.Smart_contract_calls_by_date.data) {
              let data = response.data.response.Smart_contract_calls_by_date.data;
  
              let seriesData = [
                {
                  name: 'Calls Count',
                  type: 'column',
                  data: data.ethereum.smartContractCalls.map((ele) => {
                    return ele.count
                  })
                },
              ];
  
              let optionsData = {
                chart: {
                  height: 350,
                  type: 'bar',
                  stacked: true,
                },
                stroke: {
                  width: [0, 4]
                },
                  dataLabels: {
                  enabled: true,
                  enabledOnSeries: [1]
                },
                labels: data.ethereum.smartContractCalls.map((ele) => {
                  return ele.date.date
                }),
                xaxis: {
                  type: 'datetime'
                },
                yaxis: [{
                  title: {
                    text: 'Calls Count',
                  },
                }]
              };
  
              setUniqueCallersByDateGraphSeries(seriesData);
              setUniqueCallersByDateGraphOptions(optionsData);
            }
          }
        }

        setDataLoaded(true);
      }
    }).catch((error) => {
      setDataLoaded(true);
      console.log("error: ", error);
    });
  }
  
  return (
    <>
      <div className="rounded-lg flex justify-between bg-white px-12 py-6 border-0 w-full mb-6 shadow-lg">
        <div className="flex flex-wrap">
          <Link
            to={{
              pathname: "/onchain/smartcontract/"+id+"/"+network+"/"+typename,
              state: {
                  dateRange: dateRange,
              },
            }} 
          >
            <div
              className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
              style={
                window.location.href.indexOf("/onchain/smartcontract") !== -1
                  ? { backgroundColor: "#EBEDF2" }
                  : { backgroundColor: "#FFF" }
              }
            >
              {typename == "SmartContract" && 
                <h4 className="font-semibold text-xs">Smart Contract</h4>
              }
              {typename == "Address" && 
                <h4 className="font-semibold text-xs">Address</h4>
              }
            </div>
          </Link>
          {network != "cardano" && 
            <Link
              to={{
                pathname: "/onchain/transactions/"+id+"/"+network+"/"+typename,
                state: {
                    dateRange: dateRange,
                },
              }}
            >
              <div
                className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                style={
                  window.location.href.indexOf("/onchain/transaction") !== -1
                    ? { backgroundColor: "#EBEDF2" }
                    : { backgroundColor: "#FFF" }
                }
              >
                <h4 className="font-semibold text-xs">Transcations</h4>
              </div>
            </Link>
          }
          {network != "cardano" && 
            <>
              {typename == "SmartContract" && 
                <Link
                  to={{
                    pathname: "/onchain/methods/"+id+"/"+network+"/"+typename,
                    state: {
                        dateRange: dateRange,
                    },
                  }}
                >
                  <div
                    className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                    style={
                      window.location.href.indexOf("/onchain/methods") !== -1
                        ? { backgroundColor: "#EBEDF2" }
                        : { backgroundColor: "#FFF" }
                    }
                  >
                    <h4 className="font-semibold text-xs">Methods</h4>
                  </div>
                </Link>
              }
            </>
          }
          {network != "cardano" && 
            <Link
              to={{
                pathname: "/onchain/events/"+id+"/"+network+"/"+typename,
                state: {
                    dateRange: dateRange,
                },
              }}
            >
              <div
                className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                style={
                  window.location.href.indexOf("/onchain/events") !== -1
                    ? { backgroundColor: "#EBEDF2" }
                    : { backgroundColor: "#FFF" }
                }
              >
                <h4 className="font-semibold text-xs">Events</h4>
              </div>
            </Link>
          }
          <Link
            to={{
              pathname: "/onchain/inflow/"+id+"/"+network+"/"+typename,
              state: {
                  dateRange: dateRange,
              },
            }}
          >
            <div
              className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
              style={
                window.location.href.indexOf("/onchain/inflow") !== -1
                  ? { backgroundColor: "#EBEDF2" }
                  : { backgroundColor: "#FFF" }
              }
            >
              <h4 className="font-semibold text-xs">Inflow</h4>
            </div>
          </Link>
          <Link
            to={{
              pathname: "/onchain/outflow/"+id+"/"+network+"/"+typename,
              state: {
                  dateRange: dateRange,
              },
            }}
          >
            <div
              className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
              style={
                window.location.href.indexOf("/onchain/outflow") !== -1
                  ? { backgroundColor: "#EBEDF2" }
                  : { backgroundColor: "#FFF" }
              }
            >
              <h4 className="font-semibold text-xs">Outflow</h4>
            </div>
          </Link>
          {network != "cardano" && 
            <Link
              to={{
                pathname: "/onchain/callcontracts/"+id+"/"+network+"/"+typename,
                state: {
                    dateRange: dateRange,
                },
              }}
            >
              <div
                className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                style={
                  window.location.href.indexOf("/onchain/callcontracts") !== -1
                    ? { backgroundColor: "#EBEDF2" }
                    : { backgroundColor: "#FFF" }
                }
              >
                <h4 className="font-semibold text-xs">Call Contracts</h4>
              </div>
            </Link>
          }
          <Link
            to={{
              pathname: "/onchain/graph/"+id+"/"+network+"/"+typename,
              state: {
                  dateRange: dateRange,
              },
            }}
          >
            <div
              className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
              style={
                window.location.href.indexOf("/onchain/graph") !== -1
                  ? { backgroundColor: "#EBEDF2" }
                  : { backgroundColor: "#FFF" }
              }
            >
              <h4 className="font-semibold text-xs">Graph</h4>
            </div>
          </Link>
        </div>
      </div>
      {/* Smart Contract Cards */}
      {!dataLoaded ? 
        <div className="rounded-lg flex justify-between bg-white px-12 py-6 border-0 w-full mb-6 shadow-lg">
          <div className="lg:w-12/12 md:w-12/12 sm:w-6/12">
            <ReactLoading
              className="margin-auto mt-3 mb-3"
              type={"bars"}
              color={"#a10b1d"}
              height={"50px"}
              width={"50px"}
            />
          </div>
        </div>
        :
        <>
          <div className="smartContractCards">
            <div className="rounded-lg flex justify-between items-center bg-white px-12 py-6 border-0 w-full mb-6 shadow-lg">
              <div className="flex flex-row searchContract" style={{ maxWidth: "70%" }}>
                {(bitcoinWalletArray.indexOf(network) > -1) && 
                  <img style={{ width: "20px", height: "20px", marginRight: "10px" }} src={require(`assets/img/onchain/${network}.svg`).default} />
                }
                <span className="capitalize text-sm flex flex-row"><b className="mr-2">{network ? network : "N/A"}</b> - <p className="ml-2" style={{ maxWidth: "70%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{id ? id : "N/A"}</p></span>
              </div>
              <div className="filtersTable searchInputTables linechartfilter">
                <div className="fieldSearch relative w-full mb-3">
                  <label className="label block font-semibold text-black-600 text-xs mb-2">
                    Date range
                  </label>
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    portalId="root-portal"
                    maxDate={new Date()}
                    selectsRange={true}
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    onChange={(update) => {
                      updateResults(update)
                    }}
                    peekNextMonth
                    renderCustomHeader={({
                      monthDate,
                      customHeaderCount,
                      decreaseMonth,
                      increaseMonth,
                    }) => (
                      <div>
                        <button
                          aria-label="Previous Month"
                          className={
                            "react-datepicker__navigation react-datepicker__navigation--previous"
                          }
                          style={customHeaderCount === 1 ? { visibility: "hidden" } : null}
                          onClick={decreaseMonth}
                        >
                          <span
                            className={
                              "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                            }
                          >
                            {"<"}
                          </span>
                        </button>
                        <span className="react-datepicker__current-month">
                          {monthDate.toLocaleString("en-US", {
                            month: "long",
                            year: "numeric",
                          })}
                        </span>
                        <button
                          aria-label="Next Month"
                          className={
                            "react-datepicker__navigation react-datepicker__navigation--next"
                          }
                          style={customHeaderCount === 0 ? { visibility: "hidden" } : null}
                          onClick={increaseMonth}
                        >
                          <span
                            className={
                              "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                            }
                          >
                            {">"}
                          </span>
                        </button>
                      </div>
                    )}
                    monthsShown={2}
                  />
                </div>
              </div>
              <div className="filtersTable limitChangeField">
                <div className="limitField mainField mb-3">
                  <div className="inputfield limit">
                    <label className="label block font-semibold text-black-600 text-xs mb-2">Limit</label>
                    <Select
                      styles={customStyles}
                      onChange={handleSelectChange}
                      className="globeSelect"
                      options={optionsLimit}
                      defaultValue={defaultValueLimit}
                      menuPortalTarget={document.body}
                      isSearchable={false}
                    />
                  </div>
                </div>
              </div>
            </div>
            {network == "cardano" ? 
              <>
                <div className="flex flex-wrap">
                  <div className="chartsWidth12x15 xl:w-6/12 lg:w-12/12 xl:w-12/12 xl:w-12/12 mb-12 xl:mb-0 rounded-lg pr-4">
                    <div className="relative flex flex-row flex-wrap min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white px-8 py-6 ">
                      <div className="w-full flex  items-center">
                          <h4
                            className="borderDefault summaryHeading pb-2 font-bold text-base"
                            style={{ borderBottom: "2px solid #a10b1d" }}
                          >
                            Address Statistics
                          </h4>
                      </div>
                      <Table data={smartStatsData} columns={smartStatsColumn}/>
                    </div>
                  </div>
                  <div className="chartsWidth12x15 xl:w-6/12 lg:w-12/12 xl:w-12/12 xl:w-12/12 mb-12 xl:mb-0 rounded-lg pl-4">
                    <div className="relative min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white px-8 py-6 cardLineChart ">
                      <div className="w-full flex  items-center">
                        <h4
                          className="borderDefault summaryHeading pb-2 font-bold text-base"
                          style={{ borderBottom: "2px solid #a10b1d" }}
                        >
                          In/Outbound ADA by time
                        </h4>
                      </div>
                      <ReactApexChart options={uniqueCallersByDateGraphOptions} series={uniqueCallersByDateGraphSeries} type="line" height={350}/>
                    </div>
                  </div>
                </div>
              </>
              :
              <>
                <div className="flex flex-wrap">
                  <div className="xl:w-12/12 lg:w-12/12 xl:w-12/12 xl:w-12/12 mb-12 xl:mb-0 rounded-lg flex-grow">
                    <div className="relative flex flex-row flex-wrap min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white px-8 py-6 cardLineChart ">
                      <div className="w-full flex  items-center">
                          <h4
                            className="borderDefault summaryHeading pb-2 font-bold text-base"
                            style={{ borderBottom: "2px solid #a10b1d" }}
                          >
                            Currencies Sent/Received
                          </h4>
                      </div>
                      <Table data={currencyData} columns={currencyColumn}/>
                    </div>
                  </div>
                </div>
                {typename == "SmartContract" && 
                  <div className="flex flex-wrap">
                    <div className="chartsWidth12x15 xl:w-6/12 lg:w-12/12 xl:w-12/12 xl:w-12/12 mb-12 xl:mb-0 rounded-lg pr-4">
                      <div className="relative flex flex-row flex-wrap min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white px-8 py-6 cardLineChart ">
                        <div className="w-full flex  items-center">
                            <h4
                              className="borderDefault summaryHeading pb-2 font-bold text-base"
                              style={{ borderBottom: "2px solid #a10b1d" }}
                            >
                              Address Balance by Currencies
                            </h4>
                        </div>
                        <Table data={addressBalanceData} columns={addressBalanceColumn}/>
                      </div>
                    </div>
                    <div className="chartsWidth12x15 xl:w-6/12 lg:w-12/12 xl:w-12/12 xl:w-12/12 mb-12 xl:mb-0 rounded-lg pl-4">
                      <div className="relative flex flex-row flex-wrap min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white px-8 py-6 ">
                        <div className="w-full flex  items-center">
                            <h4
                              className="borderDefault summaryHeading pb-2 font-bold text-base"
                              style={{ borderBottom: "2px solid #a10b1d" }}
                            >
                              Smart Contract Statistics
                            </h4>
                        </div>
                        <Table data={smartStatsData} columns={smartStatsColumn}/>
                      </div>
                    </div>
                  </div>
                }
                {typename == "Address" && 
                  <div className="flex flex-wrap">
                    <div className="xl:w-12/12 lg:w-12/12 xl:w-12/12 xl:w-12/12 mb-12 xl:mb-0 rounded-lg flex-grow">
                      <div className="relative flex flex-row flex-wrap min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white px-8 py-6 cardLineChart ">
                        <div className="w-full flex  items-center">
                            <h4
                              className="borderDefault summaryHeading pb-2 font-bold text-base"
                              style={{ borderBottom: "2px solid #a10b1d" }}
                            >
                              Address Balance by Currencies
                            </h4>
                        </div>
                        <Table data={addressBalanceData} columns={addressBalanceColumn}/>
                      </div>
                    </div>
                  </div>
                }
                <div className="flex flex-wrap">
                  <div className="chartsWidth12x15 xl:w-6/12 lg:w-12/12 xl:w-12/12 xl:w-12/12 mb-12 xl:mb-0 rounded-lg pr-4">
                    <div className="relative  min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white px-8 py-6 cardLineChart ">
                      <div className="w-full flex  items-center">
                          {typename == "SmartContract" && 
                            <h4
                              className="borderDefault summaryHeading pb-2 font-bold text-base"
                              style={{ borderBottom: "2px solid #a10b1d" }}
                            >
                              Calls Count by Date
                            </h4>
                          }
                          {typename == "Address" && 
                            <h4
                              className="borderDefault summaryHeading pb-2 font-bold text-base"
                              style={{ borderBottom: "2px solid #a10b1d" }}
                            >
                              In/Outbound transfer count by date
                            </h4>
                          }
                      </div>
                      <ReactApexChart options={callCountByDateGraphOptions} series={callCountByDateGraphSeries} type="line" height={350}/>
                    </div>
                  </div>
                  <div className="chartsWidth12x15 xl:w-6/12 lg:w-12/12 xl:w-12/12 xl:w-12/12 mb-12 xl:mb-0 rounded-lg pl-4">
                    <div className="relative min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white px-8 py-6 cardLineChart ">
                      <div className="w-full flex  items-center">
                          {typename == "SmartContract" && 
                            <h4
                              className="borderDefault summaryHeading pb-2 font-bold text-base"
                              style={{ borderBottom: "2px solid #a10b1d" }}
                            >
                              Unique Callers by Date
                            </h4>
                          }
                          {typename == "Address" && 
                            <h4
                              className="borderDefault summaryHeading pb-2 font-bold text-base"
                              style={{ borderBottom: "2px solid #a10b1d" }}
                            >
                              Smart contract calls (including internal) by date
                            </h4>
                          }
                      </div>
                      <ReactApexChart options={uniqueCallersByDateGraphOptions} series={uniqueCallersByDateGraphSeries} type="line" height={350}/>
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
        </>
      }
    </>
  );
}



function Table ({columns, data}){
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination,
    useRowSelect,
    
  )
  return(
    <>
      <div className="block w-full overflow-x-auto">
        {/* Projects table */}
        <table {...getTableProps()} className="items-center w-full bg-transparent border-collapse">
          <thead className="border border-solid border-t-0 border-l-0 border-r-0 border-blueGray-300">
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th 
                    {...column.getHeaderProps()}
                    className={
                      "px-6 align-middle py-3 text-xs whitespace-nowrap font-semibold text-center text-black-600"
                    }
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()} className="cursor-pointer">
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()} className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}