import React from "react";
import countryList from "react-select-country-list";
import "react-circular-progressbar/dist/styles.css";
import { getOpenSanctionRecordIndividual, getPepRecords, getListedInDetailPage } from "../../services/AMLInquire";
import { useHistory } from "react-router-dom";
import Moment from 'moment';
import ReactLoading from "react-loading";
import { Link } from "react-router-dom";

// images
import ExportIcon from "../../assets/img/icons/exporticon.png";
import leftArrow from "../../assets/img/leftArrowBlack.png";
import rightArrow from "../../assets/img/rightArrowBlack.png";

export default function AMLScreeningDetail(props) {
  const history = useHistory();
  const state_data = props.location.state;
  const record_id = state_data["entity_id"];
  const individualscreening = state_data["individualscreening"];
  const batchscreening = state_data["batchscreening"];
  const countryoptions = React.useMemo(() => countryList().getData(), []);
  countryoptions.forEach((ele) => {
    if(ele.label === "Czechia") {
      ele.label = "Czech Republic (Czechia)";
    }
  });
  const [indData, setIndData] = React.useState([]);
  const [listData, setListData] = React.useState([]);
  const [countMatched, setCountMatched] = React.useState(0);
  const [totalListCount, setTotalListCount] = React.useState(0);

  const [sanctionBtn,setSanctionBtn] = React.useState("")
  const [pepBtn,setPepBtn] = React.useState("")
  const [adverseBtn,setAdverseBtn] = React.useState("")


  const [individualActive, setIndividualActive] = React.useState(
    individualscreening ? true : false
  );
  const [corporateActive, setCorporateActive] = React.useState(
    batchscreening ? true : false
  );

  const [santionActive, setSanctionActive] = React.useState(true);
  const [pepActive, setPepActive] = React.useState(false);
  const [adverseActive, setAdverseActive] = React.useState(false);

  const [pepData, setPepData] = React.useState([]);
  const [pepDataLoaded, setPepDataLoaded] = React.useState(true);
  const [sanctionDataLoaded, setSanctionDataLoaded] = React.useState(true);
  const [indDataLoaded, setIndDataLoaded] = React.useState(true);
  const [potMatchArr, setPotMatchArray] = React.useState([
    true,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  function setActivePotMatchCardLeft(id) {
    if (id > 1) {
      let items = [...potMatchArr];
      items[id - 1] = false;
      items[id - 2] = true;
      setPotMatchArray(items);
    }
  }

  function setActivePotMatchCardRight(id) {
    if (id < 10) {
      let items = [...potMatchArr];
      items[id - 1] = false;
      items[id] = true;
      setPotMatchArray(items);
    }
  }

  function setIndivActive() {
    setIndividualActive(true);
    setCorporateActive(false);
  }

  function setCorpActive() {
    setIndividualActive(false);
    setCorporateActive(true);
  }

  function sanctionActiveFunc() {
    setSanctionActive(true);
    setPepActive(false);
    setAdverseActive(false);
  }

  function pepActiveFunc() {
    setSanctionActive(false);
    setPepActive(true);
    setAdverseActive(false);
  }

  function adverseActiveFunc() {
    setSanctionActive(false);
    setPepActive(false);
    setAdverseActive(true);
  }

  React.useEffect(()=>{
    if(record_id && record_id.charAt(0)=="Q"){
      indData.map((ele)=>{      
        if(ele.prop=="topics"){
          if(ele.value=="role.pep")
  
          setPepDataLoaded(false);
          getPepRecords(record_id).then((response)=>{
            setPepData(response.data.results[0]);
            setPepDataLoaded(true);
          })
          .catch((error)=>{
            console.log("error ", error)
          })
          pepActiveFunc()
          setSanctionBtn("disabled")
          setAdverseBtn("disabled")
          setPepBtn("")
          // document.getElementById("sanctionBtn").disabled = true
          // document.getElementById("adverseBtn").disabled = true
          
        }     
        
      })
    }  
  },[indData])

  React.useEffect(()=>{
    if(record_id) {
      let name = "";
      indData.map((ele)=>{      
        if(ele.prop=="name"){
          name = ele.value;
        }
      })
      let formdata = {
        data: JSON.stringify([{
          "name": name,
          "nationality": '',
          "birthDate": '',
          "identifier": '',
          "country": ''
        }]),
        entity_id: record_id
      }
      setSanctionDataLoaded(false);
      getListedInDetailPage(formdata).then((response) => {
        if (response.data.status) {
          setCountMatched(response.data.matches_found);
          setTotalListCount(response.data.total_lists);
          if (response.data.matches_found > 0) {
            setListData(response.data.final_response[0]);
          }
          setSanctionDataLoaded(true);
        }
      }).catch((error) => {
        console.log("error: ", error);
      })
    }  
  },[indData])

  React.useEffect(() => {
     if (record_id) {   
      setIndDataLoaded(false);   
      getOpenSanctionRecordIndividual(record_id)
        .then((response) => {
          if (response.data.status) {
            setIndData(response.data.response[0]);
            setIndDataLoaded(true);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      history.push("/aml/amlscreeninginquire");
      window.location.reload();
    }
  }, []);

  const openDetail = (entity_id) => {
    if (entity_id) {
      setIndDataLoaded(false);   
      getOpenSanctionRecordIndividual(entity_id)
        .then((response) => {
          if (response.data.status) {
            setIndData(response.data.response[0]);
            setIndDataLoaded(true);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      history.push("/aml/amlscreeninginquire");
      window.location.reload();
    }
  }

  return (
    <>
      <div className="amlscreening amldetailscreen flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
            <div className="w-full flex flex-row amlDetailSections">
              <div className="scrollmax745 lg:w-6/12 h-full relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white mr-4">
                {potMatchArr &&
                  potMatchArr.map((potMatch, index) => (
                    <div key={index}>
                      {potMatch && (
                        <>
                          {indDataLoaded && 
                            <div className="p-4">
                              <div className="topButtons flex flex-row justify-between items-center">
                                <div className="sanction lg:w-4/12 mr-8">
                                  <button
                                    id="sanctionBtn"
                                    className={"w-full py-2 px-4 rounded-lg font-semibold text-white-400 buttonRed mb-2 text-xs "+sanctionBtn}
                                    onClick={sanctionActiveFunc}
                                    disabled={(sanctionBtn === "disabled") ? true : false}
                                  >
                                    Sanction
                                  </button>
                                </div>
                                <div className="pep lg:w-4/12 mr-8">
                                  <button
                                    id="pepBtn"
                                    className={"w-full py-2 px-4 rounded-lg font-semibold text-white-400 buttonGreen mb-2 text-xs "+pepBtn}
                                    onClick={pepActiveFunc}
                                    disabled={(pepBtn === "disabled") ? true : false}
                                  >
                                    PEP Class
                                  </button>
                                </div>
                                <div className="adverseMedia lg:w-4/12">
                                  <button
                                    id="adverseBtn"
                                    className={"w-full py-2 px-4 rounded-lg font-semibold text-white-400 buttonYellow mb-2 text-xs "+adverseBtn}
                                    onClick={adverseActiveFunc}
                                    disabled={(adverseBtn === "disabled") ? true : false}
                                  >
                                    Adverse Media
                                  </button>
                                </div>
                              </div>

                              <div className="block w-full overflow-x-auto">
                                <table className="items-center bg-transparent border-collapse w-full">
                                  <thead className="">
                                    <tr>
                                      <th className=" align-middle py-3 text-xs whitespace-nowrap font-semibold text-left text-black-600 teamSettingTableheading">Type</th>
                                      <th className=" align-middle py-3 text-xs whitespace-nowrap font-semibold text-left text-black-600 teamSettingTableheading">Person</th>

                                    </tr>
                                  </thead>
                                  <tbody className="amlTableBody">
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="id"){
                                      return false
                                    }
                                    return true
                                  })) && (
                                      <tr className="">
                                        <td className=" border-t-0 align-top border-l-0 border-r-0 text-xs whitespace-nowrap text-left teamSettingTable">
                                          Id
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "id" && (
                                              <div className="border-t-0 mr-3 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>
                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}

                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="name"){
                                      return false
                                    }
                                    return true
                                  })) && (
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Name
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "name" && (
                                              <div className="border-t-0 mr-3  flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="firstName"){
                                      return false
                                    }
                                    return true
                                  })) && (
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          First Name
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "firstName" && (
                                              <div className="border-t-0 mr-2  flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="middleName"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Middle Name
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "middleName" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="lastName"){
                                      return false
                                    }
                                    return true
                                  })) && (
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Last Name
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "lastName" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="fatherName"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Father Name
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "fatherName" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="motherName"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Mother Name
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "motherName" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="email"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Email
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "email" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="birthDate"){
                                      return false
                                    }
                                    return true
                                  })) && (
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Date of Birth
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "birthDate" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="birthPlace"){
                                      return false
                                    }
                                    return true
                                  })) && (
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Place of Birth
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "birthPlace" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="country"){
                                      return false
                                    }
                                    return true
                                  })) && (
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Country
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => (
                                          <>
                                          {ele.prop === "country" && 
                                          <div data-index={ind}>
                                            {/* <td className="font-light text-xs">{ele.prop}</td> */}
                                            {ele.value && 
                                              <>
                                                {countryoptions.map((country) => (
                                                  <>
                                                  
                                                    {(country.value.toLowerCase() === ele.value) && 
                                                      <div className="flex flex-row items-center mr-2">
                                                      <p className="text-xs font-semibold ">{country.label}</p>
                                                      <div className="amlTableDot ml-1"></div>

                                                      </div> }
                                                  </>
                                                ))}
                                              </>
                                            }
                                          </div>
                                        }

                                        </>           
                          ))}
                                        </td>
                                      </tr>
                                    )}
                                    
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="city"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          City
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "city" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}

                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="nationality"){
                                      return false
                                    }
                                    return true
                                  })) && (
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Nationality
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => (
                                          <>
                                          {ele.prop === "nationality" && 
                                          <tr data-index={ind}>
                                            {ele.value && 
                                              <>
                                                {countryoptions.map((country) => (
                                                  <>
                                                    {(country.value.toLowerCase() === ele.value) && 
                                                      <div className="flex flex-row items-center mr-2">
                                                      <p className="font-semibold text-xs ">{country.label}</p>
                                                      <div className="amlTableDot ml-1"></div>
                                                      </div>

                                                    }
                                                  </>
                                                ))}
                                              </>
                                            }
                                          </tr>
                                        }
                                        </>))}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="flag"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Flag
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "flag" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="gender"){
                                      return false
                                    }
                                    return true
                                  })) && (
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Gender
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "gender" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="topics"){
                                      return false
                                    }
                                    return true
                                  }) )&& (
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Category
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "topics" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="sourceUrl"){
                                      return false
                                    }
                                    return true
                                  })) && (
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Source URL
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "sourceUrl" && (
                                              <div className="font-semibold text-xs  flex flex-row items-center">
                                              <a href={ele.value} target="_blank" rel="noopener noreferrer">
                                              {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </a>
                                              </div>

                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                  
                                  {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="idNumber"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          ID Number
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "idNumber" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="entity"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Entity
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "entity" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="number"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Number
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "number" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="createdAt"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Created At
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "createdAt" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="modifiedAt"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                        Modified At
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "modifiedAt" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="endDate"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          End Date
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "endDate" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="address"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Address
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "address" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="full"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                        Full Address
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "full" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="addressEntity"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Address Entity
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "addressEntity" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="agent"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Agent
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "agent" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="alias"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Alias
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "alias" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="amountUsd"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Amount USD
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "amountUsd" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="asset"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Assets
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "asset" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="authority"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Authority
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "authority" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="authorityId"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Authority ID
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "authorityId" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="balance"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Balance
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "balance" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="bikCode"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          BIK Code
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "bikCode" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="buildDate"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Build Date
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "buildDate" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="callSign"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Call Sign
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "callSign" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="client"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Client
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "client" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                  
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="currency"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Currency
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "currency" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="deathDate"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Death Date
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "deathDate" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="description"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Description
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "description" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="dunsCode"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Duns Code
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "dunsCode" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="grossRegisteredTonnage"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Gross Registered Tonnage
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "grossRegisteredTonnage" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="holder"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Holder
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "holder" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="imoNumber"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          IMO Number
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "imoNumber" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="incorporationDate"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Incorporate Date
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "incorporationDate" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="innCode"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          INN Code
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "innCode" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="isin"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          ISIN
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "isin" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="issuer"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Issuer
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "issuer" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="jurisdiction"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Jurisdiction
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "jurisdiction" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="keywords"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                        Keywords
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "keywords" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="kppCode"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          KPP Code
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "kppCode" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="legalForm"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                        Legal Form
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "legalForm" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="leiCode"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          LEI Code
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "leiCode" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="listingDate"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Listing Date
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "listingDate" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="member"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Member
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "member" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="mmsi"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          MMSI
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "mmsi" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="model"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                        Model
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "model" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="notes"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Notes
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "notes" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="object"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Object
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "object" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="ogrnCode"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          OGRN Code
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "ogrnCode" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="organization"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                        Organization
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "organization" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="owner"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Owner
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "owner" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="passportNumber"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                        Passport Number
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "passportNumber" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="pastFlags"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                      Past Flags
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "pastFlags" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="person"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Person
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "person" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="phone"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Phone
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "phone" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="position"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Position
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "position" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="postOfficeBox"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Post Office Box
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "postOfficeBox" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="postalCode"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                        Postal Code
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "postalCode" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="previousName"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                        Previous Name
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "previousName" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="program"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Program
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "program" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="provisions"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                        Provisions
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "provisions" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="publicKey"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Public Key
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "publicKey" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="publicKey"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Public Key
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "publicKey" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="publisher"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Publisher
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "publisher" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="reason"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Reason
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "reason" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="recordId"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Record Id
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "recordId" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="region"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Region
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "region" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="registrationNumber"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                        Registration Number
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "registrationNumber" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="relationship"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                        Relationship
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "relationship" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="relative"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                        Relative
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "relative" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="remarks"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Remarks
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "remarks" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="role"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Role
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "role" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="secondName"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Second Name
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "secondName" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="sector"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Sector
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "sector" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="serialNumber"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                        Serial Number
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "serialNumber" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="startDate"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                        Start Date
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "startDate" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="state"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          State
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "state" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="status"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Status
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "status" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="street"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Street
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "street" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="subject"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Subject
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "subject" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="summary"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Summary
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "summary" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="swiftBic"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                        Swift Bic
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "swiftBic" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="taxNumber"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                        Tax Number
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "taxNumber" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="ticker"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                        Ticker
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "ticker" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="title"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Title
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "title" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="tonnage"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                        Tonnage
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "tonnage" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="topics"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Topics
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "topics" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="type"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Type
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "type" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="unscId"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                        UNSC Id
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "unscId" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="vatCode"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                        VAT Code
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "vatCode" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="weakAlias"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Weak Alias
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "weakAlias" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="website"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                          Website
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "website" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    {(indData.length > 0 && indData.some((i)=>{
                                    if(i.prop!=="wikidataId"){
                                      return false
                                    }
                                    return true
                                  }))
                                  &&(
                                      <tr>
                                        <td className="border-t-0  align-top border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                        Wiki Data Id
                                        </td>
                                        <td className="flex flex-wrap">
                                        {indData.map((ele, ind) => {
                                          return (
                                            ele.prop === "wikidataId" && (
                                              <div className="border-t-0 mr-2 flex flex-row items-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  text-left teamSettingTable">
                                                {ele.value ? ele.value : "N/A"}
                                                <div className="amlTableDot ml-1"></div>

                                              </div>
                                            )
                                          );
                                        })}
                                        </td>
                                      </tr>
                                    )}
                                    
                                  </tbody>
                                </table>
                              </div>

                              <div className="matchPercentage pt-6 flex flex-col">
                                <h4 className="text-sm font-bold">
                                  Match Percentage
                                </h4>
                                <div
                                  className="relative pt-1"
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "90% 10%",
                                  }}
                                >
                                  <div className="overflow-hidden h-5 mb-4 text-xs flex rounded greyBg">
                                    <div
                                      style={{ width: "60%" }}
                                      className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bgGreen"
                                    ></div>
                                  </div>
                                  <p className="text-right font-bold text-xs">
                                    60%
                                  </p>
                                </div>
                              </div>
                              <div className="bottomActionButtons flex flex-row justify-center w-full pt-6">
                                <div className="sanction lg:w-4/12 mr-8">
                                  <button className="w-full py-4 px-4 rounded-lg font-semibold text-white-400 buttonYellow mb-2 text-xs">
                                    False Match
                                  </button>
                                </div>
                                <div className="pep lg:w-4/12">
                                  <button className="w-full py-4 px-4 rounded-lg font-semibold text-white-400 buttonGreen mb-2 text-xs">
                                    Positive Match
                                  </button>
                                </div>
                              </div>
                            </div>
                          }
                          {!indDataLoaded && 
                            <ReactLoading
                              className="margin-auto mt-3 mb-3"
                              type={"bars"}
                              color={"#a10b1d"}
                              height={"50px"}
                              width={"50px"}
                            />
                          }
                        </>
                      )}
                    </div>
                  ))}
              </div>
              {santionActive && (
                <div className="scrollmax745 sanction lg:w-6/12 relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white p-4">
                  <div className="mainHeader w-full flex">
                    <div className="adverseMedia w-full">
                      <button className="w-full py-3 px-4 rounded-lg font-semibold text-white-400 buttonRed mb-2 text-xs text-left flex justify-between items-center">
                        Sanctions
                        {sanctionDataLoaded && 
                          <p>
                            {countMatched} results found{" "}
                            <small className="text-xs font-light">
                              against {totalListCount} list
                            </small>
                          </p>
                        }
                      </button>
                    </div>
                  </div>

                  {sanctionDataLoaded && 
                    <div className="listKYC p-2 pt-2">
                      <h2 className="text-base font-bold pl-3">Also listed In</h2>
                      <div className="tablesSanction w-full pt-2">
                        {listData.length > 0 &&
                          listData.map((item, index) => (
                            <div className="table" data-index={index}>
                              <div className="w-full flex justify-between items-center greyBg whiteText rounded-lg p-4 cursor-pointer" onClick={() => openDetail(item.entity_id)}>
                                <div className="md:w-12/12">
                                  <h4 className="text-xs">
                                    {item.list ? item.list : "N/A"}
                                  </h4>
                                </div>
                                <div className="md:w-4/12 navigator flex justify-between items-center">
                                  {/* <h5 className="text-sm">Name Matched</h5> */}
                                </div>
                              </div>
                              <div className="rounded-lg mt-2 greybg p-5">
                                <table className="w-full table-fixed">
                                  <tbody>
                                    <tr>
                                      <td className="font-light text-xs">Name</td>
                                      <td className="font-semibold text-xs">
                                        {item.name ? item.name : "N/A"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="font-light text-xs">
                                        Date of Birth
                                      </td>
                                      <td className="font-semibold text-xs">
                                        {item.birthDate ? item.birthDate : "N/A"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="font-light text-xs">
                                        ID Number
                                      </td>
                                      <td className="font-semibold text-xs">
                                        {item.identifier ? item.identifier : "N/A"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="font-light text-xs">
                                        Country
                                      </td>
                                      {item.country ?
                                        <>
                                          {countryoptions.map((country) =>(
                                            <>
                                              {(country.value.toLowerCase() === item.country) && 
                                                <td className="font-semibold text-xs">{country.label}</td>
                                              } 
                                            </>
                                          ))}
                                        </>
                                        :
                                        <>
                                          {item.nationality ?
                                            <>
                                              {countryoptions.map((country) =>(
                                                <>
                                                  {(country.value.toLowerCase() === item.country) && 
                                                    <td className="font-semibold text-xs">{country.label}</td>
                                                  } 
                                                </>
                                              ))}
                                            </>
                                            :
                                            <td className="font-semibold text-xs">{"N/A"}</td>
                                          }
                                        </>
                                      }
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  }

                  {!sanctionDataLoaded && 
                    <ReactLoading
                      className="margin-auto mt-3 mb-3"
                      type={"bars"}
                      color={"#a10b1d"}
                      height={"50px"}
                      width={"50px"}
                    />
                  }
                </div>
              )}
              {pepActive && (
                <div className="scrollmax745 pepclass lg:w-6/12 relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white p-4">
                  <div className="mainHeader w-full flex">
                    <div className="adverseMedia w-full">
                      <button className="w-full py-3 px-4 rounded-lg font-semibold text-white-400 buttonGreen mb-2 text-xs text-left">
                        PEP Class
                      </button>
                    </div>
                  </div>
                  {pepDataLoaded && 
                    <>
                      <div className="detailsTable pt-8">
                        <table className="w-full table-fixed">
                          <tbody className="amlTableBody">
                            <tr>
                              <td colspan="2" className="font-light text-xs align-top">Name</td>
                              <td className="capitalize text-xs align-top">{pepData.name ? pepData.name : "N/A"}</td>
                            </tr>
                            <tr>
                              <td colspan="2" className="font-light text-xs align-top">Date of Birth</td>
                              <td className=" text-xs align-top">{pepData.birthDate ? Moment(pepData.birthDate.substring(1)).format("MM-DD-YYYY") : "N/A"}</td>
                            </tr>
                            <tr>
                              <td colspan="2" className="font-light text-xs align-top">Place of Birth</td>
                              <td className="capitalize text-xs align-top">{pepData.placeOfBirth ? pepData.placeOfBirth : "N/A"}</td>
                            </tr>
                            <tr>
                              <td colspan="2" className="font-light text-xs align-top">Gender</td>
                              <td className="capitalize text-xs align-top">{pepData.gender ? pepData.gender : "N/A"}</td>
                            </tr>
                            <tr>
                              <td colspan="2" className="font-light text-xs align-top">Father Name</td>
                              <td className="capitalize text-xs align-top">{pepData.fatherName ? pepData.fatherName : "N/A"}</td>
                            </tr>
                            <tr>
                              <td colspan="2" className="font-light text-xs align-top">Country of Residence</td>
                              <td className="capitalize text-xs align-top">{pepData.country ? pepData.country : "N/A"} </td>
                            </tr>
                            <tr>
                              <td colspan="2" className="font-light text-xs align-top">Country of Citizenship</td>
                              <td className="capitalize text-xs align-top">{pepData.countryOfCitizenship ? pepData.countryOfCitizenship : "N/A"}</td>
                            </tr>
                            <tr>
                              <td colspan="2" className="font-light text-xs align-top">Description</td>
                              <td className="capitalize text-xs align-top">{pepData.description ? pepData.description : "N/A"}</td>
                            </tr>
                            <tr>
                              <td colspan="2" className="font-light text-xs align-top">Wikipedia URL</td>
                              <td className=" text-xs align-top" style={{wordBreak:"break-all"}}>
                                <a className="pepClassWikiLink" href={pepData.wiki_url}>{pepData.wiki_url}</a>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2" className="font-light text-xs align-top">
                                Aliases
                              </td>
                              {pepData.aliases && 
                                <td className="capitalize flex flex-wrap  align-top">{pepData.aliases.map((ele)=>{
                                  return(
                                    <p className=" text-xs"> {ele}{"  "} , </p>
                                  )})}
                                </td>
                              }
                            </tr>
                            <tr>
                              <td className="font-light text-xs align-top"></td>
                              <td className="font-light text-xs align-top" style={{ width: "150px" }}></td>
                              <td className="font-light text-xs align-top"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="images pt-8">
                        <div className="heading">
                          <h2 className="text-base font-bold">Images</h2>
                          <div className="imageGrid pt-6">
                          {pepData.images&&
                          pepData.images.map((ele)=>{
                            return(
                              <img
                              alt="..."
                              src={ele}
                              style={{height:"180px",objectFit:"cover"}}
                              className="shadow-xl align-middle border-none rounded"
                            />
                            )
                          })}
                            
                          </div>
                        </div>
                      </div>
                    </>
                  }
                  {!pepDataLoaded && 
                    <ReactLoading
                      className="margin-auto mt-3 mb-3"
                      type={"bars"}
                      color={"#a10b1d"}
                      height={"50px"}
                      width={"50px"}
                    />
                  }
                </div>
              )}
              {adverseActive && (
                <div className="scrollmax745 adverseMedia lg:w-6/12 relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white p-4">
                  <div className="mainHeader w-full flex">
                    <div className="adverseMedia w-full">
                      <button className="w-full py-3 px-4 rounded-lg font-semibold text-white-400 buttonYellow mb-2 text-xs text-left">
                        Adverse Media
                      </button>
                    </div>
                  </div>
                  <div className="mediaReports pt-6">
                    <div className="heading">
                      <h2 className="text-base font-bold">Media Reports</h2>
                    </div>
                    <div className="mainNews pt-2">
                      <div className="news mb-4">
                        <h3 className="newsHeader text-xs font-bold underline">
                          CNNPolitics - Political News, Analysis and Opinion
                        </h3>
                        <small className="text-xs">
                          edition.cnn.com › politics
                        </small>
                        <p className="text-xs">
                          <small className="font-light">3 days ago -</small>{" "}
                          Politics at CNN has news, opinion and analysis of
                          American and global politics Find news and video about
                          elections, John snow the White House, the U.N and much
                          more. Politics at CNN has news, opinion and analysis
                          of American and global politics Find news and video
                          about elections.
                        </p>
                      </div>
                      <div className="news mb-4">
                        <h3 className="newsHeader text-xs font-bold underline">
                          CNNPolitics - Political News, Analysis and Opinion
                        </h3>
                        <small className="text-xs">
                          edition.cnn.com › politics
                        </small>
                        <p className="text-xs">
                          <small className="font-light">3 days ago -</small>{" "}
                          Politics at CNN has news, opinion and analysis of
                          American and global politics Find news and video about
                          elections, John snow the White House, the U.N and much
                          more. Politics at CNN has news, opinion and analysis
                          of American and global politics Find news and video
                          about elections.
                        </p>
                      </div>
                      <div className="news mb-4">
                        <h3 className="newsHeader text-xs font-bold underline">
                          CNNPolitics - Political News, Analysis and Opinion
                        </h3>
                        <small className="text-xs">
                          edition.cnn.com › politics
                        </small>
                        <p className="text-xs">
                          <small className="font-light">3 days ago -</small>{" "}
                          Politics at CNN has news, opinion and analysis of
                          American and global politics Find news and video about
                          elections, John snow the White House, the U.N and much
                          more. Politics at CNN has news, opinion and analysis
                          of American and global politics Find news and video
                          about elections.
                        </p>
                      </div>
                      <div className="news mb-4">
                        <h3 className="newsHeader text-xs font-bold underline">
                          CNNPolitics - Political News, Analysis and Opinion
                        </h3>
                        <small className="text-xs">
                          edition.cnn.com › politics
                        </small>
                        <p className="text-xs">
                          <small className="font-light">3 days ago -</small>{" "}
                          Politics at CNN has news, opinion and analysis of
                          American and global politics Find news and video about
                          elections, John snow the White House, the U.N and much
                          more. Politics at CNN has news, opinion and analysis
                          of American and global politics Find news and video
                          about elections.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
