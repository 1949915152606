import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { KycMobContext } from "../../layouts/KYCMob";

import backButtonImg from " ../../assets/img/kycmobile/backBtn.png";
import closeButtonImg from " ../../assets/img/kycmobile/cross_white.png";
import mainImg from " ../../assets/img/kycmobile/frame_6.png";
import footerImg from " ../../assets/img/kycmobile/footer_trademark.png";
import { getevents } from "services/casemanagement/Onboarding";

export default function Screen1() {
  const [returningClient, setReturningClient] = useState(null);
  const [alreadyComplete, setAlreadyComplete] = useState(false);
  const [returningUrl, setReturningUrl] = useState(null);
  const { data } = useContext(KycMobContext);
  const [screenStates, setScreenStates] = React.useState({
    Screen1info: false,
    Screen1i: false,
    Screen2: false,
    Screen3: false,
    Screen3iQR: false,
    Screen4: false,
    Screen5: false,
    Screen10: false,
    Screen11: false,
    Screen12: false,
    Screen13: false,
    Screen14: false,
    Screen16: false,
    Screen17: false,
    Screen18: false,
    Screen19: false,
    Screen22: false,
    Screen23: false,
    Screen24: false,
    Screen25: false,
    Screen26: false,
  });
  const storage = window.localStorage;
  
  React.useEffect(() => {
    storage.removeItem("screenmeta");
  }, []);

  const url = window.location.href.substring(
    window.location.href.lastIndexOf("/") + 1
  );
  const [urlId, setUrlId] = React.useState(url);
  const history = useHistory();

  React.useEffect(() => {
    if (url) {
      getevents(url).then((response) => {
        // console.log("response: ", response);
        if (response.data.success) {
          if (response.data.event.length > 0) {
            setReturningClient(true);
            if (screenStates) {
              if (response.data.event[response.data.event.length - 1].event_desc === "Created") {
                let tempScreenStates = {
                  Screen1info: true,
                  Screen1i: true,
                  Screen2: true,
                  Screen3: true,
                  Screen3iQR: false,
                  Screen4: false,
                  Screen5: false,
                  Screen10: false,
                  Screen11: false,
                  Screen12: false,
                  Screen13: false,
                  Screen14: false,
                  Screen16: false,
                  Screen17: false,
                  Screen18: false,
                  Screen19: false,
                  Screen22: false,
                  Screen23: false,
                  Screen24: false,
                  Screen25: false,
                  Screen26: false,
                };
                storage.setItem("screenmeta", JSON.stringify(tempScreenStates));
                setReturningUrl("/verify/d/" + url);
              } else if (response.data.event[response.data.event.length - 1].event_desc === "Basic Information Saved") {
                let tempScreenStates = {
                  Screen1info: true,
                  Screen1i: true,
                  Screen2: true,
                  Screen3: true,
                  Screen3iQR: false,
                  Screen4: true,
                  Screen5: false,
                  Screen10: false,
                  Screen11: false,
                  Screen12: false,
                  Screen13: false,
                  Screen14: false,
                  Screen16: false,
                  Screen17: false,
                  Screen18: false,
                  Screen19: false,
                  Screen22: false,
                  Screen23: false,
                  Screen24: false,
                  Screen25: false,
                  Screen26: false,
                };
                storage.setItem("screenmeta", JSON.stringify(tempScreenStates));
                setReturningUrl("/verify/e/" + url);
              } else if (response.data.event[response.data.event.length - 1].event_desc === "Selfie video verification started") {
                let tempScreenStates = {
                  Screen1info: true,
                  Screen1i: true,
                  Screen2: true,
                  Screen3: true,
                  Screen3iQR: false,
                  Screen4: true,
                  Screen5: false,
                  Screen10: false,
                  Screen11: false,
                  Screen12: false,
                  Screen13: false,
                  Screen14: false,
                  Screen16: false,
                  Screen17: false,
                  Screen18: false,
                  Screen19: false,
                  Screen22: false,
                  Screen23: false,
                  Screen24: false,
                  Screen25: false,
                  Screen26: false,
                };
                storage.setItem("screenmeta", JSON.stringify(tempScreenStates));
                setReturningUrl("/verify/e/" + url);
              } else if (response.data.event[response.data.event.length - 1].event_desc === "Selfie video verification passed") {
                let tempScreenStates = {
                  Screen1info: true,
                  Screen1i: true,
                  Screen2: true,
                  Screen3: true,
                  Screen3iQR: false,
                  Screen4: true,
                  Screen5: true,
                  Screen10: true,
                  Screen11: false,
                  Screen12: false,
                  Screen13: false,
                  Screen14: false,
                  Screen16: false,
                  Screen17: false,
                  Screen18: false,
                  Screen19: false,
                  Screen22: false,
                  Screen23: false,
                  Screen24: false,
                  Screen25: false,
                  Screen26: false,
                };
                storage.setItem("screenmeta", JSON.stringify(tempScreenStates));
                setReturningUrl("/verify/g/" + url);
              } else if (response.data.event[response.data.event.length - 1].event_desc === "Government ID verification started") {
                let tempScreenStates = {
                  Screen1info: true,
                  Screen1i: true,
                  Screen2: true,
                  Screen3: true,
                  Screen3iQR: false,
                  Screen4: true,
                  Screen5: true,
                  Screen10: true,
                  Screen11: false,
                  Screen12: false,
                  Screen13: false,
                  Screen14: false,
                  Screen16: false,
                  Screen17: false,
                  Screen18: false,
                  Screen19: false,
                  Screen22: false,
                  Screen23: false,
                  Screen24: false,
                  Screen25: false,
                  Screen26: false,
                };
                storage.setItem("screenmeta", JSON.stringify(tempScreenStates));
                setReturningUrl("/verify/g/" + url);
              } else if (response.data.event[response.data.event.length - 1].event_desc === "Government ID verification passed") {
                let tempScreenStates = {
                  Screen1info: true,
                  Screen1i: true,
                  Screen2: true,
                  Screen3: true,
                  Screen3iQR: false,
                  Screen4: true,
                  Screen5: true,
                  Screen10: true,
                  Screen11: true,
                  Screen12: true,
                  Screen13: true,
                  Screen14: true,
                  Screen16: true,
                  Screen17: true,
                  Screen18: true,
                  Screen19: false,
                  Screen22: false,
                  Screen23: false,
                  Screen24: false,
                  Screen25: false,
                  Screen26: false,
                };
                storage.setItem("screenmeta", JSON.stringify(tempScreenStates));
                setReturningUrl("/verify/k/" + url);
              } else if (response.data.event[response.data.event.length - 1].event_desc === "Document verification started") {
                let tempScreenStates = {
                  Screen1info: true,
                  Screen1i: true,
                  Screen2: true,
                  Screen3: true,
                  Screen3iQR: false,
                  Screen4: true,
                  Screen5: true,
                  Screen10: true,
                  Screen11: true,
                  Screen12: true,
                  Screen13: true,
                  Screen14: true,
                  Screen16: true,
                  Screen17: true,
                  Screen18: true,
                  Screen19: false,
                  Screen22: false,
                  Screen23: false,
                  Screen24: false,
                  Screen25: false,
                  Screen26: false,
                };
                storage.setItem("screenmeta", JSON.stringify(tempScreenStates));
                setReturningUrl("/verify/k/" + url);
              } else if (response.data.event[response.data.event.length - 1].event_desc === "Document verification passed") {
                let tempScreenStates = {
                  Screen1info: true,
                  Screen1i: true,
                  Screen2: true,
                  Screen3: true,
                  Screen3iQR: false,
                  Screen4: true,
                  Screen5: true,
                  Screen10: true,
                  Screen11: true,
                  Screen12: true,
                  Screen13: true,
                  Screen14: true,
                  Screen16: true,
                  Screen17: true,
                  Screen18: true,
                  Screen19: true,
                  Screen22: true,
                  Screen23: true,
                  Screen24: true,
                  Screen25: false,
                  Screen26: false,
                };
                storage.setItem("screenmeta", JSON.stringify(tempScreenStates));
                setReturningUrl("/verify/o/" + url);
              } else if (response.data.event[response.data.event.length - 1].event_desc === "Wallet verification passed") {
                let tempScreenStates = {
                  Screen1info: true,
                  Screen1i: true,
                  Screen2: true,
                  Screen3: true,
                  Screen3iQR: false,
                  Screen4: true,
                  Screen5: true,
                  Screen10: true,
                  Screen11: true,
                  Screen12: true,
                  Screen13: true,
                  Screen14: true,
                  Screen16: true,
                  Screen17: true,
                  Screen18: true,
                  Screen19: true,
                  Screen22: true,
                  Screen23: true,
                  Screen24: true,
                  Screen25: true,
                  Screen26: false,
                };
                storage.setItem("screenmeta", JSON.stringify(tempScreenStates));
                setReturningUrl("/verify/p/" + url);
              } else if (response.data.event[response.data.event.length - 1].event_desc === "Inquiry completed") {
                setAlreadyComplete(true);
              }
            }
          } else {
            setReturningClient(false);
          }
        }
      })
      .catch((error) => {
        console.log("error: ", error);
      });
    }
  }, []);

  const handleNext = () => {
    if (returningClient) {
      history.push(returningUrl);
      window.location.reload(false);
    } else {
      if (screenStates) {
        let temp = screenStates;
        temp.Screen1info = true;
        storage.setItem("screenmeta", JSON.stringify(temp));
        const redirecturl = "/verify/b/"+urlId;
        history.push(redirecturl);
        window.location.reload(false);
      }
    }
  }

  const handleCross = () => {
    var ua = window.navigator.userAgent;

    if (ua.indexOf("iPhone") > 0) {
      window.close();
    }
    window.close();
  };

  return (
    <>
      <div
        className="screenOne h-full flex flex-col"
        style={{ fontFamily: data ? data.font_family : "Gilroy" }}
      >
        <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
          <div className="backArrow">
            <Link to={"/verify/a/" + urlId}>
              <button>
                <img src={backButtonImg} alt="backArrow" className="" />
              </button>
            </Link>
          </div>
          <div className="progressBar w-full py-3 px-12">
            <div className="relative lg:w-8/12 mx-auto">
              <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                <div
                  style={{
                    width: "5%",
                    backgroundColor: data ? data.progressbar_color : "#a10b1d",
                  }}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center "
                ></div>
              </div>
            </div>
          </div>
          <div className="crossArrow">
            <button onClick={handleCross}>
              <img src={closeButtonImg} alt="crossArrow" className="" />
            </button>
          </div>
        </div>

        {(returningClient === false) && 
          <>
            <div class="mainContent">
              <div class="mainImage">
                <img class="mainImg" src={mainImg} alt="main image" />
              </div>
              <div class="mainText">
                <h2>Lets start your verification</h2>
              </div>
              <div class="supportingText">
                <p class="supportingTextPara">
                  We'll guide you through a simple process to verify your identity.
                </p>
              </div>
            </div>

            <div class="buttonAction">
              {/* <Link to={"/verify/b/" + urlId}> */}
                <button
                  style={{ backgroundColor: data ? data.button_color : "#a10b1d" }}
                  class="action"
                  onClick={handleNext}
                >
                  Let's Start!
                </button>
              {/* </Link> */}
            </div>
          </>
        }

        {returningClient && 
          <>
            <div class="mainContent">
              <div class="mainImage">
                <img class="mainImg" src={mainImg} alt="main image" />
              </div>
              <div class="mainText">
                <h2>Welcome back!</h2>
              </div>
              <div class="supportingText">
                <p class="supportingTextPara">
                  Let's continue from where we left off!
                </p>
              </div>
            </div>

            <div class="buttonAction">
              {/* <Link to={returningUrl}> */}
                <button
                  style={{ backgroundColor: data ? data.button_color : "#a10b1d" }}
                  class="action"
                  onClick={handleNext}
                >
                  Let's Continue!
                </button>
              {/* </Link> */}
            </div>
          </>
        }

        {returningClient && alreadyComplete && 
          <>
            <div class="mainContent">
              <div class="mainImage">
                <img class="mainImg" src={mainImg} alt="main image" />
              </div>
              <div class="mainText">
                <h2>Inquiry Already Submitted!</h2>
              </div>
              <div class="supportingText">
                <p class="supportingTextPara">
                  You can generate a new link to submit another inquiry!
                </p>
              </div>
            </div>

            {/* <div class="buttonAction">
              <Link to={"/verify/2/" + urlId}>
                <button
                  style={{ backgroundColor: data ? data.button_color : "#a10b1d" }}
                  class="action"
                >
                  Let's Start!
                </button>
              </Link>
            </div> */}
          </>
        }

        <div class="footer">
          <img class="footerImg" src={footerImg} alt="footer" />
        </div>
      </div>
    </>
  );
}
