import React, { useState, useContext } from "react";
import Select from "react-select";
import { Link, useHistory } from "react-router-dom";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import countryList from "react-select-country-list";
import ReactLoading from "react-loading";
import {KycMobContext} from "../../layouts/KYCMob"
import { getacceptablecountries } from "../../services/settings/Acceptable";

import backButtonImg from " ../../assets/img/kycmobile/backBtn.png";
import closeButtonImg from " ../../assets/img/kycmobile/cross_white.png";
import footerImg from " ../../assets/img/kycmobile/footer_trademark.png";

export default function Screen24(props) {
  const {data} = useContext(KycMobContext)

  const url = window.location.href.substring(
    window.location.href.lastIndexOf("/") + 1
  );
  const [message, setMessage] = useState("");
  const history = useHistory();
  const [value, setValue] = React.useState("");
  const [options, setOptions] = React.useState(() => countryList().getData(), []);
  options.forEach((ele) => {
    if(ele.label === "Czechia") {
      ele.label = "Czech Republic (Czechia)";
    }
  });
  const [country, setCountry] = React.useState(null);
  const [city, setCity] = React.useState(null);
  const [dataLoaded, setDataLoaded] = React.useState(true);
  const [postal, setPostal] = React.useState(null);
  const [address, setAddress] = React.useState(null);
  const [disable, setDisable] = React.useState(false);
  const storage = window.localStorage;
  const [screenStates, setScreenStates] = React.useState(JSON.parse(storage.getItem("screenmeta")));

  React.useEffect(() => {
    if (screenStates) {
      if (!screenStates.Screen24) {
        const redirecturl = "/verify/k/"+url;
        history.push(redirecturl);
        window.location.reload(false);
      }
    } else {
      const redirecturl = "/verify/k/"+url;
      history.push(redirecturl);
      window.location.reload(false);
    }
  }, [screenStates]);

  React.useEffect(() => {
    if (data) {
      if (data.screen_25 === 0) {
        history.push("/verify/p/" + url);
        window.location.reload(false);
      }
    }
  }, [data]);

  React.useEffect(() => {
    getacceptablecountries().then((response) => {
      // console.log("response: ", response);
      if (response.data.success) {
        if (response.data.acceptable_countries.length > 0) {
          var filteredArray = options.filter(function(item_op) {
            return response.data.acceptable_countries.filter(function(item_resp) {
              return item_resp.country === item_op.label;
            }).length !== 0
          });
          setOptions(filteredArray);
        }
      }
    }).catch((error) => {
      console.log("error: ", error);
    });
  }, []);

  const changeHandler = (v) => {
    setMessage("");
    setValue(v);
    setCountry(v.label);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  const handleSubmit = () => {
    if (country !== null && city !== null && postal !== null && address !== null) {
      setDataLoaded(false);
      setDisable(true);
      let temp = screenStates;
      temp.Screen19 = true;
      storage.setItem("screenmeta", JSON.stringify(temp));
      history.push({
        pathname: '/verify/m/'+url,
        state: {
          country: country,
          city: city,
          postal: postal,
          address: address,
        }
      });
      window.location.reload(false);
    } else {
      setMessage("All fields are mandatory!");
      return;
    }
  };

  const handleCross = () => {
    var ua = window.navigator.userAgent;

    if (ua.indexOf("iPhone") > 0) {
      window.close();
    }
    window.close();
  };

  return (
    <>
      <div className="screenTwentyFour h-full"  style={{fontFamily: data ? data.font_family : "Gilroy"}}>
        <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
          <div className="backArrow">
            <Link to={"/verify/k/"+url}>
              <button>
                <img src={backButtonImg} alt="backArrow" className="" />
              </button>
            </Link>
          </div>
          <div className="progressBar w-full py-3 px-12">
            <div className="relative lg:w-8/12 mx-auto">
              <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                <div
                  style={{ width: "80%",backgroundColor:data ? data.progressbar_color:"#a10b1d" }}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"
                ></div>
              </div>
            </div>
          </div>
          <div className="crossArrow">
            <button onClick={handleCross}>
              <img src={closeButtonImg} alt="crossArrow" className="" />
            </button>
          </div>
        </div>

        {!dataLoaded && (
          <>
            <ReactLoading
              className="margin-auto mt-3 mb-3 absolute"
              type={"bars"}
              style={{
                zIndex: "100000",
                top: "30%",
                left: "39%",
                width: "22%",
                height: "22%",
                fill: "#a10b1d",
              }}
            />
            <div
              id="overlay"
              className="fixed w-full h-full top-0 left-0 right-0 bottom-0 cursor-pointer"
              style={{ backgroundColor: "rgba(0,0,0,0.5)", zIndex: "10000" }}
            ></div>
          </>
        )}

        <div className="mainContent" >
          <div className="mainText">
            <h1>Please enter your address details</h1>
          </div>
          <div className="informationSection">
            <div className="nationalityField">
              <div className="inputfield dob">
                <span className="label text-sm">Country</span>
                <Select
                  className=""
                  placeholder="Search Country"
                  options={options}
                  value={value}
                  onChange={changeHandler}
                  menuPortalTarget={document.body}
                  styles={customStyles}
                />
              </div>
            </div>
            <div className="nameFields">
              <div className="firstNameField firstField">
                <div className="inputfield city">
                  <span className="label text-sm">City</span>
                  <input type="text" value={city} name="city" className="input firstnameInput" onChange={(e) => {setCity(e.target.value); setMessage("");}} />
                </div>
              </div>
              <div className="lastNameField">
                <div className="inputfield postal">
                  <span className="label text-sm">Postal Code</span>
                  <input value={postal} type="text" name="postal" className="input lastnameInput" onChange={(e) => {setPostal(e.target.value); setMessage("");}} />
                </div>
              </div>
            </div>
            <div className="emailFields">
              <div className="inputfield address">
                <span className="label text-sm">Address</span>
                <input value={address} type="email" name="address" className="input emailInput" placeholder="" onChange={(e) => {setAddress(e.target.value); setMessage("");}} />
              </div>
            </div>

            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="buttonAction">
          <button style={{backgroundColor:data?data.button_color:"#a10b1d"}} disabled={disable} onClick={handleSubmit} className="action">Next</button>
        </div>

        <div className="footer">
          <img className="footerImg" src={footerImg} alt="footer" />
        </div>
      </div>
    </>
  );
}
  
