import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

// layouts
import Admin from "layouts/Admin.js";
import CaseManagementAdmin from "layouts/CaseManagementAdmin";
import AML from "layouts/AML";
import Setting from "layouts/Setting";
import Report from "layouts/Report";
import WebHook from "layouts/WebHook";
import RiskManag from "layouts/RiskManag";
import Auth from "layouts/Auth.js";
import KYC from "layouts/KYC.js";
import KYCMob from "layouts/KYCMob.js";
import KYBMob from "layouts/KYBMob.js";
import KYBKYCMob from "layouts/KYBKYCMob.js";

import KYCBasic from "layouts/KYCBasic.js";
import KYCLiveness from "layouts/KYCLiveness.js";
import KYCIdverif from "layouts/KYCIdverif.js";
import KYCAddress from "layouts/KYCAddress.js";
import KYCWallet from "layouts/KYCWallet.js";

import Developer from "layouts/Developer";
import Docs from "layouts/Docs";
import NetworkAnalysis from "layouts/NetworkAnalysis";
import OnchainAnalysis from "layouts/OnchainAnalysis";

import ReactGA from "react-ga4";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY);

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* add routes with layouts */}
      <Route path="/admin" component={Admin} />
      <Route path="/case" component={CaseManagementAdmin} />
      <Route path="/aml" component={AML} />
      <Route path="/reports" component={Report} />
      <Route path="/settings" component={Setting} />
      <Route path="/risk" component={RiskManag} />
      <Route path="/web" component={WebHook} />
      <Route path="/network" component={NetworkAnalysis} />
      <Route path="/onchain" component={OnchainAnalysis} />
      <Route path="/dev" component={Developer} />
      <Route path="/docs" component={Docs} />
      <Route path="/auth" component={Auth} />
      {/* <Route path="/kyc" component={KYC} /> */}
      <Route path="/verify" component={KYCMob} />
      <Route path="/kyb" component={KYBMob} />
      <Route path="/kybkyc/" component={KYBKYCMob} />
      
      <Route path="/kycreturning/basic" component={KYCBasic} />
      <Route path="/kycreturning/liveness" component={KYCLiveness} />
      <Route path="/kycreturning/idverif" component={KYCIdverif} />
      <Route path="/kycreturning/addressverif" component={KYCAddress} />
      <Route path="/kycreturning/wallet" component={KYCWallet} />
      {/* add redirect for first page */}
      <Redirect from="*" to="/auth" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
