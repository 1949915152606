import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import countryList from 'react-select-country-list';
import { savebasicinfo, saveevent, getemailbyinqid } from "../../services/kyc/KycScreens";
import {KycMobContext} from "../../layouts/KYCMob"
import { getacceptablecountries } from "../../services/settings/Acceptable";
import DatePicker from "react-datepicker";
import Moment from "moment";
import { addDays } from "date-fns";
import {isMobile} from 'react-device-detect';

import backButtonImg from " ../../assets/img/kycmobile/backBtn.png";
import closeButtonImg from " ../../assets/img/kycmobile/cross_white.png";
import footerImg from " ../../assets/img/kycmobile/footer_trademark.png";

export default function Screen3() {
  const [disable, setDisable] = React.useState(false);
  const {data, height} = useContext(KycMobContext);
  const history = useHistory();
  const url = window.location.href.substring(
    window.location.href.lastIndexOf("/") + 1
  );
  const [message, setMessage] = useState("");
  const [value, setValue] = React.useState("")
  const [options, setOptions] = React.useState(() => countryList().getData(), []);
  options.forEach((ele) => {
    if(ele.label === "Czechia") {
      ele.label = "Czech Republic (Czechia)";
    }
  });
  var form, checkBtn;
  const storage = window.localStorage;
  const [screenStates, setScreenStates] = React.useState(JSON.parse(storage.getItem("screenmeta")));

  const [emailFound, setEmailFound] = useState(false);

  React.useEffect(() => {
    if (screenStates) {
      if (!screenStates.Screen3) {
        const redirecturl = isMobile ? "/verify/b/"+url : "/verify/c/"+url;
        history.push(redirecturl);
        window.location.reload(false);
      }
    } else {
      const redirecturl = isMobile ? "/verify/b/"+url : "/verify/c/"+url;
      history.push(redirecturl);
      window.location.reload(false);
    }
  }, [screenStates]);
  
  const [formdata, setFormdata] = useState({
    email: "",
    first_name: "",
    last_name: "",
    dob: null,
    nationality: "",
    inq_id: url,
  });

  React.useEffect(() => {
    if (!url) {
      return
    }
    getemailbyinqid(url).then((response) => {
      if (response.data.success) {
        setEmailFound(true);
        setFormdata((values) => ({ ...values, ["email"]: response.data.email }));
      }
    }).catch((error) => {
      console.log("error: ", error);
    })
  }, [url])

  const handleChangeSelect = (event, { name }) => {
    setMessage("");
    setFormdata((values) => ({ ...values, [name]: event }));
  };

  const handleInputChange = (event) => {
    setMessage("");
    const name = event.target.name;
    const value = event.target.value;

    if (name === "email") {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        setMessage("Invalid email address");
      }
      setFormdata((values) => ({ ...values, [name]: value }));
    } else {
      setFormdata((values) => ({ ...values, [name]: value }));
    }
  };

  const handleInputChangeDate = (date) => {
    setMessage("");
    const name = "dob";
    const value = date;
    setFormdata((values) => ({ ...values, [name]: value }));
  };

  React.useEffect(() => {
    if (data) {
      if (data.screen_3 === 0) {
        history.push("/verify/e/" + url);
        window.location.reload(false);
      }
    }
  }, [data]);

  // React.useEffect(() => {
  //   getacceptablecountries().then((response) => {
  //     if (response.data.success) {
  //       if (response.data.acceptable_countries.length > 0) {
  //         var filteredArray = options.filter(function(item_op) {
  //           return response.data.acceptable_countries.filter(function(item_resp) {
  //             return item_resp.country === item_op.label;
  //           }).length !== 0
  //         });
  //         setOptions(filteredArray);
  //       }
  //     }
  //   }).catch((error) => {
  //     console.log("error: ", error);
  //   });
  // }, []);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      paddingTop: 0,
    }),
  };

  const disableDates = () => {
    const today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (dd < 10) {
      dd = "0" + dd;
    }
    return yyyy + "-" + mm + "-" + dd;
  };

  const handleSubmit = () => {
    setMessage("");

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formdata.email)) {
      setMessage("Invalid email address");
      return;
    }

    if (formdata.nationality !== "" && formdata.dob !== null && formdata.email !== "" && formdata.first_name !== "" && formdata.last_name !== "") {
      let data_to_send = {
        email: formdata.email,
        first_name: formdata.first_name,
        last_name: formdata.last_name,
        dob: new Date(formdata.dob).toLocaleDateString(),
        nationality: formdata.nationality.label,
        inq_id: url,
      };
      setDisable(true);
      savebasicinfo(data_to_send)
        .then((response) => {
          if (response.data.success) {
            let formdata3 = {
              inq_id: url,
              event_desc: "Basic Information Saved",
              passed: 1,
            };
            saveevent(formdata3)
              .then((response) => {
                if (response.data.success) {
                  let temp = screenStates;
                  temp.Screen4 = true;
                  storage.setItem("screenmeta", JSON.stringify(temp));
                  const redirecturl = "/verify/e/" + url;
                  history.push(redirecturl);
                  window.location.reload(false);
                }
              })
              .catch((error) => {
                console.log("error: ", error);
                setDisable(false);
              });
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    } else {
      setMessage("All fields are mandatory!");
    }
  };

  const handleCross = () => {
    var ua = window.navigator.userAgent;

    if (ua.indexOf("iPhone") > 0) {
      window.close();
    }
    window.close();
  };
  
  return (
    <>
      <div className="screenThree h-full" style={{fontFamily: data ? data.font_family : "Gilroy"}}>
        <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
          <div className="backArrow">
            <Link to={isMobile ? "/verify/b/"+url : "/verify/c/"+url}>
              <button>
                <img src={backButtonImg} alt="backArrow" className="" />
              </button>
            </Link>
          </div>
          <div className="progressBar w-full py-3 px-12">
            <div className="relative lg:w-8/12 mx-auto">
              <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                <div
                  style={{ width: "25%" ,backgroundColor:data ? data.progressbar_color:"#a10b1d"}}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"
                ></div>
              </div>
            </div>
          </div>
          <div className="crossArrow">
            <button  onClick={handleCross}>
              <img src={closeButtonImg} alt="crossArrow" className="" />
            </button>
          </div>
        </div>

        <div className="mainContent" >
          <div className="mainText">
            <h1 style={{ lineHeight: 1 }}>Please fill out with your personal information, email address and nationality.</h1>
          </div>
          <Form className="informationSection mt-8">
            <div className="nameFields">
              <div className="firstNameField firstField">
                <div className="inputfield firstname">
                  <span className="label text-sm">First Name</span>
                  <Input 
                    type="text" 
                    name="first_name" 
                    className="input firstnameInput" 
                    value={formdata.first_name}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="lastNameField">
                <div className="inputfield lastname">
                  <span className="label text-sm">Last Name</span>
                  <Input 
                    type="text" 
                    name="last_name" 
                    className="input lastnameInput" 
                    value={formdata.last_name}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            <div className="dateField">
              <div className="inputfield dob">
                <span className="label text-sm">Date of Birth</span>
                <DatePicker 
                  selected={formdata.dob} 
                  maxDate={addDays(new Date(), 0)}
                  onChange={(date) => handleInputChangeDate(date)}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="mm/dd/yyyy"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>

            </div>
            <div className="emailFields">
              <div className="inputfield email">
                <span className="label text-sm">Email Address</span>
                {emailFound ? 
                  <Input 
                    type="email" 
                    name="email" 
                    className="input emailInput"
                    value={formdata.email}
                    onChange={handleInputChange}
                    readonly={"readonly"}
                  />
                  :
                  <Input 
                    type="email" 
                    name="email" 
                    className="input emailInput"
                    value={formdata.email}
                    onChange={handleInputChange}
                  />
                }
              </div>
            </div>
            <div className="nationalityField">
              <div className="inputfield dob">
                <span className="label text-sm">Nationality</span>
                <Select 
                  name="nationality"
                  className="nationalityInput"
                  style={{ all: "unset" }} 
                  placeholder="Search Country" 
                  options={options} 
                  value={formdata.nationality}
                  onChange={handleChangeSelect}
                  menuPortalTarget={document.body} 
                  styles={customStyles} 
                />
              </div>
            </div>

            {message && (
              <section className="form-group mt-2">
                <section
                  className="text-sm alert alert-danger"
                  role="alert"
                >
                  {message}
                </section>
              </section>
            )}
          </Form>
        </div>

        <div className="buttonAction">
          <button 
            className="action"
            style={{
              backgroundColor: data ? data.button_color : "#a10b1d",
            }}
            onClick={handleSubmit}
            disabled={disable}
          >
            Next
          </button>
        </div>

        <div className="footer" >
          <img className="footerImg" src={footerImg} alt="footer"/>
        </div>
      </div>
    </>
  );
}
  
