import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Countdown from 'react-countdown';
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import { verify_otp, send_otp } from "../../services/kyc/KycScreens";
import {KycMobContext} from "../../layouts/KYCMob";

import backButtonImg from " ../../assets/img/kycmobile/backBtn.png";
import closeButtonImg from " ../../assets/img/kycmobile/cross_white.png";
import footerImg from " ../../assets/img/kycmobile/footer_trademark.png";
import retryIcon from " ../../assets/img/kycmobile/retryIcon.png";

export default function ScreenExistsOtp(props) {
  const [disable, setDisable] = React.useState(false);
  const {data} = useContext(KycMobContext);
  const history = useHistory();
  const [message, setMessage] = useState("");
  const [resend, setResend] = useState(false);
  let stateData = props.location.state;
  let inq_id = stateData["inq_id"];

  const textBase = React.useRef(null);
  
  const [formdata, setFormdata] = useState({
    email: stateData["email"],
    pin: "",
  });

  const clearAll = () => {
    textBase.current.classList.remove("otp-error");
    textBase.current.childNodes.forEach((child) => {
      child.value = "";
    });
    setFormdata((val) => ({ ...val, ["pin"]: "" }));
  };

  const focusNext = (e) => {
    if (e.nativeEvent.inputType === "deleteContentBackward") {

    } else {
      const childCount = textBase.current.childElementCount;
      const currentIndex = [...e.target.parentNode.children].indexOf(e.target);
      if (currentIndex !== childCount - 1) {
        e.target.nextSibling.focus();
      } else {
        const values = [];
        textBase.current.childNodes.forEach((child) => {
          values.push(child.value.slice(0, 6));
        });
        if (values.length !== 0) {
          setFormdata((val) => ({ ...val, ["pin"]: parseInt(values.join("")) }));
        }
      }
    }
  };

  const handleInputChange = (event) => {
    setMessage("");
    const name = event.target.name;
    const value = event.target.value;
    if (name === "pin") {
      setFormdata((values) => ({ ...values, [name]: value.slice(0, 6) }));
    }
  };

  const timeout = () => {
    setResend(true);
  };

  const resendOtp = () => {
    let data = {
      email: formdata.email
    };
    send_otp(data).then((response) => {
      if (response.data.success) {
        setResend(false);
        setMessage("Please check you email for a new Auth code");
        const interval = setInterval(() => {
          setMessage("");
        }, 5000);

        return () => clearInterval(interval);
      }
    }).catch((error) => {
      console.log("error: ", error);
    })
  }

  const handleSubmit = () => {
    setMessage("");

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formdata.email)) {
      setMessage("Invalid email address");
      return;
    }

    if (formdata.email !== "" && formdata.pin !== "") {
      let data_to_send = {
        email: formdata.email,
        pin: formdata.pin
      };

      setDisable(true);
      verify_otp(data_to_send).then((response) => {
        if (response.data.success) {
          const redirecturl = "/verify/exists/b/" + inq_id;
          history.push(redirecturl);
          window.location.reload();
        } else {
          setDisable(false);
          setMessage("Auth code invalid, please try again!");
        }
      }).catch((error) => {
        setDisable(false);
        console.log("error: ", error);
      });
    } else {
      setMessage("Please enter Auth code to continue");
    }
  };

  const handleCross = () => {
    var ua = window.navigator.userAgent;

    if (ua.indexOf("iPhone") > 0) {
      window.close();
    }
    window.close();
  };
  
  return (
    <>
      <div className="screenNineteen screenThree screenVerifyStart h-full" style={{fontFamily: data ? data.font_family : "Gilroy"}}>
        <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
          <div className="backArrow">
            {/* <Link to={"/verify/start"}> */}
              <button>
                <img src={backButtonImg} alt="backArrow" className="" />
              </button>
            {/* </Link> */}
          </div>
          <div className="progressBar w-full py-3 px-12">
            <div className="relative lg:w-8/12 mx-auto">
              <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                <div
                  style={{ width: "50%" ,backgroundColor:data ? data.progressbar_color:"#a10b1d"}}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"
                ></div>
              </div>
            </div>
          </div>
          <div className="crossArrow">
            <button onClick={handleCross}>
              <img src={closeButtonImg} alt="crossArrow" className="" />
            </button>
          </div>
        </div>

        <div className="mainContent" >
          <div className="mainText">
            <h1 style={{ lineHeight: 1, height: "40px" }}>Check your Email</h1>
            <p className="justify-center" style={{ marginBottom: "20px", fontSize: "16px !important" }}>Auth code has been sent to your email.</p>
          </div>
          <Form className="informationSection mt-8">
            {/* <div className="emailFields">
              <div className="inputfield email">
                <span className="label text-sm">OTP</span>
                <Input 
                  type="number" 
                  name="pin" 
                  className="input emailInput"
                  value={formdata.pin}
                  onChange={handleInputChange}
                  maxLength={6}
                />
              </div>
            </div> */}
            <div className="base text-center mb-2">
              <div className="otp-base flex flex-row justify-center" ref={textBase}>
                {new Array(6).fill(null).map((input) => {
                  // remove rounded border and add only bottom dashes in input
                  return <input maxLength={"1"} type="text" className="mr-2 text-center" onChange={(e) => focusNext(e)} style={{ maxWidth: "40px", maxHeight: "35px", border: "0", borderBottom: "2px solid #ababab" }} />;
                })}
              </div>
            </div>

            {/* {resend &&  */}
            {/* add icon and make button margin from left, align with first input field */}
              <button type="button" className="text-sm flex flex-row" style={{ marginLeft: "23px" }} onClick={resendOtp}>
                <img src={retryIcon} style={{ width: "20px", marginRight: "5px" }} />
                Resend Auth code
              </button>
            {/* } */}
            {/* <Countdown
              date={Date.now() + (2 * 60 * 1000)}
              onComplete={timeout}
              renderer={({ hours, minutes, seconds, completed }) => {
                return <span className={"otptimer text-sm float-right " + (String(minutes).padStart(2, '0') === "00" ? "textRed" : "")}>{String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}</span>
              }}
            /> */}

            {message && (
              <section className="form-group mt-2">
                <section
                  className="text-sm alert alert-danger"
                  role="alert"
                >
                  {message}
                </section>
              </section>
            )}
          </Form>
        </div>

        <div className="buttonAction">
          <button 
            className="action"
            style={{
              backgroundColor: data ? data.button_color : "#a10b1d",
            }}
            onClick={handleSubmit}
            disabled={disable}
          >
            Next
          </button>
        </div>

        <div className="footer" >
          <img className="footerImg" src={footerImg} alt="footer"/>
        </div>
      </div>
    </>
  );
}
  
