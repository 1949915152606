import React from "react";
import { Link, useHistory } from "react-router-dom";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import Select from "react-select";
import { updateteammember, showteammember, getroles, deleteteammember } from "services/settings/Team";

import profileIcon from "../../assets/img/icons/profile-icon.png";
import profileIconClr from "../../assets/img/icons/profile-iconClr.png";

import teamIcon from "../../assets/img/icons/team-icon.png";
import teamIconClr from "../../assets/img/icons/team-iconClr.png";

import customizationIcon from "../../assets/img/icons/customization-icon.png";
import customizationIconClr from "../../assets/img/icons/customization-iconClr.png";

import auditIcon from "../../assets/img/icons/audit-icon.png";
import auditIconClr from "../../assets/img/icons/audit-iconClr.png";

export default function TeamEdit(props) {
  const [isShown, setIsShown] = React.useState("");
  const history = useHistory();
  const [message, setMessage] = React.useState("");
  const [formdata, setFormdata] = React.useState({});
  const defaultValueRole = null;
  const [roles, setRoles] = React.useState([]);

  React.useEffect(() => {
    getroles().then((response) => {
      // console.log(response.data.roles)
      setRoles(response.data.roles)
    }).catch((error) => {
      console.log("error", error);
    });
  }, []);

  React.useEffect(() => {
    if (!props.location.param) {
      history.push("/settings/team");
      window.location.reload();
    } else {
      showteammember(props.location.param).then((response) => {
        let userdata = {
          "full_name": response.data.user[0].name, 
          "email": response.data.user[0].email,
          "password": "",
          "image": response.data.user[0].team_detail.image, 
          "role": (response.data.user[0].team_detail.role=='admin') ? roles[0] : roles[1]
        };
        setFormdata(userdata);
      }).catch((error) => {
        console.log("error", error);
      });
    }
  }, [props.location.param, roles])
  
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 })
  }

  const handleChange = (event) => {
    const name = event.target.name;
    if (name === "image") {
      const file = event.target.files[0];
      setFormdata(values => ({...values, [name]: file}))
    } else {
      const value = event.target.value;
      setFormdata(values => ({...values, [name]: value}))
    }
  }

  const handleChangeSelect = (event, { name }) => {
    setFormdata(values => ({...values, [name]: event}))
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    formdata.role = formdata.role.value;
    // console.log("formdata: ", formdata)

    updateteammember(props.location.param, formdata).then((response) => {
      // console.log("respose: ", response);
      if (response.data.success) {
        history.push("/settings/team");
        window.location.reload();
      } else {
        setMessage(response.data.message);
      }
    }).catch((error) => {
      console.log("error", error);
      setMessage("There was an error, please try again!");
    });
  }

  const handleDelete = () => {
    deleteteammember(props.location.param).then((response) => {
      if (response.data.success) {
        history.push("/settings/team");
        window.location.reload();
      } else {
        setMessage(response.data.message);
      }
    }).catch((error) => {
      console.log("error", error);
      setMessage("There was an error, please try again!");
    });
  }

  return (
    <>
      <div className="amlscreening flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
            <div className="rounded-lg flex justify-between bg-white border-0 w-full mb-6 shadow-lg settingResp">
              <div
                className="lg:w-2/12 md:w-2/12 sm:w-12/12 nav-bg-grey py-16 rounded-lg shadow-2xl settingSideBarBack"
                style={{
                  borderTopRightRadius: "0",
                  borderBottomRightRadius: "0",
                }}
              >
                <ul className="md:min-w-full flex flex-col list-none leftSidebarNavListMain settingSideBar rounded-lg">
                  <li className="items-center mb-3">
                    <Link
                      className={
                        "text-xs  py-3 flex flex-row items-center font-bold block p-2 settingSidebarLink " +
                        (window.location.href.indexOf("/settings/profile") !==
                          -1 || isShown === "profile"
                          ? "text-black-500 hover:text-black-600 colorPrimary nav-bg-white"
                          : "text-black-700 hover:text-black-500")
                      }
                      to="/settings/profile"
                      onMouseEnter={() => setIsShown("profile")}
                      onMouseLeave={() => setIsShown("")}
                    >
                      {window.location.href.indexOf("/settings/profile") !==
                        -1 || isShown === "profile" ? (
                        <img
                          className={"w-auto mr-4 text-sm "}
                          src={profileIconClr}
                          style={{ width: "20px", display: "inline-block" }}
                          alt="innreg icon"
                        />
                      ) : (
                        <img
                          className={"w-auto mr-4 text-sm "}
                          src={profileIcon}
                          style={{ width: "20px", display: "inline-block" }}
                          alt="innreg icon"
                        />
                      )}{" "}
                      <p style={{ letterSpacing: "1px" }}>Profile</p>
                    </Link>
                  </li>
                  <li className="items-center mb-3">
                    <Link
                      className={
                        "text-xs py-3 flex flex-row items-center font-bold block px-2 settingSidebarLink " +
                        (window.location.href.indexOf("/settings/team") !==
                          -1 || isShown === "team"
                          ? "text-black-500 hover:text-black-600 colorPrimary nav-bg-white"
                          : "text-black-700 hover:text-black-500")
                      }
                      to="/settings/team"
                      onMouseEnter={() => setIsShown("team")}
                      onMouseLeave={() => setIsShown("")}
                    >
                      {window.location.href.indexOf("/settings/team") !== -1 ||
                      isShown === "team" ? (
                        <img
                          className={"w-auto mr-4 text-sm "}
                          src={teamIconClr}
                          style={{ width: "25px", display: "inline-block" }}
                          alt="innreg icon"
                        />
                      ) : (
                        <img
                          className={"w-auto mr-4 text-sm "}
                          src={teamIcon}
                          style={{ width: "25px", display: "inline-block" }}
                          alt="innreg icon"
                        />
                      )}{" "}
                      <p style={{ letterSpacing: "1px" }}>Team</p>
                    </Link>
                  </li>
                  <li className="items-center mb-3">
                    <Link
                      className={
                        "text-xs py-3 flex flex-row items-center font-bold block px-2 settingSidebarLink " +
                        (window.location.href.indexOf("/settings/kyc") !== -1 ||
                        isShown === "kyc"
                          ? "text-black-500 hover:text-black-600 colorPrimary nav-bg-white"
                          : "text-black-700 hover:text-black-500")
                      }
                      to="/settings/kyc"
                      onMouseEnter={() => setIsShown("kyc")}
                      onMouseLeave={() => setIsShown("")}
                    >
                      {window.location.href.indexOf("/settings/kyc") !== -1 ||
                      isShown === "kyc" ? (
                        <img
                          className={"w-auto mr-4 text-sm "}
                          src={customizationIconClr}
                          style={{ width: "23px", display: "inline-block" }}
                          alt="innreg icon"
                        />
                      ) : (
                        <img
                          className={"w-auto mr-4 text-sm "}
                          src={customizationIcon}
                          style={{ width: "23px", display: "inline-block" }}
                          alt="innreg icon"
                        />
                      )}{" "}
                      <p style={{ letterSpacing: "1px" }}>Customization</p>
                    </Link>
                  </li>
                  <li className="items-center mb-3">
                    <Link
                      className={
                        "text-xs flex flex-row items-center  py-3 font-bold block px-2 settingSidebarLink " +
                        (window.location.href.indexOf("/settings/audit") !==
                          -1 || isShown === "audit"
                          ? "text-black-500 hover:text-black-600 colorPrimary nav-bg-white"
                          : "text-black-700 hover:text-black-500")
                      }
                      to="/settings/audit"
                      onMouseEnter={() => setIsShown("audit")}
                      onMouseLeave={() => setIsShown("")}
                    >
                      {window.location.href.indexOf("/settings/audit") !== -1 ||
                      isShown === "audit" ? (
                        <img
                          className={"w-auto mr-4 text-sm "}
                          src={auditIconClr}
                          style={{ width: "23px", display: "inline-block" }}
                          alt="innreg icon"
                        />
                      ) : (
                        <img
                          className={"w-auto mr-4 text-sm "}
                          src={auditIcon}
                          style={{ width: "23px", display: "inline-block" }}
                          alt="innreg icon"
                        />
                      )}{" "}
                      <p style={{ letterSpacing: "1px" }}>Audit Logs</p>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="lg:w-10/12 md:w-10/12 sm:w-12/12 px-12 py-16">
                <div className="row flex flex-row w-full px-4">
                  <h2 className="font-bold text-lg pb-2" style={{ borderBottom: "2px solid #a10b1d" }}>Add Team Member</h2>
                </div>
                <div className="row flex flex-row w-full px-4">
                  <Form 
                    class="kycPersonalDetailsForm mt-10 row w-full lg:w-12/12" 
                    onSubmit={handleSubmit}
                  >
                    <div className="row flex flex-row w-full flex-wrap">
                      <div className="lg:w-5/12 md:w-12/12 sm:w-12/12 field relative w-full mb-6 mr-2">
                        <label
                          className="label block text-blueGray-600 text-xs mb-2"
                        >
                          Full Name
                        </label>
                        <Input
                          type="text"
                          name="full_name"
                          className="input-field border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          value={formdata.full_name}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="lg:w-5/12 md:w-12/12 sm:w-12/12 field relative w-full mb-6">
                        <label
                          className="label block text-blueGray-600 text-xs mb-2"
                        >
                          Email
                        </label>
                        <Input
                          type="email"
                          name="email"
                          className="input-field border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          value={formdata.email}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="row flex flex-row w-full flex-wrap">
                      <div className="lg:w-5/12 md:w-12/12 sm:w-12/12 field relative w-full mb-6 mr-2">
                        <label
                          className="label block text-blueGray-600 text-xs mb-2"
                        >
                          Password
                        </label>
                        <Input
                          type="password"
                          name="password"
                          className="input-field border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          value={formdata.password}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="lg:w-5/12 md:w-12/12 sm:w-12/12 field relative w-full mb-6">
                        <label
                          className="label block text-blueGray-600 text-xs mb-2"
                        >
                          Role
                        </label>
                        <Select 
                          name="role"
                          options={roles}
                          isSearchable={false}
                          value={formdata.role}
                          menuPortalTarget={document.body} 
                          onChange={handleChangeSelect}
                          styles={customStyles} 
                        />
                      </div>
                    </div>

                    <div className="row flex flex-row w-full flex-wrap">
                      <div className="lg:w-5/12 md:w-12/12 sm:w-12/12 field relative w-full mb-6 mr-2">
                        <label
                          className="label block text-blueGray-600 text-xs mb-2"
                        >
                          Image
                        </label>
                        <Input
                          type="file"
                          name="image"
                          className="input-field border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    {message && (
                      <div className="form-group">
                        <div className="alert alert-danger" role="alert">
                          {message}
                        </div>
                      </div>
                    )}

                    <button type="submit" class="buttonPink px-6 py-2 rounded-lg text-base font-bold mr-4">Update Member</button>
                    <button onClick={handleDelete} type="button" class="buttonPink px-6 py-2 rounded-lg text-base font-bold" style={{ background: "#ebedf2", color: "#a10b1d" }}>Delete Member</button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
