import React from "react";
import { Link } from "react-scroll";
import Chart from "chart.js";
import ReactLoading from 'react-loading';
import DatePicker from "react-datepicker";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import "react-datepicker/dist/react-datepicker.css";
import ReactApexChart from "react-apexcharts";
import { getlinechartvalues, getradialchartvalues } from "../../services/dashboard/Graphs";

class CardLineChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateRange: [null, null],
      // area chart
      seriesArea: [
        {
          name: "Inquiries",
          data: [31000, 40000, 28000, 51000, 42000, 109000, 100000],
        },
      ],
      optionsArea: {
        chart: {
          height: 350,
          type: "area",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "datetime",
          categories: [
            "2018-09-19T00:00:00.000Z",
            "2018-09-19T01:30:00.000Z",
            "2018-09-19T02:30:00.000Z",
            "2018-09-19T03:30:00.000Z",
            "2018-09-19T04:30:00.000Z",
            "2018-09-19T05:30:00.000Z",
            "2018-09-19T06:30:00.000Z",
          ],
        },
        tooltip: {
          x: {
            format: "dd/MM/yy HH:mm",
          },
        },
      },

      // radial chart
      seriesRadial: [70, 75, 77, 83, 73],
      optionsRadial: {
        chart: {
          height: 400,
          width: 350,
          type: "radialBar",
          toolbar: {
            show: true,
            export: {
              csv: {
                filename: "Inquiry Breakdown",
              }
            }
          },
        },
        colors: ["#A8A8A8", "#FA364C", "#15E49A", "#FFC102", "#2AA2EB", "#2B2B2B", "#a10b1d"],
        legend: {
          show: true,
          floating: true,
          fontSize: "11px",
          position: "left",
          fontFamily: "Gilroy",
          color: "",
          offsetX: 50,
          offsetY: 5,
          labels: {
            useSeriesColors: false,
          },
          formatter: function (seriesName) {
            return seriesName;
          },
          itemMargin: {
            vertical: 0,
          },
        },
        plotOptions: {
          radialBar: {
            inverseOrder: false,
            startAngle: 0,
            endAngle: 270,
            offsetX: 0,
            offsetY: 0,
            hollow: {
              margin: 5,
              size: "40%",
              background: "transparent",
              image: undefined,
              imageWidth: 150,
              imageHeight: 150,
              imageOffsetX: 0,
              imageOffsetY: 0,
              imageClipped: true,
              position: "front",
              dropShadow: {
                enabled: false,
                top: 0,
                left: 0,
                blur: 3,
                opacity: 0.5,
              },
            },
            track: {
              show: true,
              // startAngle: undefined,
              // endAngle: undefined,
              // background: "#fff",
              strokeWidth: "100%",
              opacity: 1,
              // margin: 5,
              dropShadow: {
                enabled: false,
                top: 0,
                left: 0,
                blur: 3,
                opacity: 0.5,
              },
            },
            dataLabels: {
              show: true,
              name: {
                show: false,
                fontSize: "16px",
                fontFamily: undefined,
                fontWeight: 600,
                color: undefined,
                offsetY: -10,
              },
              value: {
                show: true,
                fontSize: "20px",
                fontFamily: "Gilroy",
                fontWeight: 700,
                color: "#ababab",
                offsetY: 0,
                formatter: function (val) {
                  return val;
                },
              },
              total: {
                show: false,
                label: "Total",
                color: "#373d3f",
                fontSize: "16px",
                fontFamily: undefined,
                fontWeight: 600,
                formatter: function (w) {
                  return (
                    w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0)
                  );
                },
              },
            },
          },
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
             
            },
          },
        ],
        labels: ["Option 1", "Option 2", "Option 3", "Option 4", "Option 5"],
      },
      width: window.innerWidth,
      todayActive: true,
      yesterdayActive: false,
      lastWeekActive: false,
      lastMonthActive: false,

      graphStatus: "Today",

      dataLoaded: false,
      radialLoaded: false,
    };
  }

  setTdActive = () => {
    getlinechartvalues("today").then((response) => {
      if (response.data.success) {
        this.setState({
          todayActive: true,
          yesterdayActive: false,
          lastWeekActive: false,
          lastMonthActive: false,
          graphStatus: "Today",
        });

        response.data.data.time.sort((a, b) => a.localeCompare(b));

        this.setState({optionsArea: {
          ...this.state.optionsArea,
          xaxis: {
            ...this.state.optionsArea.xaxis,
            categories: response.data.data.time
          },
        }});

        this.setState({seriesArea: [{
          ...this.state.seriesArea,
          data: response.data.data.inquiries
        }]});
      }
    }).catch((error) => {
      console.log("error: ", error);
    });
  };

  setYesActive = () => {
    getlinechartvalues("yesterday").then((response) => {
      if (response.data.success) {
        this.setState({
          todayActive: false,
          yesterdayActive: true,
          lastWeekActive: false,
          lastMonthActive: false,
          graphStatus: "Yesterday",
        });

        response.data.data.time.sort((a, b) => a.localeCompare(b));

        this.setState({optionsArea: {
          ...this.state.optionsArea,
          xaxis: {
            ...this.state.optionsArea.xaxis,
            categories: response.data.data.time
          },
        }});
        
        this.setState({seriesArea: [{
          ...this.state.seriesArea,
          data: response.data.data.inquiries
        }]});
      }
    }).catch((error) => {
      console.log("error: ", error);
    });
  };
  
  setLstWkActive = () => {
    getlinechartvalues("lastweek").then((response) => {
      if (response.data.success) {
        this.setState({
          todayActive: false,
          yesterdayActive: false,
          lastWeekActive: true,
          lastMonthActive: false,
          graphStatus: "Last week",
        });

        response.data.data.time.sort((a, b) => a.localeCompare(b));

        this.setState({optionsArea: {
          ...this.state.optionsArea,
          xaxis: {
            ...this.state.optionsArea.xaxis,
            categories: response.data.data.time
          },
        }});
        
        this.setState({seriesArea: [{
          ...this.state.seriesArea,
          data: response.data.data.inquiries
        }]});
      }
    }).catch((error) => {
      console.log("error: ", error);
    });
  };

  setLstMnthTActive = () => {
    getlinechartvalues("lastmonth").then((response) => {
      if (response.data.success) {
        this.setState({
          todayActive: false,
          yesterdayActive: false,
          lastWeekActive: false,
          lastMonthActive: true,
          graphStatus: "Last month",
        });

        response.data.data.time.sort((a, b) => a.localeCompare(b));

        this.setState({optionsArea: {
          ...this.state.optionsArea,
          xaxis: {
            ...this.state.optionsArea.xaxis,
            categories: response.data.data.time
          },
        }});
        
        this.setState({seriesArea: [{
          ...this.state.seriesArea,
          data: response.data.data.inquiries
        }]});
      }
    }).catch((error) => {
      console.log("error: ", error);
    });
  };

  componentDidMount() {
    getlinechartvalues("today").then((response) => {
      if (response.data.success) {
        this.setState({dataLoaded: true});
        response.data.data.time.sort((a, b) => a.localeCompare(b));
        this.setState({optionsArea: {
          ...this.state.optionsArea,
          xaxis: {
            ...this.state.optionsArea.xaxis,
            categories: response.data.data.time
          },
        }});
        this.setState({seriesArea: [{
          ...this.state.seriesArea,
          data: response.data.data.inquiries
        }]});
      }
    }).catch((error) => {
      console.log("error: ", error);
    });

    getradialchartvalues().then((response) => {
      if (response.data.success) {
        if (response.data.percentages.length > 0) {
          let sumTotal = response.data.percentages[0];
          let percentagesRadial = [];
          response.data.percentages.forEach((ele) => {
            let perc = parseFloat((ele/sumTotal*100).toFixed(2));
            percentagesRadial.push(perc);
          });
          this.state.optionsRadial.plotOptions.radialBar.dataLabels.value.formatter = (val) => { return parseInt((val*sumTotal)/100) };
          this.setState({radialLoaded: true});
          this.setState({seriesRadial: percentagesRadial});
          this.setState({optionsRadial: {
            ...this.state.optionsRadial,
            labels: response.data.checks
          }});
        }
      }
    }).catch((error) => {
      console.log("error: ", error);
    })

    const handleResize = () => {
      this.setState({ width: window.innerWidth });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize");
    };
  }

  render() {
    return (
      <>
        <div className="relative flex flex-row flex-wrap min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white p-8 pt-0 cardLineChart ">
          <div className="w-full flex  items-center mt-8 cardLineTopBar">
              <div className="px-4 mr-4 cursor-pointer "
                style={{  marginLeft:"20px" }}
              >
                <h4
                  className="borderDefault summaryHeading py-2 font-bold text-base"
                  style={{ borderBottom: "2px solid #a10b1d" }}
                >
                  Overview
                </h4>
              </div>
              <div className="flex flex-row cardLineDates" style={{marginLeft:"5%"}}>
              <div className="flex flex-row items-center sm:mt-0">
                <div
                  className="flex justify-between items-center rounded-lg px-4 py-2 mr-4 cursor-pointer"
                  style={
                    this.state.todayActive
                      ? { backgroundColor: "#EBEDF2" }
                      : { backgroundColor: "#FFF" }
                  }
                  onClick={this.setTdActive}
                >
                  <h4 className="font-semibold text-xs">Today</h4>
                </div>
                <div
                  className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                  style={
                    this.state.yesterdayActive
                      ? { backgroundColor: "#EBEDF2" }
                      : { backgroundColor: "#FFF" }
                  }
                  onClick={this.setYesActive}
                >
                  <h4 className="font-semibold text-xs">Yesterday</h4>
                </div>
                <div
                  className="flex justify-between items-center rounded-lg px-4 py-2 mr-4 cursor-pointer"
                  style={
                    this.state.lastWeekActive
                      ? { backgroundColor: "#EBEDF2" }
                      : { backgroundColor: "#FFF" }
                  }
                  onClick={this.setLstWkActive}
                >
                  <h4 className="font-semibold text-xs">Last Week</h4>
                </div>
                <div
                  className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                  style={
                    this.state.lastMonthActive
                      ? { backgroundColor: "#EBEDF2" }
                      : { backgroundColor: "#FFF" }
                  }
                  onClick={this.setLstMnthTActive}
                >
                  <h4 className="font-semibold text-xs">Last Month</h4>
                </div>
              </div>
              <div className="flex flex-row items-center ml-24 ">
                <Form
                  class="filtersTable"
                  // onSubmit={(e) => handleLogin(e)}
                  // ref={c => {form = c;}}
                >
                  <div className="flex justify-between items-center justify-center mt-4">
                    <div className="searchInputTables linechartfilter">
                      <div className="fieldSearch relative w-full mb-3">
                        <label className="label block font-semibold text-black-600 text-xs mb-2">
                          Search by date
                        </label>
                        <DatePicker
                          portalId="root-portal"
                          selectsRange={true}
                          startDate={this.state.dateRange[0]}
                          endDate={this.state.dateRange[1]}
                          onChange={(update) => {
                            this.setState({dateRange: update})
                          }}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          peekNextMonth
                          renderCustomHeader={({
                            monthDate,
                            customHeaderCount,
                            decreaseMonth,
                            increaseMonth,
                          }) => (
                            <div>
                              <button
                                aria-label="Previous Month"
                                className={
                                  "react-datepicker__navigation react-datepicker__navigation--previous"
                                }
                                style={customHeaderCount === 1 ? { visibility: "hidden" } : null}
                                onClick={decreaseMonth}
                              >
                                <span
                                  className={
                                    "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                                  }
                                >
                                  {"<"}
                                </span>
                              </button>
                              <span className="react-datepicker__current-month">
                                {monthDate.toLocaleString("en-US", {
                                  month: "long",
                                  year: "numeric",
                                })}
                              </span>
                              <button
                                aria-label="Next Month"
                                className={
                                  "react-datepicker__navigation react-datepicker__navigation--next"
                                }
                                style={customHeaderCount === 0 ? { visibility: "hidden" } : null}
                                onClick={increaseMonth}
                              >
                                <span
                                  className={
                                    "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                                  }
                                >
                                  {">"}
                                </span>
                              </button>
                            </div>
                          )}
                          monthsShown={2}
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
              </div>
            </div>
          <div className="rounded-t mb-0 px-4 py-3 bg-transparent xl:w-8/12 lg:w-12/12 md:w-12/12 sm:w-12/12 areaChartDiv">
            <div className="flex flex-wrap items-center justify-between">
              



              <div className="flex-auto w-full">
                {/* Chart */}
                <h4 className="py-4 font-light text-xs textGray">
                  Inquiry Status ({this.state.graphStatus})
                </h4>
                <div className="relative h-350-px">
                  {!this.state.dataLoaded ? 
                    <ReactLoading
                      className="margin-auto mt-3 mb-3"
                      type={"bars"}
                      color={"#a10b1d"}
                      height={"20%"}
                      width={"20%"}
                    />
                    :
                    <ReactApexChart
                      options={this.state.optionsArea}
                      series={this.state.seriesArea}
                      type="area"
                      height={300}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="radialChartDash flex justify-center rounded-t mb-0 px-4 py-3 bg-transparent xl:w-4/12 lg:w-12/12 md:w-12/12 sm:w-12/12">
            {!this.state.radialLoaded ? 
              <ReactLoading
                className="margin-auto mt-3 mb-3"
                type={"bars"}
                color={"#a10b1d"}
                height={"20%"}
                width={"20%"}
              />
              :
              <ReactApexChart
                options={this.state.optionsRadial}
                series={this.state.seriesRadial}
                type="radialBar"
                height={350}
                width={350}
              />
            }
          </div>
        </div>
      </>
    );
  }
}

export default CardLineChart;
