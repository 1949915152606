import React from "react";
import "react-circular-progressbar/dist/styles.css";
import { Link as Link1 } from "react-scroll";
import "react-image-gallery/styles/css/image-gallery.css";
import { useHistory, useParams } from "react-router";
import {
  // viewdetail,
  // saveassigned,
  // updatestatus,
  getevents,
  // revisesection
} from "services/casemanagement/Onboarding";
import {
  viewdetail,
  updatestatus,
  // saveassigned,
  // updatestatus,
  // getevents,
  // revisesection
} from "services/casemanagement/OnboardingKYB";
import Select from "react-select";
import ReactLoading from "react-loading";
import Moment from "moment";
import { Link as Link2 } from "react-router-dom";
import { showallmembers } from "services/settings/Team";
import { saveevent } from "services/kyc/KycScreens";

// components
import { MemoizedMap } from "components/Maps/MapExample.js";

// images
import downloadIcon from "../../assets/img/icons/downloadIcon.png";
import ElipsesOne from "../../assets/img/event_elp_1.png";
import ElipsesTwo from "../../assets/img/event_elp_2.png";
import ElipsesError from "../../assets/img/event_elp_error.png";
import ElipsesYellow from "../../assets/img/event_elp_yellow.png";
import VerticalLine from "../../assets/img/verticalLine.png";
import dropDownIcon from "../../assets/img/dropdown.png";
import idVerRectImg from "../../assets/img/idVerRectImg.png";
import longVertLine from "../../assets/img/longVertLine.png";
import smallVertLine from "../../assets/img/smallVertLine.png";
import locIcon from "../../assets/img/locIcon.png";

export default function CaseManagementDetailOnboarding(props) {
  const { inq_id } = useParams();
  const history = useHistory();
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [assignedTo, setAssignedTo] = React.useState(null);
  const [inquiryStatus, setInquiryStatus] = React.useState(null);
  const [users, setUsers] = React.useState([]);
  const [onboardingData, setOnboardingData] = React.useState([]);
  const [onboardingEventData, setOnboardingEventData] = React.useState([]);
  const [onboardingEventDataLength, setOnboardingEventDataLength] = React.useState(0);
  const [documents, setDocuments] = React.useState([]);

  const [idVerActive, setIdVerActive] = React.useState(true);
  const [addressVerActive, setAddressVerActive] = React.useState(false);
  const [eventTimeActive, setEventTimeActive] = React.useState(false);

  const [idShow, setIdShow] = React.useState(true);
  const [addShow, setAddShow] = React.useState(true);
  const [eventShow, setEventShow] = React.useState(true);

  // google maps script include
  React.useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://maps.googleapis.com/maps/api/js?key="+process.env.REACT_APP_GOOGLE_API_KEY;
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, []);

  const submitRevisit = () => {
    // let formdata = {
    //   inq_id: inq_id,
    //   section: revisitSection,
    //   note: revisitNotes
    // };

    // revisesection(formdata).then((response) => {
    //   // console.log("response: ", response);
    //   if (response.data.success) {
    //     setRevisit(false);
    //     setRevisitSection(null);
    //     setRevisitNotes(null);
    //     setRevisionSent(true);
    //     let timeout = setTimeout(() => setRevisionSent(false), 2000);
    //     return () => clearTimeout(timeout);
    //   }
    // }).catch((error) => {
    //   console.log("error: ", error);
    // });
  }

  const customStylesSelect = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#828282" : "#828282",
      backgroundColor: state.isSelected ? "#EBEDF2" : "#FFF",
      border: 0,
      padding: 10,
      fontSize: "14px",
    }),
  };

  React.useEffect(() => {
    if (!inq_id) {
      history.push("/case/corporate/casemanagement");
      window.location.reload();
    } else {
      viewdetail(inq_id)
        .then((response) => {
          if (response.data.success) {
            // console.log("response: ", response);
            setOnboardingData(response.data.onboardings);
            setInquiryStatus(response.data.onboardings.status);
            setAssignedTo(response.data.onboardings.assigned_to);
            setDocuments(response.data.docs);

            setDataLoaded(true);
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });

      getevents(inq_id)
        .then((response) => {
          // console.log("response: ", response);
          if (response.data.success) {
            setOnboardingEventData(response.data.event);
            if (response.data.event.length > 0) {
              setOnboardingEventDataLength(response.data.event.length);
            }
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });

      showallmembers()
        .then((response) => {
          // console.log("response: ", response);
          if (response.data.members.length > 0) {
            let userarray = response.data.members.map((ele) => ({
              label: ele.member,
              value: ele.id,
            }));
            setUsers(userarray);
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    }
  }, []);

  function setIdActive() {
    setIdVerActive(true);
    setAddressVerActive(false);
    setEventTimeActive(false);
  }
  function setAddActive() {
    setIdVerActive(false);
    setAddressVerActive(true);
    setEventTimeActive(false);
  }
  function setEvTActive() {
    setIdVerActive(false);
    setAddressVerActive(false);
    setEventTimeActive(true);
  }

  const handleChangeAssignedTo = (event, { name }) => {
    // let formdata = {
    //   inq_id: inq_id,
    //   assigned_to: event.value,
    // };
    // saveassigned(formdata)
    //   .then((response) => {
    //     setAssignedTo(event.value);
    //     // console.log("response: ", response);
    //   })
    //   .catch((error) => {
    //     console.log("error: ", error);
    //   });
  };

  const setStatus = (status) => {
    let formdata = {
      inq_id: inq_id,
      status: status,
    };
    updatestatus(formdata)
      .then((response) => {
        // console.log("response: ", response);
        if (response.data.success) {
          const storage = window.localStorage;
          const username = storage.getItem("user_name");
          let formdata1 = {
            inq_id: inq_id,
            event_desc: "",
            passed: 0,
          };
          if (status === "approve") {
            formdata1 = {
              inq_id: inq_id,
              event_desc: "Inquiry approved by " + username,
              passed: 1,
            };
          } else if (status === "review") {
            formdata1 = {
              inq_id: inq_id,
              event_desc: "Inquiry under review by " + username,
              passed: 2,
            };
          } else if (status === "decline") {
            formdata1 = {
              inq_id: inq_id,
              event_desc: "Inquiry declined by " + username,
              passed: 3,
            };
          }
          saveevent(formdata1)
            .then((response) => {
              if (response.data.success) {
                setInquiryStatus(status);
                history.push("/case/corporate/casemanagement");
                window.location.reload();
              }
            })
            .catch((error) => {
              console.log("error: ", error);
            });
        }
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  return (
    <>
      {dataLoaded && (
        <div className="casemanagement casemanagementdetail flex flex-wrap">
          <div className="w-full lg:w-9/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
              <div className="rounded-lg flex justify-between items-center bg-white px-6 py-6 border-0 w-full mb-6 shadow-lg">
                <div className="flex items-center flex-wrap justify-between">
                  <div
                    className="px-4 cursor-pointer"
                    style={{ paddingLeft: "0" }}
                  >
                    <h4 className="borderDefault summaryHeading pb-1 font-bold text-base">
                      Summary
                    </h4>
                  </div>
                </div>
              </div>

              <div className="rounded-lg flex justify-between items-center bg-white px-6 py-6 border-0 w-full mb-6 shadow-lg">
                <div className="flex flex-wrap">
                  <Link1 to="idRef" spy={true} smooth={true}>
                    <div
                      className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                      style={
                        idVerActive
                          ? { backgroundColor: "#EBEDF2" }
                          : { backgroundColor: "#FFF" }
                      }
                      onClick={setIdActive}
                    >
                      <h4 className="font-semibold text-xs">
                        Company
                      </h4>
                    </div>
                  </Link1>
                  <Link1 to="addressRef" spy={true} smooth={true}>
                    <div
                      className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                      style={
                        addressVerActive
                          ? { backgroundColor: "#EBEDF2" }
                          : { backgroundColor: "#FFF" }
                      }
                      onClick={setAddActive}
                    >
                      <h4 className="font-semibold text-xs">
                        Documents
                      </h4>
                    </div>
                  </Link1>
                  <Link1 to="evtRef" spy={true} smooth={true}>
                    <div
                      className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                      style={
                        eventTimeActive
                          ? { backgroundColor: "#EBEDF2" }
                          : { backgroundColor: "#FFF" }
                      }
                      onClick={setEvTActive}
                    >
                      <h4 className="font-semibold text-xs">
                        Event Timeline
                      </h4>
                    </div>
                  </Link1>
                  <Link2
                    to={{
                      pathname: "/case/corporate/kyc/casemanagement/"+onboardingData.inq_id,
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="flex justify-between items-center rounded-lg px-4 py-2 cursor-pointer"
                    >
                      <h4 className="font-semibold text-xs">
                        KYC's
                      </h4>
                    </div>
                  </Link2>
                </div>
              </div>

              <div id="idRef" className="id-verification relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
                <div className="block w-full overflow-x-auto pb-6">
                  <div
                    className="rounded-lg flex justify-between items-center px-12 py-6 border-0 w-full cursor-pointer"
                    onClick={() => setIdShow(!idShow)}
                  >
                    <div className="flex">
                      <div
                        className="px-4 mr-4 cursor-pointer"
                        style={{ paddingLeft: "0" }}
                      >
                        <h4 className="borderDefault summaryHeading py-2 font-bold text-base">
                          Company
                        </h4>
                      </div>
                    </div>
                    <div className="flex px-4 py-2 cursor-pointer">
                      {!idShow && (
                        <img
                          src={dropDownIcon}
                          alt="down icon"
                          className="mr-3"
                          style={{ width: "15px", height: "10px" }}
                        />
                      )}
                      {idShow && (
                        <img
                          src={dropDownIcon}
                          alt="down icon"
                          className="mr-3 dropDownIconRotated"
                          style={{ width: "15px", height: "10px" }}
                        />
                      )}
                    </div>
                  </div>
                  {idShow && (
                    <div className="flex justify-between flex-wrap w-full px-12">
                      <div className="px-4 sm:px-4 xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 mb-4">
                        <div className="flex flex-col idType">
                          <div className="documentOCR xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 pb-4">
                            <h4 className="font-bold text-sm mb-3">
                              Company Information
                            </h4>
                            <table className="w-full table-fixed">
                              <tbody>
                                <tr>
                                  <td className="font-light text-xs">
                                    Legal Name
                                  </td>
                                  <td className="font-semibold text-xs text-right">
                                    {onboardingData.companyinfo ? (onboardingData.companyinfo.legal_name ? onboardingData.companyinfo.legal_name : "N/A") : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">
                                    Legal Entity Type
                                  </td>
                                  <td className="font-semibold text-xs text-right">
                                    {onboardingData.companyinfo ? (onboardingData.companyinfo.legal_entity_type ? onboardingData.companyinfo.legal_entity_type : "N/A") : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">
                                    Legal Jurisdiction
                                  </td>
                                  <td className="font-semibold text-xs text-right">
                                    {onboardingData.companyinfo ? (onboardingData.companyinfo.legal_jurisdiction ? onboardingData.companyinfo.legal_jurisdiction : "N/A") : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">
                                    Registered Address
                                  </td>
                                  <td className="font-semibold text-xs text-right">
                                    {onboardingData.companyinfo ? (onboardingData.companyinfo.registered_address ? onboardingData.companyinfo.registered_address : "N/A") : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">
                                    Email Address
                                  </td>
                                  <td className="font-semibold text-xs text-right">
                                    {onboardingData.companyinfo ? (onboardingData.companyinfo.email ? onboardingData.companyinfo.email : "N/A") : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">
                                    Registration Number
                                  </td>
                                  <td className="font-semibold text-xs text-right">
                                    {onboardingData.companyinfo ? (onboardingData.companyinfo.registration_no ? onboardingData.companyinfo.registration_no : "N/A") : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">
                                    Trading Name
                                  </td>
                                  <td className="font-semibold text-xs text-right">
                                    {onboardingData.companyinfo ? (onboardingData.companyinfo.trading_name ? onboardingData.companyinfo.trading_name : "N/A") : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">
                                    Website
                                  </td>
                                  <td className="font-semibold text-xs text-right">
                                    <Link2
                                      to={{
                                        pathname: onboardingData.companyinfo.website ? "http://"+onboardingData.companyinfo.website : "#",
                                      }}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {onboardingData.companyinfo ? (onboardingData.companyinfo.website ? onboardingData.companyinfo.website : "N/A") : "N/A"}
                                    </Link2>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-light text-xs">
                                    Date of Incorporation
                                  </td>
                                  <td className="font-semibold text-xs text-right">
                                    {onboardingData.companyinfo.date_of_incorp
                                      ? onboardingData.companyinfo.date_of_incorp.includes("T") ?
                                        Moment(onboardingData.companyinfo.date_of_incorp.split("T")[0]).format(
                                          "MM-DD-YYYY"
                                        )
                                        :
                                        (Moment(onboardingData.companyinfo.date_of_incorp, "DD/MM/YYYY").format("MM-DD-YYYY") === "Invalid date")
                                          ? Moment(onboardingData.companyinfo.date_of_incorp).format(
                                            "MM-DD-YYYY"
                                          ) : Moment(onboardingData.companyinfo.date_of_incorp, "DD/MM/YYYY").format(
                                            "MM-DD-YYYY"
                                          )
                                      : 
                                      "N/A"
                                    }
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div id="addressRef" className="address-verification relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
                <div className="block w-full overflow-x-auto pb-6">
                  <div
                    className="rounded-lg flex justify-between items-center px-12 py-6 border-0 w-full cursor-pointer"
                    onClick={() => setAddShow(!addShow)}
                  >
                    <div className="flex">
                      <div
                        className="px-4 mr-4 cursor-pointer"
                        style={{ paddingLeft: "0" }}
                      >
                        <h4 className="borderDefault summaryHeading py-2 font-bold text-base">
                          Documents
                        </h4>
                      </div>
                    </div>
                    <div className="flex px-4 py-2 cursor-pointer">
                      {!addShow && (
                        <img
                          src={dropDownIcon}
                          alt="down icon"
                          className="mr-3"
                          style={{ width: "15px", height: "10px" }}
                        />
                      )}
                      {addShow && (
                        <img
                          src={dropDownIcon}
                          alt="down icon"
                          className="mr-3 dropDownIconRotated"
                          style={{ width: "15px", height: "10px" }}
                        />
                      )}
                    </div>
                  </div>
                  {addShow && (
                    <div className="flex flex-wrap w-full px-12">
                      <div className="col-1 px-4 xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 mb-4">
                        <div className="flex flex-col idType">
                          {documents.coa.path && 
                            <>
                              <span className="text-stone-500 uppercase text-xs font-bold">
                                Document Type
                              </span>
                              <span className="text-sm font-bold ">Certificate of Incorporation</span>
                              <div className="idImage relative">
                                {documents.coa.ext === "pdf" ? 
                                  <embed 
                                    src={documents.coa.path} 
                                    type="application/pdf" 
                                    width={"200px"}
                                    height={"200px"} 
                                    style={{width: "100%"}}
                                  />
                                  :
                                  <img
                                    className="py-4 pb-0 w-full"
                                    src={
                                      documents.coa.path
                                        ? documents.coa.path
                                        : idVerRectImg
                                    }
                                    alt="addressverifimg"
                                    width={"200px"}
                                    height={"200px"}
                                    style={{maxHeight: "240px",objectFit: "cover"}}
                                  />
                                }
                                <div className="flex justify-between items-center">
                                  <Link2
                                    to={{
                                      pathname: documents.coa.path
                                        ? documents.coa.path
                                        : "#",
                                    }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <div className="flex flex-row imgDownloadButton cursor-pointer imgOverButtons absolute text-sm">
                                      <img
                                        src={downloadIcon}
                                        alt="downloadicon"
                                      />
                                    </div>
                                  </Link2>
                                </div>
                              </div>
                            </>
                          }
                        </div>
                      </div>
                      <div className="col-1 px-4 xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 mb-4">
                        <div className="flex flex-col idType">
                          {documents.aoa.path && 
                            <>
                              <span className="text-stone-500 uppercase text-xs font-bold">
                                Document Type
                              </span>
                              <span className="text-sm font-bold ">Article of Association / Memorandum of Association / Bylaws</span>
                              <div className="idImage relative">
                                {documents.aoa.ext === "pdf" ? 
                                  <embed 
                                    src={documents.aoa.path} 
                                    type="application/pdf" 
                                    width={"200px"}
                                    height={"200px"} 
                                    style={{width: "100%"}}
                                  />
                                  :
                                  <img
                                    className="py-4 pb-0 w-full"
                                    src={
                                      documents.aoa.path
                                        ? documents.aoa.path
                                        : idVerRectImg
                                    }
                                    alt="addressverifimg"
                                    width={"200px"}
                                    height={"200px"}
                                    style={{maxHeight: "240px",objectFit: "cover"}}
                                  />
                                }
                                <div className="flex justify-between items-center">
                                  <Link2
                                    to={{
                                      pathname: documents.aoa.path
                                        ? documents.aoa.path
                                        : "#",
                                    }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <div className="flex flex-row imgDownloadButton cursor-pointer imgOverButtons absolute text-sm">
                                      <img
                                        src={downloadIcon}
                                        alt="downloadicon"
                                      />
                                    </div>
                                  </Link2>
                                </div>
                              </div>
                            </>
                          }
                        </div>
                      </div>
                      <div className="col-1 px-4 xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12">
                        <div className="flex flex-col idType">
                          {documents.al.path && 
                            <>
                              <span className="text-stone-500 uppercase text-xs font-bold">
                                Document Type
                              </span>
                              <span className="text-sm font-bold ">Authorization Letter</span>
                              <div className="idImage relative">
                                {documents.al.ext === "pdf" ? 
                                  <embed 
                                    src={documents.al.path} 
                                    type="application/pdf" 
                                    width={"200px"}
                                    height={"200px"} 
                                    style={{width: "100%"}}
                                  />
                                  :
                                  <img
                                    className="py-4 pb-0 w-full"
                                    src={
                                      documents.al.path
                                        ? documents.al.path
                                        : idVerRectImg
                                    }
                                    alt="addressverifimg"
                                    width={"200px"}
                                    height={"200px"}
                                    style={{maxHeight: "240px",objectFit: "cover"}}
                                  />
                                }
                                <div className="flex justify-between items-center">
                                  <Link2
                                    to={{
                                      pathname: documents.al.path
                                        ? documents.al.path
                                        : "#",
                                    }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <div className="flex flex-row imgDownloadButton cursor-pointer imgOverButtons absolute text-sm">
                                      <img
                                        src={downloadIcon}
                                        alt="downloadicon"
                                      />
                                    </div>
                                  </Link2>
                                </div>
                              </div>
                            </>
                          }
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div id="evtRef" className="event-timeline relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
                <div className="block w-full overflow-x-auto pb-6">
                  <div
                    className="rounded-lg flex justify-between items-center px-12 py-6 border-0 w-full cursor-pointer"
                    onClick={() => setEventShow(!eventShow)}
                  >
                    <div className="flex">
                      <div
                        className="px-4 mr-4 cursor-pointer"
                        style={{ paddingLeft: "0" }}
                      >
                        <h4 className="borderDefault summaryHeading py-2 font-bold text-base">
                          Event Timeline
                        </h4>
                      </div>
                    </div>
                    <div className="flex px-4 py-2 cursor-pointer">
                      {!eventShow && (
                        <img
                          src={dropDownIcon}
                          alt="down icon"
                          className="mr-3"
                          style={{ width: "15px", height: "10px" }}
                        />
                      )}
                      {eventShow && (
                        <img
                          src={dropDownIcon}
                          alt="down icon"
                          className="mr-3 dropDownIconRotated"
                          style={{ width: "15px", height: "10px" }}
                        />
                      )}
                    </div>
                  </div>
                  {eventShow && (
                    <div className="flex flex-row w-full p-12">
                      {onboardingEventData && (
                        <div className="p-12 md:w-12/12">
                          <div className="text-left">
                            <div className="flex flex-wrap">
                              <div
                                className="w-full px-12 mt-2 fields block"
                                style={{
                                  maxHeight: "500px",
                                  height: "500px",
                                  overflow: "auto",
                                }}
                              >
                                {onboardingEventData.map((element, ind) => (
                                  <div className="status mt-2">
                                    <div
                                      className="w-full mt-2"
                                      style={{
                                        display: "grid",
                                        gridTemplateColumns: "20% 80%",
                                      }}
                                    >
                                      {element.passed === 0 && (
                                        <div className="imgTag">
                                          <img src={ElipsesOne} alt="created" />
                                        </div>
                                      )}
                                      {element.passed === 1 && (
                                        <div className="imgTag">
                                          <img src={ElipsesTwo} alt="created" />
                                        </div>
                                      )}
                                      {element.passed === 2 && (
                                        <div className="imgTag">
                                          <img
                                            src={ElipsesYellow}
                                            alt="created"
                                          />
                                        </div>
                                      )}
                                      {element.passed === 3 && (
                                        <div className="imgTag">
                                          <img
                                            src={ElipsesError}
                                            alt="created"
                                          />
                                        </div>
                                      )}
                                      <div className="text text-sm font-semibold">
                                        {element.event_desc}
                                        {onboardingEventDataLength - 1 ==
                                          ind && (
                                          <p className="text-xs createdDate font-normal mt-2">
                                            {Moment(element.created_at).format(
                                              "MM-DD-YYYY"
                                            )}{" "}
                                            |{" "}
                                            {Moment(element.created_at).format(
                                              "hh:mm a"
                                            )}
                                          </p>
                                        )}
                                      </div>
                                    </div>

                                    <div
                                      className="w-full items-center justify-center"
                                      style={{
                                        display: "grid",
                                        gridTemplateColumns: "20% 80%",
                                      }}
                                    >
                                      {onboardingEventDataLength - 1 !== ind ? (
                                        <div className="imgTag px-2">
                                          <img
                                            src={
                                              ind === 0
                                                ? longVertLine
                                                : smallVertLine
                                            }
                                            alt="created"
                                          />
                                        </div>
                                      ) : (
                                        <div className="imgTag px-2"></div>
                                      )}
                                      <div className="text text-xs font-semibold">
                                        {onboardingEventDataLength - 1 !==
                                          ind && (
                                          <p className="createdDate font-normal mt-2">
                                            {Moment(element.created_at).format(
                                              "MM-DD-YYYY"
                                            )}{" "}
                                            |{" "}
                                            {Moment(element.created_at).format(
                                              "hh:mm a"
                                            )}
                                          </p>
                                        )}
                                        <div className="createdDate text-sm font-normal mt-2 flex">
                                          <img
                                            src={locIcon}
                                            alt="loc icon"
                                            className="mr-2"
                                            style={{ width: "20px" }}
                                          />
                                          <p className="createdDate text-xs font-normal">
                                            {element.ip_address}
                                          </p>
                                        </div>
                                        {ind === 0 && (
                                          <div className="w-full items-center justify-center mt-8">
                                            <table className="w-full table-fixed">
                                              <tbody>
                                                <tr>
                                                  <td className="pb-4" style={{ maxWidth: "100px" }}>
                                                    <h4 className="font-light text-xs">
                                                      IP Address
                                                    </h4>
                                                    <h4 className="font-semibold text-xs">
                                                      {element.ip_address
                                                        ? element.ip_address
                                                        : "N/A"}
                                                    </h4>
                                                  </td>
                                                  <td className="pb-4" style={{ maxWidth: "100px" }}>
                                                    <h4 className="font-light text-xs">
                                                      Latitude
                                                    </h4>
                                                    <h4 className="font-semibold text-xs">
                                                      {onboardingData.latitude
                                                        ? onboardingData.latitude
                                                        : "N/A"}
                                                    </h4>
                                                  </td>
                                                  <td className="pb-4" style={{ maxWidth: "100px" }}>
                                                    <h4 className="font-light text-xs">
                                                      Longitude
                                                    </h4>
                                                    <h4 className="font-semibold text-xs">
                                                      {onboardingData.longitude
                                                        ? onboardingData.longitude
                                                        : "N/A"}
                                                    </h4>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td className="pb-4" style={{ maxWidth: "100px" }}>
                                                    <h4 className="font-light text-xs">
                                                      First Seen
                                                    </h4>
                                                    <h4 className="font-semibold text-xs">
                                                      {onboardingData.first_seen
                                                        ? Moment(onboardingData.first_seen).format("MM-DD-YYYY")
                                                        : "N/A"}
                                                    </h4>
                                                  </td>
                                                  <td className="pb-4" style={{ maxWidth: "100px" }}>
                                                    <h4 className="font-light text-xs">
                                                      Last Seen
                                                    </h4>
                                                    <h4 className="font-semibold text-xs">
                                                      {onboardingData.last_seen
                                                        ? Moment(onboardingData.last_seen).format("MM-DD-YYYY")
                                                        : "N/A"}
                                                    </h4>
                                                  </td>
                                                  <td className="pb-4" style={{ maxWidth: "100px" }}>
                                                    <h4 className="font-light text-xs">
                                                      Device OS
                                                    </h4>
                                                    <h4 className="font-semibold text-xs">
                                                      {onboardingData.device_os
                                                        ? onboardingData.device_os
                                                        : "N/A"}
                                                    </h4>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td className="pb-4" style={{ maxWidth: "100px" }}>
                                                    <h4 className="font-light text-xs">
                                                      Browser
                                                    </h4>
                                                    <h4 className="font-semibold text-xs">
                                                      {onboardingData.browser
                                                        ? onboardingData.browser
                                                        : "N/A"}
                                                    </h4>
                                                  </td>
                                                  <td className="pb-4" style={{ maxWidth: "100px" }}>
                                                    <h4 className="font-light text-xs">
                                                      Browser Version
                                                    </h4>
                                                    <h4 className="font-semibold text-xs">
                                                      {onboardingData.browser_version
                                                        ? onboardingData.browser_version
                                                        : "N/A"}
                                                    </h4>
                                                  </td>
                                                  <td className="pb-4" style={{ maxWidth: "100px" }}>
                                                    <h4 className="font-light text-xs">
                                                      Incognito
                                                    </h4>
                                                    <h4 className="font-semibold text-xs">
                                                      {onboardingData.incognito
                                                        ? (onboardingData.incognito == "0" ? "No" : "Yes")
                                                        : "N/A"}
                                                    </h4>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td className="pb-4" style={{ maxWidth: "100px" }}>
                                                    <h4 className="font-light text-xs">
                                                      Browser Fingerprint
                                                    </h4>
                                                    <h4 className="font-semibold text-xs">
                                                      {onboardingData.visitor_id
                                                        ? onboardingData.visitor_id
                                                        : "N/A"}
                                                    </h4>
                                                  </td>
                                                  <td className="pb-4" style={{ maxWidth: "100px" }}>
                                                    <h4 className="font-light text-xs">
                                                      OS Version
                                                    </h4>
                                                    <h4 className="font-semibold text-xs">
                                                      {onboardingData.os_version
                                                        ? onboardingData.os_version
                                                        : "N/A"}
                                                    </h4>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="p-12 md:w-12/12">
                        <div className="flex flex-wrap">
                          <div className="w-full px-4">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                              <MemoizedMap
                                location={{
                                  lat: onboardingData.latitude,
                                  long: onboardingData.longitude,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="profileright w-full lg:w-3/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg">
              <div className="flex flex-wrap justify-center">
                <div className="w-full px-4 text-center">
                  <div className="flex justify-center py-4 lg:pt-4 pt-8">
                    {onboardingData.companyinfo.legal_name ? (
                      <h3 className="text-xl font-semibold leading-normal mb-2 text-black-700 mb-2">
                        {onboardingData.companyinfo.legal_name}
                      </h3>
                    ) : (
                      <h3 className="text-xl font-semibold leading-normal mb-2 text-black-700 mb-2">
                        N/A
                      </h3>
                    )}
                  </div>
                </div>
              </div>
              <div className="text-center">
                <div className="action-buttons text-base leading-normal mt-0 mb-2 text-white-400 font-bold">
                  <button
                    onClick={() => setStatus("approve")}
                    className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 buttonGreen"
                    style={
                      inquiryStatus === "approve"
                        ? { background: "#15e49a" }
                        : { background: "#15e49a80" }
                    }
                  >
                    Approve
                  </button>
                  <button
                    onClick={() => setStatus("decline")}
                    className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 buttonRed"
                    style={
                      inquiryStatus === "decline"
                        ? { background: "#fa364c" }
                        : { background: "#fa364c80" }
                    }
                  >
                    Decline
                  </button>
                  <button
                    onClick={() => setStatus("review")}
                    className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 buttonYellow"
                    style={
                      inquiryStatus === "review"
                        ? { background: "#ffc102" }
                        : { background: "#ffc10280" }
                    }
                  >
                    Review
                  </button>
                  {/* <button
                    onClick={() => setStatus("duplicate")}
                    className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 buttonBlack"
                    style={
                      inquiryStatus === "duplicate"
                        ? { background: "#000000" }
                        : { background: "#00000080" }
                    }
                  >
                    Duplicate
                  </button> */}
                  {/* <button
                    onClick={() => setRevisit(!revisit)}
                    className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 buttonGreen"
                    style={revisit ? { background: "#a10b1d" } : { background: "#a10b1d80" }}
                  >
                    Revise
                  </button> */}
                </div>
              </div>

              {/* {revisit && 
                <div className="py-4 text-left">
                  <div className="flex flex-wrap">
                    <div className="flex items-center justify-between font-semibold w-full">
                      <p className="lg:w-4/12 md:w-2/12 sm:w-2/12 text-sm">
                        Revise Section
                      </p>
                      <div
                        className="lg:w-8/12 md:w-10/12 sm:w-10/12"
                        style={{ height: "0", border: "1px solid #D3D3D3" }}
                      ></div>
                    </div>
                    <div className="mt-2 fields block filtersTable w-full">
                      <div
                        className="enquiryId mt-2"
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          alignItems: "center",
                        }}
                      >
                        <p className="text-xs">Section</p>
                        <div className="field dropdown relative w-full mb-3">
                          <Select
                            styles={customStylesSelect}
                            options={[
                              {label: "Basic Info", value: "basic"},
                              {label: "Liveness", value: "liveness"},
                              {label: "ID Verification", value: "idverif"},
                              {label: "Address Verification", value: "addressverif"},
                              {label: "Wallet Verification", value: "wallet"}
                            ]}
                            style={{ width: "100%" }}
                            isSearchable={false}
                            onChange={(e) => setRevisitSection(e.value)}
                          />
                        </div>
                      </div>
                      <div
                        className="enquiryId mt-2"
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          alignItems: "center",
                        }}
                      >
                        <p className="text-xs">Notes</p>
                        <div className="field dropdown relative w-full mb-3">
                          <textarea onChange={(e) => setRevisitNotes(e.target.value)}></textarea>
                        </div>
                      </div>
                      {revisitSection && 
                        <button className="w-full py-2 px-4 rounded-lg font-semibold text-white-400 buttonPink text-sm mt-2" onClick={submitRevisit}>Submit</button>
                      }

                    </div>
                  </div>
                </div>
              }
              {revisionSent && 
                <p className="text-xs mt-2">Revision email sent successfully!</p>
              } */}

              <div className="py-4 text-left">
                <div className="flex flex-wrap">
                  <div className="flex items-center justify-between font-semibold w-full">
                    <p className="lg:w-4/12 md:w-2/12 sm:w-2/12 text-sm">
                      Managers
                    </p>
                    <div
                      className="lg:w-8/12 md:w-10/12 sm:w-10/12"
                      style={{ height: "0", border: "1px solid #D3D3D3" }}
                    ></div>
                  </div>
                  <div className="mt-2 fields block filtersTable w-full">
                    <div
                      className="enquiryId mt-2"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        alignItems: "center",
                      }}
                    >
                      <p className="text-xs">Assigned To</p>
                      {users && (
                        <div className="field dropdown relative w-full mb-3">
                          <Select
                            styles={customStylesSelect}
                            options={users}
                            isSearchable={false}
                            value={users.filter(
                              (obj) => obj.value === assignedTo
                            )}
                            onChange={handleChangeAssignedTo}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="py-4 text-left">
                <div className="flex flex-wrap">
                  <div className="flex items-center justify-center font-semibold w-full">
                    <p className="lg:w-2/12 md:w-2/12 sm:w-2/12 text-sm">
                      Info
                    </p>
                    <div
                      className="lg:w-10/12 md:w-10/12 sm:w-10/12"
                      style={{ height: "0", border: "1px solid #D3D3D3" }}
                    ></div>
                  </div>
                  <div className="mt-2 fields block">
                    <div
                      className="enquiryId mt-2"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                      }}
                    >
                      <p className="text-xs">Inquiry ID</p>
                      <b className="text-xs">
                        {onboardingData.inq_id ? onboardingData.inq_id : "N/A"}
                      </b>
                    </div>
                    <div
                      className="dob mt-2"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                      }}
                    >
                      <p className="text-xs">Date of Incorporation</p>
                      <b className="text-xs">
                        {onboardingData.companyinfo.date_of_incorp
                          ? onboardingData.companyinfo.date_of_incorp.includes("T") ?
                            Moment(onboardingData.companyinfo.date_of_incorp.split("T")[0]).format(
                              "MM-DD-YYYY"
                            )
                            :
                            (Moment(onboardingData.companyinfo.date_of_incorp, "DD/MM/YYYY").format("MM-DD-YYYY") === "Invalid date")
                              ? Moment(onboardingData.companyinfo.date_of_incorp).format(
                                "MM-DD-YYYY"
                              ) : Moment(onboardingData.companyinfo.date_of_incorp, "DD/MM/YYYY").format(
                                "MM-DD-YYYY"
                              )
                          : 
                          "N/A"
                        }
                      </b>
                    </div>
                    <div
                      className="nationality mt-2"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                      }}
                    >
                      <p className="text-xs">Jurisdiction</p>
                      <b className="text-xs">
                        {onboardingData.companyinfo.legal_jurisdiction
                          ? onboardingData.companyinfo.legal_jurisdiction
                          : "N/A"}
                      </b>
                    </div>
                    <div
                      className="nationality mt-2"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                      }}
                    >
                      <p className="text-xs">Registration No</p>
                      <b className="text-xs">
                        {onboardingData.companyinfo.registration_no
                          ? onboardingData.companyinfo.registration_no
                          : "N/A"}
                      </b>
                    </div>
                  </div>
                </div>
              </div>

              {onboardingEventData && (
                <div className="py-4 text-left">
                  <div className="flex flex-wrap">
                    <div className="flex items-center justify-center font-semibold w-full">
                      <p className="lg:w-2/12 md:w-2/12 sm:w-2/12 text-sm">
                        Event
                      </p>
                      <div
                        className="lg:w-10/12 md:w-10/12 sm:w-10/12"
                        style={{ height: "0", border: "1px solid #D3D3D3" }}
                      ></div>
                    </div>
                    <div className="w-full mt-2 fields block eventTimeline">
                      {onboardingEventData.map((element, ind) => (
                        <div data-index={ind} className="status mt-2">
                          <div
                            className="w-full mt-2"
                            style={{
                              display: "grid",
                              gridTemplateColumns: "20% 80%",
                            }}
                          >
                            {element.passed === 0 && (
                              <div className="imgTag">
                                <img src={ElipsesOne} alt="created" />
                              </div>
                            )}
                            {element.passed === 1 && (
                              <div className="imgTag">
                                <img src={ElipsesTwo} alt="created" />
                              </div>
                            )}
                            {element.passed === 2 && (
                              <div className="imgTag">
                                <img src={ElipsesYellow} alt="created" />
                              </div>
                            )}
                            {element.passed === 3 && (
                              <div className="imgTag">
                                <img src={ElipsesError} alt="created" />
                              </div>
                            )}
                            <div className="text text-xs font-semibold">
                              {element.event_desc}
                              <p className="createdDate font-normal mt-2">
                                {Moment(element.created_at).format(
                                  "MM-DD-YYYY"
                                )}{" "}
                                | {Moment(element.created_at).format("hh:mm a")}
                              </p>
                            </div>
                          </div>
                          {onboardingEventDataLength - 1 !== ind && (
                            <div
                              className="w-full items-center justify-center"
                              style={{
                                display: "grid",
                                gridTemplateColumns: "20% 80%",
                              }}
                            >
                              <div className="imgTag px-2">
                                <img
                                  className="ml-1"
                                  src={VerticalLine}
                                  alt="created"
                                />
                              </div>
                              <div className="text text-xs font-semibold"></div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {!dataLoaded && (
        <>
          <ReactLoading
            className="margin-auto mt-3 mb-3 absolute"
            type={"bars"}
            style={{
              zIndex: "100000",
              top: "30%",
              left: "39%",
              width: "8%",
              height: "8%",
              fill: "#a10b1d",
            }}
          />
          <div
            id="overlay"
            className="fixed w-full h-full top-0 left-0 right-0 bottom-0 cursor-pointer"
            style={{ backgroundColor: "rgba(0,0,0,0.5)", zIndex: "10000" }}
          ></div>
        </>
      )}
    </>
  );
}
