import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import { getwatchlistdata } from "../../services/dashboard/Graphs";
import verticalLineBarGraph from "../../assets/img/verticalLineBarGraph.png";

class CardBarChartFull extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          id: "apexchart-example",
        },
        colors: ["#FA364C", "#FFC102", "#15E49A"],
        xaxis: {
          categories: ["Sanctions", "PEPs", "Adverse Media"],
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            distributed: true,
            endingShape: "rounded",
            borderRadius: 15,
            // dataLabels: {
            //   show: false,
            //   position: 'bottom'
            // }
          },
        },
        // grid: {
        //   show: false
        // },
        // yaxis: {
        //   show: false
        // },
        // xaxis: {
        //   show: false
        // },
        legend: {
          show: false,
        },
      },
      series: [
        {
          name: "",
          data: [0, 0, 0],
        },
      ],

      percentage_sanction: 0,
      percentage_pep: 0,
      percentage_am: 0,
    };
  }

  componentDidMount() {
    getwatchlistdata().then((response) => {
      // console.log("response: ", response);
      if (response.data.success) {
        this.setState({series: [{
          ...this.state.series,
          data: [response.data.bars.sanctions, response.data.bars.peps, response.data.bars.adverse_media],
        }]});
        this.setState({percentage_sanction: response.data.percentages.sanctions});
        this.setState({percentage_pep: response.data.percentages.peps});
        this.setState({percentage_am: response.data.percentages.adverse_media});
      }
    }).catch((error) => {
      console.log("error: ", error);
    });
  }

  render() {
    return (
      <div className=" barChartFullDiv relative flex min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg flex-wrap p-8">
        <div className="headingWatchList p-4 px-12 pb-6">
          <h4
            className="font-bold text-base py-2"
            style={{ borderBottom: "2px solid #a10b1d" }}
          >
            Watch List Matches
          </h4>
        </div>
        <div
          className="p-4 flex flex-row w-full cardBarChart"
          style={{ minHeight: "400px" }}
        >
          <div className="relative w-full flex flex-row flex-wrap justify-between barChartFull">
            <div className="xl:w-6/12 lg:w-6/12 md:w-12/12 sm:w-12/12 px-12  ">
              <ReactApexChart
                options={this.state.options}
                series={this.state.series}
                type="bar"
                width={"90%"}
                height={"90%"}
              />
            </div>
            <div className="xl:w-4/12 lg:w-12/12 md:w-12/12 sm:w-12/12 justify-center">
              <div className="headerHeading p-4 px-12">
                <h4 className="font-bold text-lg pb-3">Breakdown</h4>
              </div>
              <div className="flex flex-col items-center w-full h-full align-middle py-4 ">
                <div className="optionValues grid-50 px-12 w-full text-left">
                  <div className="flex flex-col optionInd pb-4">
                    <h3 className="labelOption textGray font-bold text-xl">
                      Sanction
                    </h3>
                    <h1 className="labelOption textRed font-bold text-2xl">
                      {this.state.percentage_sanction}%
                    </h1>
                  </div>
                  <div className="flex flex-col optionInd pb-4">

                  </div>
                  <div className="flex flex-col optionInd pb-4">
                    <h3 className="labelOption textGray font-bold text-xl">
                      Peps
                    </h3>
                    <h1 className="labelOption textYellow font-bold text-2xl">
                      {this.state.percentage_pep}%
                    </h1>
                  </div>
                  <div className="flex flex-col optionInd">
                    
                  </div>
                  <div className="flex flex-col optionInd">
                    <h3 className="labelOption textGray font-bold text-xl">
                      Adverse Media
                    </h3>
                    <h1 className="labelOption textGreen font-bold text-2xl">
                      {this.state.percentage_am}%
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CardBarChartFull;
