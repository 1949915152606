import React, { PureComponent, createRef, Component} from "react";
import { ForceGraph2D, ForceGraph3D, ForceGraphVR, ForceGraphAR } from 'react-force-graph';
import SpriteText from 'three-spritetext';
import { withSize } from "react-sizeme";

const withSizeHOC = withSize({monitorWidth:true, monitorHeight:false, noPlaceholder:true});
const NODE_R = 8;

class OnchainChart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      highlightNodes: new Set(),
      highlightLinks: new Set(),
      hoverNode: null,
    }
    this.containerRef = createRef()
  }
  
  updateHighlight = () => {
    this.setState({highlightNodes: this.state.highlightNodes});
    this.setState({highlightLinks: this.state.highlightLinks});
  };

  handleNodeHover = node => {
    this.state.highlightNodes.clear();
    this.state.highlightLinks.clear();
    if (node) {
      this.state.highlightNodes.add(node);
      node.neighbors.forEach(neighbor => this.state.highlightNodes.add(neighbor));
      node.links.forEach(link => this.state.highlightLinks.add(link));
    }

    this.setState({hoverNode: this.state.hoverNode || null});
    this.updateHighlight();
  };

  handleLinkHover = link => {
    this.state.highlightNodes.clear();
    this.state.highlightLinks.clear();

    if (link) {
      this.state.highlightLinks.add(link);
      this.state.highlightNodes.add(link.source);
      this.state.highlightNodes.add(link.target);
    }

    this.updateHighlight();
  };

  paintRing = (node, ctx) => {
    // add ring just for highlighted nodes
    ctx.beginPath();
    ctx.arc(node.x, node.y, NODE_R * 1.4, 0, 2 * Math.PI, false);
    ctx.fillStyle = node === this.state.hoverNode ? '#ebedf2' : '#a10b1d';
    ctx.fill();
  };

  handleClick = (node) => {
    console.log("node: ", node);
  }

  render() {
    var width = this.props.size.width

    return (
      <ForceGraph2D
        ref={this.containerRef}
        graphData={this.props.myData}
        width={width}
        linkDirectionalArrowLength={3.5}
        linkDirectionalArrowRelPos={1}
        linkThreeObjectExtend={true}
        linkThreeObject={link => {
          const sprite = new SpriteText(`${link.source} > ${link.target}`);
          sprite.color = 'lightgrey';
          sprite.textHeight = 1.5;
          return sprite;
        }}
        linkPositionUpdate={(sprite, { start, end }) => {
          const middlePos = Object.assign(...['x', 'y', 'z'].map(c => ({
            [c]: start[c] + (end[c] - start[c]) / 2 // calc middle point
          })));

          Object.assign(sprite.position, middlePos);
        }}
        onNodeDragEnd={node => {
          node.fx = node.x;
          node.fy = node.y;
          node.fz = node.z;
        }}
        onEngineStop={() => this.containerRef.current.zoomToFit(400)}

        autoPauseRedraw={false}
        linkWidth={link => this.state.highlightLinks.has(link) ? 5 : 1}
        linkDirectionalParticles={4}
        linkDirectionalParticleWidth={link => this.state.highlightLinks.has(link) ? 4 : 0}
        nodeCanvasObjectMode={node => this.state.highlightNodes.has(node) ? 'before' : undefined}
        nodeCanvasObject={this.paintRing}
        onNodeHover={this.handleNodeHover}
        onLinkHover={this.handleLinkHover}
        onNodeClick={this.handleClick}
      />
    )
  }
}

export default withSizeHOC(OnchainChart)