import React from "react";
import { Link } from "react-router-dom";
import DataTableSettingTeam from "../../components/Datatables/DataTableSettingTeam";

import profileIcon from "../../assets/img/icons/profile-icon.png";
import profileIconClr from "../../assets/img/icons/profile-iconClr.png";

import teamIcon from "../../assets/img/icons/team-icon.png";
import teamIconClr from "../../assets/img/icons/team-iconClr.png";

import customizationIcon from "../../assets/img/icons/customization-icon.png";
import customizationIconClr from "../../assets/img/icons/customization-iconClr.png";

import auditIcon from "../../assets/img/icons/audit-icon.png";
import auditIconClr from "../../assets/img/icons/audit-iconClr.png";

export default function Team() {
  const [isShown, setIsShown] = React.useState("");

  return (
    <>
      <div className="amlscreening flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
            <div className="rounded-lg flex justify-between bg-white border-0 w-full mb-6 shadow-lg settingResp">
              <div
                className="lg:w-2/12 md:w-2/12 sm:w-12/12 nav-bg-grey py-16 rounded-lg shadow-2xl settingSideBarBack"
                style={{
                  borderTopRightRadius: "0",
                  borderBottomRightRadius: "0",
                }}
              >
                <ul className="md:min-w-full flex flex-col list-none leftSidebarNavListMain settingSideBar rounded-lg">
                  <li className="items-center mb-3">
                    <Link
                      className={
                        "text-xs  py-3 flex flex-row items-center font-bold block p-2 settingSidebarLink " +
                        (window.location.href.indexOf("/settings/profile") !==
                          -1 || isShown === "profile"
                          ? "text-black-500 hover:text-black-600 colorPrimary nav-bg-white"
                          : "text-black-700 hover:text-black-500")
                      }
                      to="/settings/profile"
                      onMouseEnter={() => setIsShown("profile")}
                      onMouseLeave={() => setIsShown("")}
                    >
                      {window.location.href.indexOf("/settings/profile") !==
                        -1 || isShown === "profile" ? (
                        <img
                          className={"w-auto mr-4 text-sm "}
                          src={profileIconClr}
                          style={{ width: "20px", display: "inline-block" }}
                          alt="innreg icon"
                        />
                      ) : (
                        <img
                          className={"w-auto mr-4 text-sm "}
                          src={profileIcon}
                          style={{ width: "20px", display: "inline-block" }}
                          alt="innreg icon"
                        />
                      )}{" "}
                      <p style={{ letterSpacing: "1px" }}>Profile</p>
                    </Link>
                  </li>
                  <li className="items-center mb-3">
                    <Link
                      className={
                        "text-xs py-3 flex flex-row items-center font-bold block px-2 settingSidebarLink " +
                        (window.location.href.indexOf("/settings/team") !==
                          -1 || isShown === "team"
                          ? "text-black-500 hover:text-black-600 colorPrimary nav-bg-white"
                          : "text-black-700 hover:text-black-500")
                      }
                      to="/settings/team"
                      onMouseEnter={() => setIsShown("team")}
                      onMouseLeave={() => setIsShown("")}
                    >
                      {window.location.href.indexOf("/settings/team") !== -1 ||
                      isShown === "team" ? (
                        <img
                          className={"w-auto mr-4 text-sm "}
                          src={teamIconClr}
                          style={{ width: "25px", display: "inline-block" }}
                          alt="innreg icon"
                        />
                      ) : (
                        <img
                          className={"w-auto mr-4 text-sm "}
                          src={teamIcon}
                          style={{ width: "25px", display: "inline-block" }}
                          alt="innreg icon"
                        />
                      )}{" "}
                      <p style={{ letterSpacing: "1px" }}>Team</p>
                    </Link>
                  </li>
                  <li className="items-center mb-3">
                    <Link
                      className={
                        "text-xs py-3 flex flex-row items-center font-bold block px-2 settingSidebarLink " +
                        (window.location.href.indexOf("/settings/kyc") !== -1 ||
                        isShown === "kyc"
                          ? "text-black-500 hover:text-black-600 colorPrimary nav-bg-white"
                          : "text-black-700 hover:text-black-500")
                      }
                      to="/settings/kyc"
                      onMouseEnter={() => setIsShown("kyc")}
                      onMouseLeave={() => setIsShown("")}
                    >
                      {window.location.href.indexOf("/settings/kyc") !== -1 ||
                      isShown === "kyc" ? (
                        <img
                          className={"w-auto mr-4 text-sm "}
                          src={customizationIconClr}
                          style={{ width: "23px", display: "inline-block" }}
                          alt="innreg icon"
                        />
                      ) : (
                        <img
                          className={"w-auto mr-4 text-sm "}
                          src={customizationIcon}
                          style={{ width: "23px", display: "inline-block" }}
                          alt="innreg icon"
                        />
                      )}{" "}
                      <p style={{ letterSpacing: "1px" }}>Customization</p>
                    </Link>
                  </li>
                  <li className="items-center mb-3">
                    <Link
                      className={
                        "text-xs flex flex-row items-center  py-3 font-bold block px-2 settingSidebarLink " +
                        (window.location.href.indexOf("/settings/audit") !==
                          -1 || isShown === "audit"
                          ? "text-black-500 hover:text-black-600 colorPrimary nav-bg-white"
                          : "text-black-700 hover:text-black-500")
                      }
                      to="/settings/audit"
                      onMouseEnter={() => setIsShown("audit")}
                      onMouseLeave={() => setIsShown("")}
                    >
                      {window.location.href.indexOf("/settings/audit") !== -1 ||
                      isShown === "audit" ? (
                        <img
                          className={"w-auto mr-4 text-sm "}
                          src={auditIconClr}
                          style={{ width: "23px", display: "inline-block" }}
                          alt="innreg icon"
                        />
                      ) : (
                        <img
                          className={"w-auto mr-4 text-sm "}
                          src={auditIcon}
                          style={{ width: "23px", display: "inline-block" }}
                          alt="innreg icon"
                        />
                      )}{" "}
                      <p style={{ letterSpacing: "1px" }}>Audit Logs</p>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="lg:w-12/12 md:w-12/12 sm:w-12/12 px-12 py-16 overflow-x-hidden">
                <div className="row flex flex-row w-full px-4">
                  <h2
                    className="font-bold text-base pb-2"
                    style={{ borderBottom: "2px solid #a10b1d" }}
                  >
                    Team
                  </h2>
                </div>
                <DataTableSettingTeam />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
