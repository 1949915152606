import { getuniqueid } from "services/kyc/KycScreens";
import ReactGA from "react-ga4";
import React, { useState,useContext } from "react";
import {KycMobContext} from "../../layouts/KYCMob"
import { Link, useHistory } from "react-router-dom";

import backButtonImg from " ../../assets/img/kycmobile/backBtn.png";
import closeButtonImg from " ../../assets/img/kycmobile/cross_white.png";
import footerImg from " ../../assets/img/kycmobile/footer_trademark.png";
import map from " ../../assets/img/kycmobile/screen27.png";

ReactGA.send({ hitType: "pageview", page: window.location.pathname });

export default function ScreenExists5() {
  const {data} = useContext(KycMobContext);
  const history = useHistory();
  
  const alreadyVerifiied = () => {
    const redirecturl = "/verify/exists/a";
    history.push(redirecturl);
    window.location.reload(false);
  }

  const handleCross = () => {
    var ua = window.navigator.userAgent;

    if (ua.indexOf("iPhone") > 0) {
      window.close();
    }
    window.close();
  };

  return (
    <>
      <div className="screenTwentySeven screenVerifyStart h-full" style={{fontFamily: data ? data.font_family : "Gilroy"}}>
        <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
          <div className="backArrow">
            <button>
              <img src={backButtonImg} alt="backArrow" className="" />
            </button>
          </div>
          <div className="progressBar w-full py-3 px-12">
            <div className="relative lg:w-8/12 mx-auto">
              <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                <div
                  style={{ width: "100%",backgroundColor:data ? data.progressbar_color:"#a10b1d" }}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center "
                ></div>
              </div>
            </div>
          </div>
          <div className="crossArrow">
            <button onClick={handleCross}>
              <img src={closeButtonImg} alt="crossArrow" className="" />
            </button>
          </div>
        </div>

        <div className="mainContent">
          <div className="mapImgBackground">
            <div className="mapImage">
              <img className="map" src={map} alt="" />
            </div>
            <div className="headingText">
              <h2>Thankyou for submitting your information</h2>
              <p className="justify-center"><b style={{ fontWeight: "500", fontSize: "21px", color: "#000" }}>We already have a KYC with this information. Please wait patiently as we get back to you with your verification status.</b></p>
              <p className="text-center text-sm" style={{ color: "#bcbcbc", letterSpacing: "-0.03em" }}>You may close this window now.</p>
            </div>
          </div>
        </div>

        {/* <div className="buttonAction">
          <p className="text-center text-sm" style={{ color: "#bcbcbc", letterSpacing: "-0.03em" }}>You may close this window now.</p>
        </div> */}

        <div className="footer">
          <img className="footerImg" src={footerImg} alt="footer" />
        </div>
      </div>
    </>
  );
}
