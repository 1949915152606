import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

import verticalLineBarGraph from "../../assets/img/verticalLineBarGraph.png";

class CardBarChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          id: "apexchart-example",
        },
        colors: ["#b72e58", "#2A94FC", "#15E49A", "#FFC102", "#FA364C"],
        xaxis: {
          categories: ["Returning", "Completed", "Approved", "Pending", "Decline"],
        },
        dataLabels: {
          enabled: true,
        },
        plotOptions: {
          bar: {
            distributed: true,
            endingShape: "rounded",
            borderRadius: 15,
            dataLabels: {
              show: true,
              position: 'bottom'
            }
          },
        },
        grid: {
          show: false,
        },
        yaxis: {
          show: false,
        },
        // xaxis: {
        //   show: false
        // },
        legend: {
          show: false,
        },
      },
      series: [
        {
          name: "",
          data: [props.counts.returning, props.counts.completed, props.counts.approved, props.counts.review, props.counts.declined],
        },
      ],
    };
  }

  render() {
    return (
      <div className="barchartSmall relative flex min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg">
        <div
          className="p-4 flex flex-row w-full"
          style={{ minHeight: "300px" }}
        >
          {/* Chart */}
          <div className="relative w-full flex flex-row flex-wrap justify-center">
            <div className="barChart xl:w-9/12 lg:w-12/12 md:w-6/12 sm:w-12/12 px-12 ">
              <ReactApexChart
                options={this.state.options}
                series={this.state.series}
                type="bar"
                width={"90%"}
                height={"100%"}
              />
            </div>
            <div className="xl:w-3/12 lg:w-12/12 md:w-12/12 sm:w-12/12" style={{ height: "250px", maxHeight: "250px", overflowY: "scroll" }}>
              <div className="flex flex-row items-center w-full h-full align-middle items-center">
                <div
                  className="h-full divider_img px-12 xl:hidden lg:hidden md:hidden sm:hidden"
                  style={{ paddingTop: "22%" }}
                >
                  <img src={verticalLineBarGraph} />
                </div>
                <div className="optionValues flex sm:flex-row md:flex-row lg:flex-row xl-flex-column px-12 mx-auto" style={{ height: "250px", maxHeight: "250px" }}>
                  <div className="flex flex-col optionInd pb-2 xl:mr-0 lg:mr-4 md:mr-4 sm:mr-4">
                    <h3 className="labelOption textGray font-bold text-lg">
                      Returning
                    </h3>
                    <h1 className="labelOption textPinkPurple font-bold text-lg">
                      {parseFloat(this.props.percentages.returning).toFixed(2)}%
                    </h1>
                  </div>
                  <div className="flex flex-col optionInd pb-2 xl:mr-0 lg:mr-4 md:mr-4 sm:mr-4">
                    <h3 className="labelOption textGray font-bold text-lg">
                      Completed
                    </h3>
                    <h1 className="labelOption textBlue font-bold text-lg">
                      {parseFloat(this.props.percentages.completed).toFixed(2)}%
                    </h1>
                  </div>
                  <div className="flex flex-col optionInd pb-2 xl:mr-0 lg:mr-4 md:mr-4 sm:mr-4">
                    <h3 className="labelOption textGray font-bold text-lg">
                      Approved
                    </h3>
                    <h1 className="labelOption textGreen font-bold text-lg">
                      {parseFloat(this.props.percentages.approved).toFixed(2)}%
                    </h1>
                  </div>
                  <div className="flex flex-col optionInd pb-2 xl:mr-0 lg:mr-4 md:mr-4 sm:mr-4">
                    <h3 className="labelOption textGray font-bold text-lg">
                      Pending
                    </h3>
                    <h1 className="labelOption textYellow font-bold text-lg">
                      {parseFloat(this.props.percentages.review).toFixed(2)}%
                    </h1>
                  </div>
                  <div className="flex flex-col optionInd pb-2 ">
                    <h3 className="labelOption textGray font-bold text-lg">
                      Declined
                    </h3>
                    <h1 className="labelOption textRed font-bold text-lg">
                      {parseFloat(this.props.percentages.declined).toFixed(2)}%
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CardBarChart;
