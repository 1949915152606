import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import countryList from 'react-select-country-list';
import { saveevent } from "../../services/kyc/KycScreens";
import { savecompanyinfo } from "../../services/kyb/KybScreens";
import {KycMobContext} from "../../layouts/KYCMob"
import { getacceptablecountries } from "../../services/settings/Acceptable";
import DatePicker from "react-datepicker";
import Moment from "moment";
import { addDays } from "date-fns";
import { isMobile } from 'react-device-detect';

import backButtonImg from " ../../assets/img/kycmobile/backBtn.png";
import closeButtonImg from " ../../assets/img/kycmobile/cross_white.png";
import footerImg from " ../../assets/img/kycmobile/footer_trademark.png";

export default function Screen3() {
  const [disable, setDisable] = React.useState(false);
  const {data, height} = useContext(KycMobContext);
  const history = useHistory();
  const url = window.location.href.substring(
    window.location.href.lastIndexOf("/") + 1
  );
  const [message, setMessage] = useState("");
  const [value, setValue] = React.useState("")
  const [options, setOptions] = React.useState(() => countryList().getData(), []);
  options.forEach((ele) => {
    if(ele.label === "Czechia") {
      ele.label = "Czech Republic (Czechia)";
    }
  });
  var form, checkBtn;
  const storage = window.localStorage;
  const [screenStates, setScreenStates] = React.useState(JSON.parse(storage.getItem("screenmeta")));

  React.useEffect(() => {
    if (screenStates) {
      if (!screenStates.Screen3) {
        const redirecturl = isMobile ? "/kyb/b/"+url : "/kyb/c/"+url;
        history.push(redirecturl);
        window.location.reload();
      }
    } else {
      const redirecturl = isMobile ? "/kyb/b/"+url : "/kyb/c/"+url;
      history.push(redirecturl);
      window.location.reload();
    }
  }, [screenStates]);
  
  const [formdata, setFormdata] = useState({
    full_legal_name: "",
    legal_entity: "",
    registration_number: "",
    trading_name: "",
    registered_address: "",
    operating_address: "",
    legal_juris: "",
    date_of_incor: "",
    company_website: "",
    email: "",
    inq_id: url,
  });

  const handleInputChange = (event) => {
    setMessage("");
    const name = event.target.name;
    const value = event.target.value;

    setFormdata((values) => ({ ...values, [name]: value }));
  };

  const handleInputChangeDate = (date) => {
    setMessage("");
    const name = "date_of_incor";
    const value = date;
    setFormdata((values) => ({ ...values, [name]: value }));
  };

  const handleChangeSelect = (event, { name }) => {
    setMessage("");
    setFormdata((values) => ({ ...values, [name]: event }));
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      paddingTop: 0,
    }),
  };

  const optionsLegalEntityType = [
    { label: "Sole Proprietorship", value: 1 },
    { label: "Partnership", value: 2 },
    { label: "Corporation", value: 3 },
    { label: "S corporation", value: 4 },
    { label: "Limited Liability Company (LLC)", value: 5 },
  ];

  const disableDates = () => {
    const today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (dd < 10) {
      dd = "0" + dd;
    }
    return yyyy + "-" + mm + "-" + dd;
  };

  const handleSubmit = () => {
    setMessage("");

    if (formdata.full_legal_name === "") {
      setMessage("Company legal name is required!");
      return;
    }
    if (formdata.legal_entity === "") {
      setMessage("Legal entity type is required!");
      return;
    }
    if (formdata.legal_juris === "") {
      setMessage("Legal jurisdiction is required!");
      return;
    }
    if (formdata.registered_address === "") {
      setMessage("Company registered address is required!");
      return;
    }
    if (formdata.email === "") {
      setMessage("Email Address is required!");
      return;
    }
    if (formdata.date_of_incor === "") {
      setMessage("Date of incorporation is required!");
      return;
    }
    if (formdata.company_website === "") {
      setMessage("Company website is required!");
      return;
    }
    if (formdata.registration_number === "") {
      setMessage("Registration number / Tax ID is required!");
      return;
    }

    let data_to_send = {
      legal_name: formdata.full_legal_name,
      legal_entity_type: formdata.legal_entity.label,
      trading_name: formdata.trading_name,
      date_of_incorp: new Date(formdata.date_of_incor).toLocaleDateString(),
      legal_jurisdiction: formdata.legal_juris.label,
      registered_address: formdata.registered_address,
      registration_no: formdata.registration_number,
      website: formdata.company_website,
      email: formdata.email,
      inq_id: url,
    };
    setDisable(true);
    savecompanyinfo(data_to_send)
      .then((response) => {
        if (response.data.success) {
          let formdata3 = {
            inq_id: url,
            event_desc: "Company Information Saved",
            passed: 1,
          };
          saveevent(formdata3)
            .then((response) => {
              if (response.data.success) {
                let temp = screenStates;
                temp.Screen3docs = true;
                storage.setItem("screenmeta", JSON.stringify(temp));
                const redirecturl = "/kyb/f/" + url;
                history.push(redirecturl);
                window.location.reload();
              }
            })
            .catch((error) => {
              console.log("error: ", error);
              setDisable(false);
            });
        }
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  const handleCross = () => {
    var ua = window.navigator.userAgent;

    if (ua.indexOf("iPhone") > 0) {
      window.close();
    }
    window.close();
  };

  return (
    <>
      <div className="screenThree screenThreeKyb screenThreeDocs h-full" style={{fontFamily: data ? data.font_family : "Gilroy"}}>
        <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
          <div className="backArrow">
            <Link to={isMobile ? "/kyb/b/"+url : "/kyb/c/"+url}>
              <button>
                <img src={backButtonImg} alt="backArrow" className="" />
              </button>
            </Link>
          </div>
          <div className="progressBar w-full py-3 px-12">
            <div className="relative lg:w-8/12 mx-auto">
              <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                <div
                  style={{ width: "25%" ,backgroundColor:data ? data.progressbar_color:"#a10b1d"}}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"
                ></div>
              </div>
            </div>
          </div>
          <div className="crossArrow">
            <button  onClick={handleCross}>
              <img src={closeButtonImg} alt="crossArrow" className="" />
            </button>
          </div>
        </div>

        <div className="mainContent" >
          <div className="mainText">
            <h1 style={{ lineHeight: 1 }}>Please fill out with your company information.</h1>
          </div>
          <Form className="informationSection mt-8">
            <div className="nameFields">
              <div className="firstNameField firstField">
                <div className="inputfield firstname">
                  <span className="label text-xs">Legal Name</span>
                  <Input 
                    type="text" 
                    name="full_legal_name" 
                    className="input firstnameInput" 
                    value={formdata.full_legal_name}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="lastNameField">
                <div className="inputfield lastname">
                  <span className="label text-xs">Legal Entity Type</span>
                  <Select
                    name="legal_entity"
                    className="globeSelect"
                    styles={customStyles}
                    value={formdata.legal_entity}
                    onChange={handleChangeSelect}
                    options={optionsLegalEntityType}
                  />
                </div>
              </div>
            </div>
            <div className="nameFields">
              <div className="firstNameField firstField">
                <div className="inputfield firstname">
                  <span className="label text-xs">Trading Name</span>
                  <Input 
                    type="text" 
                    name="trading_name" 
                    className="input firstnameInput" 
                    value={formdata.trading_name}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="lastNameField">
                <div className="inputfield lastname">
                  <span className="label text-xs">Legal Jurisdiction</span>
                  <Select 
                    name="legal_juris"
                    className="nationalityInput"
                    style={{ all: "unset" }} 
                    placeholder="Search Country" 
                    options={options} 
                    value={formdata.legal_juris}
                    onChange={handleChangeSelect}
                    menuPortalTarget={document.body} 
                    styles={customStyles} 
                  />
                </div>
              </div>
            </div>
            <div className="emailFields">
              <div className="inputfield email">
                <span className="label text-xs">Registered Address</span>
                <Input 
                  type="text" 
                  name="registered_address" 
                  className="input emailInput"
                  value={formdata.registered_address}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="emailFields">
              <div className="inputfield email">
                <span className="label text-xs">Email Address</span>
                <Input 
                  type="email" 
                  name="email" 
                  className="input emailInput"
                  value={formdata.email}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="nameFields">
              <div className="dateField mr-1">
                <div className="inputfield dob">
                  <span className="label text-xs">Date of Incorporation</span>
                  <DatePicker 
                    selected={formdata.date_of_incor} 
                    maxDate={addDays(new Date(), 0)}
                    onChange={(date) => handleInputChangeDate(date)}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="mm/dd/yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </div>
              </div>
              <div className="lastNameField">
                <div className="inputfield lastname">
                  <span className="label text-xs">Company Website</span>
                  <Input 
                    type="text" 
                    name="company_website" 
                    className="input lastnameInput" 
                    value={formdata.company_website}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            <div className="lastNameField">
              <div className="inputfield lastname">
                <span className="label text-xs">Registration Number / Tax ID</span>
                <Input 
                  type="text" 
                  name="registration_number" 
                  className="input lastnameInput" 
                  value={formdata.registration_number}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            {message && (
              <section className="form-group mt-2">
                <section
                  className="text-sm alert alert-danger"
                  role="alert"
                >
                  {message}
                </section>
              </section>
            )}
          </Form>
        </div>

        <div className="buttonAction">
          <button 
            className="action"
            style={{
              backgroundColor: data ? data.button_color : "#a10b1d",
            }}
            onClick={handleSubmit}
            disabled={disable}
          >
            Next
          </button>
        </div>

        <div className="footer" >
          <img className="footerImg" src={footerImg} alt="footer"/>
        </div>
      </div>
    </>
  );
}
  
