import React from "react";

function MapExample(props) {
  const mapRef = React.useRef(null);
  
  React.useEffect(() => {
    let google = window.google;
    let map = mapRef.current;
    let lat = props.location.lat;
    let lng = props.location.long;
    const myLatlng = new google.maps.LatLng(lat, lng);
    const mapOptions = {
      zoom: 12,
      center: myLatlng,
      scrollwheel: false,
      zoomControl: true,
      styles: [
        {
          featureType: "administrative",
          elementType: "labels.text.fill",
          stylers: [{ color: "#444444" }],
        },
        {
          featureType: "landscape",
          elementType: "all",
          stylers: [{ color: "#f2f2f2" }],
        },
        {
          featureType: "poi",
          elementType: "all",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "road",
          elementType: "all",
          stylers: [{ saturation: -100 }, { lightness: 45 }],
        },
        {
          featureType: "road.highway",
          elementType: "all",
          stylers: [{ visibility: "simplified" }],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.icon",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "transit",
          elementType: "all",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "water",
          elementType: "all",
          stylers: [{ color: "#4299e1" }, { visibility: "on" }],
        },
      ],
    };

    map = new google.maps.Map(map, mapOptions);

    const marker = new google.maps.Marker({
      position: myLatlng,
      map: map,
      animation: google.maps.Animation.DROP,
      // title: "The Hedge!",
    });

    const contentString = '';

    const infowindow = new google.maps.InfoWindow({
      content: contentString,
    });

    google.maps.event.addListener(marker, "click", function () {
      infowindow.open(map, marker);
    });
  });
  return (
    <>
      <div className="relative w-full rounded h-350-px">
        <div className="rounded h-full" ref={mapRef} style={{ borderRadius: "30px" }} />
      </div>
    </>
  );
}

function mapPropsAreEqual(prevMap, nextMap) {
  return prevMap.location.lat === nextMap.location.lat
    && prevMap.location.long === nextMap.location.long;
}

// export default React.memo(MapExample);
export const MemoizedMap = React.memo(MapExample, mapPropsAreEqual);
