import React from "react";
import Select from "react-select";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";
import NetworkChart from "../../components/Cards/NetworkChart";

export default function NetworkGraphView() {
  const color = "light";
  const [value, setValue] = React.useState([]);

  const myData = React.useMemo(() => {
    const gData = {
      nodes: [...Array(30).keys()].map(i => ({ id: i })),
      links: [...Array(30).keys()]
      .filter(id => id)
      .map(id => ({
        ['source']: id,
        ['target']: Math.round(Math.random() * (id-1))
      }))
    };
    // console.log("gData prev: ", gData);

    // cross-link node objects
    gData.links.forEach(link => {
      const a = gData.nodes[link.source];
      const b = gData.nodes[link.target];
      !a.neighbors && (a.neighbors = []);
      !b.neighbors && (b.neighbors = []);
      a.neighbors.push(b);
      b.neighbors.push(a);

      !a.links && (a.links = []);
      !b.links && (b.links = []);
      a.links.push(link);
      b.links.push(link);
    });

    // console.log("gData final: ", gData);
    return gData;
  }, []);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "12px",
    }),
  };

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  return (
    <>
      <div className="networkGraph flex flex-wrap">
        <div className="w-full xl:w-12/12 lg:w-12/12 md:w-12/12 sm:w-12/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words mb-6 rounded">
            <div
              className={
                "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg " +
                (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
              }
            >
              <div className="rounded-t mb-0 px-4 py-4 border-0">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full px-4 max-w-full flex-grow flex-1 ">
                    <div className="px-4 mr-4" style={{ paddingLeft: "0" }}>
                      <h4 className="borderDefault summaryHeading py-2 font-bold text-base">
                        Nodes of Interest
                      </h4>
                    </div>
                    <p class="text-xs mt-2">
                      These settings will be used as default for graph
                      functionality where applicable.
                    </p>
                    <div className="w-full mb-8 mt-6">
                      <Form class="inquireForm w-full">
                        <div className="lg:w-12/12 md:w-12/12 sm:w-12/12 mr-4 flex flex-wrap">
                            <div className="searchInputTables mt-4 mr-2" style={{width:"250px"}}>
                              <div className="fieldInquire relative w-full mb-3">
                                <label className="label block font-semibold text-black-600 text-xs mb-2">
                                  Properties
                                </label>
                                <Select
                                  name="properties"
                                  className="input_Fields"
                                  style={{ all: "unset" }}
                                  placeholder="Properties"
                                  options={options}
                                  
                                  menuPortalTarget={document.body}
                                  styles={customStyles}
                                />
                            </div>
                          </div>
                            <div className="searchInputTables mt-4 mr-2" style={{width:"400px"}}>
                              <div className="fieldInquire relative w-full mb-3">
                                <label className="label block font-semibold text-black-600 text-xs mb-2">
                                  Date Updated
                                </label>
                                <DatePicker
                                  portalId="root-portal"
                                  name="updateDate"
                                  maxDate={addDays(new Date(), 0)}
                                  className="amlInquireFields cursor-pointer input-field border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-xs shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  style={{ fontSize: "0.75rem", height:"41px" }}
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                />
                            </div>
                          </div>
                          <div className="searchInputTables mt-4 mr-2" style={{width:"150px"}}>
                              <div className="fieldInquire relative w-full mb-3">
                              <label className="label block font-semibold text-black-600 text-xs mb-2">
                                Min Cluster Size
                              </label>
                              <Input
                                type="number"
                                name="minCluster"
                                className="amlInquireFields input_Fields border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                style={{ fontSize: "0.75rem" }}
                              />
                            </div>
                          </div>
                          <div className="searchInputTables mt-4 mr-2" style={{width:"150px"}}>
                              <div className="fieldInquire relative w-full mb-3">
                              <label className="label block font-semibold text-black-600 text-xs mb-2">
                                Max Cluster Size
                              </label>
                              <Input
                                type="number"
                                name="maxCluster"
                                className="amlInquireFields input_Fields border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                style={{ fontSize: "0.75rem" }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="lg:w-12/12 md:w-12/12 sm:w-12/12 mr-4 flex flex-wrap">
                            <div className="searchInputTables mt-4 mr-2" style={{width:"250px"}}>
                              <div className="fieldInquire relative w-full mb-3">
                                <label className="label block font-semibold text-black-600 text-xs mb-2">
                                  Type
                                </label>
                                <Select
                                  name="type"
                                  className="input_Fields"
                                  style={{ all: "unset" }}
                                  placeholder="Select Type"
                                  options={options}                                          
                                  menuPortalTarget={document.body}
                                  styles={customStyles}
                                />
                            </div>
                          </div>
                            <div className="searchInputTables mt-4" style={{width:"400px"}}>
                              <div className="fieldInquire relative w-full mb-3">
                                <label className="label block font-semibold text-black-600 text-xs mb-2">
                                  Search
                                </label>
                                <Input
                                  type="text"
                                  name="search"
                                  className="amlInquireFields input_Fields border-0 px-3 py-3 placeholder-blueGray-300 text-black-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  style={{ fontSize: "12px" }}
                                  placeholder="Search"
                                />
                            </div>
                          </div>
                          
                        </div>
                        <div className="searchButton mb-2 mt-2">
                              <button
                                type="button"
                                className="py-2 px-12 rounded-lg font-semibold text-white-400 buttonPink mb-2 text-xs"
                              >
                                Search
                              </button>
                            </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={"networkanalysisGraph relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white"}>
              <div className="rounded-t mb-0 px-4 py-4 border-0">
                {/* <NetworkChart key="network-0" nodeColor="blue" myData={myData} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
