import axios from "axios";
import address from "../Address";


export function viewall(category) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        if (category === "all") {
            return axios.get(address + "/casemanagement/viewall", { headers: {"Authorization" : `Bearer ${accessToken}`} });
        } else {
            return axios.get(address + "/casemanagement/viewall/" + category, { headers: {"Authorization" : `Bearer ${accessToken}`} });
        }
    } else {
        return false;
    }
}

export function viewallprocessed(category) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        if (category === "all") {
            return axios.get(address + "/casemanagement/viewall_processed", { headers: {"Authorization" : `Bearer ${accessToken}`} });
        } else {
            return axios.get(address + "/casemanagement/viewall_processed/" + category, { headers: {"Authorization" : `Bearer ${accessToken}`} });
        }
    } else {
        return false;
    }
}

export function viewsingleright(id) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.get(address + "/casemanagement/showrightdetail/" + id, { headers: {"Authorization" : `Bearer ${accessToken}`} });
    } else {
        return false;
    }
}

export function viewdetail(id) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.get(address + "/casemanagement/showdetail/" + id, { headers: {"Authorization" : `Bearer ${accessToken}`} });
    } else {
        return false;
    }
}

export function getevents(id) {
    return axios.get(address + "/kyc/getevents/" + id, { headers: { "Content-Type": "multipart/form-data", "Accept": "*/*", "Access-Control-Request-Private-Network": true } });
    // if (accessToken) {
    //     return axios.get(address + "/kyc/getevents/" + id, { headers: {"Authorization" : `Bearer ${accessToken}`} });
    // } else {
    //     return false;
    // }
}

export function saveassigned(formdata) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.post(address + "/casemanagement/saveassigned", formdata, { headers: {"Authorization" : `Bearer ${accessToken}`} });
    } else {
        return false;
    }
}

export function updatestatus(formdata) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.post(address + "/casemanagement/updatestatus", formdata, { headers: {"Authorization" : `Bearer ${accessToken}`} });
    } else {
        return false;
    }
}

export function revisesection(formdata) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.post(address + "/casemanagement/revisesection", formdata, { headers: {"Authorization" : `Bearer ${accessToken}`} });
    } else {
        return false;
    }
}