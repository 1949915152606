import React, { useState,useContext} from "react";
import { Link,useHistory   } from "react-router-dom";
import { getreviewinfo, calculatechecks } from "services/kyc/KycScreens";
import {KycMobContext} from "../../layouts/KYCMob"
import ReactLoading from "react-loading";

import backButtonImg from " ../../assets/img/kycmobile/backBtn.png";
import closeButtonImg from " ../../assets/img/kycmobile/cross_white.png";
import footerImg from " ../../assets/img/kycmobile/footer_trademark.png";
import edit from " ../../assets/img/kycmobile/edit.png";
import Moment from "moment";

export default function Screen25() {
  const {data} = useContext(KycMobContext)
  const [dataLoaded, setDataLoaded] = React.useState(true);
  const history = useHistory();
  const url = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
  const [basicInfo, setBasicInfo] = useState(null);
  const [idVerif, setIdVerif] = useState(null);
  const [addressVerif, setAddressVerif] = useState(null);
  const [walletETHVerif, setWalletETHVerif] = useState(null);
  const [walletADAVerif, setWalletADAVerif] = useState(null);
  const [disable, setDisable] = React.useState(false);

  const storage = window.localStorage;
  const [screenStates, setScreenStates] = React.useState(JSON.parse(storage.getItem("screenmeta")));

  React.useEffect(() => {
    if (screenStates) {
      if (!screenStates.Screen25) {
        const redirecturl = "/verify/o/"+url;
        history.push(redirecturl);
        window.location.reload(false);
      }
    } else {
      const redirecturl = "/verify/o/"+url;
      history.push(redirecturl);
      window.location.reload(false);
    }
  }, [screenStates]);
  
  React.useEffect(()=>{
    if (data) {
      if (data.screen_26 === 0){
        history.push("/verify/r/"+url);
        window.location.reload(false);
      }
    }
  },[data]);

  React.useEffect(() => {
    getreviewinfo(url).then((response) => {
      // console.log("response: ", response);
      if (response.data.success) {
        setBasicInfo(response.data.basicinfo);
        setIdVerif(response.data.idverif);
        setAddressVerif(response.data.addressverif);
        setWalletETHVerif(response.data.wallet_ETH);
        setWalletADAVerif(response.data.wallet_ADA);
      }
    }).catch((error) => {
      console.log("error: ", error);
    })
  }, [])

  const handleCross = () => {
    var ua = window.navigator.userAgent;

    if (ua.indexOf("iPhone") > 0) {
      window.close();
    }
    window.close();
  };

  const checks_calculate = () => {
    setDataLoaded(false);
    setDisable(true);
    calculatechecks(url).then((response) => {
      // console.log("response: ", response);
      if (response.data.success) {
        setDataLoaded(true);
        let temp = screenStates;
        temp.Screen26 = true;
        storage.setItem("screenmeta", JSON.stringify(temp));
        history.push("/verify/q/"+url);
        window.location.reload(false);
      }
    }).catch((error) => {
      console.log("error: ", error);
    });
  }

  return (
    <>
      <div className="screenTwentyFive h-full"  style={{fontFamily: data ? data.font_family : "Gilroy"}}>
        <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
          <div className="backArrow">
            <Link to={"/verify/k/"+url}>
              <button>
                <img src={backButtonImg} alt="backArrow" className="" />
              </button>
            </Link>
          </div>
          <div className="progressBar w-full py-3 px-12">
            <div className="relative lg:w-8/12 mx-auto">
              <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                <div
                  style={{ width: "97%",backgroundColor:data ? data.progressbar_color:"#a10b1d" }}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"
                ></div>
              </div>
            </div>
          </div>
          <div className="crossArrow">
            <button onClick={handleCross}>
              <img src={closeButtonImg} alt="crossArrow" className="" />
            </button>
          </div>
        </div>

        {!dataLoaded &&
          <>
            <ReactLoading className="margin-auto mt-3 mb-3 absolute" type={"bars"} style={{ zIndex: "100000", top: "30%", left: "39%", width: "22%", height: "22%", fill: "#a10b1d" }} />
            <div id="overlay" className="fixed w-full h-full top-0 left-0 right-0 bottom-0 cursor-pointer" style={{ backgroundColor: "rgba(0,0,0,0.5)", zIndex: "10000" }}></div>
          </> 
        }

        <div className="mainContent" >
          <div className="mainText">
            <h1>Review your Information</h1>
            <p className="text-center" style={{ color: "#a8a8a8" }}>If all good! Please click submit for verification.</p>
          </div>
          <div className="informationSection">
            <div className="block mb-2">
              <div className="headingText">
                <h5>Questionnaire</h5>
              </div>
              {basicInfo && 
                <>
                  <div className="fields">
                    <p className="title">Email</p>
                    <p className="value" id="email">{(basicInfo.email) ? basicInfo.email : 'N/A'}</p>
                  </div>
                  <div className="fields">
                    <p className="title">Full Name</p>
                    <p className="value" id="firstname">{(basicInfo.first_name) ? basicInfo.first_name : 'N/A'} {(basicInfo.last_name) ? basicInfo.last_name : 'N/A'}</p>
                  </div>
                  <div className="fields">
                    <p className="title">Date of Birth</p>
                    <p className="value" id="dob">{(basicInfo.dob) ? 
                      (Moment(basicInfo.dob, "DD/MM/YYYY").format("MM-DD-YYYY") === "Invalid date")
                        ? Moment(basicInfo.dob).format("MM-DD-YYYY")
                        : Moment(basicInfo.dob, "DD/MM/YYYY").format("MM-DD-YYYY")
                      : 'N/A'}
                    </p>
                  </div>
                  <div className="fields">
                    <p className="title">Nationality</p>
                    <p className="value" id="nationality_0">{(basicInfo.nationality) ? basicInfo.nationality : 'N/A'}</p>
                  </div>
                </>
              }
            </div>

            <div className="block mb-2">
              <div className="headingText">
                <h5>Identity document</h5>
              </div>
              {idVerif && 
                <>
                  <div className="fields" style={{wordWrap: "break-word" }} >
                    <p className="title">ID Card No</p>
                    <p className="value" id="idno">{(idVerif.id_nnum) ? idVerif.id_nnum : 'N/A'}</p>
                  </div>
                  <div className="fields">
                    <p className="title">Issuance Country</p>
                    <p className="value" id="nationality_1">{(idVerif.nationality) ? idVerif.nationality : 'N/A'}</p>
                  </div>
                </>
              }
            </div>

            <div className="block mb-2">
              <div className="headingText">
                <h5>Proof of residence</h5>
              </div>
              {addressVerif && 
                <>
                  <div className="fields">
                    <p className="title">Country</p>
                    <p className="value" id="country">{(addressVerif.country) ? addressVerif.country : 'N/A'}</p>
                  </div>
                  <div className="fields">
                    <p className="title">City</p>
                    <p className="value" id="city">{(addressVerif.city) ? addressVerif.city : 'N/A'}</p>
                  </div>
                  <div className="fields">
                    <p className="title">Postal Code</p>
                    <p className="value" id="postal">{(addressVerif.postal) ? addressVerif.postal : 'N/A'}</p>
                  </div>
                  <div className="fields">
                    <p className="title">Address</p>
                    <p className="value" id="address">{(addressVerif.address) ? addressVerif.address : 'N/A'}</p>
                  </div>
                </>
              }
            </div>

            <div className="block">
              <div className="headingText">
                <h5>Wallet Information</h5>
              </div>
              {walletETHVerif && 
                <>
                  {/* <div className="fields">
                    <p className="title">Network</p>
                    <p className="value" id="country">Ethereum</p>
                  </div> */}
                  <div className="fields">
                    <p className="title">Address</p>
                    <p className="value" id="city" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{(walletETHVerif.wallet_address) ? walletETHVerif.wallet_address : 'N/A'}</p>
                  </div>
                  {/* <div className="fields">
                    <p className="title">TGE Contribution</p>
                    <p className="value" id="postal">{walletETHVerif.tge_contribution}</p>
                  </div> */}
                  {/* <div className="fields">
                    <p className="title">AGIX Balance</p>
                    <p className="value" id="postal">{walletETHVerif.agix_balance}</p>
                  </div> */}
                </>
              }
              {/* {walletADAVerif && 
                <>
                  <div className="fields">
                    <p className="title">Network</p>
                    <p className="value" id="country">Cardano</p>
                  </div>
                  <div className="fields">
                    <p className="title">Address</p>
                    <p className="value" id="city" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{(walletADAVerif.wallet_address) ? walletADAVerif.wallet_address : 'N/A'}</p>
                  </div>
                  <div className="fields">
                    <p className="title">AGIX Eligible</p>
                    <p className="value" id="postal">{(walletADAVerif.agix_eligible == 1) ? 'Yes' : 'No'}</p>
                  </div>
                  <div className="fields">
                    <p className="title">SDAO Eligible</p>
                    <p className="value" id="postal">{(walletADAVerif.sdao_eligible == 1) ? 'Yes' : 'No'}</p>
                  </div>
                </>
              } */}
            </div>
          </div>
        </div>

        <div className="buttonAction">
          {/* <Link to={"/verify/q/"+url}> */}
          <button onClick={checks_calculate} disabled={disable} style={{backgroundColor:data?data.button_color:"#a10b1d"}} className="action">Submit</button>
          {/* </Link> */}
        </div>

        <div className="footer">
          <img className="footerImg" src={footerImg} alt="footer" />
        </div>
      </div>
    </>
  );
}
  
