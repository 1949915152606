import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
// import FooterAdmin from "components/Footers/FooterAdmin.js";

// asset
// import BackgroundImg from "../assets/img/dashboardBg.png";
import BackgroundImg from "../assets/img/bglo.svg";
import sidebarIcon from "../assets/img/icons/sidebarIcon.png";

// views
import Settings from "views/admin/Settings.js";
import AuditLog from "views/admin/AuditLog";
import Customization from "views/admin/Customization";
import Team from "views/admin/Team";
import TeamAdd from "views/admin/TeamAdd";
import Profile from "views/admin/Profile";
import Kyc from "views/admin/Kyc";
import AcceptableIds from "views/admin/AcceptableIds";
import AcceptedCountries from "views/admin/AcceptedCountries";
import TeamEdit from "views/admin/TeamEdit";

export default function Setting() {
  const [sidebarOpen, setSidebarOpen] = React.useState(true);
  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} />
      {sidebarOpen ? (
        <button
          className=" sidebarOpenButton "
          onClick={() => setSidebarOpen(!sidebarOpen)}
        >
          {sidebarOpen && <img src={sidebarIcon} />}
        </button>
      ) : (
        <button
          className=" sidebarCloseButton "
          onClick={() => setSidebarOpen(!sidebarOpen)}
        >
          {!sidebarOpen && <img src={sidebarIcon} className="sidebarRotated" />}
        </button>
      )}
      <div 
        className={"relative bg-no-repeat bg-cover " + (sidebarOpen ? "md:ml-52" : "md:ml-18")}
        style={{
          backgroundImage: `url(${BackgroundImg})`,
          backgroundPosition: 'center', 
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: "100%",
          minHeight: "100vh",
        }}
      >
        <AdminNavbar />
        {/* Header */}
        <HeaderStats />
        <div className=" md:px-4 mx-auto w-full -m-24">
          <Switch>
            <Route path="/settings" exact component={Settings} />
            <Route path="/settings/profile" exact component={Profile} />
            <Route path="/settings/team" exact component={Team} />
            <Route path="/settings/team/add" exact component={TeamAdd} />
            <Route path="/settings/team/edit" exact component={TeamEdit} />
            <Route path="/settings/customization" exact component={Customization} />
            <Route path="/settings/audit" exact component={AuditLog} />
            <Route path="/settings/kyc" exact component={Kyc} />
            <Route path="/settings/acceptedcountries" exact component={AcceptedCountries} />
            <Route path="/settings/acceptableids" exact component={AcceptableIds} />

            {/* <Redirect from="/settings" to="/settings" /> */}
          </Switch>
          {/* <FooterAdmin /> */}
        </div>
      </div>
    </>
  );
}
