import axios from "axios";
import address from "../Address";

export function getacceptableid() {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.get(address + "/setting/get_acceptableid", { headers: { "Content-Type": "multipart/form-data" } });
    } else {
        return false;
    }
}

export function getacceptableidbycountry(country) {
    return axios.get(address + "/setting/get_acceptableidbycountry/" + country, { headers: { "Content-Type": "multipart/form-data" } });
}

export function saveacceptableid(formdata) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.post(address + "/setting/save_acceptableid", formdata, { headers: { "Authorization" : `Bearer ${accessToken}`, "Content-Type": "multipart/form-data" } });
    } else {
        return false;
    }
}

export function deleteacceptableid(id) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.get(address + "/setting/delete_acceptableid/" + id, { headers: { "Authorization" : `Bearer ${accessToken}`, "Content-Type": "multipart/form-data" } });
    } else {
        return false;
    }
}

export function getacceptablecountries() {
    return axios.get(address + "/setting/get_acceptablecountries", { headers: { "Content-Type": "multipart/form-data" } });
}

export function saveacceptablecountries(formdata) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.post(address + "/setting/save_acceptablecountries", formdata, { headers: { "Authorization" : `Bearer ${accessToken}`, "Content-Type": "multipart/form-data" } });
    } else {
        return false;
    }
}

export function deleteacceptablecountries(country) {
    const storage = window.localStorage;
    const accessToken = storage.getItem("access_token");

    if (accessToken) {
        return axios.get(address + "/setting/delete_acceptablecountry/" + country, { headers: { "Authorization" : `Bearer ${accessToken}`, "Content-Type": "multipart/form-data" } });
    } else {
        return false;
    }
}