import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { saveevent } from "services/kyc/KycScreens";
import { KycMobContext } from "../../../layouts/KYCBasic";

import backButtonImg from " ../../assets/img/kycmobile/backBtn.png";
import closeButtonImg from " ../../assets/img/kycmobile/cross_white.png";
import footerImg from " ../../assets/img/kycmobile/footer_trademark.png";
import map from " ../../assets/img/kycmobile/screen26.svg";

export default function Screen26() {
  const { data } = useContext(KycMobContext);
  const history = useHistory();
  const url = window.location.href.substring(
    window.location.href.lastIndexOf("/") + 1
  );
  const [disable, setDisable] = useState(false);
  const [finishClicked, setFinishClicked] = useState(false);

  const storage = window.localStorage;
  const [screenStates, setScreenStates] = React.useState(JSON.parse(storage.getItem("screenmeta")));

  React.useEffect(() => {
    if (screenStates) {
      if (!screenStates.Screen26) {
        const redirecturl = "/kycreturning/addressverif/a/"+url;
        history.push(redirecturl);
        window.location.reload();
      }
    } else {
      const redirecturl = "/kycreturning/addressverif/a/"+url;
      history.push(redirecturl);
      window.location.reload();
    }
  }, [screenStates]);

  const endProcess = () => {
    setDisable(true);
    let formdata = {
      inq_id: url,
      event_desc: "Inquiry re-submitted",
      passed: 1,
    };
    saveevent(formdata)
      .then((response) => {
        if (response.data.success) {
          setFinishClicked(true);

          let timer = setTimeout(() => {
            window.opener = null;
            window.open("", "_self");
            window.close();
  
            var ua = window.navigator.userAgent;
  
            if (ua.indexOf("iPhone") > 0) {
              window.close();
            }
          }, 5000);
          return () => clearTimeout(timer);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  const handleCross = () => {
    var ua = window.navigator.userAgent;

    if (ua.indexOf("iPhone") > 0) {
      window.close();
    }
    window.close();
  };

  return (
    <>
      <div
        className="screenTwentySix h-full"
        style={{ fontFamily: data ? data.font_family : "Gilroy" }}
      >
        <div className="w-full flex flex-row justify-between items-center p-4 pt-4">
          <div className="backArrow">
            <Link to={"#"}>
              <button>
                <img src={backButtonImg} alt="backArrow" className="" />
              </button>
            </Link>
          </div>
          <div className="progressBar w-full py-3 px-12">
            <div className="relative lg:w-8/12 mx-auto">
              <div className="overflow-hidden h-2 text-xs flex rounded progressBarBgGrey">
                <div
                  style={{
                    width: "100%",
                    backgroundColor: data ? data.progressbar_color : "#a10b1d",
                  }}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center "
                ></div>
              </div>
            </div>
          </div>
          <div className="crossArrow">
            <button onClick={handleCross}>
              <img src={closeButtonImg} alt="crossArrow" className="" />
            </button>
          </div>
        </div>

        <div className="mainContent">
          <div className="mapImgBackground">
            <div className="mapImage">
              <img className="map" src={map} alt="" />
            </div>
            <div className="headingText">
              <h2>Thank you for letting us know you.</h2>
              <p>
                We will review your information and get back to you shortly.
              </p>
            </div>
          </div>
        </div>

        <div className="buttonAction">
          {finishClicked && 
            <p className="text-center text-sm" style={{ color: "#bcbcbc", letterSpacing: "-0.03em" }}>You may close this window now.</p>
          }
          {!finishClicked && 
            <button
              style={{ backgroundColor: data ? data.button_color : "#a10b1d" }}
              onClick={endProcess}
              className={"action"}
              disabled={disable}
              id="finishBtn"
            >
              Finish
            </button>
          }
        </div>

        <div className="footer">
          <img className="footerImg" src={footerImg} alt="footer" />
        </div>
      </div>
    </>
  );
}